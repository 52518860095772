import { Injectable } from "@angular/core";
import { AlertaDocumentoRepository } from "../bandeja-alerta-documento/domain/alerta_documento_repository";
import { HttpClient } from "@angular/common/http";
import { StorageService } from "./storage.service";
import { requestAlertaDocumento } from "../bandeja-alerta-documento/domain/request/alerta_documento.request";
import { AlertaDocumentoResponse } from "../bandeja-alerta-documento/domain/response/alerta_documento.response";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";
import { GestionSalasRepository } from "../matenimiento_salas/domain/mante_salas_repository";
import { GestionSalasResponse, GuardaSalaReservadaResponse, ListaClienteSalaResponse, ListaSalasReservadasResponse } from "../matenimiento_salas/domain/response/salas_response";
import { gestionSalasRequest, listaClienteSalaRequest, reservaSalaCorreoRequest, reservaSalaRequest } from "../matenimiento_salas/domain/request/salas_request";
import { ActualizarSalaResponse, AgregarSalaResponse, ListarDatosSalaResponse, ListarSalaResponse, anularSalaResponse, eliminarArticuloResponse, guardarArticulosResponse, listarArticulosResponse } from "../administracion_salas/domain/response/mantSalas-response";
import { AgregarSalaRequest, GuardarArticulosRequest, ListarDatosSalaRequest, actualizarSalaRequest, anularSalaRequest, eliminarArticuloRequest, listarArticulosRequest, listarSalaRequest } from "../administracion_salas/domain/request/mantSalas-request";

@Injectable()
export class GestionSalasService extends GestionSalasRepository{

  constructor(private readonly http:HttpClient,
    private readonly storage: StorageService

    ){
    super();
  }

  listaGestionSalas(prolrequest: gestionSalasRequest): Observable<GestionSalasResponse>
  {
      return this.http.post<GestionSalasResponse>(`${environment.PATH_API}/administracion/gestionSalas`,prolrequest);
  }

  listaSalasReservadas(prolrequest: gestionSalasRequest): Observable<ListaSalasReservadasResponse>
  {
      return this.http.post<ListaSalasReservadasResponse>(`${environment.PATH_API}/administracion/listaSalasReservadas`,prolrequest);
  }

  guardaSalaReservada(fd:reservaSalaRequest):Observable<GuardaSalaReservadaResponse>
  {
    return this.http.post<GuardaSalaReservadaResponse>(`${environment.PATH_API}/administracion/reservaSala`,fd);
  }

  enviaCorreoSalaReservada(fd:reservaSalaCorreoRequest){
    return this.http.post(`${environment.PATH_API}/autenticacion/PruebaCorreo`,fd);
  }
  
  listaSalaCliente(prolrequest: listaClienteSalaRequest): Observable<ListaClienteSalaResponse>
  {
      return this.http.post<ListaClienteSalaResponse>(`${environment.PATH_API}/soporte/ListaParticipantesSala`,prolrequest);
  }
  agregarSala(prolrequest: AgregarSalaRequest): Observable<AgregarSalaResponse>
  {
      return this.http.post<AgregarSalaResponse>(`${environment.PATH_API}/administracion/AgregarSala`,prolrequest);
  }
  listarSala(prolrequest: ListarDatosSalaRequest): Observable<ListarDatosSalaResponse>
  {
      return this.http.post<ListarDatosSalaResponse>(`${environment.PATH_API}/administracion/ListarDatosSala`,prolrequest);
  }
  anularSala(prolrequest: anularSalaRequest): Observable<anularSalaResponse>
  {
      return this.http.post<anularSalaResponse>(`${environment.PATH_API}/administracion/AnularSala`,prolrequest);
  }
  actualizarSala(prolrequest: actualizarSalaRequest): Observable<ActualizarSalaResponse>
  {
      return this.http.post<ActualizarSalaResponse>(`${environment.PATH_API}/administracion/ActualizarSala`,prolrequest);
  }
  listarNombreSala(prolrequest: listarSalaRequest): Observable<ListarSalaResponse>
  {
      return this.http.post<ListarSalaResponse>(`${environment.PATH_API}/administracion/ListarSalas`,prolrequest);
  }
  guardarArticulos(prolrequest: GuardarArticulosRequest): Observable<guardarArticulosResponse>
  {
      return this.http.post<guardarArticulosResponse>(`${environment.PATH_API}/administracion/GuardarArticulos`,prolrequest);
  }
  listarArticulos(prolrequest: listarArticulosRequest): Observable<listarArticulosResponse>
  {
      return this.http.post<listarArticulosResponse>(`${environment.PATH_API}/administracion/ListarArticulos`,prolrequest);
  }
  eliminarArticulo(prolrequest: eliminarArticuloRequest): Observable<eliminarArticuloResponse>
  {
      return this.http.post<eliminarArticuloResponse>(`${environment.PATH_API}/administracion/EliminarArticulo`,prolrequest);
  }
}
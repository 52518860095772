<div class="todo">
    <mat-toolbar  style="height: 33px;" fxLayout  >
      <h3 class="title">Ingreso Producto</h3>
  </mat-toolbar>
    
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutAlign="center" style="margin-top: 0px;">
  
    <div fxFlex="100" style=" padding: 0px;">
         <mat-card appearance="outlined" >
         
              <mat-card-content >
                <form [formGroup] = "group" > 
                          
                    <div fxLayout = "row" fxLayoutGap="20px" >
                      
                        <mat-card class="example-card">
                         
                          <mat-card-content>
                            <div  fxLayoutGap="20px"  class="labels">
                                    
                              <mat-form-field class="list"  appearance="outline" class="example-full-width-alterno3" >
                                <input matInput type="text" placeholder="Roles" formControlName="descripcion" >
                              </mat-form-field> 
                              
                              <mat-form-field class="list"  appearance="outline" class="example-full-width-alterno3" >
                                <input matInput type="text" placeholder="Roles" formControlName="descripcion" >
                              </mat-form-field> 
  
                            </div>
  
                          </mat-card-content>
                        </mat-card>
                     </div>
                </form>
                <div   fxLayoutGap="20px"  color="primary"  class="botones" >
                 
                   
                    <button mat-raised-button  class="botones"
                    matTooltip="Nuevo registro"
                    
                    type="button">Buscar
                    <mat-icon>description</mat-icon>
                </button>



  
                </div>
          
              </mat-card-content>
  
          </mat-card>
  
          
          <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px">
        
            <div fxFlex="50"  style="background-color:  rgb(219, 217, 217); padding: 1px;">
             
                
                
  
            
            </div>
          </div>
  
  </div>
  
  </div>
  
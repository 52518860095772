import { Component } from '@angular/core';

@Component({
  selector: 'app-page-gestionvisitas',
  templateUrl: './page-gestionvisitas.component.html',
  styleUrls: ['./page-gestionvisitas.component.css']
})
export class PageGestionvisitasComponent {

}

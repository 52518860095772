import { Observable } from "rxjs";
import { ListaEntregaNave, listaNaves, ResumenEntregaNave, TransaccionConsultaNave, EnvioCorreoETAETDActualizado, AsignarEventoPorOv, ConsultaDesgloseViajeRequest, MandarCorreoTodoDatos, ConsultaAvisoLlegadaequest, ReporteTeusDetalladoRequest } from "../operacion/operacion/consulta-nave/domain/consulta-naves-request";
import { ConsultaAvisoLlegadaResponse, ConsultaDesgloseViajeResponse, consultaEntregaNaveResponse, consultaNaveResponse, consultaResumenNaveResponse, GeneralResponse, ReporteTeusDetalladoResponse, ResponseEnvioCorreo } from "../operacion/operacion/consulta-nave/domain/consulta-naves-response";
// import { ListaEntregaNave, listaNaves, ResumenEntregaNave, TransaccionConsultaNave, EnvioCorreoETAETDActualizado, AsignarEventoPorOv, MandarCorreoTodoDatos } from "../operacion/operacion/consulta-nave/domain/consulta-naves-request";
// import { consultaEntregaNaveResponse, consultaNaveResponse, consultaResumenNaveResponse, GeneralResponse, ResponseEnvioCorreo } from "../operacion/operacion/consulta-nave/domain/consulta-naves-response";
import { listaFletacionesRequest, listaVersioanacionesRequest } from "../operacion/reportes-operacion/domain/reportes-operaciones.request";
import { listaDetalleFletacionesResponse, listaFletacionesResponse, listaVersionacionesResponse, listaDetalleVersionacionesResponse } from "../operacion/reportes-operacion/domain/reportes-operaciones.response";
import { DireccionamientoCargaRequest, InsertVEN_DOCVSI_UnRegANDVEN_DDOVSI_UnRegRequest, ListaContenedorPorOVRequest, ListadoNaveViajeDetalladoRequest, ListaNaveViajeRequest, listarTodosOVRequest, opeActualizarDepositoEnDireccionamientoRequest, opeInsertServicesTOPRequest, opeUpdateHbls, opeUpdateNaveViajeRequest, paActualizaDocumentoVentas2Request, paObtieneOperacionesLogisticasPorPreFacturarRequest, RequestImportarEnviosIngresoCargonet, RequestImportarReporteAduanet, RequestImportarReporteHapagMaster, RequestImportarReporteMaerksMaster, RequestImportarReporteSolicitudMSC, RequestListarAvisosSobreestadia, RequestListarVitacoraNave, RequestReporteAduanet, RequestReporteEnviosIngresoCargonet, RequestReporteHapagMaster, RequestReporteMaerksMaster, RequestReporteSolicitudMSC, TransaccionesMontoSobreestadiaRequest, TransaccionFechaDevolucionRequest, UpdateFechaDireccionamientoRequest } from "../operacion/operacion/control-ovs/domain/control-ovs.request";
import { DireccionamientoCargaResponse, ListaContenedorPorOVResponse, ListadoNaveViajeDetalladoResponse, ListaNaveViajeResponse, listarTodosOVResponse, paObtieneOperacionesLogisticasPorPreFacturarResponse, ResponseImportarEnviosIngresoCargonet, ResponseImportarReporteAduanet, ResponseImportarReporteHapagMaster, ResponseImportarReporteMaerksMaster, ResponseImportarReporteMSC, ResponseListarAvisosSobreestadia, ResponseListarVitacoraNave, ResponseReporteAduanet, ResponseReporteEnviosIngresoCargonet, ResponseReporteHapagMaster, ResponseReporteMaerksMaster, ResponseReporteSolicitudMSC, TransaccionesMontoSobreestadiaResponse } from "../operacion/operacion/control-ovs/domain/control-ovs.response";
import { D_TablaRequest, ListarTarifasProveedorRequest, TransaccionFlujoTarjaRequest, TransaccionTarifaProveedorRequest } from "../operacion/tarja/domain/tarja.request";
import { GeneralTransaccionResponse, ListarTarifasProveedorResponse } from "../operacion/tarja/domain/tarja.response";
import { ReporteCompOVRequest } from "../dashboard-comportamiento-ov/domain/request/dashboard-comportamiento-ov-request";
import { ReporteCompOVDetalleResponse, ReporteCompOVResumenResponse } from "../dashboard-comportamiento-ov/domain/response/dashboard-comportamiento-ov-response";
// import { listarTodosOVRequest, RequestImportarEnviosIngresoCargonet, RequestListarAvisosSobreestadia, RequestListarVitacoraNave, RequestReporteEnviosIngresoCargonet } from "../operacion/operacion/control-ovs/domain/control-ovs.request";
// import { listarTodosOVResponse, ResponseImportarEnviosIngresoCargonet, ResponseListarAvisosSobreestadia, ResponseListarVitacoraNave, ResponseReporteEnviosIngresoCargonet } from "../operacion/operacion/control-ovs/domain/control-ovs.response";
// import { listarTodosOVRequest, opeUpdateNaveViajeRequest, RequestImportarEnviosIngresoCargonet, RequestListarVitacoraNave, RequestReporteEnviosIngresoCargonet } from "../operacion/operacion/control-ovs/domain/control-ovs.request";
// import { listarTodosOVResponse, ResponseImportarEnviosIngresoCargonet, ResponseListarVitacoraNave, ResponseReporteEnviosIngresoCargonet } from "../operacion/operacion/control-ovs/domain/control-ovs.response";

export abstract class OperacionRepository {
    abstract listaNaves(fd: listaNaves): Observable<consultaNaveResponse>
    abstract listaEntregaNaves(fd: ListaEntregaNave): Observable<consultaEntregaNaveResponse>;
    abstract listaResumenNaves(fd: ResumenEntregaNave): Observable<consultaResumenNaveResponse>;
    // abstract listaNaveEntregada(fd: NaveEntregada):Observable<GeneralResponse>;
    // abstract listaFechaETAETD2(fd: FechaETAETD2): Observable<GeneralResponse>;
    abstract listaTransacionConsultaNave(fd: TransaccionConsultaNave): Observable<GeneralResponse>
    abstract EnviarCorreo(fd: EnvioCorreoETAETDActualizado): Observable<ResponseEnvioCorreo>;
    abstract AsignarEventoPorOv(fd: AsignarEventoPorOv): Observable<GeneralResponse>;
    abstract ConsultaDesglosePorViaje(fd: ConsultaDesgloseViajeRequest): Observable<ConsultaDesgloseViajeResponse>;

    //prueba
    abstract MandarCorreoTodoDatos(fd: EnvioCorreoETAETDActualizado): Observable<GeneralResponse>;
    abstract listaFletaciones(fd: listaFletacionesRequest): Observable<listaFletacionesResponse>
    abstract listarDetalleFletaciones(fd: listaFletacionesRequest): Observable<listaDetalleFletacionesResponse>
    abstract listaVersionaciones(fd: listaVersioanacionesRequest): Observable<listaVersionacionesResponse>
    abstract listarDetalleVersionaciones(fd: listaVersioanacionesRequest): Observable<listaDetalleVersionacionesResponse>
    abstract listarTodosOV(fd: listarTodosOVRequest): Observable<listarTodosOVResponse>
    abstract listarVitacoraNave(fd: RequestListarVitacoraNave): Observable<ResponseListarVitacoraNave>
    abstract reporteEnviosIngresoCargonet(fd: RequestReporteEnviosIngresoCargonet): Observable<ResponseReporteEnviosIngresoCargonet>
    abstract importarEnviosIngresoCargonet(fd: RequestImportarEnviosIngresoCargonet): Observable<ResponseImportarEnviosIngresoCargonet>
    abstract reporteAduanet(fd: RequestReporteAduanet): Observable<ResponseReporteAduanet>
    abstract importarReporteAduanet(fd: RequestImportarReporteAduanet): Observable<ResponseImportarReporteAduanet>
    abstract ListarAvisosSobreestadia(fd: RequestListarAvisosSobreestadia): Observable<ResponseListarAvisosSobreestadia>
    abstract opeUpdateNaveViaje(fd: opeUpdateNaveViajeRequest): Observable<GeneralResponse>
    abstract transaccionesMontoSobreestadia(fd: TransaccionesMontoSobreestadiaRequest): Observable<TransaccionesMontoSobreestadiaResponse>
    abstract opeUpdateHbls(fd: opeUpdateHbls): Observable<GeneralResponse>

    abstract reporteSolicitudMSC(fd: RequestReporteSolicitudMSC): Observable<ResponseReporteSolicitudMSC>
    abstract importarreporteSolicitudMSC(fd: RequestImportarReporteSolicitudMSC): Observable<ResponseImportarReporteMSC>
    abstract TransaccionFechaDevolucion(fd: TransaccionFechaDevolucionRequest): Observable<GeneralResponse>
    abstract DireccionamientoCarga(fd: DireccionamientoCargaRequest): Observable<DireccionamientoCargaResponse>
    abstract ListaNaveViaje(fd: ListaNaveViajeRequest): Observable<ListaNaveViajeResponse>
    abstract ListaContenedorPorOV(fd: ListaContenedorPorOVRequest): Observable<ListaContenedorPorOVResponse>

    abstract ListadoNaveViajeDetallado(fd: ListadoNaveViajeDetalladoRequest): Observable<ListadoNaveViajeDetalladoResponse>
    abstract UpdateFechaDireccionamiento(fd: UpdateFechaDireccionamientoRequest): Observable<GeneralResponse>
    abstract paActualizaDocumentoVentas2(fd: paActualizaDocumentoVentas2Request): Observable<GeneralResponse>

    // abstract paObtieneOperacionesLogisticasPorPreFacturar(fd: paObtieneOperacionesLogisticasPorPreFacturarRequest): Observable<paObtieneOperacionesLogisticasPorPreFacturarResponse>
    abstract ConsultaAvisoLlegada(fd: ConsultaAvisoLlegadaequest): Observable<ConsultaAvisoLlegadaResponse>

    //#region TARJA

    abstract ReporteTeusDetallado(fd: ReporteTeusDetalladoRequest): Observable<ReporteTeusDetalladoResponse>
    abstract TransaccionTarifaProveedor(request: TransaccionTarifaProveedorRequest): Observable<GeneralTransaccionResponse>
    abstract ListarTarifasProveedor(fd: ListarTarifasProveedorRequest): Observable<ListarTarifasProveedorResponse>
    abstract TransaccionFlujoTarja(fd: TransaccionFlujoTarjaRequest): Observable<GeneralTransaccionResponse>

    //#endregion

    //Daniel
    abstract Tabla(fd: D_TablaRequest): Observable<GeneralTransaccionResponse>
    
    abstract listaReporteComportamientoOV(fd: ReporteCompOVRequest): Observable<ReporteCompOVResumenResponse>
    abstract listaReporteComportamientoOVDetalle(fd: ReporteCompOVRequest): Observable<ReporteCompOVDetalleResponse>
    abstract paObtieneOperacionesLogisticasPorPreFacturar (fd: paObtieneOperacionesLogisticasPorPreFacturarRequest):Observable<paObtieneOperacionesLogisticasPorPreFacturarResponse>
    abstract InsertVEN_DOCVSI_UnRegANDVEN_DDOVSI_UnReg (fd:InsertVEN_DOCVSI_UnRegANDVEN_DDOVSI_UnRegRequest): Observable<GeneralResponse>
    abstract reporteHapagMaster(fd: RequestReporteHapagMaster): Observable<ResponseReporteHapagMaster>
    abstract importarDatosHapagMaster(fd: RequestImportarReporteHapagMaster): Observable<ResponseImportarReporteHapagMaster>
    abstract reporteMaerksMaster(fd: RequestReporteMaerksMaster): Observable<ResponseReporteMaerksMaster>
    abstract importarDatosMaerksMaster(fd: RequestImportarReporteMaerksMaster): Observable<ResponseImportarReporteMaerksMaster>

    abstract opeActualizarDepositoEnDireccionamiento (fd:opeActualizarDepositoEnDireccionamientoRequest):Observable<GeneralResponse>

    abstract opeInsertServicesTOP (fd:opeInsertServicesTOPRequest):Observable<GeneralTransaccionResponse>

}
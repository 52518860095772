<form fxLayout="column" fxLayoutAlign="space" class="controlclaros">
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-card-header class="cierra">
      <div class="cierra_caja1">
        <mat-card-title>CAMBIAR CONTRASEÑA</mat-card-title>
      </div>
      <div class="cierra_caja2">
        <button mat-icon-button (click)="cerrarVentana()">  
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <mat-card>
  
      <mat-card-content>
        <form [formGroup]="cambiarContrasenaForm" (ngSubmit)="cambiarContrasena()">
          <mat-form-field appearance="outline">
            <mat-label>Usuario</mat-label>
            <input matInput formControlName="usuario" type="text" required>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Contraseña Antigua</mat-label>
            <input matInput formControlName="antiguaContrasena" type="password" required [type]="hide ? 'password':'text' ">
            <i class="material-icons" matSuffix (click)="hide =!hide">{{hide ? 'visibility':'visibility_off'}}</i>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>Nueva Contraseña</mat-label>
            <input matInput formControlName="nuevaContrasena" type="password" required [type]="hide1 ? 'password':'text'" (input)="ingresaTexto()" [ngStyle]="borderColorV">
            <i class="material-icons" matSuffix *ngIf="muestra" [ngStyle]="color">{{valido ? 'done':'report_problem'}}</i>
            <i class="material-icons" matSuffix (click)="hide1 =!hide1">{{hide1 ? 'visibility':'visibility_off'}}</i>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>Confirmar Nueva Contraseña</mat-label>
            <input matInput formControlName="confirmarNuevaContrasena" type="password" required [type]="hide2 ? 'password':'text'" (input)="ingresaTexto()">
            <i class="material-icons" matSuffix *ngIf="muestra" [ngStyle]="color">{{valido ? 'done':'report_problem'}}</i>
            <i class="material-icons" matSuffix (click)="hide2 =!hide2">{{hide2 ? 'visibility':'visibility_off'}}</i>
          </mat-form-field>
  
          <button type="submit" mat-raised-button color="primary" >Cambiar Contraseña</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

</form>
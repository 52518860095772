<p>tableresponsive !</p>
<div class="example-header" fxLayout="row">
   
  </div>
  
  <div class="example-container mat-elevation-z8">
  
    <mat-table [dataSource]="dataSource" matSort>
  
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="id"> {{row.cnt}} </mat-cell>
      </ng-container>
  
      <!-- Progress Column -->
      <ng-container matColumnDef="progress">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Progress </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="progress"> {{row.progress}}% </mat-cell>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="name"> {{row.name}} </mat-cell>
      </ng-container>
  
      <!-- Color Column -->
      <ng-container matColumnDef="color">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Color </mat-header-cell>
        <mat-cell *matCellDef="let row" [style.color]="row.color" data-label="color"> {{row.color}} </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
  
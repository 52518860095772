import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderby'
})
export class OrderbyPipe implements PipeTransform {

  transform(array: any[], field: string, reverse: boolean = false): any[] {
    if (!Array.isArray(array) || !field) {
      return array;
    }

    array.sort((a, b) => {
      let aValue = a[field];
      let bValue = b[field];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return reverse ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
      } else {
        return reverse ? bValue - aValue : aValue - bValue;
      }
    });

    return array;
  }

}

import { s } from "@fullcalendar/core/internal-common";
import { CrudOrdenesVentaRequest } from "../../domain/request/comercial-orden-venta-request";

export class MantenimientoOrdenVentaValidacion {
    crudOrdenesVenta: CrudOrdenesVentaRequest;
    esProspecto: boolean;
    insertActualizarOVFlete = { items: [] };
    insertActualizarOVServicio = { items: [] };
    //listaFletes: any[] = []
    //listaServicio: any[] = []
    m_mensajeError: Set<string> = new Set();

    constructor(crudOrdenesVenta: CrudOrdenesVentaRequest, insertActualizarOVFlete: {items: any[]}, insertActualizarOVServicio = { items: [] }) {
        this.crudOrdenesVenta = crudOrdenesVenta;
        this.insertActualizarOVFlete = insertActualizarOVFlete;
        this.insertActualizarOVServicio = insertActualizarOVServicio;
    }

    public guardarCambios(esProspecto: boolean) {
        try {
            if (this.crudOrdenesVenta != null) {
                if (this.crudOrdenesVenta.psinCCOT_Tipo === 1 &&
                    (this.crudOrdenesVenta.pchrCONS_CodEST === "003" ||
                        this.crudOrdenesVenta.pchrCONS_CodEST === "004")) {
                    return;
                }
                if (this.crudOrdenesVenta.psinCCOT_Tipo === 2 &&
                    (this.crudOrdenesVenta.pchrCONS_CodEST === "003" ||
                        this.crudOrdenesVenta.pchrCONS_CodEST === "004" ||
                        this.crudOrdenesVenta.pchrCONS_CodEST === "005")) {
                    return;
                }

                //if ("Yes") { // "¿Está seguro de que desea guardar los cambios?"
                if (this.Guardar(esProspecto)) {
                    console.log("Se guardó correctamente")
                } else {
                }
                // } else {
                //     console.log( "Error al guardar")
                // }

            } else {
                console.log("Ha ocurrido un error al cerrar el formulario.")
            }
        } catch (error) {
            console.log("Error al intentar guardar: ")
        }

    }

    Guardar(EsProspecto: boolean): { isValid: boolean, errorMessage: string[] } {
        let _isCorrect: boolean = true;
        this.m_mensajeError = new Set();

        try {
            const { isValid, errorMessage } = this.validar(EsProspecto);
            if (!this.crudOrdenesVenta.pchrCONS_CodRGM || this.crudOrdenesVenta.pchrCONS_CodRGM == null) {
                _isCorrect = false;
                this.m_mensajeError.add("* Debe escoger un régimen");
            }

            if (!this.crudOrdenesVenta.pchrCONS_CodLNG || this.crudOrdenesVenta.pchrCONS_CodLNG == null) {
                _isCorrect = false;
                this.m_mensajeError.add("* Debe escoger una línea de negocio");
            }

            if (!isValid) {
                errorMessage.forEach(error => this.m_mensajeError.add(error));
                _isCorrect = false;
            }

            for (const itemFlete of this.insertActualizarOVFlete.items) {
                if (itemFlete.CCOT_Tipo === 1) {
                    if (itemFlete.CONS_CodEST === "003") {
                        this.m_mensajeError.add("La Cotización se encuentra en estado CONFIRMADA y no se guardará ningún cambio realizado.");
                        _isCorrect = false;
                    } else if (itemFlete.CONS_CodEST === "005") {
                        this.m_mensajeError.add("La Cotización se encuentra en estado ANULADA y no se guardará ningún cambio realizado.");
                        _isCorrect = false;
                    }
                }

                if (itemFlete.CCOT_Tipo === 2) {
                    if (itemFlete.CONS_CodEST === "004") {
                        this.m_mensajeError.add("La Orden de Venta se encuentra en estado CONCLUIDA y no se guardará ningún cambio realizado.");
                        _isCorrect = false;
                    } else if (itemFlete.CONS_CodEST === "005") {
                        this.m_mensajeError.add("La Orden de Venta se encuentra en estado ANULADA y no se guardará ningún cambio realizado.");
                        _isCorrect = false;
                    }
                }

                if (itemFlete.CCOT_Tipo === 2 && EsProspecto) {
                    this.m_mensajeError.add("Debe actualizar la información del Cliente para poder crearle una Orden de Venta.");
                    _isCorrect = false;
                }

                if (itemFlete.FleteNegativo === true) {
                    this.m_mensajeError.add("La Orden de Venta tiene un Profit Negativo.");
                    _isCorrect = false;
                }

                if (itemFlete.Instance) {
                    itemFlete.AUDI_UsrMod = this.crudOrdenesVenta.pvchAUDI_UsrCrea;
                    itemFlete.AUDI_FecMod = new Date();
                }

                if (itemFlete.CCOT_Tipo === 2) {
                    if (localStorage.getItem("Opcion") === "EDITAR") {
                        console.log("se editara la orden");
                    } else {
                        console.log("se guardara la orden");
                    }
                }
            }
        } catch (error) {
            _isCorrect = false;
            this.m_mensajeError.add("Ha ocurrido un error: " + error.message);
        }

        return { isValid: _isCorrect, errorMessage: Array.from(this.m_mensajeError) }; // Convertir a array
    }


    validar(esProspecto): { isValid: boolean, errorMessage: string[] } {
        let _isCorrect: boolean = true;
        const m_mensajeError: Set<string> = new Set();

        try {
            if (this.crudOrdenesVenta.psinCCOT_Tipo == 0 || this.crudOrdenesVenta.psinCCOT_Tipo == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo CCOT_Tipo");
            }

            // if (!this.crudOrdenesVenta.pvchCCOT_NumDoc || this.crudOrdenesVenta.pvchCCOT_NumDoc == null) {
            //     _isCorrect = false;
            //     m_mensajeError.add("* Debe ingresar el campo CCOT_NumDoc");
            // }

            if (this.crudOrdenesVenta.psinCCOT_Version == 0 || this.crudOrdenesVenta.psinCCOT_Version == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo CCOT_Version");
            }

            if (!this.crudOrdenesVenta.pchrCONS_TabEST || this.crudOrdenesVenta.pchrCONS_CodEST == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo CCOT_NumDoc");
            }

            if (this.crudOrdenesVenta.pintENTC_CodCliente == 0 || this.crudOrdenesVenta.pintENTC_CodCliente == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Cliente");
            }

            if (!this.crudOrdenesVenta.pdtmCCOT_FecEmi || this.crudOrdenesVenta.pdtmCCOT_FecEmi == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Fecha Emisión");
            }

            if (!this.crudOrdenesVenta.pchrCONS_TabRGM || this.crudOrdenesVenta.pchrCONS_TabRGM == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Régimen");
            }

            if (!this.crudOrdenesVenta.pchrCONS_TabVia || this.crudOrdenesVenta.pchrCONS_TabVia == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Vía");
            }

            if (!this.crudOrdenesVenta.pdtmCCOT_FecVcto || this.crudOrdenesVenta.pdtmCCOT_FecVcto == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Fecha Vencimiento");
            }

            if (!this.crudOrdenesVenta.pintENTC_CodAgente || this.crudOrdenesVenta.pintENTC_CodAgente == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Agente");
            }

            if (this.crudOrdenesVenta.pintENTC_CodEjecutivo == 0 || this.crudOrdenesVenta.pintENTC_CodEjecutivo == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Ejecutivo");
            }

            if (this.crudOrdenesVenta.pintENTC_CodCustomer == 0 || this.crudOrdenesVenta.pintENTC_CodCustomer == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Customer");
            }

            if (this.crudOrdenesVenta.pintPUER_CodOrigen == 0 || this.crudOrdenesVenta.pintPUER_CodOrigen == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Puerto Origen");
            }

            if (this.crudOrdenesVenta.pintPUER_CodDestino == 0 || this.crudOrdenesVenta.pintPUER_CodDestino == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Puerto Destino");
            }

            if (!this.crudOrdenesVenta.pchrTIPO_TabMND || this.crudOrdenesVenta.pchrTIPO_CodMND == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Moneda");
            }

            if (!this.crudOrdenesVenta.pchrCONS_TabFLE || this.crudOrdenesVenta.pchrCONS_CodFLE == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar el campo Condición");
            }

            if (this.crudOrdenesVenta.pintNVIA_Codigo == 0 || this.crudOrdenesVenta.pintNVIA_Codigo == null) {
                _isCorrect = false;
                m_mensajeError.add("* Debe ingresar seleccionar una via");
            }

            if (_isCorrect) {
                const resultadoFlete = this.validarFlete();
                if (!resultadoFlete.isValid) {
                    _isCorrect = false;
                    resultadoFlete.errorMessage.forEach(error => m_mensajeError.add(error));
                    console.error("Errores en la validación de fletes:", resultadoFlete.errorMessage);
                } else {
                    console.log("Validación de fletes exitosa");
                }

                const resultadoServicio = this.validarServicio();
                if (!resultadoServicio.isValid) {
                    _isCorrect = false;
                    resultadoServicio.errorMessage.forEach(error => m_mensajeError.add(error));
                    console.error("Errores en la validación de Items Servicio:", resultadoServicio.errorMessage);
                } else {
                    console.log("Validación de Items Servicio exitosa");
                }
            }

            // Validación de Carga Peligrosa
            if (this.crudOrdenesVenta.pbitCCOT_CargaPeligrosa) {
                let TIPO_CodImoOK = true;
                if (!this.crudOrdenesVenta.pchrTIPO_CodImo) {
                    _isCorrect = false;
                    TIPO_CodImoOK = false;
                    m_mensajeError.add("* Debe seleccionar el IMO Class");
                }

                let CCOT_ImoUNOK = true;
                if (!this.crudOrdenesVenta.pvchCCOT_ImoUN) {
                    _isCorrect = false;
                    CCOT_ImoUNOK = false;
                    m_mensajeError.add("* Debe ingresar el IMO UN");
                }
            }

        } catch (error) {
            _isCorrect = false;
            m_mensajeError.add("Ha ocurrido un error: " + error.message);
            return { isValid: _isCorrect, errorMessage: Array.from(m_mensajeError) };
        }

        console.log("crudOrdenesVenta:", this.crudOrdenesVenta);
        console.log("listadofletes:", this.insertActualizarOVFlete);
        console.log("listaServicio:", this.insertActualizarOVServicio);
        return { isValid: _isCorrect, errorMessage: Array.from(m_mensajeError) };
    }

    validarFlete(): { isValid: boolean, errorMessage: string[] } {
        let _isCorrect: boolean = true;
        const m_mensajeError: Set<string> = new Set();
        let ItemsFleteMSGERROR: Set<string> = new Set();

        try {
            if (this.insertActualizarOVFlete.items && this.insertActualizarOVFlete.items.length > 0) {
                for (const itemFlete of this.insertActualizarOVFlete.items) {
                    // Validaciones específicas
                    if (!itemFlete.DCOT_Item) {
                        ItemsFleteMSGERROR.add(`Item: ${itemFlete.DCOT_Item}\nEl campo 'Item' del flete está vacío.`);
                        _isCorrect = false;
                    }
                    if (itemFlete.DCOT_Cantidad == null || itemFlete.DCOT_Cantidad <= 0) {
                        ItemsFleteMSGERROR.add(`Item: ${itemFlete.DCOT_Item}\nEl campo 'Cantidad' del flete debe ser mayor que 0.`);
                        _isCorrect = false;
                    }
                    if (itemFlete.DCOT_PrecioUniCosto == null) {
                        ItemsFleteMSGERROR.add(`Item: ${itemFlete.DCOT_Item}\nEl campo 'Precio unitario costo' del flete no puede ser nulo.`);
                        _isCorrect = false;
                    }
                    if (itemFlete.DCOT_PrecioUniVenta == null) {
                        ItemsFleteMSGERROR.add(`Item: ${itemFlete.DCOT_Item}\nEl campo 'Precio unitario venta' del flete no puede ser nulo.`);
                        _isCorrect = false;
                    }
                    if (itemFlete.DCOT_TotalUniCosto == null) {
                        ItemsFleteMSGERROR.add(`Item: ${itemFlete.DCOT_Item}\nEl campo 'Total costo' del flete no puede ser nulo.`);
                        _isCorrect = false;
                    }
                    if (itemFlete.DCOT_TotalUniVenta == null) {
                        ItemsFleteMSGERROR.add(`Item: ${itemFlete.DCOT_Item}\nEl campo 'Total venta' del flete no puede ser nulo.`);
                        _isCorrect = false;
                    }
                    if (!itemFlete.PACK_Codigo) {
                        ItemsFleteMSGERROR.add(`Item: ${itemFlete.DCOT_Item}\nEl campo 'Tipo paquete' del flete está vacío.`);
                        _isCorrect = false;
                    }

                    if (itemFlete.CCOT_Tipo == null) {
                        _isCorrect = false;
                        m_mensajeError.add("* Debe ingresar el campo CCOT_Tipo del flete");
                    }
                    if (itemFlete.DCOT_Producto == null) {
                        _isCorrect = false;
                        m_mensajeError.add("* Debe ingresar el campo Producto del flete");
                    }
                    if (itemFlete.DCOT_Importe == null) {
                        _isCorrect = false;
                        m_mensajeError.add("* Debe ingresar el campo DCOT_Importe del flete");
                    }
                    if (itemFlete.DCOT_Bultos == null) {
                        _isCorrect = false;
                        m_mensajeError.add("* Debe ingresar el campo DCOT_Bultos del flete");
                    }
                }
            } else {
                console.warn("* El listado de fletes está vacío");
                m_mensajeError.add("* El listado de fletes está vacío");
                _isCorrect = false;
                return { isValid: _isCorrect, errorMessage: Array.from(m_mensajeError) };
            }

            if (!ItemsFleteMSGERROR.size && !m_mensajeError.size) {
                return { isValid: true, errorMessage: [] };
            }

        } catch (error) {
            _isCorrect = false;
            m_mensajeError.add("Ha ocurrido un error: " + error.message);
            return { isValid: _isCorrect, errorMessage: Array.from(m_mensajeError) };
        }

        m_mensajeError.add("La Grilla de Flete contiene los siguientes errores:");
        ItemsFleteMSGERROR.forEach(error => m_mensajeError.add(error));
        return { isValid: _isCorrect, errorMessage: Array.from(m_mensajeError) };
    }


    validarServicio(): { isValid: boolean, errorMessage: string[] } {
        let _isCorrect: boolean = true;
        const m_mensajeError: Set<string> = new Set();

        if (!this.crudOrdenesVenta.pchrCCOT_PagoHBL) {
            _isCorrect = false;
            m_mensajeError.add(`* El campo pago con HBL está vacío.`);
        }
        if (!this.crudOrdenesVenta.pchrCCOT_PagoMBL) {
            _isCorrect = false;
            m_mensajeError.add(`* El campo pago con MBL está vacío.`);
        }

        if (this.insertActualizarOVServicio.items && this.insertActualizarOVServicio.items.length > 0) {
            for (const itemServicio of this.insertActualizarOVServicio.items) {
                if (itemServicio.CCOT_Tipo == null) {
                    _isCorrect = false;
                    m_mensajeError.add("* Debe ingresar el campo CCOT_Tipo");
                }
                if (itemServicio.SCOT_Item == null) {
                    _isCorrect = false;
                    m_mensajeError.add("* Debe ingresar el campo Item");
                }
                if (itemServicio.SERV_Codigo == null) {
                    _isCorrect = false;
                    m_mensajeError.add("* Debe ingresar el campo Servicio");
                }
                if (itemServicio.CCOT_IngresoGasto == null) {
                    _isCorrect = false;
                    m_mensajeError.add("* Debe ingresar el campo Ingreso/Egreso en la tabla servicio");
                }
                if (!itemServicio.SCOT_Cantidad || itemServicio.SCOT_Cantidad <= 0) {
                    _isCorrect = false;
                    m_mensajeError.add("* Debe ingresar el campo Cantidad en el servicio");
                }
                if (itemServicio.SCOT_Origen == null) {
                    _isCorrect = false;
                    m_mensajeError.add("* Debe ingresar el campo Origen en el servicio");
                }
                if (itemServicio.SCOT_PrecioUni == null || itemServicio.SCOT_PrecioUni <= 0) {
                    _isCorrect = false;
                    m_mensajeError.add("* Debe ingresar el campo Precio Unitario en la tabla servicio");
                }
                if (itemServicio.SCOT_Origen === "I" && (itemServicio.SCOT_Importe_Ingreso == null || itemServicio.scot_importe_ingreso <= 0)) {
                    _isCorrect = false;
                    m_mensajeError.add("* Debe ingresar el campo Importe Ingreso");
                }
                if (itemServicio.SCOT_Origen === "E" && (itemServicio.SCOT_Importe_Egreso == null || itemServicio.scot_importe_egreso <= 0)) {
                    _isCorrect = false;
                    m_mensajeError.add("* Debe ingresar el campo Importe Egreso");
                }
                if (itemServicio.SCOT_ChageControl && !itemServicio.SCOT_FechaOperacion) {
                    _isCorrect = false;
                    m_mensajeError.add("* Debe ingresar la Fecha de Operación");
                }
            }
        } else {
            console.warn("* El listado de SERVICIOS está vacío");
            m_mensajeError.add("* El listado de servicios está vacío");
            _isCorrect = false;
            return { isValid: _isCorrect, errorMessage: Array.from(m_mensajeError) };
        }

        return { isValid: _isCorrect, errorMessage: Array.from(m_mensajeError) }; // Convertir a array al final
    }



}




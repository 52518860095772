import { estado } from 'src/app/proforma/domain/entity-proforma';
import { Component, Inject, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from 'src/app/services/storage.service';
import { ActualizarTareaRequest, InsertarTareaRequest, ListarRecursoRequest } from '../../domain/request/gestionProyectos-request';
import { UtilService } from 'src/app/services/util.service';
import { GestionProyectosRepository } from '../../domain/gestionProyectos-repository';
import { listarRecurso } from '../../domain/response/gestionProyectos-response';


@Component({
  selector: 'app-agregar-nueva-tarea',
  templateUrl: './agregar-nueva-tarea.component.html',
  styleUrls: ['./agregar-nueva-tarea.component.css']
})
export class AgregarNuevaTareaComponent {
  constructor(private readonly reference: MatDialogRef<AgregarNuevaTareaComponent>,
    private readonly storage: StorageService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private util: UtilService,
    private readonly proyectosService: GestionProyectosRepository
  ) { }


  group: FormGroup
  nombreUsuario: string
  modo: boolean
  listarRecurso: listarRecurso[] = []
  listarImportancia: any[] = []
  ListaEstadosProyecto: any[] = []
  iniciarFormulario() {
    this.group = new FormGroup({
      desctarea: new FormControl(null, null),
      fechaInicio: new FormControl(null, null),
      fechaFin: new FormControl(null, null),
      recurso: new FormControl(null, null),
      importancia: new FormControl(null, null),
      dias: new FormControl(null, null),
      estado: new FormControl(null, null),
      horas: new FormControl(null, null),
    })
  }


  idproyecto: string
  nivelpadre: number

  ngOnInit(): void {
    this.listaRecurso()
    this.ListaEstadosProyecto = [
      { nombre: "NUEVO", codigo: "N" },
      { nombre: "APROBADO", codigo: "A" },
      { nombre: "PLANIFICADO", codigo: "P" },
      { nombre: "EN CURSO", codigo: "E" },
      { nombre: "EN REVISION", codigo: "R" },
      { nombre: "COMPLETADO", codigo: "C" },
      { nombre: "STAMPBY", codigo: "S" },
      { nombre: "ANULADO", codigo: "O" },
    ]

    this.listarImportancia = [
      { nombre: "ALTA", codigo: "ALTA" },
      { nombre: "MEDIA", codigo: "MEDIA" },
      { nombre: "BAJA", codigo: "BAJA" }
    ]



    if (localStorage.getItem('row') != null) {
      const datosSerializados = localStorage.getItem('row')
      const datos = JSON.parse(datosSerializados);
      localStorage.removeItem('datos');
      console.log("Datos llegados a agregar... ", datos)
      this.nivelpadre = datos.nivel
    }

    this.nombreUsuario = this.storage.get('usuario')
    this.iniciarFormulario()

    setTimeout(() => {
      if (this.data != 'T' && this.data != 'S') {
        this.group.get('desctarea').setValue(this.data.descripcion_tarea)
        const formatDate = (dateString: string): string => {
          const [day, month, year, time] = dateString.split(/[/\s-]+/);
          const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${time}:00`;
          return formattedDate;
        };
        this.group.get('fechaInicio').setValue(new Date(this.data.fecha_iniciotarea));
        this.group.get('fechaFin').setValue(new Date(this.data.fecha_fintarea));
        console.log(this.listarRecurso)
        console.log("Valor que setea: ", this.data.nom_recurso)
        this.group.get('recurso').setValue(this.data.id_recurso)
        this.group.get('estado').setValue(this.data.estado)
        this.group.get('importancia').setValue(this.data.importancia)
        this.group.get('dias').setValue(this.data.dias)
        this.group.get('horas').setValue(this.data.horas)
        this.modo = true
        console.log(this.data)
      }
    }, 1000);



    if (localStorage.getItem('idproyecto') != null) {
      this.idproyecto = localStorage.getItem('idproyecto')
      localStorage.removeItem('idproyecto');
      console.log(this.idproyecto)
    }


  }


  listaRecurso() {
    const requestRecurso: ListarRecursoRequest = <ListarRecursoRequest>{}
    requestRecurso.estado = 'A'
    this.proyectosService.listarRecurso(requestRecurso).subscribe(response => {
      if (response.datos.status === 200) {
        for (let i = 0; i < response.datos.result.length; i++) {
          const item: listarRecurso = {
            usuA_CodUsr: response.datos.result[i].usuA_CodUsr,
            usuA_Nombres: response.datos.result[i].usuA_Nombres
          }

          this.listarRecurso.push(item)
        }

        console.log("Los recursos en el listar son: ", this.listarRecurso)
      }
    })
  }

  mostrarDats(event: any): void {
    console.log(".....", event.value)
  }


  guardarTarea() {
    // const value = this.data.value;
    const values = this.group.value
    let horas = 0;
    let minutos = 0;
    let isHora = true;


    if (values.horas && typeof values.horas === 'string') {
      for (let i = 0; i < values.horas.length; i++) {
        const char = values.horas[i];
        if (char === ':') {
          isHora = false;
          continue;
        }
        if (isHora) {
          horas = horas * 10 + parseInt(char, 10);
        } else {
          minutos = minutos * 10 + parseInt(char, 10);
        }
      }
    } else {
      console.error("El valor de horas no es una cadena válida");
    }


    const NuevaTareaRequest: InsertarTareaRequest = <InsertarTareaRequest>{}
    NuevaTareaRequest.tipo = this.data
    NuevaTareaRequest.id_proyecto = this.idproyecto
    NuevaTareaRequest.id_recurso = values["recurso"] || ""
    NuevaTareaRequest.descripcion_tarea = values["desctarea"]
    NuevaTareaRequest.fecha_iniciotarea = values["fechaInicio"]
    NuevaTareaRequest.fecha_fintarea = values["fechaFin"]
    NuevaTareaRequest.importancia = values["importancia"]
    NuevaTareaRequest.horas = values["horas"]
    NuevaTareaRequest.dias = values["dias"]


    NuevaTareaRequest.estado = values["estado"]
    NuevaTareaRequest.nivel = this.nivelpadre
    NuevaTareaRequest.audi_usrcrea = this.nombreUsuario,
      NuevaTareaRequest.audi_feccrea = this.util.formatearfecha(new Date(), 'yyyy-MM-ddTHH:mm:ss')


    this.proyectosService.insertarTarea(NuevaTareaRequest).subscribe(response => {
      if (response.datos.status === 200) {
        this.util.ShowMessage({ mensaje: 'Se guardó correctamente', tipo: 'ok' })
        const datarespuesta = {
          mensaje: 'Se registro correctamente'
        }
        this.reference.close(datarespuesta);
      }
    })
  }


  actualizarTarea() {
    const values = this.group.value
    let horas = 0;
    let minutos = 0;
    let isHora = true;


    if (values.horas && typeof values.horas === 'string') {
      for (let i = 0; i < values.horas.length; i++) {
        const char = values.horas[i];
        if (char === ':') {
          isHora = false;
          continue;
        }
        if (isHora) {
          horas = horas * 10 + parseInt(char, 10);
        } else {
          minutos = minutos * 10 + parseInt(char, 10);
        }
      }
    } else {
      console.error("El valor de horas no es una cadena válida");
    }
    const actualizarTareaRequest: ActualizarTareaRequest = {
      nivel_padre: this.data.nivel_padre,
      nivel: this.data.nivel,
      id_recurso: values.recurso,
      descripcion_tarea: values.desctarea,
      fecha_iniciotarea: values.fechaInicio,
      fecha_fintarea: values.fechaFin,
      importancia: values.importancia,
      horas: values.horas,
      dias: values.dias,
      estado: values.estado,
      audi_usrmodcrea: this.nombreUsuario,
      audi_fecmodcrea: this.util.formatearfecha(new Date(), 'yyyy-MM-ddTHH:mm:ss')
    }
    this.proyectosService.actualizarTarea(actualizarTareaRequest).subscribe(response => {
      if (response.datos.status === 200) {
        this.util.ShowMessage({ mensaje: 'Se actualizó correctamente', tipo: 'ok' });
        const datarespuesta = {
          mensaje: 'Se actualizo correctamente'
        }
        this.reference.close(datarespuesta);
      }
    }, (errorServicio) => {
      this.util.ShowMessage({ mensaje: 'No se pudo actualizar datos', tipo: 'warning' })
    })
  }


  salir() {
    this.reference.close();
  }


  onDateChangeDesde(event: any, campo: string) {
    // console.log("Holla")
    // console.log("FECHA SELECCIONADA", event)
    // console.log("Campo ", campo)

    // const selectedDate = event.value;
    // // selectedDate.setHours(0, 0, 0, 0);
    // console.log("xxx ",selectedDate)
    // // this.group.get(campo).setValue(selectedDate.toISOString());
    // this.group.get(campo).setValue(new Date(selectedDate));

    console.log("fecha Inicio: ", this.group.get('fechaInicio').value)
    console.log("fecha Fin: ", this.group.get('fechaFin').value)

    const fechaInicio = this.group.get('fechaInicio')?.value ? new Date(this.group.get('fechaInicio')?.value) : null;
    const fechaFin = this.group.get('fechaFin')?.value ? new Date(this.group.get('fechaFin')?.value) : null;


    if (fechaInicio && fechaFin) {
      const diferenciaDias = this.calculateDaysDifference(fechaInicio, fechaFin);
      this.group.get('dias')?.setValue(diferenciaDias);
    }
  }



  calculateDaysDifference(startDate: Date, endDate: Date): number {
    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
    }
    let totalDays = 0;
    let currentDate = new Date(startDate);
    // const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    // return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) { // 0 es domingo, 6 es sábado
        totalDays++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return totalDays;
  }
}







import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MetadataTable } from 'src/app/interfaces/metada-table.interface';
import { Proforma, estado } from 'src/app/proforma/domain/entity-proforma';
import { UtilService } from 'src/app/services/util.service';
import { ContactoComponent } from '../contacto/contacto.component';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { DetalleProforma, DetalleProformaServicioLocales, contactoRequest, contenedorRequest, incoterRequest, listaClienteRequest, proformaDetalleRequest, proformaGuardaRequest, proformaRequest, puertoRequest, request_serviciolocal, requestcontacto, requestejecutivo, requestgastolocales, requestnaves, requestservicios, rucInternoRequest, sunatRequest, tarifaRequest, } from 'src/app/proforma/domain/request/proforma_request';
import { Observable, } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ListContenedor, ListIncoter, ListItem, ListPrtoD, ListPrtoO, ListaCliente2, ListaContactoJ, ListaDetalleProforma, ListaMantProforma, ListaTarifa, listadogastoslocales, listadonaves, listadoproformagastoslocales, listadoserviciolocales, listaejecutivos, listcontacto, response_serviciolocales } from 'src/app/proforma/domain/response/proforma_response';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { DatosProformaComponent } from '../datos-proforma/datos-proforma.component';
import { ActivatedRoute, ResolveEnd, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { StorageService } from 'src/app/services/storage.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { requestlistarejecutivo } from 'src/app/gestionvisitas/domain/request/requestgestionvisitas';
import { listadoejecutivos } from 'src/app/gestionvisitas/domain/response/response/response-llistadocarteraxejecutivo';
import { PageEvent } from '@angular/material/paginator';


@Component({
  selector: 'app-proforma',
  templateUrl: './proforma.component.html',
  //template:'<app-mant-proforma (outputListadoProforma)="receiveMessage($event)"></app-mant-proforma>',
  styleUrls: ['./proforma.component.css']
})


export class ProformaComponent {

  constructor(private util: UtilService, private readonly proformaService: ProformaRepository, private route: ActivatedRoute, private readonly router: Router, private readonly storage: StorageService,) { }

  //LISTA QUE SIRVE PARA AGREGAR A MAS PAREMETROS
  listaAgrega: DetalleProforma[] = []
  //boolean GENERAL
  condicion: boolean = false
  ptoDestinoB: number
  //DATOS PARA RECUPERAR DE LAS FILAS Y GUARDARLOS
  paquete: string
  fechaInicio: string
  fechaFin: Date
  linea: string
  valor: string
  cantidad: string
  //FILAS SELECCIONADAS
  selectedRows: any[] = [];
  listAgente: ListIncoter[] = [];

  //FECHA ACTUAL
  fechaActual: Date;

  //AUTOCOMPLETE
  myControl = new FormControl('');
  limitedOptions: string[] = [];
  listRazonSocial: string[] = [];
  listIncoter: string[] = [];
  filteredOptions: Observable<string[]>;


  ListaContedorTarifaGeneral: ListaTarifa[] = [];
  ListaContenedor: ListaTarifa[];

  groupProforma: FormGroup;
  groupContenedor: FormGroup;
  groupPricing: FormGroup;
  dataTable: ListaTarifa[];
  dataTablesl: listadoserviciolocales[];

  dataTableEdita: ListaDetalleProforma[];
  dataTablePrincipal: ListaDetalleProforma[];

  dataTablePrincipalSL: listadoserviciolocales[];



  ListaContedorTarifaPrincipal: ListaDetalleProforma[] = [];
  ListaContenedorPrincipal: ListaDetalleProforma;


  ListaSLPrincipal: listadoserviciolocales[] = [];
  ItemSLPrincipal: listadoserviciolocales;


  listReg: ListItem[] = [];
  listVia: ListItem[] = [];
  listEstado: ListItem[] = [];
  listDescripcion: ListItem[] = [];

  listLineas: ListItem[] = [];

  listPrtoO: ListPrtoO[] = [];
  listPrtoD: ListPrtoD[] = [];
  listContenedor: ListContenedor[] = [];
  contactoComponent = ContactoComponent;
  datosProformaComponent = DatosProformaComponent;

  DatosRecibidos: ListaMantProforma;

  //valores Razon Social y RUC
  codigoRazonSocial: string;
  razonSocial: string;
  ruc: string;
  codigoIncoter: string;

  Modo: string; //  I para indicar que es nuevo y U para indicar que es un modificar
  ahora: any
  disable: boolean
  listTipoCarga: any[] = []


  metadataTable: MetadataTable[] = [
    { field: 'select', title: '', type: 'checkbox' },
    { field: 'Item', title: 'ITEM', type: 'text' },
    { field: 'puertoorigen', title: 'ORIGEN', type: 'text' },
    { field: 'puertodestino', title: 'DESTINO', type: 'text' },
    { field: 'linea', title: 'TRANSPORTISTA', type: 'text' },
    { field: 'descripcion', title: 'CONTRATO', type: 'text' },
    { field: 'paquete', title: 'PAQUETE', type: 'text' },
    { field: 'cantidad', title: 'CANTIDAD', type: 'text' },
    { field: 'fechainicioformateada', title: 'FECHA INICIO', type: 'date' },
    { field: 'fechafinformateada', title: 'FECHA FIN', type: 'date' },
    { field: 'soes_diassobrestadiacliente', title: 'SOBRESTADIA', type: 'text' },
    { field: 'valor', title: 'VALOR', type: 'text' },
    { field: 'total', title: 'TOTAL', type: 'text' }
  ];

  metadataTableSL: MetadataTable[] = [
    { field: 'select', title: '', type: 'checkbox' },
    { field: 'transportista', title: 'TRANSPORTISTA', type: 'text' },
    { field: 'conceptDescription', title: 'DESCRIPCION', type: 'text' },
    { field: 'conS_Desc_SPA', title: 'UNIDAD BASE', type: 'text' },
    { field: 'almacen', title: 'RAZON SOCIAL', type: 'text' },
    { field: 'dopE_PrecioUnitVta', title: 'VENTA(UNIDAD)', type: 'text' },
    { field: 'moneda', title: 'MONEDA', type: 'text' }
  ];


  metadataTablePrincipal: MetadataTable[] = [
    { field: 'dprO_ITEM', title: 'ITEM', type: 'text' },
    { field: 'tipotarifa', title: 'TIPO', type: 'text' },
    { field: 'puertoorigen', title: 'ORIGEN', type: 'text' },
    { field: 'puertodestino', title: 'DESTINO', type: 'text' },
    { field: 'transportista', title: 'TRANSPORTISTA', type: 'text' },
    { field: 'contrato', title: 'CONTRATO', type: 'text' },
    { field: 'pacK_Desc', title: 'PAQUETE', type: 'text' },
    { field: 'fechainicioformateada', title: 'FECHA INICIO', type: 'date' },
    { field: 'fechafinformateada', title: 'FECHA FIN', type: 'date' },
    { field: 'dprO_Cantidad', title: 'CANTIDAD', type: 'number', editable: true },
    { field: 'dcoT_PrecioUniVenta', title: 'VALOR', type: 'text' },
    { field: 'dcoT_TotalUniVenta', title: 'TOTAL', type: 'text' },
    { field: 'estado', title: 'ESTADO', type: 'text' }
  ];


  metadataTablePrincipalSL: MetadataTable[] = [
    { field: 'item', title: 'ITEM', type: 'text' },
    { field: 'puertoOrigen', title: 'ORIGEN', type: 'text' },
    { field: 'puertoDestino', title: 'DESTINO', type: 'text' },
    { field: 'transportista', title: 'TRANSPORTISTA', type: 'text' },
    { field: 'paquete', title: 'CNT', type: 'text' },
    { field: 'conceptDescription', title: 'DESCRIPCION', type: 'text' },
    { field: 'conS_Desc_SPA', title: 'UNIDAD BASE', type: 'text' },
    { field: 'almacen', title: 'ALMACEN', type: 'text' },
    { field: 'dopE_PrecioUnitVta', title: 'VENTA(UNIDAD)', type: 'text' },
    { field: 'moneda', title: 'MONEDA', type: 'text' },
    { field: 'cantidad', title: 'CANTIDAD', type: 'text' },
    { field: 'total', title: 'TOTAL', type: 'text' }
  ];


  receiveMessage($event) {
    this.DatosRecibidos = $event;
  }

  inicializaFormulario(accion: string, row) {

    if (accion == "I") {
      this.groupProforma = new FormGroup({
        nroProforma: new FormControl(null, null),
        razonSocial: new FormControl(null, [Validators.required]),
        ruc: new FormControl(null, [Validators.required]),
        contacto: new FormControl(null, [Validators.required]),
        incoter: new FormControl("FOB", null),
        via: new FormControl("001", [Validators.required]),
        regimen: new FormControl(null, [Validators.required]),
        fechaFin: new FormControl(null, [Validators.required]),
        fechaInicio: new FormControl(null, [Validators.required]),
        tipoCarga: new FormControl("1", [Validators.required]),
        observaciones: new FormControl(null, null),
        // observacionesIngles : new FormControl(null, null),
        excepcion: new FormControl(false, null),
        estado: new FormControl('001', null),
        glosa: new FormControl(null, null),
        agente: new FormControl('CIMC WETRANS DELFIN LOGISTICS(HK)CO., LIMITED', null),

      });

      this.groupContenedor = new FormGroup({
        contenedor: new FormControl(null, null),
        cantidad: new FormControl(null, null),
        ptoOrigen: new FormControl("", [Validators.required]),
        ptoDestino: new FormControl("", [Validators.required]),
        descripcion: new FormControl("", null),
        idtarifa: new FormControl("", null),
        almacen: new FormControl("", null),
        terminal: new FormControl("", null),
        estado: new FormControl(false, null),
        gastoslocales: new FormControl(false, null),
        selectgasto: new FormControl(false, null),
        chksl: new FormControl(null, null),
        transportistaSL:new FormControl(null,null)


      });

      this.groupPricing = new FormGroup({
        agente: new FormControl(null, null),
        tiempoTransito: new FormControl(null, null),
        flete: new FormControl(null, null),
      })
    } else {
      this.groupProforma = new FormGroup({
        nroProforma: new FormControl(row.cprO_Numero, null),
        razonSocial: new FormControl('', [Validators.required]),
        ruc: new FormControl(null, [Validators.required]),
        contacto: new FormControl(row.codcontacto, [Validators.required]),
        incoter: new FormControl('FOB', null),
        via: new FormControl(row.conS_CodVia, [Validators.required]),
        regimen: new FormControl(row.conS_CodRGM, [Validators.required]),
        fechaFin: new FormControl(row.cprO_FecTarifaHasta, [Validators.required]),
        fechaInicio: new FormControl(row.cprO_FecTarifa, [Validators.required]),
        tipoCarga: new FormControl("1", [Validators.required]),
        observaciones: new FormControl(row.observacionesIngles, null),
        // observacionesIngles : new FormControl(row.observacionesIngles, null),
        excepcion: new FormControl(row.cprO_Execpcion, null),
        estado: new FormControl(row.cprO_CODESTADO, null),
        glosa: new FormControl(row.glosa, null),
        agente: new FormControl(row.entC_CodAgente, null),
      });

      this.groupContenedor = new FormGroup({
        contenedor: new FormControl(null, null),
        cantidad: new FormControl(null, null),
        ptoOrigen: new FormControl(row.pueR_CodOrigen, [Validators.required]),
        ptoDestino: new FormControl(null, [Validators.required]),
        estado: new FormControl(false, null),
        chksl: new FormControl(false, null),
        descripcion: new FormControl("", null),
        idtarifa: new FormControl("", null),
        almacen: new FormControl("", null),
        terminal: new FormControl("", null),

        gastoslocales: new FormControl(false, null),
        selectgasto: new FormControl(false, null),
        transportistaSL:new FormControl(null,null)



      });

    }
  }



  tabIndex: Tabs = Tabs.New_Proforma;

  siguiente() {

    this.tabIndex = 1;
  }

  ngOnInit(): void {

    this.disable = true
    //this.cargaTarifa()

    //this.inicializaFormulario("I",null);
    this.cargaLista('RGM')
    this.cargaLista('VIA')
    this.cargaLista('WEB')
    this.cargaContenedor()
    this.listarterminal()
    this.listaralmacen()
    //this.cargaPuerto('ptoOrigen')
    //this.cargaPuerto('ptoDestino')
    this.cargaIncoter();
    this.listarservicios()
    this.cargaTodosAgentes() //carga todos los agentes
    this.cargaTodosAgente() //carga todos los agentes
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );


    this.columnsToView = this.metadataTablePrincipalGL.map(
      (el: MetadataTable) => el.field);


    if (localStorage.getItem('dato') == null) {
      this.Modo = "I"

      this.inicializaFormulario("I", null);


      // const datepi = new DatePipe('en-US')
      // this.ahora = datepi.transform(new Date(), 'yyyy-MM-ddT00:00:00');
      // this.groupProforma.controls['fechaInicio'].setValue(this.ahora)


      const agentePricingControl = this.groupProforma.get('agente')
      agentePricingControl.setValue('CIMC WETRANS DELFIN LOGISTICS(HK)CO., LIMITED')
      this.codigoAgentePricing = 14168
      // Buscar y seleccionar la opción correspondiente para el puerto origen

      //rucControl.setValue(null); // O rucControl.setValue('');

      setTimeout(() => {
        agentePricingControl.setValue('CIMC WETRANS DELFIN LOGISTICS(HK)CO., LIMITED')
        this.codigoAgentePricing = 14168
      }, 0);


    } else {

      //INICALIZA DATOS
      // this.route.queryParams.subscribe(params => {
      this.Modo = "U"
      const datosSerializados = localStorage.getItem('dato')// params['datos'];
      // alert( localStorage.getItem('dato'));
      const datos = JSON.parse(datosSerializados);;//this.DatosRecibidos;//JSON.parse(decodeURIComponent(datosSerializados));

      localStorage.removeItem('dato');
      console.log('CAMBIA CONDICION')

      const item2: ListPrtoD = {
        nombre: datos.puertoDestino,
        codigo: datos.pueR_Coddestino
      };
      this.listPrtoD.push(item2);

      const item: ListPrtoO = {
        nombre: datos.puertoOrigen,
        codigo: datos.pueR_CodOrigen
      };
      this.listPrtoO.push(item);
      this.inicializaFormulario("U", datos);
      // this.displayPuertoDestino = datos.puertoDestino;
      this.ListarTarifaBD(datos.cprO_Numero);
      this.ListarDetalleProformaServicioLocales(datos.cprO_Numero)
      this.ListarDetalleProformaGastoLocales(datos.cprO_Numero)
      this.limitedOptions.push(datos.cliente)
      this.codigoRazonSocial = datos.entC_CodCliente
      this.listadocontacto()
      this.groupProforma.controls['contacto'].setValue(datos.codcontacto)
      this.groupProforma.controls['razonSocial'].setValue(datos.cliente);
      this.groupProforma.controls['ruc'].setValue(datos.ruc);


      const agentePricingControl = this.groupProforma.get('agente')
      agentePricingControl.setValue(null)
      // this.codigoAgentePricing = 14168
      // Buscar y seleccionar la opción correspondiente para el puerto origen
      const puertoOrigen = datos.puertoOrigen;
      //rucControl.setValue(null); // O rucControl.setValue('');

      setTimeout(() => {
        agentePricingControl.setValue(datos.agenteNombre)
        this.codigoAgentePricing = datos.entC_CodAgente
      }, 0);



      /*
            this.condicion = true
            if (this.condicion) {
              this.groupProforma.get('nroProforma').setValue(datos.cprO_Numero);
              this.groupProforma.get('razonSocial').setValue(datos.cliente);
              //this.groupProforma.get('ptoDestino').setValue(datos.puertoDestino);
              //this.groupProforma.get('ptoOrigen').setValue(datos.puertoOrigen);
      
              const item: ListPrtoO = {
                nombre: datos.puertoOrigen,
                codigo: datos.pueR_CodOrigen
              };
              this.listPrtoO.push(item);
              //this.groupProforma.get('ptoOrigen').setValue(datos.puertoDestino)
              this.groupProforma.controls['ptoOrigen'].setValue(datos.pueR_CodOrigen)
      
              const item2: ListPrtoD = {
                nombre: datos.puertoOrigen,
                codigo: datos.pueR_CodOrigen
              };
              this.listPrtoD.push(item2);
              this.groupProforma.get('ptoDestino').setValue(datos.pueR_CodDestino);
             // this.groupProforma.controls['ptoOrigen']=datos.pueR_CodOrigen
      
             alert(this.groupProforma.get('ptoOrigen').value)
             alert(this.groupProforma.get('ptoDestino').value)
      
              this.groupProforma.get('regimen').setValue(datos.conS_CodRGM)
              this.groupProforma.get('via').setValue(datos.conS_CodVia)
              this.groupProforma.get('fechaInicio').setValue(datos.cprO_FecTarifa)
              this.groupProforma.get('fechaFin').setValue(datos.cprO_FecTarifaHasta)
              this.groupProforma.get('observaciones').setValue(datos.cprO_Observaciones)
              this.groupProforma.get('estado').setValue(datos.cprO_CODESTADO)
      
      
              //this.cargaPuerto("ptoDestino") ;
              //this.cargaPuerto("ptoOrigen") ;
      
              //this.groupProforma.get('ptoDestino').setValue(datos.puertoDestino)
              //this.groupProforma.get('ptoOrigen').setValue(datos.puertoOrigen)
              console.log('Este es el valor que tiene puerto Destino: '+ this.groupProforma.get('ptoDestino').value)
              this.cargaProformaDetalle(datos.CPRO_Numero)
              this.cargaRucInterno('I')
              //this.cargaPuerto('ptoOrigen')
              //this.cargaPuerto('ptoDestino')
            }
          //});*/
    }
    this.listTipoCarga = [
      {nombre: "Selecciona un Tipo Carga", codigo: "usuario", disabled: true},
      {nombre: "General", codigo: "1", disabled: false}
    ]
    console.log('CONDICION CAMBIADA: ' + this.condicion)
  }


  requestcontacto: requestcontacto;
  listContacto: listcontacto[];
 // nombreCompleto: string
  listadocontacto() {

    const values = this.groupProforma.value

    this.requestcontacto = <requestcontacto>{}
    this.requestcontacto.ENTC_CodCliente = parseInt(this.codigoRazonSocial);


    this.proformaService.listarcontacto(this.requestcontacto).subscribe
      ((response) => {



        if (response.datos.status === 200) {

          this.listContacto = response.datos.result.map(contacto => {
            return{
              ...contacto,
              nombreCompleto :  `${contacto.nombre} ${contacto.apellidos}`
            }
            
          })

        }

      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);
      });


  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.listRazonSocial.filter(option => option.toLowerCase().includes(filterValue));
  }


  //abre modal
  crear_contacto: boolean
  openModal(value, panel) {
    const values = this.groupProforma.value;

    if ((values["razonSocial"] === undefined)) {

      //  this.crear_contacto = true
      this.util.showMessage("seleccione primero un cliente");

    }

    const codcliente = this.codigoRazonSocial
    const options = {
      disableClose: true,
      panelClass: panel,
      data: codcliente,
    };

    const reference = this.util.openModal(
      value,
      options,


    );
    reference.subscribe((response) => {
      if (response) {



      }

      this.listadocontacto();
    });
  }



  openModalDatosAdicionales(value, panel, row: any) {

    const values = this.groupProforma.value;



    const options = {
      disableClose: true,
      panelClass: panel,
      data: row,
    };

    const reference = this.util.openModal(
      value,
      options,


    );
    reference.subscribe((response) => {
      if (response) {

        row.entC_CodShipper = response.nave
        row.dprO_ETD = response.etd
        row.dprO_ETA = response.eta
        row.dprO_TiempoTranscurrido = response.tiempotransito
        row.dprO_CUTOFF = response.cutoff
        row.montoajuste = response.monto
        row.diaslibressugerido = response.diaslibressugerido
        row.ows = response.ows

        if ((response.autizacion === false) && (row.montoajuste === 0)) {

          row.cpro_CODESTADO = '001'
          row.estado = "INGRESADO"

        }
        if ((response.autizacion === false) && (row.montoajuste > 0)) {

          row.cpro_CODESTADO = '006'
          row.estado = "POR REVISAR"

        }

        if ((response.autizacion === true) && (row.montoajuste > 0)) {

          row.cpro_CODESTADO = '007'
          row.estado = "AUTORIZADO"

        }



      }

      this.listadocontacto();
    });
  }

  //Carga las listas de VIA y REGIMEN
  cargaLista(value: string) {
    const requestProforma: proformaRequest = <proformaRequest>{};
    requestProforma.pchrCONS_CodTabla = value;
    this.proformaService.listar(requestProforma).subscribe(response => {
      if (value == "RGM") {
        this.listReg = []
        for (let i = 0; i < response.datos.result.length; i++) {
          const item: ListItem = {
            nombre: response.datos.result[i].conS_Desc_SPA,
            codigo: response.datos.result[i].conS_CodTipo
          };
          this.listReg.push(item);
        }
      } else if (value == "VIA") {
        this.listVia = []
        for (let i = 0; i < response.datos.result.length; i++) {
          const item: ListItem = {
            nombre: response.datos.result[i].conS_Desc_SPA,
            codigo: response.datos.result[i].conS_CodTipo
          };
          this.listVia.push(item);
        }
      } else if (value == "WEB") {
        this.listEstado = []
        for (let i = 0; i < response.datos.result.length; i++) {
          const item: ListItem = {
            nombre: response.datos.result[i].conS_Desc_SPA,
            codigo: response.datos.result[i].conS_CodTipo
          };
          this.listEstado.push(item);
        }
      }

    });
  }

  //CARGA EL RUC Y RAZON SOCIAL
  cargaSunat() {
    const values = this.groupProforma.value
    const requestSunat: sunatRequest = <sunatRequest>{}
    const razonSocialControl = this.groupProforma.get('razonSocial');
    const rucControl = this.groupProforma.get('ruc');
    requestSunat.razonsocial = values["razonSocial"]
    requestSunat.ruc = values["ruc"]

    this.proformaService.listarSunat(requestSunat).subscribe(response => {
      if (response.datos.result.length > 0) {
        this.razonSocial = response.datos.result[0].nombre_o_razon_social
        this.ruc = response.datos.result[0].ruc

        razonSocialControl.setValue(this.razonSocial);
        rucControl.setValue(this.ruc);
        this.cargaRucInterno('I')
      } else {
        this.cargaRucInterno('E')
      }
    },
      error => {
        this.cargaRucInterno('E')
        console.error('Error en la consulta a la API:', error);
      }
    )
  }
  //CARGA RUC Y RAZON SOCIALES YA REGISTRADOS
  codPadreContacto: string;
  cargaRucInterno(value) {
    console.log(value)
    const values = this.groupProforma.value;
    const requestRucInterno: rucInternoRequest = <rucInternoRequest>{};
    const razonSocialControl = this.groupProforma.get('razonSocial');
    const rucControl = this.groupProforma.get('ruc');

    requestRucInterno.pvchTIPE_Codigo = "1";
    requestRucInterno.pvchENTC_NomCompleto = values["razonSocial"];

    if (requestRucInterno.pvchENTC_DocIden == null) {
      requestRucInterno.pvchENTC_DocIden = "";
    }
    if (value == 'I') {
      requestRucInterno.pvchENTC_DocIden = "";
    }
    if (value == 'E') {
      requestRucInterno.pvchENTC_DocIden = values["ruc"];
      requestRucInterno.pvchENTC_NomCompleto = "";
      this.proformaService.listarRucInterno(requestRucInterno).subscribe(response => {
        this.razonSocial = response.datos.result[0].entC_RazonSocial
        this.codigoRazonSocial = String(response.datos.result[0].entC_Codigo)
        this.codPadreContacto = response.datos.result[0].entC_Codigo.toString()
        razonSocialControl.setValue(this.razonSocial);
        // this.cargaContacto(response.datos.result[0].entC_Codigo)

      })

    }

    if (requestRucInterno.pvchENTC_NomCompleto.length > 3) {
      this.proformaService.listarRucInterno(requestRucInterno).subscribe(response => {
        this.listRazonSocial = []
        for (let i = 0; i < response.datos.result.length; i++) {
          this.listRazonSocial.push(response.datos.result[i].entC_RazonSocial);
        }

        for (let i = 0; i < this.listRazonSocial.length; i++) {
          if (response.datos.result[i].entC_NomCompleto == values["razonSocial"]) {
            this.ruc = response.datos.result[i].entC_DocIden
          }
        }
        // this.ruc = response.datos.result[0].entC_DocIden
        this.codigoRazonSocial = String(response.datos.result[0].entC_Codigo)
        rucControl.setValue(this.ruc);
        //this.cargaContacto(response.datos.result[0].entC_Codigo)
        this.listadocontacto();
        console.log(this.codigoRazonSocial)
      });
    }

    this.limitedOptions = this.listRazonSocial.slice(0, 10);
  }

  //CARGA LISTA INCOTER
  cargaIncoter() {
    console.log('Entra Funcion')
    const requestIncoter: incoterRequest = <incoterRequest>{}
    //const razonSocialControl = this.groupProforma.get('incoter');
    requestIncoter.TIPO_CodTabla = 'INC'
    this.proformaService.listarIncoter(requestIncoter).subscribe(response => {
      this.listIncoter = []
      for (let i = 0; i < response.datos.result.length; i++) {
        if (response.datos.result[i].tipO_DescC == 'FOB') {
          console.log('ASIGNA')
          this.codigoIncoter = response.datos.result[i].tipo_CodTipo
          //     razonSocialControl.setValue(response.datos.result[i].tipO_DescC)
        }
      }
    })
  }

  //CARGA PUERTOS
  cargaPuerto(value: string) {

    const values = this.groupProforma.value;
    const ValuesContendor = this.groupContenedor.value;
    const requestPuerto: puertoRequest = <puertoRequest>{};
    requestPuerto.pintPUER_Codigo = '';
    requestPuerto.pchrCONS_TabVia = 'VIA';
    requestPuerto.pchrCONS_CodVia = values['via'];
    requestPuerto.pchrPUER_CodEstandar = '';
    requestPuerto.pvchPUER_Nombre = ValuesContendor[value] === undefined ? "" : ValuesContendor[value];

    if (requestPuerto.pvchPUER_Nombre.length >= 3) {
      this.proformaService.listarPuerto(requestPuerto).subscribe(response => {
        if (value === 'ptoOrigen') {
          this.listPrtoO = [] // Eliminar todos los elementos existentes
          for (let i = 0; i < response.datos.result.length; i++) {
            const item: ListPrtoO = {
              nombre: response.datos.result[i].pueR_Nombre,
              codigo: response.datos.result[i].pueR_Codigo
            };
            this.listPrtoO.push(item);
          }
        }
        if (value === 'ptoDestino') {
          this.listPrtoD = [] // Eliminar todos los elementos existentes
          for (let i = 0; i < response.datos.result.length; i++) {
            const item: ListPrtoD = {
              nombre: response.datos.result[i].pueR_Nombre,
              codigo: response.datos.result[i].pueR_Codigo
            };
            this.listPrtoD.push(item);
          }
        }
      });
    }
  }



  requestDetallePro: proformaDetalleRequest;
  ListaDetallepro: ListaDetalleProforma[];
  item: ListaDetalleProforma;
  itemdetalle: DetalleProforma;

  ListaContedorTarifaPrincipaltmp: ListaDetalleProforma[] = []
  ListarTarifaBD(nroproforma: number) {

    this.requestDetallePro = <proformaDetalleRequest>{}
    this.requestDetallePro.pintCPRO_Numero = nroproforma.toString();


    this.proformaService.detalleProforma(this.requestDetallePro).subscribe
      ((response) => {



        if (response.datos.status === 200) {
          // this.util.showMessage(this.ListaResponseInsertaCoti.meta.mensaje)

          this.ListaDetallepro = response.datos.result.map(registro => {

            const Accion = 'U'
            const fechaInicio = new Date(registro.dprO_FechaInicio);
            const fechaInicioFormateada = fechaInicio.toLocaleDateString();
            // Formatear la fecha de fin
            const fechaFin = new Date(registro.dprO_FechaFin);
            const fechaFinFormateada = fechaFin.toLocaleDateString();


            return {
              ...registro,
              accion: Accion,
              fechainicioformateada: fechaInicioFormateada,
              fechafinformateada: fechaFinFormateada,

            };


          });

          this.ListaContedorTarifaPrincipal = this.ListaDetallepro
          this.ListaContedorTarifaPrincipaltmp = this.ListaContedorTarifaPrincipal.slice()




          this.dataTablePrincipal = this.ListaContedorTarifaPrincipaltmp//Array.from(this.ListaContedorTarifaPrincipal)

          /*  //llamar a los serviciows locales
    
            this.ListaContedorTarifaGeneral.forEach(element => {
    
               this.listarserviciolocales (element.entC_CodTransportista)
            });
    
           */

        }



      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);


      });




  }


  listadoproformaserviciolocales: listadoserviciolocales[];
  ListarDetalleProformaServicioLocales(nroproforma: number) {

    this.requestDetallePro = <proformaDetalleRequest>{}
    this.requestDetallePro.pintCPRO_Numero = nroproforma.toString();


    this.proformaService.listadoproformadetservlocales(this.requestDetallePro).subscribe
      ((response) => {



        if (response.datos.status === 200) {
          // this.util.showMessage(this.ListaResponseInsertaCoti.meta.mensaje)

          this.listadoproformaserviciolocales = response.datos.result.map(registro => {

            const Accion = 'U'



            return {
              ...registro,
              accion: Accion,


            };


          });

          this.ListaSLPrincipal = this.listadoproformaserviciolocales




          this.dataTablePrincipalSL = Array.from(this.ListaSLPrincipal)



        }



      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);


      });




  }

  //CARGA CONTENEDOR
  cargaContenedor() {
    const requestContenedor: contenedorRequest = <contenedorRequest>{}
    requestContenedor.TipoCodPAQ = '001'
    requestContenedor.pintPACK_Codigo = ''
    this.proformaService.listarContenedor(requestContenedor).subscribe(response => {
      this.listContenedor = []
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListContenedor = {
          nombre: response.datos.result[i].pacK_Desc,
          codigo: response.datos.result[i].pacK_Codigo.toString()
        };
        this.listContenedor.push(item);
      }
    })
  }


  cargaTarifa() {
    const requestTarifa: tarifaRequest = <tarifaRequest>{}
    const valuesProforma = this.groupProforma.value
    const valuesContenedor = this.groupContenedor.value

    requestTarifa.psinEMPR_Codigo = 1; //Siempre es UNO
    requestTarifa.pchrCONS_TabRGM = "RGM"
    requestTarifa.pchrCONS_CodRGM = valuesProforma.regimen; //ESTO ES LO QUE CAMBIA
    requestTarifa.pchrCONS_TabVIA = "VIA"
    requestTarifa.pchrCONS_CodVIA = valuesProforma.via; //ESTO ES LO QUE CAMBIA
    requestTarifa.pdtmCONT_Fecha = valuesProforma["fechaInicio"]
    requestTarifa.pdtmCONT_Fecha2 = valuesProforma["fechaFin"]
    requestTarifa.pintPACK_Codigo = valuesContenedor["contenedor"]
    requestTarifa.pintENTC_CodTransportista = ""
    requestTarifa.pintCONT_Codigo = ""
    requestTarifa.pintPUER_CodOrigen = valuesContenedor.ptoOrigen.codigo
    requestTarifa.pintPUER_CodDestino = valuesContenedor.ptoDestino.codigo
    requestTarifa.pbitCONT_Activo = 1
    //VALORES DEL REQUEST


    const index = this.ListaContedorTarifaGeneral.findIndex(x => (x.pacK_Codigo.toString() + x.pueR_CodigoOrigen.toString()) === (valuesContenedor["contenedor"] + valuesContenedor["ptoOrigen"].codigo));

    if (index !== -1) {

      //   this.ListaContedorTarifaGeneral.forEach((element, index) => {
      //   if (element.pacK_Codigo ===  parseInt(valuesContenedor["contenedor"])) {
      //     this.ListaContedorTarifaGeneral.splice(index, 1); // Elimina el elemento en el índice actual
      //   }
      // });

      for (let i = this.ListaContedorTarifaGeneral.length - 1; i >= 0; i--) {
        const element = this.ListaContedorTarifaGeneral[i];
        if ((element.pacK_Codigo.toString() + element.pueR_CodigoOrigen.toString()) === (valuesContenedor["contenedor"] + valuesContenedor["ptoOrigen"].codigo)) {
          this.ListaContedorTarifaGeneral.splice(i, 1); // Elimina el elemento en el índice actual
        }
      }


    

      this.dataTable = Array.from(this.ListaContedorTarifaGeneral);

    }

    this.proformaService.listarTarifa(requestTarifa).subscribe(response => {


      if (response.datos.status === 200) {

        this.ListaContenedor = response.datos.result.map(registro => {
          // Formatear la fecha de inicio
          const fechaInicio = new Date(registro.conT_FecIni);
          const fechaInicioFormateada = fechaInicio.toLocaleDateString();
          // Formatear la fecha de fin
          const fechaFin = new Date(registro.conT_FecFin);
          const fechaFinFormateada = fechaFin.toLocaleDateString();
          const cantidad = valuesContenedor["cantidad"]
          const total = valuesContenedor["cantidad"] * registro.valor;

          return {
            ...registro,
            fechainicioformateada: fechaInicioFormateada,
            fechafinformateada: fechaFinFormateada,
            cantidad: cantidad,
            total: total
            //valor : this.valor total



          };


        });



      
        //  this.ListaContenedor= response.datos.result;

        //this.ListaContedorTarifaGeneral=this.ListaContenedor

        this.ListaContenedor.forEach(element => {
          element.cantidad = valuesContenedor["cantidad"]
          element.total = valuesContenedor["cantidad"] * element.valor;
          this.ListaContedorTarifaGeneral.push(element);

        });

        this.dataTable = Array.from(this.ListaContedorTarifaGeneral);

        this.listado_serviciolocales = []
        this.dataTablesl = Array.from(this.listado_serviciolocales)



        const list  =    this.ListaContedorTarifaGeneral.filter((obj, index, self) => {
          return self.findIndex((item) => item.entC_CodTransportista === obj.entC_CodTransportista) === index;
        });


       

    
         //this.listado_serviciolocales.filter((valor, indice, self) => {
          //  return self.indexOf(valor) === indice;
         // });
    
    
    
          list.forEach(element => {
            let itemtransportista = <ListItem>{}
            itemtransportista.codigo = element.entC_CodTransportista.toString()
            itemtransportista.nombre=element.linea
            this.listLineas.push( itemtransportista)
          });
          

       /* this.ListaContedorTarifaGeneral.forEach(element => {

          var  item   = <ListItem>{} 
    
                  item.codigo = element.entC_CodTransportista.toString()
                  item.nombre  = element.linea
                  this.listLineas.push(item)
          });
          */

        /// this.ListaContedorTarifaGeneral.forEach(element => {

        //   this.listarserviciolocales (0,"","","",element.entC_CodTransportista,true)
        // });





      }

    }, (errorServicio) => {
      this.util.showMessage(errorServicio.error.meta.mensaje);
    });



  }

  limpiartarifa() {

    this.ListaContedorTarifaGeneral = [];
    this.dataTable = Array.from(this.ListaContedorTarifaGeneral);

  }

  //LIMPIAR CONTROLES
  limpiar(value, form) {

    //this.groupProforma.get(value).reset();

    this.groupContenedor
    if (form == 'groupContenedor') {
      console.log(value)
      console.log(form)
      this.groupContenedor.get(value).reset();

    } else if (form == 'groupProforma') {
      this.groupProforma.get(value).reset();
    }

    if (value === "razonSocial") {

      this.listaContactoJ = [];
      this.listContacto = []

    }
  }

  displayPuertoDestino = this.displayOption;
  displayPuertoOrigen = this.displayOption;
  displayOption(option: any): string {
    return option.nombre;
  }

  validarFechas(): void {
    /*
    const fechaInicio = this.groupProforma.get('fechaInicio').value;
    const fechaFin = this.groupProforma.get('fechaFin').value;

    if (fechaInicio.getTime() > fechaFin.getTime()) {

     this.groupProforma.controls['fechaFin'].setValue(fechaInicio)
      console.log('FECHA MAYOR NO');
      this.util.showMessageError('Fecha inicio, mayor a Fecha final')
    }
    */
  }

  Salir(path: string): void {
    this.router.navigate(['/Proforma']);
  }



  vDetalleProforma: DetalleProforma[] = [];
  itemdetalleproforma: DetalleProforma;

  vDetalleProformaSL: DetalleProformaServicioLocales[] = [];
  itemDetalleProformaSL: DetalleProformaServicioLocales;



  vDetalleProformaGL: requestgastolocales[] = []
  itemvDetalleProformaGL: requestgastolocales;

  //-------------------------------------------------------------------------------------------------
  guardarProforma(accion: string) {

    var estado: string
    var excepcion: boolean
    var solicitaajuste: boolean
    var aunhayporrevisar: boolean
    var precioventa: number

    this.vDetalleProforma = []
    this.vDetalleProformaSL = []
    this.vDetalleProformaGL = []

    aunhayporrevisar = false

    const codusuario = this.storage.get('codusuario')
    const correo = this.storage.get('correo');


    solicitaajuste = false


    this.ListaContedorTarifaPrincipal.forEach(element => {

      this.itemdetalleproforma = <DetalleProforma>{}

      this.itemdetalleproforma.DPRO_Numero = 0
      this.itemdetalleproforma.dprO_ITEM = element.dprO_ITEM
      this.itemdetalleproforma.PACK_Codigo = element.pacK_Codigo
      this.itemdetalleproforma.DPRO_Cantidad = element.dprO_Cantidad
      this.itemdetalleproforma.DCOT_PrecioUniCosto = element.dcoT_PrecioUniCosto
      this.itemdetalleproforma.DCOT_TotalUniCosto = element.dcoT_TotalUniCosto
      this.itemdetalleproforma.DCOT_TotalUniVenta = element.dcoT_TotalUniVenta
      this.itemdetalleproforma.DCOT_PrecioUniVenta = element.dcoT_PrecioUniVenta
      this.itemdetalleproforma.DPRO_Importe = element.dprO_Importe
      this.itemdetalleproforma.DPRO_CodTransportista = element.dprO_CodTransportista
      this.itemdetalleproforma.DPRO_FechaInicio = element.dprO_FechaInicio
      this.itemdetalleproforma.DPRO_FechaFin = element.dprO_FechaFin
      this.itemdetalleproforma.DPRO_DiasLibres = element.dprO_DiasLibres
      this.itemdetalleproforma.ENTC_CodShipper = element.entC_CodShipper === undefined || element.entC_CodShipper === null ? '0' : element.entC_CodShipper.toString()
      this.itemdetalleproforma.DPRO_ETD = element.dprO_ETD
      this.itemdetalleproforma.DPRO_ETA = element.dprO_ETA
      this.itemdetalleproforma.DPRO_TiempoTranscurrido = element.dprO_TiempoTranscurrido === undefined || element.dprO_TiempoTranscurrido === null ? '0' : element.dprO_TiempoTranscurrido.toString()
      this.itemdetalleproforma.DPRO_Seleccionada = false
      this.itemdetalleproforma.CONT_Codigo = parseInt(element.conT_Codigo)
      this.itemdetalleproforma.DPAccion = element.accion
      this.itemdetalleproforma.PUER_CodDestino = parseInt(element.puer_codigodestino)
      this.itemdetalleproforma.PUER_CodOrigen = parseInt(element.puer_codigoorigen)
      this.itemdetalleproforma.montoajuste1 = element.montoajuste
      this.itemdetalleproforma.montoajuste2 = element.montoajuste
      this.itemdetalleproforma.cpro_CODESTADO = element.cpro_CODESTADO
      this.itemdetalleproforma.cpro_TABESTADO = element.cpro_TABESTADO
      this.itemdetalleproforma.numdoc = element.numdoc
      this.itemdetalleproforma.diaslibressugerido = element.diaslibressugerido
      this.itemdetalleproforma.ows = element.ows




      if ((element.montoajuste > 0) && (element.cpro_CODESTADO === '006')) {
        if (element.dcoT_PrecioUniVenta != element.montoajuste) {

          aunhayporrevisar = true
          excepcion = true,
            estado = "006"
          solicitaajuste = true
        }

      }

      if (element.cpro_CODESTADO === '007') {

        excepcion = false
        estado = '007'
        solicitaajuste = true

      }


      this.vDetalleProforma.push(this.itemdetalleproforma)

    });









    this.ListaSLPrincipal.forEach(element => {
      this.itemDetalleProformaSL = <DetalleProformaServicioLocales>{}
      this.itemDetalleProformaSL.dpro_Numero = 0
      this.itemDetalleProformaSL.item = 0
      this.itemDetalleProformaSL.codtransportista = element.value
      this.itemDetalleProformaSL.puertOrigen_codigo = element.puertOrigen_codigo
      this.itemDetalleProformaSL.puertDestinmo_codigo = element.puertDestinmo_codigo
      this.itemDetalleProformaSL.cod_paquete = element.cod_paquete
      this.itemDetalleProformaSL.codterminal = element.codterminal
      this.itemDetalleProformaSL.codalmacen = element.codalmacen
      this.itemDetalleProformaSL.remarks = element.remarks
      this.itemDetalleProformaSL.equivalentCode = element.equivalentCode
      this.itemDetalleProformaSL.serV_Codigo = element.serV_Codigo
      this.itemDetalleProformaSL.conceptDescription = element.conceptDescription;
      this.itemDetalleProformaSL.idunit = element.idunit
      this.itemDetalleProformaSL.conS_CodBas = element.conS_CodBas
      this.itemDetalleProformaSL.conS_Desc_SPA = element.conS_Desc_SPA;
      this.itemDetalleProformaSL.dopE_PrecioUnitVta = element.dopE_PrecioUnitVta
      this.itemDetalleProformaSL.dopE_PrecioUnitCosto = element.dopE_PrecioUnitCosto
      this.itemDetalleProformaSL.dopE_Cantidad = element.cantidad
      this.itemDetalleProformaSL.dopE_PrecioTotVta = element.dopE_PrecioTotVta
      this.itemDetalleProformaSL.dopE_PrecioTotCosto = element.dopE_PrecioTotCosto
      this.itemDetalleProformaSL.tipE_Codigo = element.tipE_Codigo
      this.itemDetalleProformaSL.entC_Codigo = element.entC_Codigo
      this.itemDetalleProformaSL.idtariffDetail = element.idtariffDetail
      this.itemDetalleProformaSL.dtaR_Item = element.dtaR_Item
      this.itemDetalleProformaSL.currency = element.currency
      this.itemDetalleProformaSL.tipO_CodMnd = element.tipO_CodMnd
      this.itemDetalleProformaSL.accion = element.accion
      this.itemDetalleProformaSL.idTariff = element.idTariff
      this.itemDetalleProformaSL.TotalProfit = parseInt(element.TotalProfit)
      this.itemDetalleProformaSL.cantidad = element.cantidad
      this.itemDetalleProformaSL.total = element.total;
      this.vDetalleProformaSL.push(this.itemDetalleProformaSL)
    });



    this.dataTablePrincipalGL.forEach(element => {

      this.itemvDetalleProformaGL = <requestgastolocales>{}

      this.itemvDetalleProformaGL.dpro_Numero = element.dpro_numero
      this.itemvDetalleProformaGL.item = element.item
      this.itemvDetalleProformaGL.serv_codigo = element.serv_codigo
      this.itemvDetalleProformaGL.serv_valor = element.serv_valor
      this.itemvDetalleProformaGL.serv_valorsugerido = element.serv_valorsugerido
      this.itemvDetalleProformaGL.accion = element.accion

      this.vDetalleProformaGL.push(this.itemvDetalleProformaGL)
    });




    if (this.ListaContedorTarifaPrincipal.length > 0) {


      /*

     this.ListaContenedorPrincipal(element => {

       DPRO_Numero:  values["nroProforma"] == null ? 0:values["nroProforma"] ,
       PACK_Codigo: row.pacK_Codigo,
       DPRO_Cantidad: row.cantidad,
       DCOT_PrecioUniCosto: row.valor,
       DCOT_TotalUniCosto: row.valor,
       DCOT_TotalUniVenta: row.valor,
       DCOT_PrecioUniVenta: row.valor,
       DPRO_Importe: row.valor,
       DPRO_CodTransportista: row.entC_CodTransportista,
       DPRO_FechaInicio :  row.conT_FecIni ,
       DPRO_FechaFin: row.conT_FecFin,//fechaFin,
       DPRO_DiasLibres: row.dprO_DiasLibres,
       ENTC_CodShipper: row.entC_CodShipper,
       DPRO_ETD: row.dprO_ETD,
       DPRO_ETA: row.dprO_ETA,
       DPRO_TiempoTranscurrido: row.dprO_TiempoTranscurrido,
       DPRO_Seleccionada: true,
       CONT_Codigo  :row.codigo,
       DPAccion: 'I',

      });
      */


      this.fechaActual = new Date();
      const groupContenedor = this.groupContenedor.value;
      const groupProforma = this.groupProforma.value;

      if ((solicitaajuste === false) && (aunhayporrevisar === false)) {

        excepcion = groupProforma.excepcion,
          estado = groupProforma.estado

      }
      const requestGuardaProforma: proformaGuardaRequest = {
        CPRO_Numero: groupProforma.nroProforma == null ? 0 : groupProforma.nroProforma, //numero correlativo proforma
        EMPR_Codigo: 1, //codigo empresa
        SUCR_Codigo: 1, //codigo empresa
        ENTC_CodCliente: this.codigoRazonSocial, //codigo razon social.
        ENTC_CodBroker: null,
        ENTC_CodAgente: this.codigoAgentePricing,
        ENTC_CodContacto: null,
        ENTC_CodEjecutivo: 0, //
        CPRO_FecEmi: this.fechaActual, //fecha del dia
        CPRO_FecVcto: null,
        CPRO_CargaPeligrosa: false,
        CPRO_ValidezOferta: null,
        CONS_CodRGM: groupProforma.regimen,
        CONS_CodVia: groupProforma.via,
        PUER_CodDestino: null, //groupContenedor.ptoDestino.codigo,
        PUER_CodOrigen: null,//groupContenedor.ptoOrigen.codigo,
        CPRO_UsrAprueba: "", //TODAVIA
        CPRO_FecAprueba: null,
        CPRO_UsrAceptaRechaza: "",
        CPRO_FecAceptaRechaza: null,
        CPRO_FecTarifa: groupProforma.fechaInicio,
        CPRO_FecTarifaHasta: groupProforma.fechaFin,
        TIPO_TabMND: "",//Buscar
        TIPO_CodMND: "",//Buscar

        CPRO_Execpcion: excepcion,//groupProforma.excepcion,
        CPRO_Observaciones: groupProforma.observaciones,//groupProforma.observaciones,
        AUDI_UsrCrea: codusuario,// "ext_mcarhuavilca", //LOGUEADO
        AUDI_FecCrea: this.fechaActual, //FECHA DEL SISTEMA
        AUDI_UsrMod: this.Modo == 'U' ? codusuario : '', //Buscar
        AUDI_FecMod: null,
        CPRO_TABESTADO: "WEB",
        CPRO_CODESTADO: estado,//groupProforma.estado,
        CCOT_NumDoc: "",
        Accion: this.Modo,
        codcontacto: groupProforma.contacto,
        correousuario: correo,
        glosacondiciones: groupProforma.glosa,
        // observacionesIngles: groupProforma.observacionesIngles,
        ListaDetalleProformar: this.vDetalleProforma,//this.listaAgrega
        ListarDetalleProformaServLocales: this.vDetalleProformaSL,
        ListarDetalleProformaGastoLocales: this.vDetalleProformaGL
      };




      this.proformaService.guardarProforma(requestGuardaProforma).subscribe(response => {


        if (response.datos.status === 200) {

          this.util.showMessage('GUARDADO CORRECTAMENTE');

          this.Salir('')

        }

      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);

      })


    } else {

      this.util.showMessage("debe haber una tarifa como minimo")


    }


  }


  //-------------------------------------------------------------------------------------------------
  @ViewChild('checkboxRef') checkboxRef: any;
  @ViewChild('checkboxrefsl') checkboxrefsl: any;
  @ViewChild('checkboxGeneral') checkboxGeneral: any;
  @ViewChild('checkboxGeneralSL') checkboxGeneralSL: any;




  //PRUEBA FUNCIONES CHECKBOX
  selectAllRows(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedRows = this.dataTable.slice(); // Seleccionar todas las filas
    } else {
      this.selectedRows = []; // Deseleccionar todas las filas
    }
    this.updateListaAgrega();
  }

  selectRow(checkbox: any, row: any) {




    //alert(checkbox.checked)


    /*const index = this.selectedRows.findIndex(selectedRow => selectedRow === row);
    if (index >= 0) {
      this.selectedRows.splice(index, 1);
    } else {
      this.selectedRows.push(row);
    }
    //this.updateListaAgrega();
*/

    if (checkbox.checked) {

      const index = this.ListaContedorTarifaPrincipal.findIndex(x => (x.pacK_Codigo.toString() + x.puer_codigoorigen.toString()) === (row.pueR_CodigoOrigen + row.pacK_Codigo));

      if (index == -1) {

        //   this.ListaContedorTarifaGeneral.forEach((element, index) => {
        //   if (element.pacK_Codigo ===  parseInt(valuesContenedor["contenedor"])) {
        //     this.ListaContedorTarifaGeneral.splice(index, 1); // Elimina el elemento en el índice actual
        //   }
        // });

        this.ListaContenedorPrincipal = <ListaDetalleProforma>{}
        //dprO_Numero: number,
        //dprO_ITEM: number,
        this.ListaContenedorPrincipal.tipotarifa = "Tarifa"
        this.ListaContenedorPrincipal.pacK_Codigo = row.pacK_Codigo
        this.ListaContenedorPrincipal.pacK_Desc = row.paquete,
          this.ListaContenedorPrincipal.dprO_Cantidad = row.cantidad
        this.ListaContenedorPrincipal.dcoT_PrecioUniCosto = row.costo
        this.ListaContenedorPrincipal.dcoT_TotalUniCosto = row.costo * row.cantidad
        this.ListaContenedorPrincipal.dcoT_TotalUniVenta = row.valor * row.cantidad
        this.ListaContenedorPrincipal.dcoT_PrecioUniVenta = row.valor
        this.ListaContenedorPrincipal.dprO_Importe = row.valor
        this.ListaContenedorPrincipal.dprO_CodTransportista = row.entC_CodTransportista
        this.ListaContenedorPrincipal.transportista = row.linea,
          this.ListaContenedorPrincipal.dprO_FechaInicio = row.conT_FecIni
        this.ListaContenedorPrincipal.dprO_FechaFin = row.conT_FecFin
        this.ListaContenedorPrincipal.dprO_DiasLibres = row.soes_diassobrestadiacliente
        //entC_CodShipper: number,
        //dprO_ETD: string,
        //dprO_ETA: string,
        //dprO_TiempoTranscurrido: number,
        //dprO_CUTOFF: string,
        //dprO_Seleccionada: boolean,
        this.ListaContenedorPrincipal.conT_Codigo = row.codigo
        this.ListaContenedorPrincipal.contrato = row.contrato
        //codigo: string ,
        this.ListaContenedorPrincipal.accion = 'I'
        this.ListaContenedorPrincipal.fechainicioformateada = row.fechainicioformateada,
          this.ListaContenedorPrincipal.fechafinformateada = row.fechafinformateada,
          this.ListaContenedorPrincipal.puer_codigoorigen = row.pueR_CodigoOrigen
        this.ListaContenedorPrincipal.puertoorigen = row.puertoorigen
        this.ListaContenedorPrincipal.puer_codigodestino = row.pueR_CodigoDestino
        this.ListaContenedorPrincipal.puertodestino = row.puertodestino

        this.ListaContenedorPrincipal.cpro_CODESTADO = '001'
        this.ListaContenedorPrincipal.cpro_TABESTADO = 'WEB'
        this.ListaContenedorPrincipal.estado = "INGRESADO"



        this.ListaContedorTarifaPrincipal.push(this.ListaContenedorPrincipal);
        this.ListaContedorTarifaPrincipaltmp.push(this.ListaContenedorPrincipal)

        this.dataTablePrincipal = Array.from(this.ListaContedorTarifaPrincipaltmp)

        /* for (let i = this.ListaContedorTarifaGeneral.length - 1; i >= 0; i--) {
          const element = this.ListaContedorTarifaGeneral[i];
          if ((element.pacK_Codigo.toString() + element.pueR_CodigoOrigen.toString()) === (valuesContenedor["contenedor"]+valuesContenedor["ptoOrigen"].codigo)) {
            this.ListaContedorTarifaGeneral.splice(i, 1); // Elimina el elemento en el índice actual
          }
        }
      
          this.dataTable =Array.from(this.ListaContedorTarifaGeneral);
      
         }
      */

      }


    }


  }






  selectRowSL(checkbox: any, row: any) {


    if (checkbox.checked) {

      const index = -1// this.ListaSLPrincipal.findIndex(x=> (x.pacK_Codigo.toString() + x.puer_codigoorigen.toString()) === (row.pueR_CodigoOrigen + row.pacK_Codigo ));

      if (index == -1) {


        this.ItemSLPrincipal = <listadoserviciolocales>{}



        this.ItemSLPrincipal.item = row.item
        this.ItemSLPrincipal.codterminal = row.codterminal
        this.ItemSLPrincipal.codalmacen = row.codalmacen
        this.ItemSLPrincipal.value = row.value
        this.ItemSLPrincipal.transportista = row.transportista
        this.ItemSLPrincipal.remarks = row.remarks
        this.ItemSLPrincipal.equivalentCode = row.equivalentCode
        this.ItemSLPrincipal.serV_Codigo = row.serV_Codigo
        this.ItemSLPrincipal.conceptDescription = row.conceptDescription
        this.ItemSLPrincipal.idunit = row.idunit
        this.ItemSLPrincipal.conS_CodBas = row.conS_CodBas
        this.ItemSLPrincipal.conS_Desc_SPA = row.conS_Desc_SPA
        this.ItemSLPrincipal.dopE_PrecioUnitVta = row.dopE_PrecioUnitVta
        this.ItemSLPrincipal.dopE_PrecioUnitCosto = row.dopE_PrecioUnitCosto
        this.ItemSLPrincipal.dopE_Cantidad = row.dopE_Cantidad
        this.ItemSLPrincipal.dopE_PrecioTotVta = row.dopE_PrecioTotVta
        this.ItemSLPrincipal.dopE_PrecioTotCosto = row.dopE_PrecioTotCosto
        this.ItemSLPrincipal.tipE_Codigo = row.tipE_Codigo
        this.ItemSLPrincipal.entC_Codigo = row.entC_Codigo
        this.ItemSLPrincipal.almacen = row.almacen
        this.ItemSLPrincipal.terminal = row.terminal
        this.ItemSLPrincipal.idtariffDetail = row.idtariffDetail
        this.ItemSLPrincipal.dtaR_Item = row.dtaR_Item
        this.ItemSLPrincipal.currency = row.currency
        this.ItemSLPrincipal.tipO_CodMnd = row.TIPO_CodMnd
        this.ItemSLPrincipal.moneda = row.moneda
        this.ItemSLPrincipal.idTariff = row.idTariff
        this.ItemSLPrincipal.TotalProfit = row.TotalProfit
        this.ItemSLPrincipal.accion = "I"
        //this.ItemSLPrincipal.cantidad = row.cantidad

        this.ItemSLPrincipal.puertOrigen_codigo = this.codpuertoOrigenGlobal
        this.ItemSLPrincipal.puertDestinmo_codigo = this.codpuertoDestinoGlobal
        this.ItemSLPrincipal.puertoOrigen = this.puertoOrigenGlobal
        this.ItemSLPrincipal.puertoDestino = this.puertoDestinoGlobal
        this.ItemSLPrincipal.cod_paquete = this.codpaqueteGlobal
        this.ItemSLPrincipal.paquete = this.paqueteGlobal
        this.ItemSLPrincipal.cantidad = this.cantidadGlobal
        this.ItemSLPrincipal.total = this.cantidadGlobal * row.dopE_PrecioUnitVta



        this.ListaSLPrincipal.push(this.ItemSLPrincipal);

        this.dataTablePrincipalSL = Array.from(this.ListaSLPrincipal)

        /*this.ListaContenedorPrincipal.tipotarifa = "Servicio Local"
        this.ListaContenedorPrincipal.pacK_Codigo =  //row.pacK_Codigo
        this.ListaContenedorPrincipal.pacK_Desc = //row.paquete,
        this.ListaContenedorPrincipal.dprO_Cantidad = row.cantidad
        this.ListaContenedorPrincipal.dcoT_PrecioUniCosto =row.DOPE_PrecioTotCosto//row.costo
        this.ListaContenedorPrincipal.dcoT_TotalUniCosto = row.DOPE_PrecioTotCosto * row.cantidad
        this.ListaContenedorPrincipal.dcoT_TotalUniVenta =  row.DOPE_PrecioTotVta*row.cantidad
        this.ListaContenedorPrincipal.dcoT_PrecioUniVenta = row.DOPE_PrecioTotVta
        this.ListaContenedorPrincipal.dprO_Importe = row.DOPE_PrecioTotVta
        this.ListaContenedorPrincipal.dprO_CodTransportista = row.value
        this.ListaContenedorPrincipal.transportista = row.transportista,
        this.ListaContenedorPrincipal.dprO_FechaInicio = //row.conT_FecIni
        this.ListaContenedorPrincipal.dprO_FechaFin = //row.conT_FecFin
        this.ListaContenedorPrincipal.dprO_DiasLibres = 0//row.soes_diassobrestadiacliente
        //entC_CodShipper: number,
        //dprO_ETD: string,
        //dprO_ETA: string,
        //dprO_TiempoTranscurrido: number,
        //dprO_CUTOFF: string,
        //dprO_Seleccionada: boolean,
        this.ListaContenedorPrincipal.conT_Codigo= //row.codigo
        this.ListaContenedorPrincipal.contrato = //row.contrato
        //codigo: string ,
        this.ListaContenedorPrincipal.accion = 'I'
        this.ListaContenedorPrincipal.fechainicioformateada = //row.fechainicioformateada ,
        this.ListaContenedorPrincipal.fechafinformateada = //row.fechafinformateada,
        this.ListaContenedorPrincipal.puer_codigoorigen= //row.pueR_CodigoOrigen
        this.ListaContenedorPrincipal.puertoorigen = //row.puertoorigen
        this.ListaContenedorPrincipal.puer_codigodestino = //row.pueR_CodigoDestino
        this.ListaContenedorPrincipal.puertodestino= //row.puertodestino
      
      
         this.ListaContedorTarifaPrincipal.push( this.ListaContenedorPrincipal);
      */
        this.dataTablePrincipal = Array.from(this.ListaContedorTarifaPrincipal)

        /* for (let i = this.ListaContedorTarifaGeneral.length - 1; i >= 0; i--) {
          const element = this.ListaContedorTarifaGeneral[i];
          if ((element.pacK_Codigo.toString() + element.pueR_CodigoOrigen.toString()) === (valuesContenedor["contenedor"]+valuesContenedor["ptoOrigen"].codigo)) {
            this.ListaContedorTarifaGeneral.splice(i, 1); // Elimina el elemento en el índice actual
          }
        }
      
          this.dataTable =Array.from(this.ListaContedorTarifaGeneral);
      
         }
      */

      }

    }

  }

  updateListaAgrega() {
    /* const values = this.groupProforma.value
     this.listaAgrega = this.selectedRows.map(row => {
       const fechaParts = row.conT_FecFin.split("-");
       const fechaFin = new Date(
         parseInt(fechaParts[2]), // Año
         parseInt(fechaParts[1]) - 1, // Mes (restar 1 ya que los meses son zero-based)
         parseInt(fechaParts[0]) // Día
       );
 
       return {
         DPRO_Numero:  values["nroProforma"] == null ? 0:values["nroProforma"] ,
         PACK_Codigo: row.pacK_Codigo,
         DPRO_Cantidad: row.cantidad,
         DCOT_PrecioUniCosto: row.valor,
         DCOT_TotalUniCosto: row.valor,
         DCOT_TotalUniVenta: row.valor,
         DCOT_PrecioUniVenta: row.valor,
         DPRO_Importe: row.valor,
         DPRO_CodTransportista: row.entC_CodTransportista,
         DPRO_FechaInicio :  row.conT_FecIni ,
         DPRO_FechaFin: row.conT_FecFin,//fechaFin,
         DPRO_DiasLibres: row.dprO_DiasLibres,
         ENTC_CodShipper: row.entC_CodShipper,
         DPRO_ETD: row.dprO_ETD,
         DPRO_ETA: row.dprO_ETA,
         DPRO_TiempoTranscurrido: row.dprO_TiempoTranscurrido,
         DPRO_Seleccionada: true,
         CONT_Codigo  :row.codigo,
         DPAccion: 'I',
 
       };
     });
     console.log(this.listaAgrega)
     */
  }


  listaContactoJ: ListaContactoJ[] = []
  autocompleteTrigger: MatAutocompleteTrigger;
  cargaContacto(value) {
    const requestContacto: contactoRequest = <contactoRequest>{}
    requestContacto.pintENTC_CodPadre = value
    requestContacto.pvchENT_NombreCompleto = ""
    this.proformaService.listaContacto(requestContacto).subscribe(response => {
      this.listaContactoJ = []
      for (let i = 0; i < response.datos.result.length; i++) {
        if (response.datos.result[i].relA_Tipos == "CON") {
          const contactoJ: ListaContactoJ = {
            entC_CodHijo: response.datos.result[i].entC_CodHijo,
            entC_NomCompleto: response.datos.result[i].entC_NomCompleto
          };
          this.listaContactoJ.push(contactoJ);
        }
      }
      if (this.listaContactoJ.length <= 0) {
        this.util.showMessageError('NO EXISTE CONTACTOS PARA ESTE CLIENTE');
      } else {
        this.util.showMessageError('Tiene CLIENTES');
      }
    });
  }

  cargaProformaDetalle(value) {
    const requestProformaDetalle: proformaDetalleRequest = <proformaDetalleRequest>{}
    requestProformaDetalle.pintCPRO_Numero = value
    this.proformaService.detalleProforma(requestProformaDetalle).subscribe(response => {
      this.dataTableEdita = response.datos.result.map(registro => {
        // Formatear la fecha de inicio
        const fechaInicio = new Date(registro.dprO_ETD);
        const fechaInicioFormateada = fechaInicio.toLocaleDateString();
        // Formatear la fecha de fin
        const fechaFin = new Date(registro.dprO_ETA);
        const fechaFinFormateada = fechaFin.toLocaleDateString();

        return {
          ...registro,
          //paquete: registro.,
          linea: registro.transportista,
          paquete: registro.pacK_Desc,
          fechainicioformateada: fechaInicioFormateada,
          fechafinformateada: fechaFinFormateada,
          valor: registro.dcoT_TotalUniCosto

          //dprO_ETD: fechaInicioFormateada,
          //dprO_ETA: fechaFinFormateada
        };
      });
    });
  }

  editarItem(row) {
    //const valuesContenedor = this.groupContenedor.value
    this.groupContenedor.controls['cantidad'].setValue(row.cantidad);
    this.groupContenedor.controls['contenedor'].setValue(row.pacK_Codigo.toString());

  }

  transportistaglobal: number
  filtrarsl() {

    const values = this.groupContenedor.value

    const almacen = values['almacen']
    const terminal = values['terminal']
    const idtarifa = values['idtarifa']


    this.listarserviciolocales(parseInt(values["descripcion"]), almacen, terminal, '', this.transportistaglobal, false, this.codpuertoOrigenGlobal, this.codpuertoDestinoGlobal, this.puertoOrigenGlobal, this.puertoDestinoGlobal, this.codpaqueteGlobal, this.paqueteGlobal, this.cantidadGlobal);


  }



  listarServiciosLocalesCombo(){
   var values =  this.groupContenedor.value
   this.transportistaglobal= parseInt(values["transportistaSL"])


   const listatarifafiltrada = this.ListaContedorTarifaGeneral.filter(elemento => elemento.entC_CodTransportista === this.transportistaglobal);
   
   let cantidad : number
   let puertoorigen :number
   let puertodestino : number 
   let paquete : number 
   let descptoorigen :string 
   let descptodestino : string 

   if ( listatarifafiltrada  != null){

   cantidad  = listatarifafiltrada[0].cantidad
   puertoorigen  = listatarifafiltrada[0].pueR_CodigoOrigen
   puertodestino  = listatarifafiltrada[0].pueR_CodigoDestino
   paquete =  listatarifafiltrada[0].pacK_Codigo
   descptoorigen =  listatarifafiltrada[0].puertoorigen
   descptodestino =  listatarifafiltrada[0].puertodestino 
   }
   
    this.codpuertoOrigenGlobal = puertoorigen
    this.codpuertoDestinoGlobal = puertodestino
    this.puertoOrigenGlobal = descptoorigen
    this.puertoDestinoGlobal =descptodestino
    this.codpaqueteGlobal = paquete.toString();
    this.paqueteGlobal = ''
    this.cantidadGlobal = cantidad

    // alert(this.transportistaglobal)
    this.listarserviciolocales(0, '', '', '',this.transportistaglobal, true, this.codpuertoOrigenGlobal, this.codpuertoDestinoGlobal, this.puertoOrigenGlobal, this.puertoDestinoGlobal, this.codpaqueteGlobal, this.paqueteGlobal, this.cantidadGlobal);

  }

  response_serviciolocales: response_serviciolocales[]
  listado_serviciolocales: listadoserviciolocales[]
  request_serviciolocales: request_serviciolocal;
  listartmp: ListItem;

  codpuertoOrigenGlobal: number
  codpuertoDestinoGlobal: number
  puertoOrigenGlobal: string
  puertoDestinoGlobal: string
  codpaqueteGlobal: string
  paqueteGlobal: string
  cantidadGlobal: number


  listarserviciolocales(id_tarifa, almacen, terminal, descripcion, transportista: number, limpiar: boolean, codpuertoOrigen, codpuertoDestino, puertoOrigen, puertoDestino, codpaquete, paquete, cantidad) {

    this.transportistaglobal = transportista;
    this.codpuertoOrigenGlobal = codpuertoOrigen
    this.codpuertoDestinoGlobal = codpuertoDestino
    this.puertoOrigenGlobal = puertoOrigen
    this.puertoDestinoGlobal = puertoDestino
    this.codpaqueteGlobal = codpaquete
    this.paqueteGlobal = paquete
    this.cantidadGlobal = cantidad





    const values = this.groupContenedor.value

    this.request_serviciolocales = <request_serviciolocal>{}

    this.request_serviciolocales.IdTariff = id_tarifa// parseInt( values["idtarifa"]);
    this.request_serviciolocales.ENTC_CodigoTransportista = this.transportistaglobal;
    this.request_serviciolocales.psinCCOT_Tipo = 1

    if (almacen == '') {

      this.request_serviciolocales.Almacen = null
    } else {
      this.request_serviciolocales.Almacen = almacen
    }

    if (terminal == '') {

      this.request_serviciolocales.Terminal = null//values["terminal"]
    } else {

      this.request_serviciolocales.Terminal = terminal//values["terminal"]
    }
    //this.request_serviciolocales.Almacen  = almacen//values["almacen"]

    this.request_serviciolocales.descripcion = descripcion//values["descripcion"]

    this.proformaService.listarserviciolocales(this.request_serviciolocales).subscribe
      ((response) => {



        if (response.datos.status === 200) {

          this.listado_serviciolocales = response.datos.result;
          this.dataTablesl = this.listado_serviciolocales

          if (limpiar) {
            this.listDescripcion = []

          }



          const list = this.listado_serviciolocales.filter((obj, index, self) => {
            return self.findIndex((item) => item.remarks === obj.remarks) === index;
          });

          //this.listado_serviciolocales.filter((valor, indice, self) => {
          //  return self.indexOf(valor) === indice;
          // });

          this.listartmp = <ListItem>{}
          this.listartmp.codigo = "0"
          this.listartmp.nombre = "Seleccione"
          this.listDescripcion.push(this.listartmp)

          list.forEach(element => {
            this.listartmp = <ListItem>{}
            this.listartmp.codigo = element.idTariff.toString()
            this.listartmp.nombre = element.remarks
            this.listDescripcion.push(this.listartmp)
          });


        }

      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);
        this.listDescripcion = []

      });


  }

  request_terminal: listaClienteRequest;
  listTerminal: ListItem[] = [];
  item_termimal: ListItem;
  response_terminal: ListaCliente2[];
  listarterminal() {

    this.request_terminal = <listaClienteRequest>{}
    this.request_terminal.pintENTC_Codigo = null,
      this.request_terminal.pvchENTC_NomCompleto = null,
      this.request_terminal.pvchTIPE_Codigo = 17,
      this.request_terminal.pvchENTC_DocIden = ""

    this.proformaService.listaCliente(this.request_terminal).subscribe
      ((response) => {


        if (response.datos.status === 200) {

          this.response_terminal = response.datos.result;

          this.item_termimal = <ListItem>{}

          this.item_termimal.codigo = "";
          this.item_termimal.nombre = "Seleccione";
          this.listTerminal.push(this.item_termimal);

          this.response_terminal.forEach(element => {
            this.item_termimal = <ListItem>{}

            this.item_termimal.codigo = element.entC_Codigo.toString();
            this.item_termimal.nombre = element.entC_RazonSocial;
            this.listTerminal.push(this.item_termimal);
          });
        }

      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);

      });



  }


  request_almacen: listaClienteRequest;
  listAlmacen: ListItem[] = [];
  item_almacen: ListItem;
  response_almacen: ListaCliente2[];
  listaralmacen() {

    this.request_almacen = <listaClienteRequest>{}
    this.request_almacen.pintENTC_Codigo = null,
      this.request_almacen.pvchENTC_NomCompleto = null,
      this.request_almacen.pvchTIPE_Codigo = 14,
      this.request_almacen.pvchENTC_DocIden = ""

    this.proformaService.listaCliente(this.request_almacen).subscribe
      ((response) => {


        if (response.datos.status === 200) {

          this.response_almacen = response.datos.result;

          this.item_almacen = <ListItem>{}

          this.item_almacen.codigo = "";
          this.item_almacen.nombre = "Seleccione";
          this.listAlmacen.push(this.item_almacen);


          this.response_almacen.forEach(element => {
            this.item_almacen = <ListItem>{}

            this.item_almacen.codigo = element.entC_Codigo.toString();
            this.item_almacen.nombre = element.entC_RazonSocial;
            this.listAlmacen.push(this.item_almacen);
          });
        }

      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);

      });



  }







  deletetarifa(row) {

    const index = this.ListaContedorTarifaPrincipaltmp.indexOf(row)

    if (index !== -1) {
      this.ListaContedorTarifaPrincipaltmp.splice(index, 1);
    }

    this.dataTablePrincipal = Array.from(this.ListaContedorTarifaPrincipaltmp)

    const index2 = this.ListaContedorTarifaPrincipal.indexOf(row)//this.ListaContedorTarifaPrincipal.findIndex(x => x.dprO_ITEM == row.dprO_ITEM)
    if (index2 > -1) {
      this.ListaContedorTarifaPrincipal[index2].accion = 'D'
    }


  }


  deletetarifalocal(row) {



    const index = this.ListaSLPrincipal.indexOf(row)

    if (index !== -1) {
      this.ListaSLPrincipal.splice(index, 1);
    }

    this.dataTablePrincipalSL = Array.from(this.ListaSLPrincipal)


  }



  metadataTableGL: MetadataTable[] = [
    { field: 'select', title: '', type: 'checkbox' },
    { field: 'serv_nombre_spa', title: 'CONCEPTO', type: 'text' },
    { field: 'serv_valor', title: 'MONTO TARIFA', type: 'text' },

  ];

  metadataTablePrincipalGL: MetadataTable[] = [
    { field: 'select', title: '', type: 'checkbox' },
    { field: 'serv_nombre_spa', title: 'CONCEPTO', type: 'text' },
    { field: 'serv_valor', title: 'MONTO TARIFA', type: 'text' },
    { field: 'serv_valorsugerido', title: 'MONTO SUGERIDO', type: 'number'},
    { field: 'actions', title: 'Monto Sugerido', type: 'number' },

  ];

  dataTableGL: any;

  requestservicio: requestservicios;
  listadoservicios: listadogastoslocales[];
  columnsToView: string[] = [];

  listarservicios() {

    this.requestservicio = <requestservicios>{}
    this.requestservicio.linea = 0,


      this.proformaService.listarservicio(this.requestservicio).subscribe
        ((response) => {


          if (response.datos.status === 200) {




            this.listadoservicios = response.datos.result
            this.dataTableGL = this.listadoservicios



          }

        }, (errorServicio) => {
          this.util.showMessage(errorServicio.error.meta.mensaje);

        });



  }

  isgastoslocales: boolean
  onCheckboxChange(event: any) {
    this.isgastoslocales = event.checked;
  }

  onPageChange(event: PageEvent): void {
    const pageIndex = event.pageIndex;
    if (pageIndex > 0) {

      this.storage.save("indice", pageIndex.toString())
    }

    console.log('Índice de la página actual:', pageIndex);

    // Lógica para cambiar de página si lo necesitas
    // Aquí puedes actualizar tus datos y mantener el índice del paginador
  }


  onInputBlur(event: any, row: listadoproformagastoslocales, metadataField: string) {
    const inputValue = event.target.value;

    row.serv_valorsugerido = inputValue
    //  alert(inputValue)
  }



  listadoprincipalgastolocales: listadoproformagastoslocales[] = [];
  dataTablePrincipalGL: any[] = []
  itemprincipalgastoslocales: listadoproformagastoslocales


  selectRowGL(checkbox: any, row: listadogastoslocales) {


    if (checkbox.checked) {

      const index = -1// this.ListaSLPrincipal.findIndex(x=> (x.pacK_Codigo.toString() + x.puer_codigoorigen.toString()) === (row.pueR_CodigoOrigen + row.pacK_Codigo ));

      if (index == -1) {


        this.itemprincipalgastoslocales = <listadoproformagastoslocales>{}

        this.itemprincipalgastoslocales.dpro_Numero = 0
        this.itemprincipalgastoslocales.serv_codigo = row.serv_codigo
        this.itemprincipalgastoslocales.serv_valor = row.serv_valor
        this.itemprincipalgastoslocales.serv_nombre_spa = row.serv_nombre_spa
        this.itemprincipalgastoslocales.serv_valorsugerido = row.montosugerido
        this.itemprincipalgastoslocales.accion = 'I'


        this.listadoprincipalgastolocales.push(this.itemprincipalgastoslocales);

        this.dataTablePrincipalGL = Array.from(this.listadoprincipalgastolocales)

      }
    }

  }



  listadoproformagastolocales: listadoproformagastoslocales[];
  ListarDetalleProformaGastoLocales(nroproforma: number) {

    this.requestDetallePro = <proformaDetalleRequest>{}
    this.requestDetallePro.pintCPRO_Numero = nroproforma.toString();


    this.proformaService.listadoproformadetgastoslocales(this.requestDetallePro).subscribe
      ((response) => {



        if (response.datos.status === 200) {
          // this.util.showMessage(this.ListaResponseInsertaCoti.meta.mensaje)

          this.isgastoslocales = true

          this.listadoproformagastolocales = response.datos.result.map(registro => {

            const Accion = 'U'

            return {
              ...registro,
              accion: Accion,

            };

          });

          this.listadoprincipalgastolocales = this.listadoproformagastolocales

          this.dataTablePrincipalGL = Array.from(this.listadoprincipalgastolocales)

        }



      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);


      });




  }

  textoFiltroAgente: string = '';
  cargaTodosAgentes() {
    const requestClienteT: listaClienteRequest = <listaClienteRequest>{};
    requestClienteT.pintENTC_Codigo = null,
      requestClienteT.pvchENTC_NomCompleto = "",
      requestClienteT.pvchTIPE_Codigo = 6,
      requestClienteT.pvchENTC_DocIden = ""
    this.proformaService.listaCliente(requestClienteT).subscribe(response => {
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListIncoter = {
          nombre: response.datos.result[i].entC_NomCompleto,
          codigo: response.datos.result[i].entC_Codigo.toString(),
        }
        this.listAgente.push(item)
      }
    })
  }


  cargaTodosAgente() {
    const requestClienteT: listaClienteRequest = <listaClienteRequest>{};
    requestClienteT.pintENTC_Codigo = null,
      requestClienteT.pvchENTC_NomCompleto = "",
      requestClienteT.pvchTIPE_Codigo = 2,
      requestClienteT.pvchENTC_DocIden = ""
    this.proformaService.listaCliente(requestClienteT).subscribe(response => {
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListIncoter = {
          nombre: response.datos.result[i].entC_NomCompleto,
          codigo: response.datos.result[i].entC_Codigo.toString(),
        }
        this.listAgente.push(item)
      }
    })
  }

  codigoAgentePricing: number
  nombreAgentePricing: string
  agenteSeleccionado: any;
  agenteSelecciona(event: MatAutocompleteSelectedEvent) {
    this.agenteSeleccionado = this.listAgente.find(option => option.nombre.toLowerCase() === event.option.value.toLowerCase());
    this.codigoAgentePricing = this.agenteSeleccionado.codigo
    this.nombreAgentePricing = this.agenteSeleccionado.nombre
  }







  editargeneral(row) {


    row.IsEditing = true

    this.dataTablePrincipal = Array.from(this.ListaContedorTarifaPrincipaltmp)

  }

}


enum Tabs {
  New_Proforma = 0,
  All_Employees = 1
}

import { Component, ContentChild, EventEmitter, Input, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { dbmetadata, dbmetadatatable } from './domain/dbTable-domain';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-db-table',
  templateUrl: './db-table.component.html',
  styleUrls: ['./db-table.component.css']
})
export class DbTableComponent {

  constructor(
    public readonly util: UtilService
  ) { }


  @Input() dataTable: any[] = []
  @Input() metadataTable: dbmetadatatable[] = []
  @Input() key: string = ''
  @Input() rowsOption: number[] = [20, 30, 40, 50]
  @Input() caption: boolean = false
  @Input() title: string = ''

  @ContentChild('addHeaderStart', { static: true }) addHeaderStart!: TemplateRef<any>
  @ContentChild('addBodyStart', { static: true }) addBodyStart!: TemplateRef<any>
  @ContentChild('addHeaderEnd', { static: true }) addHeaderEnd!: TemplateRef<any>
  @ContentChild('addBodyEnd', { static: true }) addBodyEnd!: TemplateRef<any>

  @Output() Row = new EventEmitter<any>()
  onRowSelect(event) {
    this.Row.emit(event.data)
  }

  filtrosGlobal: any[] = []
  metadata: dbmetadatatable[] = []
  paginator: boolean = false
  ngOnChanges(changes: SimpleChanges): void {
    this.metadata = this.metadataTable
    if (this.dataTable.length > 20) { this.paginator = true }
    this.metadataTable.forEach(r => {
      this.filtrosGlobal.push(r.field)
    })
  }



  viewData() {
    console.log('DATATABLE', this.dataTable)
    // console.log('PRECARGA', this.precarga)
    console.log('KEY', this.key)
    console.log('METADATA', this.metadata)
    console.log('FILTRO GLOBAL', this.filtrosGlobal)
    console.log('METADATATABLE', this.metadataTable)
    console.log('CAPTION', this.caption)
    console.log('CABECERAS', this.cabecerastotal)
    // console.log('TABLE PRIME', this.table)
    // if (this.group) {
    //   console.log('GROUP', this.group);
    //   console.log('GROUP VALUE', this.group.value);
    // }

  }
  cabecerastotal: any[] = []
  formatHeaders(): any[] {
    const cabeceras: number[] = [];

    this.metadata.forEach(r => {
      if (r.positiongroup !== undefined) {
        if (!cabeceras.includes(r.positiongroup)) {
          cabeceras.push(r.positiongroup);
        }
      }
    });
    this.cabecerastotal = cabeceras
    return cabeceras;
  }

  colspanGenerate(group, position): number {
    let value = 1
    if (group != undefined) {
      this.metadata.forEach(r => {
        if (group === r.groupby) {
          value++
        }
      })

      value = value - 1
    }
    console.log(value)
    return value
  }
}

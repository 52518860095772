<!-- <div *ngIf="isPopupVisible" class="overlay"></div> -->
<p-confirmPopup></p-confirmPopup>

<p-table [value]="data" [dataKey]="key" [tableStyle]="{ 'min-width': '60rem' }" [paginator]="paginator"
    [rowsPerPageOptions]="rows" [rows]="rows[0]" [showCurrentPageReport]="true"
    currentPageReportTemplate="{first} de {totalRecords}" scrollHeight="400px"
    styleClass="p-datatable-sm p-datatable-gridlines" [resizableColumns]="true">

    <!-- HEADER -->
    <ng-template pTemplate="header">
        <tr class="header-table">
            <th></th>
            <th [pSortableColumn]="item.field" pResizableColumn *ngFor="let item of metadatatable" class="header">
                <div class="flex align-items-center items-header">
                    <span [innerHTML]="item.title"></span>
                    <p-sortIcon [field]="item.field"></p-sortIcon>
                    <p-columnFilter type="{{item['type']}}" field="{{item.field}}" display="menu" *ngIf="filter"
                        appendTo="body"></p-columnFilter>
                </div>
            </th>
        </tr>
    </ng-template>

    <!-- BODY -->
    <ng-template pTemplate="body" let-rowdata let-expanded="expanded">
        <tr class="body-table" [pSelectableRow]="rowdata" style="background-color: rgb(247,247, 247)">
            <td>
                <button type="button" pButton pRipple [pRowToggler]="rowdata"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td *ngFor="let item2 of metadatatable">
                <ng-container *ngIf="item2.type !== 'array'" class="body">
                    <!--==========Solo Vista (PADRES)==============-->
                    <span class="datos-body" *ngIf="item2.type == 'text'" [ngStyle]="generateStyle(item2.style)">
                        {{rowdata[item2.field]}}
                    </span>

                    <span class="datos-body" *ngIf="item2.type == 'date'">
                        {{ rowdata[item2.field] ? util.formatearfecha(rowdata[item2.field], 'dd/mm/yyyy') : '' }}
                    </span>

                    <span class="datos-body" *ngIf="item2.type == 'datetime'">
                        {{util.formatearfecha(rowdata[item2.field],'dd/mm/yyyy hh:mm')}}
                    </span>



                    <div class="datos-body" *ngIf="item2.type == 'checkbox'"><!--Es un checkbox deshabilitado-->
                        <p-checkbox [(ngModel)]="rowdata[item2.field]" [binary]="true" inputId="binary"
                            [disabled]="true" (onChange)="item2.function({event:$event,row:rowdata})">
                        </p-checkbox>
                    </div>

                    <!--==========Campos Interactivos (PADRES)==============-->

                    <div class="datos-body" *ngIf="item2.type == 'icon'"><!--Es para poner iconos-->
                        <img [src]="item2.function(rowdata)" alt="" style="height: 20px;">
                    </div>

                    <span class="datos-body" *ngIf="item2.type == 'dateEdit'"><!--Es un calendar editable-->
                        <p-calendar [(ngModel)]="rowdata[item2.field]" appendTo="body"
                            (onSelect)="handleDateSelect($event)" dateFormat="dd/mm/yy">
                        </p-calendar>
                        <p-button [disabled]="!selectedDate" icon="pi pi-save"
                            (onClick)=" item2.function({event:$event,row:rowdata});isDisabled()">
                        </p-button>


                    </span>

                    <span class="datos-body" *ngIf="item2.type == 'dropdownEdit'"><!--Es un dropdown editable-->
                        <p-dropdown [options]="item2.list" optionLabel="nombre" optionValue="codigo" appendTo="body"
                            [filter]="true" [showClear]="true" class="p-inputtext-sm"
                            (onChange)="handleDropdownSelect($event)" [(ngModel)]="rowdata[item2.field]">
                        </p-dropdown>
                        <p-button [disabled]="!selectDropdown" icon="pi pi-save"
                            (onClick)=" item2.function({event:$event,row:rowdata});isDisabled()">
                        </p-button>


                    </span>

                    <div class="datos-body" *ngIf="item2.type == 'checkboxEdit'"><!--Es un checkbox editable-->
                        <p-checkbox [(ngModel)]="rowdata[item2.field]" [binary]="true" inputId="binary"
                             (onChange)="item2.function({event:$event,row:rowdata})">
                        </p-checkbox><!--[disabled]="true"-->


                    </div>

                    <!-- <div class="datos-body" *ngIf="item2.type == 'checkbox'">

                    </div> -->


                    <!--Es un checkbox que simula el hover, util cuando se tiene un pdialog para confirmacion-->
                    <!-- <div class="datos-body" *ngIf="item2.type == 'checkboxEditForConfirmation'"
                        style="position: relative;">
                        <div class="overlay12" style="position: absolute; top: 50%; left: 50%; 
                                    transform: translate(-50%, -50%); 
                                    width: 30px; height: 100%; /* Ajusta estos valores al tamaño del checkbox */
                                    
                                    z-index: 1;" (click)="item2.function({event:$event,row:rowdata})"
                            (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
                        </div>
                        <p-checkbox [(ngModel)]="rowdata[item2.field]" [binary]="true" inputId="binary"
                            [disabled]="false" [ngClass]="{'hovered': isHovered}">
                        </p-checkbox>
                    </div> -->

                    <span *ngIf="item2.type == 'button'"><!--Es para un boton-->
                        <p-button icon={{item2.iconobutton}} (onClick)="item2.function({event:$event,row:rowdata})" pTooltip={{item2.toolTip}}>
                        </p-button>
                    </span>
                    <span *ngIf="item2.type == 'checkboxEditForConfirmation'"><!--Boton q simula un checbox, usarlo para cuando quieres un checkbox q al presionar sale un p-confirm-->
                        <p-button 
                          [icon]="rowdata[item2.field]?'pi pi-check' :'pi pi-circle'"
                          (onClick)="item2.function({event:$event,row:rowdata})"
                          pTooltip="{{item2.toolTip}}"
                          
                          [styleClass]="rowdata[item2.field] ? '' : 'p-button-secondary'"
                        ></p-button>
                      </span>


                    <span *ngIf="item2.type == 'buttonOneUse'"><!--Es un Boton que si recibe true de deshabilita-->
                        <p-button icon={{item2.iconobutton}} [disabled]="bool(rowdata[ item2.field])" 
                            (onClick)="item2.function({event:$event,row:rowdata})" pTooltip={{item2.toolTip}} [styleClass]="rowdata[item2.field] ? 'p-button-success' : ''">
                        </p-button>
                    </span>

                    <span class="row-button-table" *ngIf="item2.type == 'textAreaEdit'">
                        <p-button pRipple icon="pi pi-eye"
                            (click)="showDialog(rowdata,item2.title,item2.field,'textAreaEdit',item2.function, rowdata[item2.field])"
                            class="p-button-rounded p-button-text" styleClass=" p-button-secondary"
                            pTooltip="Ver Detalle" [tooltipPosition]="'left'"
                            *ngIf="rowdata[item2.field] == null"></p-button>

                        <p-button pRipple icon="pi pi-eye"
                            (click)="showDialog(rowdata,item2.title,item2.field,'textAreaEdit',item2.function, rowdata[item2.field])"
                            class="p-button-rounded p-button-text p-button-info" pTooltip="Ver Detalle"
                            [tooltipPosition]="'left'" *ngIf="rowdata[item2.field] != null"></p-button>
                    </span>

                    <span class="row-button-table"
                        *ngIf="item2.type == 'textArea'"><!--Es para un boton que abre un textArea-->
                        <p-button pRipple icon="pi pi-eye"
                            (click)="showDialog(rowdata,item2.title,item2.field,'textArea',item2.function, rowdata[item2.field])"
                            class="p-button-rounded p-button-text p-button-danger" pTooltip="Ver Detalle"
                            [tooltipPosition]="'left'"></p-button>
                    </span>

                </ng-container>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-row>
        <tr>
            <td [attr.colspan]="metadatatable.length + 1"> <!-- Ajusta el colspan -->
                <div class="p-3">
                    <p-table [value]="row['listadonavedetalle']" [dataKey]="key">
                        <ng-template pTemplate="header">
        <tr class="header-table">
            <ng-container *ngFor="let item of metadatechildren">
                <ng-container *ngFor="let item2 of item.metadata">
                    <th pSortableColumn="{{item2.field}}">
                        <span [innerHTML]="item2.title"></span><!--{{item2.title}} -->
                        <p-sortIcon [field]="item2.field"></p-sortIcon>
                        <p-columnFilter type="{{item2['type']}}" field="{{item2.field}}" display="menu" *ngIf="filter"
                        appendTo="body"></p-columnFilter>
                    </th>
                </ng-container>
            </ng-container>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-order>
        <tr>
            <ng-container *ngFor="let item of metadatechildren">
                <ng-container *ngFor="let item2 of item.metadata">
                    <!--==========Solo Vista (HIJOS)==============-->
                    <td class="datos-hijos-body" *ngIf="item2.type == 'text'" [ngStyle]="generateStyle(item2.style)">
                        {{order[item2.field]}}
                    </td>
                    <td *ngIf="item2.type == 'date'">
                        {{ order[item2.field]!=null ? util.formatearfecha(order[item2.field], 'dd/mm/yyyy') : '' }}
                    </td>
                    <td class="datos-hijos-body" *ngIf="item2.type == 'checkbox'"><!--Button Deshabilitado-->
                        <p-checkbox [(ngModel)]="order[item2.field]" [binary]="true" inputId="binary" [disabled]="true"
                            (onChange)="item2.function({event:$event,row:order})">
                        </p-checkbox>
                    </td>
                    <!--==========Campos Interactivos (HIJOS)==============-->
                    <td *ngIf="item2.type == 'button'">
                        <p-button icon="pi pi-sitemap" (onClick)="item2.function({event:$event,row:order})"> </p-button>
                        <!-- <p-button icon="pi pi-sitemap" (onClick)="item2.function({event:$event,row:order})">
                        </p-button> -->
                    </td>
                    <td class="datos-body" *ngIf="item2.type == 'dropdownEdit'"><!--Es un dropdown editable-->
                        <p-dropdown [options]="item2.list" optionLabel="nombre" optionValue="codigo" appendTo="body"
                            [filter]="true" [showClear]="true" class="p-inputtext-sm"
                            (onChange)="handleDropdownSelect($event)" [(ngModel)]="order[item2.field]">
                        </p-dropdown>
                        <p-button [disabled]="!selectDropdown" icon="pi pi-save"
                            (onClick)=" item2.function({event:$event,row:order});isDisabled()">
                        </p-button>


                    </td>
                </ng-container>
            </ng-container>
        </tr>
    </ng-template>
</p-table>




</div>
</td>
</tr>
</ng-template>
</p-table>




<p-dialog header="GENERAL" [(visible)]="visibleP_Dialog_General"><!--[style]="{ width: '50vw' }"-->
    <ng-template pTemplate="header">
        <span class="text-xl font-bold title-dialog">{{tituloP1_Dialog}}</span>
    </ng-template>
    <!--De anthony-->

    <!--Para TextArea-->
    <div *ngIf="visibleTextArea" class="flex py-2 justify-content-center"
        style="display: flex; justify-content: center;align-items: center; flex-direction: column-reverse; gap:10px">
        <div class="card flex justify-content-center">
            <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="datoTextArea" style="resize: none;"></textarea>
        </div>
    </div>
    <!--Para TextAreaEdit-->
    <div *ngIf="visibleTextAreaEdit" class="flex py-2 justify-content-center"
        style="display: flex; justify-content: center;align-items: center; flex-direction: column-reverse; gap:10px">
        <div class="card flex justify-content-center">
            <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="datoTextArea" style="resize: none;"></textarea>
        </div>
        <p-button icon="pi pi-check" (click)="onConfirm()"></p-button>
    </div>
</p-dialog>


<style>
    :host ::ng-deep .items-header {
        font-size: 1.4vh;
    }

    :host ::ng-deep .p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
        font-size: 1vh;
    }

    :host ::ng-deep .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
        font-size: 1.5vh;
    }

    /*.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td { ANCHO DE LAS CUADRILLAS*/
    :host ::ng-deep .p-datatable .p-sortable-column .p-sortable-column-icon {
        width: 1.5vh;

    }

    :host ::ng-deep .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
        padding: 4px 8px
    }

    :host ::ng-deep .p-datatable .p-datatable-tbody>tr>td {
        text-align: center;
    }

    :host ::ng-deep .p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
        text-align: center;
    }

    :host ::ng-deep .p-calendar {
        width: 110px;
    }

    :host ::ng-deep .p-calendar .p-inputtext {
        padding: 4px;
    }

    :host ::ng-deep .p-button {
        padding: 0.6rem;
        width: 10px;
        height: 10px;
    }

    :host ::ng-deep .pi {
        font-size: 0.7rem;
    }

    :host ::ng-deep .p-inputtext {
        padding: 4px;
    }

    /* :host ::ng-deep .p-inputtext{
        width: 35vw;
    } */

    :host ::ng-deep .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 1rem;
        height: 1rem;
    }

    :host ::ng-deep.p-dialog .p-dialog-content {
        padding: 3px;
    }

    :host ::ng-deep .p-button.p-button-icon-only.p-button-rounded {
        height: 1.5rem;
        width: 1.5rem;
        margin: 1vh;
    }

    /*Hoy*/

    /* :host ::ng-deep .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
        border-width: 0px;
    }
    :host ::ng-deep .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr:last-child>td {
        border-width: 0px;
    } */

    .overlay12:hover {
        cursor: pointer;
        /* Cambia el cursor al pasar el ratón */
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.9);
        /* Aumenta el brillo alrededor */
    }

    .overlay12::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.15);
        /* Fondo blanco más opaco */
        filter: blur(4px);
        /* Desenfoque */
        transition: opacity 0.3s ease;
        /* Suaviza la transición */
        opacity: 0;
        /* Inicialmente invisible */
    }

    .overlay12:hover::after {
        opacity: 1;
        /* Muestra el fondo desenfocado al hacer hover */
    }

    
/* :host ::ng-deep ::-webkit-scrollbar {
  width: 6px;
  height: 15px;
} */

 .Prueba{
    background-color: orange;
 }
 :host ::ng-deep .p-button .button-hover:hover {
  background-color: red;  /* Cambia al color que prefieras */
  border-color: #888; /* Cambia el color del borde en hover */
}

::ng-deep .p-button-secondary{
    border: 2px solid #ced4da !important;
    background-color: #ffffff !important;
}

::ng-deep .p-button-secondary:hover{
    border-color: #3b82f6 !important;
}
</style>
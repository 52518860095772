import { Observable } from "rxjs/internal/Observable";
import { requestCierreMensual, requestClientesRecuperados, requestPricingAtendido, requestSeguimientoCarga, requestcierremensualfinal, requestreportecomercialxmes, requesttodoslosanios } from "./request/dashboard.request";
import { ClientesRecuperadosResponse, ListaPricingResumenResponse, ListaPricingTablaResponse, ListarCierreMensualResponse, ListaSeguimientoCargaResponse, ListaTodosAniosResponse, registrarcierremensualfinalresponse } from "./response/dashboard.response";
import { Request_ExComar, listaExcomarFechas_Request } from "src/app/excomar/domain/request/excomar-request";
import { Response_ExComar, listaExcomarFechas_Response } from "src/app/excomar/domain/response/excomar-response";
import { requestRentabilidadComercial } from "src/app/dashboard-comercial-rentabilidad/domain/request/request-rentabilidad-comercial";
import { RentabilidadComercial2, RentabilidadComercialResponse2, RentabilidadComercialResponse3, RentabilidadComercialResponse6 } from "src/app/dashboard-comercial-rentabilidad/domain/response/response-rentabilidad-comercial";
import { requestProvicion, requestProvicionOrigen } from "src/app/dashboard-provicion-origen/domain/request/request-provicion-origen";
import { ProvicionOrigenResponse, ProvicionOrigenResponse2, ProvicionOrigenResponse3, ProvicionOrigenResponse4, ProvicionOrigenResponse5, ProvicionOrigenResponse7, listareportecomercialmes, reporteProvicionResponse, reporteProvicionResponse2, resposereportecomercialmes } from "src/app/dashboard-provicion-origen/domain/response/response-provicion-origen";
import { requestReporteRentabilidad } from "src/app/reporte-rentabilidad-lnegocio/domain/request/reporte-rentabilidad-lnegocio-request";
import { ListaReporteRentabilidadResponse, ListaReporteRentabilidadxMesResponse, ListaReporteRentabilidadxMesResponseTR } from "src/app/reporte-rentabilidad-lnegocio/domain/response/reporte-rentabilidad-lnegocio-response";
import { ClienteProfitRequest } from "src/app/reporte-cliente-profit/domain/request/cliente-profit-request";
import { ClienteProfitResponse } from "src/app/reporte-cliente-profit/domain/response/cliente-profit-response";
import { ListaConsignarioRequest, ListarankingxconsignatarioRequest } from "src/app/dashboard-gestion-excomar/domain/request/gestion-excomar-request";
import { ListaConsignarioResponse, ListaConsignatarioExcomarvsDelfinTotalesResponse, ListarankingxconsignatarioResponse, ListatiporankingxconsignatarioResponse, listaConsignatariosDelfinvsExcomarResponse, listaExcomarFinalesResponse } from "src/app/dashboard-gestion-excomar/domain/response/gestion-excomar-response";
import { requestRepRentPorEjecutivo } from "src/app/reporte-rentaxejecutivo/domain/request/repRentPorEjecutivo.request";
import { ListaRepRentPorEjecutivoResponse, responselistaEntidad, responselistpaises } from "src/app/reporte-rentaxejecutivo/domain/response/repRentPorEjecutivo.response";
import { requestlistatracking } from "src/app/tracking/domain/request/trackingrequest";
import { responselistatracking, responselistatrackingtabla } from "src/app/tracking/domain/response/trackingresponse";
import { ListaejecutivosTeusCerradosRequest } from "src/app/dashboard-teus-cerrados/domain/request/teuscerradosRequest";
import { ListaeTeusLineaResponse, ListaejecutivosTeusCerradosResponse } from "src/app/dashboard-teus-cerrados/domain/response/teuscerradosResponse";
import { fasesPorOvRequest } from "src/app/fasesPorOv/domain/request/fasesPorOv.request";
import { fasesPorOv_Response } from "src/app/fasesPorOv/domain/response/fasesPorOv.response";
import { TablaReporteServiciosLocales03Response, TablaReporteServiciosLocales04Response, TablaReporteServiciosLocalesResponse, tablareporteservicioslocales } from "src/app/reporte-servicios-locales/domain/response/reporte-servicio-local-response";
import { TablaReporteServiciosLocalesRequest } from "src/app/reporte-servicios-locales/domain/request/reporte-servicio-local-request";
import { ResumenMensualComercialRequest } from "src/app/ResumenMenusalComercial/domain/request/resumenMensualComercial.request";
import { ResumenMensualComercial02Response, ResumenMensualComercialResponse } from "src/app/ResumenMenusalComercial/domain/response/resumenMensualComercial.response";
import { ListadoAvisoEspacioResponse, registroreporteunoResponse } from "src/app/reporte-uno/domain/response/reporte-uno-response";
import { listareporteunoRequest, reporteunoRequest } from "src/app/reporte-uno/domain/request/reporte-uno-request";
import { ReporteListaCtnrAlmacenConsolidadoRequest, ReporteListaCtnrAlmacenRequest } from "src/app/reporte-ctnr-almacen/domain/request/reporte-ctnr-almacen-request";
import { GraficoGestionSliResponse, GraficoGestionSliTipo8Response, ReporteListaCtnrAlmacenConsolidadoResponse, ReporteListaCtnrAlmacenResponse } from "src/app/reporte-ctnr-almacen/domain/response/reporte-ctnr-almacen-response";
import { ReporteClienteContactoRequest } from "src/app/reporte-cliente-contacto/domain/request/reporte-cliente-contacto-request";
import { ReporteClienteContactoResponse } from "src/app/reporte-cliente-contacto/domain/response/reporte-cliente-contacto-response";
import { InsertarPlantillasAcosIIRequest, InsertarPlantillasAcosRequest, ListarAcosRequest, ValidarAcosImportacionRequest } from "src/app/reporte-acos/domain/request/reporte-acos-request";
import { InsertarPlantillasAcosResponse, ListarAcosResponse, ValidarAcosImportacionResponse } from "src/app/reporte-acos/domain/response/reporte-acos-response";
import { DashboardSoporteRequest } from "src/app/dashboard-soporte/domain/request/dashboard-soporte-request";
import { DashboardSoporteObservacionesResponse, DashboardSoporteResponse, DashboardSoporteResponse2 } from "src/app/dashboard-soporte/domain/response/dashboard-soporte-response";
import { listarReporteNivelesCreditoResponse } from "src/app/dashboard-finanzas/domain/response/dashboard-finanzas-response";
import { listarReporteNivelesCreditoRequest } from "src/app/dashboard-finanzas/domain/request/dashboard-finanzas-request";
import { listaTransmisionesRequest } from "src/app/dashboard-transmision/domain/request/dashboard-transmision-request";
import { listaDetalleTransmisionResponse, listaTransmisionResponse } from "src/app/dashboard-transmision/domain/response/dashboard-transmision-response";
import { listaStatmentRequest } from "src/app/dashboard-statement/domain/request/dashboard-statement-request";
import { listaDetalleStatmentResponse, listaStatmentResponse } from "src/app/dashboard-statement/domain/response/dashboard-statement-response";
import { AvisoLlegadaRequest } from "src/app/dashboard-aviso-llegada/domain/request/dashboard-aviso-llegada-request";
import { AvisoLlegadaResponse, DetalleAvisoLlegadaResponse } from "src/app/dashboard-aviso-llegada/domain/response/dashboard-aviso-llegada-response";
import { DesgloseRequest } from "src/app/dashboard-desglose/domain/request/dashboard-desglose-request";
import { DesgloseResponse, DetalleDesgloseResponse } from "src/app/dashboard-desglose/domain/response/dashboard-desglose-response";
import { DireccionamientoRequest } from "src/app/dashboard-direccionamiento/domain/request/dashboard-direccionamiento-request";
import { DetalleDireccionamientoResponse, DireccionamientoResponse } from "src/app/dashboard-direccionamiento/domain/response/dashboard-direccionamiento-response";

export abstract class DashBoardRepository {
   abstract listaSeguimientoCarga(fd: requestSeguimientoCarga): Observable<ListaSeguimientoCargaResponse>;
   abstract listaTodosAnios(request: requesttodoslosanios): Observable<ListaTodosAniosResponse>;
   abstract listaPricingTabla(fd: requestPricingAtendido): Observable<ListaPricingTablaResponse>;
   abstract listaClientesRecuperados(prolrequest: requestClientesRecuperados): Observable<ClientesRecuperadosResponse>
   abstract insertarExcomar(prolrequest: Request_ExComar): Observable<Response_ExComar>;
   abstract listaExcomarFechas(prolrequest: listaExcomarFechas_Request): Observable<listaExcomarFechas_Response>;
   //abstract insertarcotizacion(prolrequest: Request_Cotizacion): Observable<responseinsertarcotizacion>
   abstract listaRentabilidadComercial2(prolrequest: requestRentabilidadComercial): Observable<RentabilidadComercialResponse2>
   abstract listaRentabilidadComercial3(prolrequest: requestRentabilidadComercial): Observable<RentabilidadComercialResponse3>
   abstract listaRentabilidadComercial6(prolrequest: requestRentabilidadComercial): Observable<RentabilidadComercialResponse6>
   abstract listaProvicionOrigen(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse>
   abstract listaProvicionOrigen3(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse3>
   abstract listaProvicionOrigen5(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse5>
   abstract listaProvicionOrigen4(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse4>
   abstract listaProvicionOrigen2(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse2>
   abstract listaProvicionOrigen7(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse7>
   //GRAFICOS DE REPORTE PROVICION 
   abstract listaReporteProvicion(prolrequest: requestProvicion): Observable<reporteProvicionResponse>
   abstract listaReporteProvicion2(prolrequest: requestProvicion): Observable<reporteProvicionResponse2>
   // FIN GRAFICOS DE REPORTE PROVICION (AURICH)
   abstract listaReporteRentabilidad(prolrequest: requestReporteRentabilidad): Observable<ListaReporteRentabilidadResponse>
   abstract listaReporteRentabilidadxMes(prolrequest: requestReporteRentabilidad): Observable<ListaReporteRentabilidadxMesResponse>
   abstract listaReporteRentabilidadxMes3(prolrequest: requestReporteRentabilidad): Observable<ListaReporteRentabilidadxMesResponseTR>
   abstract listaReporteRentabilidadxMes4(prolrequest: requestReporteRentabilidad): Observable<ListaReporteRentabilidadxMesResponseTR>
   abstract listareportecomercialxmes(prolrequest: requestreportecomercialxmes): Observable<resposereportecomercialmes>
   abstract listarconsignatarios(prolrequest: ListaConsignarioRequest): Observable<ListaConsignarioResponse>
   abstract listaRankingxConsignatarios(prolrequest: ListarankingxconsignatarioRequest): Observable<ListarankingxconsignatarioResponse>
   abstract listaTipoRankingxConsignatarios(prolrequest: ListarankingxconsignatarioRequest): Observable<ListatiporankingxconsignatarioResponse>
   abstract listaConsignatariosDelfinvsExcomar(prolrequest: ListarankingxconsignatarioRequest): Observable<listaConsignatariosDelfinvsExcomarResponse>
   abstract listaExcomarfinales(prolrequest: ListarankingxconsignatarioRequest): Observable<listaExcomarFinalesResponse>
   abstract ListaConsignatarioExcomarvsDelfinTotales(prolrequest: ListarankingxconsignatarioRequest): Observable<ListaConsignatarioExcomarvsDelfinTotalesResponse>
   abstract listaReporteClienteProfit(prolrequest: ClienteProfitRequest): Observable<ClienteProfitResponse>
   abstract listaRepRentPorEjecutivo(prolrequest: requestRepRentPorEjecutivo): Observable<ListaRepRentPorEjecutivoResponse>


   abstract listapaises(prolrequest: ListarankingxconsignatarioRequest): Observable<responselistpaises>
   abstract listaEntidad(prolrequest: ListarankingxconsignatarioRequest): Observable<responselistaEntidad>


   abstract Listatracking(prolrequest: requestlistatracking): Observable<responselistatracking>
   abstract Listatrackingtabla(prolrequest: requestlistatracking): Observable<responselistatrackingtabla>
   abstract ListaejecutivosTeusCerrados(prolrequest: ListaejecutivosTeusCerradosRequest): Observable<ListaejecutivosTeusCerradosResponse>
   abstract ListateusLinea(prolrequest: ListaejecutivosTeusCerradosRequest): Observable<ListaeTeusLineaResponse>
   abstract ListaFasesOvCustomer(prolrequest: fasesPorOvRequest): Observable<fasesPorOv_Response>
   abstract TablaReporteServiciosLocales(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocalesResponse>
   abstract TablaReporteServiciosLocales02(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocalesResponse>
   // abstract TablaReporteServiciosLocales03(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocalesResponse>
   abstract TablaReporteServiciosLocales03(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocales03Response>
   //abstract TablaReporteServiciosLocales03(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocalesResponse>
   //   abstract TablaReporteServiciosLocales03(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocales03Response>
   abstract ListaResumenMensualComercial(prolrequest: ResumenMensualComercialRequest): Observable<ResumenMensualComercialResponse>
   abstract ListaResumenMensualComercial02(prolrequest: ResumenMensualComercialRequest): Observable<ResumenMensualComercial02Response>
   //   TablaReporteServiciosLocales03(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocalesResponse>{
   abstract InsertarAvisoEspacio(prolrequest: reporteunoRequest): Observable<registroreporteunoResponse>
   abstract ListadoAvisoEspacio(prolrequest: listareporteunoRequest): Observable<ListadoAvisoEspacioResponse>
   abstract TablaReporteServiciosLocales04(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocales04Response>
   abstract ReporteListaCtnrAlmacen(prolrequest: ReporteListaCtnrAlmacenRequest): Observable<ReporteListaCtnrAlmacenResponse>
   abstract ReporteListaCtnrAlmacenNew(prolrequest: ReporteListaCtnrAlmacenConsolidadoRequest): Observable<ReporteListaCtnrAlmacenConsolidadoResponse>
   abstract ReporteClienteContacto(prolrequest: ReporteClienteContactoRequest): Observable<ReporteClienteContactoResponse>
   abstract GraficoGestionSli(prolrequest: ReporteListaCtnrAlmacenConsolidadoRequest): Observable<GraficoGestionSliResponse>
   abstract GraficoGestionSliTipo8(prolrequest: ReporteListaCtnrAlmacenConsolidadoRequest): Observable<GraficoGestionSliTipo8Response>

   abstract ValidarAcosImportacion(prolrequest: ValidarAcosImportacionRequest): Observable<ValidarAcosImportacionResponse>
   abstract ListarAcos(prolrequest: ListarAcosRequest): Observable<ListarAcosResponse>
   abstract InsertarPlantillasAcos(prolrequest: InsertarPlantillasAcosRequest): Observable<InsertarPlantillasAcosResponse>
   abstract InsertarPlantillasAcosII(prolrequest: InsertarPlantillasAcosIIRequest): Observable<InsertarPlantillasAcosResponse>
   abstract savePlantillaAcosEjecutivos(prolrequest: InsertarPlantillasAcosIIRequest): Observable<InsertarPlantillasAcosResponse>
   abstract DashboardSoporte(prolrequest: DashboardSoporteRequest): Observable<DashboardSoporteResponse>
   abstract DashboardSoporte2(prolrequest: DashboardSoporteRequest): Observable<DashboardSoporteResponse2>
   abstract DashboardSoporteObservaciones(prolrequest: DashboardSoporteRequest): Observable<DashboardSoporteObservacionesResponse>

   abstract confimarcierremensual(prolrequest: requestcierremensualfinal): Observable<registrarcierremensualfinalresponse> ;
   abstract   listarcierremensual(prolrequest: requestCierreMensual): Observable<registrarcierremensualfinalresponse> 
 
   abstract listaCierreMensual(prolrequest: requestCierreMensual): Observable<ListarCierreMensualResponse>;
   abstract listaPricingResumen(prolrequest: requestPricingAtendido): Observable<ListaPricingResumenResponse>;
   abstract listaReporteNivelesCredito(prolrequest: listarReporteNivelesCreditoRequest): Observable<listarReporteNivelesCreditoResponse>;
   abstract listarReporteTransmision(prolrequest: listaTransmisionesRequest): Observable<listaTransmisionResponse>;
   abstract listarReporteDetalleTransmision(prolrequest: listaTransmisionesRequest): Observable<listaDetalleTransmisionResponse>;
   abstract listarReporteStatment(prolrequest: listaStatmentRequest): Observable<listaStatmentResponse>;
   abstract listarReporteDetalleStatment(prolrequest: listaStatmentRequest): Observable<listaDetalleStatmentResponse>;
   abstract listarReporteAvisoLlegada(prolrequest: AvisoLlegadaRequest): Observable<AvisoLlegadaResponse>;
   abstract listarReporteDetalleAvisoLlegada(prolrequest: AvisoLlegadaRequest): Observable<DetalleAvisoLlegadaResponse>;
   abstract listarReporteDesglose(prolrequest: DesgloseRequest): Observable<DesgloseResponse>;
   abstract listarReporteDetalleDesglose(prolrequest: DesgloseRequest): Observable<DetalleDesgloseResponse>;
   abstract listarReporteDireccionamiento(prolrequest: DireccionamientoRequest): Observable<DireccionamientoResponse>;
   abstract listarReporteDetalleDireccionamiento(prolrequest: DireccionamientoRequest): Observable<DetalleDireccionamientoResponse>;
}

<div fxLayoutGap="20px" class="container_botones">
  <button mat-stroked-button class="button1" (click)="insertaCliente()">Guardar
    <mat-icon>save</mat-icon>
  </button>
  <button mat-stroked-button class="button1" (click)="salir()">Salir
    <mat-icon>exit_to_app</mat-icon>

  </button>
</div>


<mat-tab-group animationDuration="1500ms">
  <mat-tab label="Datos Generales">

    <div class="tituloPantalla">
      <mat-toolbar style="height: 33px;" fxLayout class="tituloPantalla">
        <h3 class="title">Registro de Cliente</h3>
      </mat-toolbar>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutAlign="center"
      style="margin-top: 0px; overflow: hidden;">
      <div fxFlex="100" style="padding: 0px;" class="general">
        <form [formGroup]="groupDatos" class="">
          <div class="label">
            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno3">
              <mat-label>Codigo</mat-label>
              <input matInput type="text" formControlName="codigo" min="0">
            </mat-form-field>

                    <mat-checkbox class="example-margin" formControlName="activo">Activo</mat-checkbox>
            <mat-checkbox class="example-margin" formControlName="servicioLogistico">Servicio Logistico</mat-checkbox>

          </div>

          <div class="label">


            <mat-radio-group aria-label="Select an option" class="checkbox-group" formControlName="estadoPersona">
              <mat-radio-button value="J" (click)="accionJuridica()">Jurídica</mat-radio-button>
              <mat-radio-button value="N" (click)="accionNatural()">Natural</mat-radio-button>
            </mat-radio-group>

          </div>

          <div class="label">

            <mat-form-field class="phone" appearance="outline" class="example-full-width-alterno2">
              <mat-label>Tipo Documento</mat-label>
              <mat-select formControlName="tipoDocumento">
                <mat-option *ngFor="let tipoDocumento of listTipoDocumento"
                  [value]="tipoDocumento.codigo">{{tipoDocumento.nombre}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="phone" class="example-full-width-alterno">
              <input matInput type="text" formControlName="nroDocumento">
              <mat-label>Nro Documento</mat-label>
              <mat-icon matSuffix (click)="cargaSunat()">search</mat-icon>
              <mat-icon matSuffix (click)="limpiar('nroDocumento')" (click)="limpiar('razonSocial')"
                (click)="limpiar('razonComercial')">close</mat-icon>
            </mat-form-field>


          </div>

          <div class="label" *ngIf="groupDatos.get('estadoPersona').value === 'J'">
            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno2">
              <mat-label>Razon Social</mat-label>
              <input matInput type="text" formControlName="razonSocial" min="0">
            </mat-form-field>

            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno">
              <mat-label>Razon Comercial</mat-label>
              <input matInput type="text" formControlName="razonComercial" min="0">
            </mat-form-field>



          </div>

          <div class="label" *ngIf="groupDatos.get('estadoPersona').value === 'N'" >
            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno2">
              <mat-label>Apellido Paterno</mat-label>
              <input matInput type="text" formControlName="apellidoPaterno" min="0">
            </mat-form-field>

            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno">
              <mat-label>Apellido Materno</mat-label>
              <input matInput type="text" formControlName="apellidoMaterno" min="0">
            </mat-form-field>



          </div>

          <div class="label" *ngIf="groupDatos.get('estadoPersona').value === 'N'">
            <mat-form-field class="list" appearance="outline" class="phone"  class="example-full-width-alterno2">
              <mat-label>Nombres</mat-label>
              <input matInput type="text" formControlName="nombres" min="0">
            </mat-form-field>

            <mat-radio-group class="radio-group" formControlName="generoPersona" class="example-full-width-alterno">
              <mat-label>GENERO:</mat-label>
              <mat-radio-button value="M" name="genero">Masculino</mat-radio-button>
              <mat-radio-button value="F" name="genero">Femenino</mat-radio-button>
            </mat-radio-group>

          </div>

          <div class="label" *ngIf="groupDatos.get('estadoPersona').value === 'N'">
            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno2">
              <mat-label>Area</mat-label>
              <input matInput type="text" formControlName="area" min="0">
            </mat-form-field>

            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno">
              <mat-label>Cargo</mat-label>
              <input matInput type="text" formControlName="cargo" min="0">
            </mat-form-field>


          </div>

          <div class="label" *ngIf="groupDatos.get('estadoPersona').value === 'N'">
            <mat-form-field color="accent" appearance="outline" class="phone" class="example-full-width-alterno2">
              <mat-label>Fecha Nacimiento</mat-label>
              <input formControlName="fechaNacimiento" matInput [matDatepicker]="picker2">
              <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2 color="primary"></mat-datepicker>
            </mat-form-field>

            <mat-form-field color="accent" appearance="outline" class="phone" class="example-full-width-alterno">
              <mat-label>Fecha Ingreso</mat-label>
              <input formControlName="fechaIngreso" matInput [matDatepicker]="picker3">
              <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker #picker3 color="primary"></mat-datepicker>
            </mat-form-field>


          </div>

          <div class="label">
            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno2">
              <mat-label>Correo Contrato Mandato</mat-label>
              <input matInput type="text" formControlName="correoContratoMandato" min="0">
            </mat-form-field>

            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno">
              <mat-label>Correo Otros Traficos</mat-label>
              <input matInput type="text" formControlName="correoOtrosTraficos" min="0">
            </mat-form-field>


          </div>

          <div class="label">
            <mat-form-field class="list" appearance="outline" class="phone">
              <mat-label>Nombre Gerente</mat-label>
              <input matInput type="text" formControlName="nombreGerente" min="0">
            </mat-form-field>
          </div>

          <div class="label">
            <mat-form-field appearance="outline" class="phone" class="example-full-width-alterno2">
              <mat-label>Fecha Nacimiento</mat-label>
              <input formControlName="fechaNacimientoGerente" matInput [matDatepicker]="picker3" autocomplete="off"
                readonly [disabled]="true">
              <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker #picker3 color="primary"></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="phone" class="example-full-width-alterno">
              <mat-label>Fecha Aniversario</mat-label>
              <input formControlName="fechaAniversarioGerente" matInput [matDatepicker]="picker4" autocomplete="off"
                readonly>
              <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
              <mat-datepicker #picker4 color="primary"></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="label">
            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno2">
              <mat-label>Contacto Nombre</mat-label>
              <input matInput type="text" formControlName="contactoNombre" min="0">
            </mat-form-field>

            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno">
              <mat-label>Apellidos</mat-label>
              <input matInput type="text" formControlName="apellidos" min="0">
            </mat-form-field>

          </div>

          <div class="label">
            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno2">
              <mat-label>Correo Exportaciones</mat-label>
              <input matInput type="text" formControlName="correoExportaciones" min="0">
            </mat-form-field>

            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno">
              <mat-label>Correo Facturacion</mat-label>
              <input matInput type="text" formControlName="correoFacturacion" min="0">
            </mat-form-field>


          </div>

          <div class="label">

            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno2">
              <mat-label>Telefono Fijo</mat-label>
              <input matInput type="number" formControlName="telefonoFijo" min="0">
            </mat-form-field>

            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno">
              <mat-label>Telefono Movil</mat-label>
              <input matInput type="number" formControlName="telefonoMovil" min="0">
            </mat-form-field>

          </div>

          <div class="label">
            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno2">
              <mat-label>Direccion Fiscal</mat-label>
              <input matInput type="text" formControlName="direccionFiscal" min="0">
            </mat-form-field>

            <mat-form-field class="phone" appearance="outline" class="example-full-width-alterno">
              <mat-label>Pais</mat-label>
              <mat-select formControlName="pais1" (selectionChange)="onPaisSeleccionado($event)">
                <mat-option *ngFor="let via of listPais" [value]="via.codigo">{{via.nombre}}</mat-option>
              </mat-select>
            </mat-form-field>


          </div>

          <div class="label">

            <mat-form-field class="phone" appearance="outline" class="example-full-width-alterno2">
              <mat-label>Departamento</mat-label>
              <mat-select formControlName="departamento1" (selectionChange)="onDepartamentoSeleccionado($event)">
                <mat-option *ngFor="let dep of listDepartamento" [value]="dep.id">{{dep.descrip_departamento}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="phone" appearance="outline" class="example-full-width-alterno">
              <mat-label>Provincia</mat-label>
              <mat-select formControlName="provincia1" (selectionChange)="onprovinciaSeleccionada($event)">
                <mat-option *ngFor="let pro of listProvincia" [value]="pro.id_provincia">{{pro.descrip_provincia}}</mat-option>
              </mat-select>
            </mat-form-field>


          </div>
          <div class="label">

            <mat-form-field class="phone" appearance="outline" class="example-full-width-alterno2">
              <mat-label>Distrito</mat-label>
              <mat-select formControlName="distrito1">
                <mat-option *ngFor="let dis of listDistrito" [value]="dis.id_distrito">{{dis.descrip_distrito}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="list" appearance="outline" class="phone" class="example-full-width-alterno">
              <mat-label>Direccion Comercial</mat-label>
              <input matInput type="text" formControlName="direccionComercial" min="0">
            </mat-form-field>

          </div>

          <div class="label">
            <mat-form-field class="list" appearance="outline" class="phone">
              <mat-label>Observaciones</mat-label>
              <input matInput type="text" formControlName="observaciones" min="0">
            </mat-form-field>
          </div>

        </form>

        <div fxLayoutGap="20px" class="label">

        </div>



        <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px">
          <div fxFlex="50" style="background-color: rgb(219, 217, 217); padding: 1px;">


          </div>
        </div>

      </div>
    </div>


  </mat-tab>

</mat-tab-group>
<div style="height: 60px;"></div>
import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { PreResRepository } from "../cotizaciones_resumentes/domain/pre_res_repository";
import { PreResResponse } from "../cotizaciones_resumentes/domain/response/pre_res_response";
import { PreResRequest } from "../cotizaciones_resumentes/domain/request/pre_res_request";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { OvVersionarRepository } from "../ovPorVersionar/domain/ov-por-versionar.repository";
import { requestControlFletamento, requestOvVersionar } from "../ovPorVersionar/domain/request/ovPorVersionar.request";
import { controlFletamentoResponse, ovVersionarResponse } from "../ovPorVersionar/domain/response/ovPorVersionar.response";

@Injectable()
export class OvVersionarService extends OvVersionarRepository{

  constructor(private readonly http:HttpClient,
    private readonly storage: StorageService

    ){

    super();
  }

  listaOvVersionar(prolrequest:requestOvVersionar): Observable<ovVersionarResponse>
  {
      return this.http.post<ovVersionarResponse>(`${environment.PATH_API}/OV/listaOvVersionar`,prolrequest);
  }

  listaControlFletamento(prolrequest:requestControlFletamento):Observable<controlFletamentoResponse>{
    return this.http.post<controlFletamentoResponse>(`${environment.PATH_API}/OV/listaControlFletamento`,prolrequest);
  }
}
import { Observable } from "rxjs";
import { requestcarteracliente, requestlistadoprogramacionxdia, requestlistarprogramacioncab, requestlistarprogramaciondet, requestmantprogramacion } from "./request/visitacliente-request";
import { reponselistadoprogramacionxdia, reponselistarprogramacioncab, reponselistarprogramaciondet, reponsemantprogramacion, responsecartera } from "./response/visitacliente-response";
import { requestlistarcarteraxejecutivo, requestlistarejecutivo, requesttransaccionclienteprospecto, requestupdateprogramacionvisita, requestvisitasconfirmadas } from "src/app/gestionvisitas/domain/request/requestgestionvisitas";
import { responselistadocarteraxejecutivo, responselistarejecutivo, responsetransaccionclienteprospecto, responseupdateprogramacionvisita, responsevisitasconfirmadas } from "src/app/gestionvisitas/domain/response/response/response-llistadocarteraxejecutivo";

export abstract class ClienteRepository{



    abstract  listarcartera(prolrequest:requestcarteracliente): Observable<responsecartera>;
    abstract  mantprogramacion(prolrequest:requestmantprogramacion): Observable<reponsemantprogramacion>
    abstract  listarprogramacioncab(prolrequest:requestlistarprogramacioncab): Observable<reponselistarprogramacioncab>
    abstract  listarprogramaciondet(prolrequest:requestlistarprogramaciondet): Observable<reponselistarprogramaciondet>
    abstract  listarprogramaciondet(prolrequest:requestlistarprogramaciondet): Observable<reponselistarprogramaciondet>
    abstract  listarcarteraxejecutivo(prolrequest:requestlistarcarteraxejecutivo): Observable<responselistadocarteraxejecutivo>
    abstract  updatevisitaprogramada(prolrequest:requestupdateprogramacionvisita): Observable<responseupdateprogramacionvisita>;
    abstract  listarvisistasconfirmadas(prolrequest:requestvisitasconfirmadas): Observable<responsevisitasconfirmadas>

    abstract listadoprogramacionxdia(prolrequest:requestlistadoprogramacionxdia): Observable<reponselistadoprogramacionxdia>

    abstract   listarejecutivo(prolrequest:requestlistarejecutivo): Observable<responselistarejecutivo>

    abstract  transaccionclienteprospecto(prolrequest:requesttransaccionclienteprospecto): Observable<responsetransaccionclienteprospecto>
 

}


import { Injectable } from "@angular/core";
import { AlertaDocumentoRepository } from "../bandeja-alerta-documento/domain/alerta_documento_repository";
import { HttpClient } from "@angular/common/http";
import { StorageService } from "./storage.service";
import { requestAlertaDocumento } from "../bandeja-alerta-documento/domain/request/alerta_documento.request";
import { AlertaDocumentoResponse } from "../bandeja-alerta-documento/domain/response/alerta_documento.response";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";

@Injectable()
export class AlertaDocumentoService extends AlertaDocumentoRepository{

  constructor(private readonly http:HttpClient,
    private readonly storage: StorageService

    ){

    super();
  }

  listarAlertaDocumento(prolrequest:requestAlertaDocumento): Observable<AlertaDocumentoResponse>
  {
      return this.http.post<AlertaDocumentoResponse>(`${environment.PATH_API}/reportes/semaforoVigencia`,prolrequest);
  }

}

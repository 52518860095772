import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActualizarEstadoRequest } from '../../domain/request/gestionProyectos-request';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';
import { GestionProyectosRepository } from '../../domain/gestionProyectos-repository';

@Component({
  selector: 'app-actualizar-estado',
  templateUrl: './actualizar-estado.component.html',
  styleUrls: ['./actualizar-estado.component.css']
})
export class ActualizarEstadoComponent {
  constructor(private readonly reference: MatDialogRef<ActualizarEstadoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly storage: StorageService,
    private util: UtilService,
    private readonly proyectosService: GestionProyectosRepository
  ){}
  group: FormGroup
  estado: string
  id:number
  nivel_padre:string
  nivel: string

  iniciarFormulario(){
    this.group = new FormGroup({
      estado: new FormControl(null,null)
    })
  }
  ngOnInit(): void {
    this.iniciarFormulario()
    // if(this.data != null){
    //   this.group.get('estado').setValue(this.data.estado)
    // }
    if (this.data == 'T') {
      const datosSerializados = localStorage.getItem('rowtarea')
      const datos = JSON.parse(datosSerializados);
      localStorage.removeItem('datos');
      console.log(datos)
      this.estado = datos.estado
      this.id = datos.id
      this.nivel_padre = datos.nivel_padre
      this.nivel = datos.nivel
      this.group.get('estado').setValue(this.estado)
    }
    if (this.data == 'P') {
      const datosSerializados = localStorage.getItem('rowproyecto')
      const datos = JSON.parse(datosSerializados);
      localStorage.removeItem('datos');
      console.log(datos)
      this.estado = datos.estado
      this.id = datos.id
      this.group.get('estado').setValue(this.estado)
    }
  }
  actualizarEstado(){
    const values = this.group.value
    const actualizarEstadoRequest: ActualizarEstadoRequest = {
      tipo: this.data,
      nivel_padre: this.nivel_padre,
      nivel: this.nivel,
      id: this.id,
      estado: values.estado,
      audi_usrmodcrea: this.storage.get('usuario'),
      audi_fecmodcrea: this.util.formatearfecha(new Date(), 'yyyy-MM-ddTHH:mm:ss')
    }
    this.proyectosService.actualizarEstado(actualizarEstadoRequest).subscribe(response => {
      if(response.datos.status === 200){
        this.util.ShowMessage({mensaje:'Se actualizó correctamente',tipo:'ok'});
        const datarespuesta = {
          mensaje: 'Se actualizo correctamente'
        }
        this.reference.close(datarespuesta);
      }
    },(errorServicio) => {
      this.util.ShowMessage({mensaje:'No se pudo actualizar estado',tipo:'warning'})
    })
  }
  salir(){
    this.reference.close();
  }
}

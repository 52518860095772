import { DetalleServiciosLocalesRequest, ServiciosLocalesRequest, BuscarFavoritosPorViaRequest, BuscarServicio_ByViaRegimenRequest, ConsultarContratoAyudaPivotOVRequest, ConsultarTarifaRequest, ConsultarTariffDetailByIdRequest, GetServiceCalculatedByTariffRequest, ListaDetCotizacionOVArchivoRequest, ListaDetCotizacionOVEventoActualizarRequest, ListaDetCotizacionOVFleteActualizarRequest, ListaDetCotizacionOVItinerarioRequest, ListaDetCotizacionOVServicioActualizarRequest, ConsultarEntidadServicio_Request, ConsultarDTECliente_Request, ConsultarDirecENTC_Request, ConsultarEntidadAcuerdo_Request, ConsultarEntidadRelacionadosRequest, BuscarPorEntidad_Request, GetCotizacionOVArchivoRequest } from './../comercial/orden-venta/domain/request/comercial-orden-venta-request';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BuscarNavesItinerarioRequest, InsertItinerarioRefferRequest, InsertServicioRefferRequest, ListarDropdownRequest, ListarItinerariosRefferRequest, ListarNavesRequest, ListarNuevasReservaRequest, ListarNuevosClientesRequest, ListarServiciosRefferMultiListaRequest, ListarServiciosRefferRequest, NuevaReservaRequest, NuevoPerfilClienteRequest, operacionesNaveRequest, transbordoReservaRequest, transbordoTrackingRequest, UpdateNuevosClienteRequest, updatePasswordRefferRequest, updateReservaRefferRequest } from '../comercial/reffer/domain/reffer-request';
import { BuscarNavesItinerarioResponse, GeneralResponse, ListarDropdownResponse, ListarItinerariosRefferResponse, ListarNavesResponse, ListarNuevaReservaResponse, ListarNuevosClientesResponse, ListarServiciosRefferMultiListaResponse, ListarServiciosRefferResponse, transbordoTrackingResponse } from '../comercial/reffer/domain/reffer-response';
import { ComercialRepository } from '../repositorio/comercial.repository';
import { ListarOrdenesVentaRequest, CrudOrdenesVentaRequest, listAllOVRequest, listNaveViajePorPuertoRequest, } from '../comercial/orden-venta/domain/request/comercial-orden-venta-request';
import { ListarOrdenesVentaResponse, CrudOrdenesVentaResponse, ListaDetCotizacionOVFleteActualizarResponse, ListaDetCotizacionOVServicioActualizarResponse, ListaDetCotizacionOVEventoActualizarResponse, ListaDetCotizacionOVArchivoResponse, BuscarServicio_ByViaRegimenResponse, BuscarFavoritosPorViaResponse, ListaDetCotizacionOVItinerarioResponse, ConsultarContratoAyudaPivotOVoResponse, ConsultarTarifaResponse, ConsultarTariffDetailByIdResponse, ConsultarServiceCalculatedByTariffResponse, DetalleServiciosLocalesResponse, ServiciosLocalesResponse, InsertarServiciosLocalesResponse, ConsultarEntidadServicioResponse, ConsultarDTEClienteResponse, ConsultarDirecENTCResponse, ConsultarEntidadAcuerdoResponse, ConsultarEntidadRelacionadosResponse, BuscarPorEntidad_Response, GetCotizacionOVArchivoResponse } from '../comercial/orden-venta/domain/response/comercial-orden-venta-response';
import { searchTrackingShipmentRequest } from '../comercial/tracking-shipment/domain/tracking-shipment-request';
import { searchTrackingShipmentResponse } from '../comercial/tracking-shipment/domain/tracking-shipment-response';
@Injectable({
  providedIn: 'root'
})
export class ComercialService implements ComercialRepository {

  constructor(
    private readonly http: HttpClient
  ) { }

  //#region REFFER

  NuevoPerfil(request: NuevoPerfilClienteRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/NuevoPerfil`, request);
  }

  ListarNuevosClientes(request: ListarNuevosClientesRequest): Observable<ListarNuevosClientesResponse> {
    return this.http.post<ListarNuevosClientesResponse>(`${environment.PATH_API}/reffer/ListarNuevoCliente`, request);
  }

  UpdateNuevosCliente(request: UpdateNuevosClienteRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/updatenuevocliente`, request);
  }

  ListarDropdown(request: ListarDropdownRequest): Observable<ListarDropdownResponse> {
    return this.http.post<ListarDropdownResponse>(`${environment.PATH_API}/reffer/ListarDropdown`, request);
  }

  NuevaReserva(request: NuevaReservaRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/insertsolicitudreserva`, request);
  }

  ListarNuevasReserva(request: ListarNuevasReservaRequest): Observable<ListarNuevaReservaResponse> {
    return this.http.post<ListarNuevaReservaResponse>(`${environment.PATH_API}/reffer/ListarNuevasReserva`, request);
  }

  InsertServicioReffer(request: InsertServicioRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/InsertServicioReffer`, request);
  }

  ListarServiciosReffer(request: ListarServiciosRefferRequest): Observable<ListarServiciosRefferResponse> {
    return this.http.post<ListarServiciosRefferResponse>(`${environment.PATH_API}/reffer/ListarServiciosReffer`, request);
  }

  InsertItinerarioReffer(request: InsertItinerarioRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/InsertItinerarioReffer`, request);
  }

  ListarItinerariosReffer(request: ListarItinerariosRefferRequest): Observable<ListarItinerariosRefferResponse> {
    return this.http.post<ListarItinerariosRefferResponse>(`${environment.PATH_API}/reffer/ListarItinerariosReffer`, request);
  }

  ListarNaves(): Observable<ListarDropdownResponse> {
    return this.http.post<ListarDropdownResponse>(`${environment.PATH_API}/reffer/ListarNaves`, '');
  }

  updateReservaReffer(request: updateReservaRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/updateReserva`, request);
  }

  updatePasswordReffer(request: updatePasswordRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/updatePassword`, request);
  }

  BuscarNavesItinerario(request: BuscarNavesItinerarioRequest): Observable<BuscarNavesItinerarioResponse> {
    return this.http.post<BuscarNavesItinerarioResponse>(`${environment.PATH_API}/reffer/BuscarNavesItinerario`, request);
  }

  ListarNavesNext(request: ListarNavesRequest): Observable<ListarNavesResponse> {
    return this.http.post<ListarNavesResponse>(`${environment.PATH_API}/reffer/ListarNavesNext`, request);
  }

  operacionesNave(request: operacionesNaveRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/operacionesNave`, request);
  }

  transbordoReserva(request: transbordoReservaRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/transbordoReserva`, request);
  }

  transbordoTracking(request: transbordoTrackingRequest): Observable<transbordoTrackingResponse> {
    return this.http.post<transbordoTrackingResponse>(`${environment.PATH_API}/reffer/transbordoTracking`, request);
  }

  //#endregion

  //#region SEGUIMIENTO DE EMBARQUE

  searchTrackingShipment(prolrequest: searchTrackingShipmentRequest): Observable<searchTrackingShipmentResponse> {
    return this.http.post<searchTrackingShipmentResponse>(`${environment.PATH_API}/comercial/searchTrackingShipment`, prolrequest);
  }

  //#endregion

  ListarOrdenesVenta(prolrequest: ListarOrdenesVentaRequest): Observable<ListarOrdenesVentaResponse> {
    return this.http.post<ListarOrdenesVentaResponse>(`${environment.PATH_API}/comercial/BuscarCotizacionesOrdenVenta`, prolrequest);
  }

  CrudOrdenesVenta(prolrequest: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse> {
    return this.http.post<CrudOrdenesVentaResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CRUD_Cab_Cotizacion_OV`, prolrequest);
  }

  CrudOrdenesVentaEditar(prolrequest: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse> {
    return this.http.post<CrudOrdenesVentaResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CRUD_Cab_Cotizacion_OV_editar`, prolrequest);
  }

  listAllOV(prolrequest: listAllOVRequest): Observable<any> {
    return this.http.post<any>(`${environment.PATH_API}/comercial/ListarAllOrdenVenta`, prolrequest);
  }

  //Daniel 03/09/24
  listNaveViajePorPuerto(prolrequest: listNaveViajePorPuertoRequest): Observable<any> {
    return this.http.post<any>(`${environment.PATH_API}/comercial/ListarNaveViajePorPuerto`, prolrequest);
  }
  listarServiciosRefferMultiLista(prolrequest: ListarServiciosRefferMultiListaRequest): Observable<ListarServiciosRefferMultiListaResponse> {
    return this.http.post<ListarServiciosRefferMultiListaResponse>(`${environment.PATH_API}/reffer/ConsultaServiciosReffer`, prolrequest);
  }

  OVFleteActualizar(prolrequest: ListaDetCotizacionOVFleteActualizarRequest): Observable<ListaDetCotizacionOVFleteActualizarResponse> {
    return this.http.post<ListaDetCotizacionOVFleteActualizarResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ACTUALIZAR_Det_Cotizacion_OV_Flete`, prolrequest);
  }

  OVServicioActualizar(prolrequest: ListaDetCotizacionOVServicioActualizarRequest): Observable<ListaDetCotizacionOVServicioActualizarResponse> {
    return this.http.post<ListaDetCotizacionOVServicioActualizarResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ACTUALIZAR_Det_Cotizacion_OV_Servicio`, prolrequest);
  }

  OVEventoActualizar(prolrequest: ListaDetCotizacionOVEventoActualizarRequest): Observable<ListaDetCotizacionOVEventoActualizarResponse> {
    return this.http.post<ListaDetCotizacionOVEventoActualizarResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ACTUALIZAR_Det_Cotizacion_OV_Evento`, prolrequest);
  }

  OVEventoInsertar(prolrequest: ListaDetCotizacionOVEventoActualizarRequest): Observable<ListaDetCotizacionOVEventoActualizarResponse> {
    return this.http.post<ListaDetCotizacionOVEventoActualizarResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_Det_Cotizacion_OV_Evento`, prolrequest);
  }

  OVGetArchivos(prolrequest: GetCotizacionOVArchivoRequest): Observable<GetCotizacionOVArchivoResponse> {
    return this.http.post<GetCotizacionOVArchivoResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Archivos`, prolrequest);
  }

  OVArchivoInsertar(prolrequest: ListaDetCotizacionOVArchivoRequest): Observable<ListaDetCotizacionOVArchivoResponse> {
    return this.http.post<ListaDetCotizacionOVArchivoResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_Det_Cotizacion_OV_Archivo`, prolrequest);
  }

  OVArchivoActualizar(prolrequest: ListaDetCotizacionOVArchivoRequest): Observable<ListaDetCotizacionOVArchivoResponse> {
    return this.http.post<ListaDetCotizacionOVArchivoResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ACTUALIZAR_Det_Cotizacion_OV_Archivo`, prolrequest);
  }

  OVItinerarioInsertar(prolrequest: ListaDetCotizacionOVItinerarioRequest): Observable<ListaDetCotizacionOVItinerarioResponse> {
    return this.http.post<ListaDetCotizacionOVItinerarioResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_Det_Cotizacion_OV_Itinerario`, prolrequest);
  }

  OVItinerarioActualizar(prolrequest: ListaDetCotizacionOVItinerarioRequest): Observable<ListaDetCotizacionOVItinerarioResponse> {
    return this.http.post<ListaDetCotizacionOVItinerarioResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ACTUALIZAR_Det_Cotizacion_OV_Itinerario`, prolrequest);
  }

  BuscarServicio_ByViaRegimen(prolrequest: BuscarServicio_ByViaRegimenRequest): Observable<BuscarServicio_ByViaRegimenResponse> {
    return this.http.post<BuscarServicio_ByViaRegimenResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Servicio_ByViaRegimen`, prolrequest);
  }

  BuscarFavoritosPorVia(prolrequest: BuscarFavoritosPorViaRequest): Observable<BuscarFavoritosPorViaResponse> {
    return this.http.post<BuscarFavoritosPorViaResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_FavoritosPorVia`, prolrequest);
  }

  BuscarContratoAyudaPivot(prolrequest: ConsultarContratoAyudaPivotOVRequest): Observable<ConsultarContratoAyudaPivotOVoResponse> {
    return this.http.post<ConsultarContratoAyudaPivotOVoResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Contrato_AyudaPivotOV`, prolrequest);
  }

  BuscarTarifasServiciosLocales(prolrequest: ConsultarTarifaRequest): Observable<ConsultarTarifaResponse> {
    return this.http.post<ConsultarTarifaResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Tarifa`, prolrequest);
  }

  BuscarTariffDetailById(prolrequest: ConsultarTariffDetailByIdRequest): Observable<ConsultarTariffDetailByIdResponse> {
    return this.http.post<ConsultarTariffDetailByIdResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_TariffDetailById`, prolrequest);
  }

  GetServiceCalculatedByTariff(prolrequest: GetServiceCalculatedByTariffRequest): Observable<ConsultarServiceCalculatedByTariffResponse> {
    return this.http.post<ConsultarServiceCalculatedByTariffResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_ServiceCalculatedByTariff`, prolrequest);
  }

  ActualizarServiciosLocales(prolrequest: ServiciosLocalesRequest): Observable<ServiciosLocalesResponse> {
    return this.http.post<ServiciosLocalesResponse>(`${environment.PATH_API}/comercial/TCOM_SP_EDITAR_ServicioLocalOV`, prolrequest);
  }

  ActualizarDetalleServiciosLocales(prolrequest: DetalleServiciosLocalesRequest): Observable<DetalleServiciosLocalesResponse> {
    return this.http.post<DetalleServiciosLocalesResponse>(`${environment.PATH_API}/comercial/TCOM_SP_EDITAR_DetalleServicioLocalOV`, prolrequest);
  }

  InsertarServiciosLocales(prolrequest: ServiciosLocalesRequest): Observable<InsertarServiciosLocalesResponse> {
    return this.http.post<InsertarServiciosLocalesResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_ServicioLocalOV`, prolrequest);
  }

  BuscarEntidadServicio(prolrequest: ConsultarEntidadServicio_Request): Observable<ConsultarEntidadServicioResponse> {
    return this.http.post<ConsultarEntidadServicioResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Entidad_Servicio`, prolrequest);
  }

  BuscarDTECliente(fd: ConsultarDTECliente_Request): Observable<ConsultarDTEClienteResponse> {
    return this.http.post<ConsultarDTEClienteResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Relacion_DTE_Cliente`, fd);
  }

  BuscarDirecENTC(fd: ConsultarDirecENTC_Request): Observable<ConsultarDirecENTCResponse> {
    return this.http.post<ConsultarDirecENTCResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Direccion_Por_Entidad`, fd);
  }

  BuscarEntidadAcuerdo(fd: ConsultarEntidadAcuerdo_Request): Observable<ConsultarEntidadAcuerdoResponse> {
    return this.http.post<ConsultarEntidadAcuerdoResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Entidad_Acuerdo`, fd);
  }

  BuscarRelacionadosPorEntidadPadre(fd: ConsultarEntidadRelacionadosRequest): Observable<ConsultarEntidadRelacionadosResponse> {
    return this.http.post<ConsultarEntidadRelacionadosResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Relacionados_Por_Entidad_Padre`, fd);
  }

  BuscarPorEntidad(fd: BuscarPorEntidad_Request): Observable<BuscarPorEntidad_Response> {
    return this.http.post<BuscarPorEntidad_Response>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_ENTCSS_Todos`, fd);
  }
}

import { Observable } from "rxjs";
import { gestionSalasRequest, listaClienteSalaRequest, reservaSalaCorreoRequest, reservaSalaRequest } from "./request/salas_request";
import { GestionSalasResponse, GuardaSalaReservadaResponse, ListaClienteSalaResponse, ListaSalasReservadasResponse } from "./response/salas_response";
import { AgregarSalaRequest, GuardarArticulosRequest, ListarDatosSalaRequest, actualizarSalaRequest, anularSalaRequest, eliminarArticuloRequest, listarArticulosRequest, listarSalaRequest } from "src/app/administracion_salas/domain/request/mantSalas-request";
import { ActualizarSalaResponse, AgregarSalaResponse, ListarDatosSalaResponse, ListarSalaResponse, anularSalaResponse, eliminarArticuloResponse, guardarArticulosResponse, listarArticulosResponse } from "src/app/administracion_salas/domain/response/mantSalas-response";

export abstract class GestionSalasRepository {

    //abstract insert( fd : ParteEntity):Observable<ParteInsertResponse>;
    abstract listaGestionSalas(fd: gestionSalasRequest): Observable<GestionSalasResponse>;
    abstract listaSalasReservadas(fd: gestionSalasRequest): Observable<ListaSalasReservadasResponse>;
    abstract guardaSalaReservada(fd:reservaSalaRequest):Observable<GuardaSalaReservadaResponse>;
    abstract listaSalaCliente(fd:listaClienteSalaRequest):Observable<ListaClienteSalaResponse>;
    abstract enviaCorreoSalaReservada(fd:reservaSalaCorreoRequest);
    abstract agregarSala (prolrequest: AgregarSalaRequest): Observable<AgregarSalaResponse>
    abstract listarSala (prolrequest: ListarDatosSalaRequest): Observable<ListarDatosSalaResponse>
    abstract anularSala (prolrequest: anularSalaRequest): Observable<anularSalaResponse>
    abstract actualizarSala (prolrequest: actualizarSalaRequest): Observable<ActualizarSalaResponse>
    abstract listarNombreSala (prolrequest: listarSalaRequest): Observable<ListarSalaResponse>
    abstract guardarArticulos (prolrequest: GuardarArticulosRequest): Observable<guardarArticulosResponse>
    abstract listarArticulos (prolrequest: listarArticulosRequest): Observable<listarArticulosResponse>
    abstract eliminarArticulo (prolrequest: eliminarArticuloRequest): Observable<eliminarArticuloResponse>
}

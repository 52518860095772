import { Observable } from "rxjs";
import { ListarClientesTarifasSLRespose, ListarLayoutRespose, ModificarClientesTarifasSLResponse } from "./operaciones-response";
import { ListarLayoutRequest, ModificarClientesTarifasSLRequest } from "./operaciones-request";

export abstract class OperacionesRepository {


    abstract ListarLayout(fd: ListarLayoutRequest): Observable<ListarLayoutRespose>;
    abstract ListarClientesTarifasSL(fd: ListarLayoutRequest): Observable<ListarClientesTarifasSLRespose>;
    abstract ModificarClientesTarifasSL(fd: ModificarClientesTarifasSLRequest): Observable<ModificarClientesTarifasSLResponse>;

}

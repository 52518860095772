


<div fxLayout fxLayoutAlign="center center" class="containers">

    <mat-card appearance="outlined" fxFlex="1 1 100%">

      <ng-content></ng-content>

    </mat-card>


</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-visor',
  templateUrl: './visor.component.html',
  styleUrls: ['./visor.component.css']
})
export class VisorComponent implements OnInit {
  pdfUrl
  values: any
  constructor(
    private readonly reference: MatDialogRef<VisorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private sanitizer: DomSanitizer
  ) {
    this.values = this.data
    console.log(this.values)
  }

  base64: string
  async ngOnInit(): Promise<void> {
    try {
      this.pdfUrl = this.getSafeUrl(this.values);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }

  getSafeUrl(base64: string): SafeResourceUrl {
    try {
      const url = base64;
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } catch (error) {
      console.error('Error creating safe URL:', error);
      return null;
    }
  }


  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result as string;
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  close() {
    this.reference.close()
  }
}

import { Observable } from "rxjs";
import { BuscarNavesItinerarioRequest, InsertItinerarioRefferRequest, InsertServicioRefferRequest, ListarDropdownRequest, ListarItinerariosRefferRequest, ListarNavesRequest, ListarNuevasReservaRequest, ListarNuevosClientesRequest, ListarServiciosRefferMultiListaRequest, ListarServiciosRefferRequest, NuevaReservaRequest, NuevoPerfilClienteRequest, operacionesNaveRequest, transbordoReservaRequest, transbordoTrackingRequest, UpdateNuevosClienteRequest, updatePasswordRefferRequest, updateReservaRefferRequest } from "../comercial/reffer/domain/reffer-request";
import { BuscarNavesItinerarioResponse, GeneralResponse, ListarDropdownResponse, ListarItinerariosRefferResponse, ListarNavesResponse, ListarNuevaReservaResponse, ListarNuevosClientesResponse, ListarServiciosRefferMultiListaResponse, ListarServiciosRefferResponse, transbordoTrackingResponse } from "../comercial/reffer/domain/reffer-response";
import { ServiciosLocalesRequest, DetalleServiciosLocalesRequest, BuscarFavoritosPorViaRequest, BuscarServicio_ByViaRegimenRequest, ConsultarContratoAyudaPivotOVRequest, ConsultarTarifaRequest, ConsultarTariffDetailByIdRequest, CrudOrdenesVentaRequest, GetServiceCalculatedByTariffRequest, ListaDetCotizacionOVArchivoRequest, ListaDetCotizacionOVEventoActualizarRequest, ListaDetCotizacionOVFleteActualizarRequest, ListaDetCotizacionOVItinerarioRequest, ListaDetCotizacionOVServicioActualizarRequest, listAllOVRequest, ListarOrdenesVentaRequest, listNaveViajePorPuertoRequest, ConsultarEntidadServicio_Request, ConsultarDTECliente_Request, ConsultarDirecENTC_Request, ConsultarEntidadAcuerdo_Request, ConsultarEntidadRelacionadosRequest, BuscarPorEntidad_Request, GetCotizacionOVArchivoRequest } from "../comercial/orden-venta/domain/request/comercial-orden-venta-request";
import { DetalleServiciosLocalesResponse, ServiciosLocalesResponse, BuscarFavoritosPorViaResponse, BuscarServicio_ByViaRegimenResponse, ConsultarContratoAyudaPivotOVoResponse, ConsultarServiceCalculatedByTariffResponse, ConsultarTarifaResponse, ConsultarTariffDetailByIdResponse, CrudOrdenesVentaResponse, InsertarServiciosLocalesResponse, ListaDetCotizacionOVArchivoResponse, ListaDetCotizacionOVEventoActualizarResponse, ListaDetCotizacionOVFleteActualizarResponse, ListaDetCotizacionOVItinerarioResponse, ListaDetCotizacionOVServicioActualizarResponse, ListarOrdenesVentaResponse, ConsultarEntidadServicioResponse, ConsultarDTEClienteResponse, ConsultarDirecENTCResponse, ConsultarEntidadAcuerdoResponse, ConsultarEntidadRelacionadosResponse, BuscarPorEntidad_Response, GetCotizacionOVArchivoResponse } from "../comercial/orden-venta/domain/response/comercial-orden-venta-response";
import { searchTrackingShipmentRequest } from "../comercial/tracking-shipment/domain/tracking-shipment-request";
import { searchTrackingShipmentResponse } from "../comercial/tracking-shipment/domain/tracking-shipment-response";

export abstract class ComercialRepository {

    abstract NuevoPerfil(fd: NuevoPerfilClienteRequest): Observable<GeneralResponse>;
    abstract ListarNuevosClientes(fd: ListarNuevosClientesRequest): Observable<ListarNuevosClientesResponse>;
    abstract UpdateNuevosCliente(fd: UpdateNuevosClienteRequest): Observable<GeneralResponse>;
    abstract ListarDropdown(fd: ListarDropdownRequest): Observable<ListarDropdownResponse>;
    abstract NuevaReserva(fd: NuevaReservaRequest): Observable<GeneralResponse>;
    abstract ListarNuevasReserva(fd: ListarNuevasReservaRequest): Observable<ListarNuevaReservaResponse>;
    abstract InsertServicioReffer(fd: InsertServicioRefferRequest): Observable<GeneralResponse>;
    abstract ListarServiciosReffer(fd: ListarServiciosRefferRequest): Observable<ListarServiciosRefferResponse>;
    abstract InsertItinerarioReffer(fd: InsertItinerarioRefferRequest): Observable<GeneralResponse>;
    abstract ListarItinerariosReffer(fd: ListarItinerariosRefferRequest): Observable<ListarItinerariosRefferResponse>;
    abstract ListarNaves(): Observable<ListarDropdownResponse>;
    abstract updateReservaReffer(fd: updateReservaRefferRequest): Observable<GeneralResponse>;
    abstract updatePasswordReffer(fd: updatePasswordRefferRequest): Observable<GeneralResponse>;
    abstract BuscarNavesItinerario(fd: BuscarNavesItinerarioRequest): Observable<BuscarNavesItinerarioResponse>;
    abstract ListarNavesNext(fd: ListarNavesRequest): Observable<ListarNavesResponse>;
    abstract operacionesNave(fd: operacionesNaveRequest): Observable<GeneralResponse>;
    abstract transbordoReserva(fd: transbordoReservaRequest): Observable<GeneralResponse>;
    abstract transbordoTracking(fd: transbordoTrackingRequest): Observable<transbordoTrackingResponse>;
    abstract searchTrackingShipment(fd: searchTrackingShipmentRequest): Observable<searchTrackingShipmentResponse>;

    abstract ListarOrdenesVenta(fd: ListarOrdenesVentaRequest): Observable<ListarOrdenesVentaResponse>;
    abstract CrudOrdenesVenta(fd: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse>;
    abstract CrudOrdenesVentaEditar(fd: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse>;
    abstract listAllOV(fd: listAllOVRequest): any;
    //Daniel 03/09/24
    abstract listNaveViajePorPuerto(fd: listNaveViajePorPuertoRequest): any;
    abstract listarServiciosRefferMultiLista(fd: ListarServiciosRefferMultiListaRequest): Observable<ListarServiciosRefferMultiListaResponse>;
 
 
    abstract OVFleteActualizar(fd: ListaDetCotizacionOVFleteActualizarRequest): Observable<ListaDetCotizacionOVFleteActualizarResponse>;
    abstract OVServicioActualizar(fd: ListaDetCotizacionOVServicioActualizarRequest): Observable<ListaDetCotizacionOVServicioActualizarResponse>;
    abstract OVEventoActualizar(fd: ListaDetCotizacionOVEventoActualizarRequest): Observable<ListaDetCotizacionOVEventoActualizarResponse>;
    abstract OVArchivoActualizar(fd: ListaDetCotizacionOVArchivoRequest): Observable<ListaDetCotizacionOVArchivoResponse>;
    abstract OVEventoInsertar(fd: ListaDetCotizacionOVEventoActualizarRequest): Observable<ListaDetCotizacionOVEventoActualizarResponse>;
    abstract OVArchivoInsertar(fd: ListaDetCotizacionOVArchivoRequest): Observable<ListaDetCotizacionOVArchivoResponse>;
    abstract OVItinerarioInsertar(fd: ListaDetCotizacionOVItinerarioRequest): Observable<ListaDetCotizacionOVItinerarioResponse>;
    abstract OVItinerarioActualizar(fd: ListaDetCotizacionOVItinerarioRequest): Observable<ListaDetCotizacionOVItinerarioResponse>;
    abstract BuscarServicio_ByViaRegimen(fd: BuscarServicio_ByViaRegimenRequest): Observable<BuscarServicio_ByViaRegimenResponse>;
    abstract BuscarFavoritosPorVia(fd: BuscarFavoritosPorViaRequest): Observable<BuscarFavoritosPorViaResponse>;
    abstract BuscarContratoAyudaPivot(fd: ConsultarContratoAyudaPivotOVRequest): Observable<ConsultarContratoAyudaPivotOVoResponse>;
    abstract BuscarTarifasServiciosLocales(fd:ConsultarTarifaRequest): Observable<ConsultarTarifaResponse>;
    abstract BuscarTariffDetailById(fd: ConsultarTariffDetailByIdRequest): Observable<ConsultarTariffDetailByIdResponse>;
    abstract GetServiceCalculatedByTariff(fd: GetServiceCalculatedByTariffRequest): Observable<ConsultarServiceCalculatedByTariffResponse>;
    abstract ActualizarServiciosLocales(fd: ServiciosLocalesRequest): Observable<ServiciosLocalesResponse>;
    abstract ActualizarDetalleServiciosLocales(fd: DetalleServiciosLocalesRequest): Observable<DetalleServiciosLocalesResponse>;
    abstract InsertarServiciosLocales(fd: ServiciosLocalesRequest): Observable<InsertarServiciosLocalesResponse>;
    abstract BuscarEntidadServicio(fd: ConsultarEntidadServicio_Request): Observable<ConsultarEntidadServicioResponse>;
    abstract BuscarDTECliente(fd: ConsultarDTECliente_Request): Observable<ConsultarDTEClienteResponse>;
    abstract BuscarDirecENTC(fd: ConsultarDirecENTC_Request): Observable<ConsultarDirecENTCResponse>;
    abstract BuscarEntidadAcuerdo(fd: ConsultarEntidadAcuerdo_Request): Observable<ConsultarEntidadAcuerdoResponse>;
    abstract BuscarRelacionadosPorEntidadPadre(fd: ConsultarEntidadRelacionadosRequest): Observable<ConsultarEntidadRelacionadosResponse>;
    abstract BuscarPorEntidad(fd: BuscarPorEntidad_Request): Observable<BuscarPorEntidad_Response>;
    abstract OVGetArchivos(fd: GetCotizacionOVArchivoRequest): Observable<GetCotizacionOVArchivoResponse>;
}

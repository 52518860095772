<div class="tituloPantalla">
    <mat-toolbar style="height: 33px;" fxLayout class="tituloPantalla">
        <h3 class="title">Tareas</h3>
    </mat-toolbar>
</div>

        <form [formGroup]="group" class="a-example-full-width-alterno-content" style="margin: 0px !important; padding: 4vh 4vh;">
            <div class="a-example-full-width-alterno-content-column-2">

                <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content">
                        <div class="a-example-full-width-alterno-content">
                            <span class="p-float-label">
                                <input pInputText
                                    formControlName="desctarea" appendTo="body" />
                                <label>Descripcion tarea</label>
                            </span>
                        </div>
                    </div>
                </div>


                <div class="a-example-full-width-alterno-content-row" >

                    <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label">
                            <p-calendar formControlName="fechaInicio" (onSelect)="onDateChangeDesde($event,'fechaInicio')"
                                appendTo="body" [showIcon]="true" [showWeek]="false" class="p-inputtext-sm"
                                dateFormat="dd/mm/yy">
                            </p-calendar>
                            <label for="float-label">Fecha Inicio</label>
                        </span>
                    </div>

                    <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label">
                            <p-calendar formControlName="fechaFin" (onSelect)="onDateChangeDesde($event,'fechaInicio')"
                                appendTo="body" [showIcon]="true" [showWeek]="false" class="p-inputtext-sm"
                                dateFormat="dd/mm/yy">
                            </p-calendar>
                            <label for="float-label">Fecha Fin</label>
                        </span>
                    </div>
                </div>   

                <div class="a-example-full-width-alterno-content-row" >

                    <div class="a-example-full-width-alterno-content">
                        <span class="p-float-label">
                            <p-dropdown [options]="ListaEstadosProyecto"
                                placeholder="‎ " optionLabel="nombre" formControlName="estado" optionValue="codigo"
                                appendTo="body" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Estado</label>
                        </span>
                    </div>
                    
                </div>   

                <div class="a-example-full-width-alterno-content-row" >
                    <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label">
                            <p-dropdown [options]="listarRecurso"
                                placeholder="‎ " optionLabel="usuA_Nombres" formControlName="recurso" optionValue="usuA_CodUsr"
                                appendTo="body" class="p-inputtext-sm" (onChange)="mostrarDats($event)">
                            </p-dropdown>
                            <label for="float-label">Recurso</label>
                        </span>
                    </div>
                    <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label">
                            <p-dropdown [options]="listarImportancia"
                                placeholder="‎ " optionLabel="nombre" formControlName="importancia" optionValue="codigo"
                                appendTo="body" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Importancia</label>
                        </span>
                    </div>
                </div>

                <div class="a-example-full-width-alterno-content-row" >
                    <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label">
                            <p-inputNumber
                                formControlName="dias" mode="decimal" [showButtons]="true"
                                [useGrouping]="false" [inputStyle]="{'width':'100%'}">
                            </p-inputNumber>
                            <label for="float-label">Días</label>
                        </span>
                    </div>
                    <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label">
                            <p-calendar formControlName="horas"
                                appendTo="body" [showIcon]="true" [showWeek]="false" class="p-inputtext-sm"
                                [timeOnly]="true" >
                            </p-calendar>
                            <label for="float-label">Horas</label>
                        </span>
                    </div>
                </div>

                <div class="a-example-full-width-alterno-content-row" >
                    <div class="a-example-full-width-alterno-content-2" *ngIf="!modo">
                        <div class="a-example-full-width-alterno-content" style="display: flex; justify-content: center;">                            
                            <p-button   class="button-width" (click)="guardarTarea()" label="Guardar"></p-button>
                        </div>
                    </div>
                    <div class="a-example-full-width-alterno-content-2" *ngIf="modo">
                        <div class="a-example-full-width-alterno-content" style="display: flex; justify-content: center;">                            
                            <p-button  class="button-width" (click)="actualizarTarea()" label="Actualizar"></p-button>
                        </div>
                    </div>
                    <div class="a-example-full-width-alterno-content-2" >
                        <div class="a-example-full-width-alterno-content" style="display: flex; justify-content: center;">                            
                            <p-button class="button-width" (click)="salir()" label="Cancelar"></p-button>
                        </div>
                    </div>
                </div>


            </div>
                
        </form>

        <style>
            .p-inputtext {
             width: 100%;
            }
            
            ::ng-deep .p-calendar {
                width: 100%;
                /* height: 47px; */
            }
            /* .button-width{
                width: 100%;
            } */
        
            .button-width{
                width: 100%;
                display: flex;
                justify-content: center;
                
            }
            ::ng-deep .p-button{
                width: 50%;
                margin: auto;
            }
            :host ::ng-deep .p-dropdown {
                width: 100%;
                /* height: 47px; */
            }

            :host ::ng-deep .p-inputnumber {
        width: 100%;
    }

    :host ::ng-deep p-inputnumber,
    .p-inputnumber {
        width: 100%;
    }
        
        </style>
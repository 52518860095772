import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReporteTeusFechaEtaResponse, ReporteVentasGraficosResponse, ReporteVentasIIIResponse, ReporteVentasIIResponse, ReporteVentasIResponse } from '../reporte-ventas/domain/response/reporte-ventas-response';
import { ReporteTeusFechaEtaRequest, ReporteVentasIRequest } from '../reporte-ventas/domain/request/reporte-ventas-request';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReportesRepository } from '../reporte-ventas/domain/reportes.repository';
import { ListaMenuReportesRequest, operacionesGastoEmpresaRequest, ReporteCicloEmbarqueRequest, ReporteClienteAnio03Request, ReporteClientesAnioEtaDetalleRequest, ReporteServicioContenedorRequest, TransaccionEjecutivoCostoRequest } from '../reporte-ciclo-embarque/domain/reporte-ciclo-embarque-request';
import { ListaMenuReportesResponse, ReporteCicloEmbarqueDetalleResponse, ReporteCicloEmbarqueResponse, ReporteClienteAnio03Response, ReporteClienteAnioTipo03Response, ReporteClienteAnioTipo04Response, ReporteClientesAnioEtaDetalleResponse, ReporteClientesAnioEtaResumenResponse, ReporteFlujoProyectado07Response, ReporteFrecuenciaClienteDetalleResponse, ReporteFrecuenciaClienteResumen02Response, ReporteFrecuenciaClienteResumenResponse, ReporteParetodeCarteraDetalleResponse, ReporteParetodeCarteraResumenResponse, ReporteServicioContenedorResponse } from '../reporte-ciclo-embarque/domain/reporte-ciclo-embarque-response';
import { GeneralResponse } from '../comercial/reffer/domain/reffer-response';
import { ListaAvanceVentasResponse, ListaAvanceVentasResumenResponse } from '../efectividad-ventas/domain/response/efectividad-ventas-response';
import { AvanceVentasRequest } from '../proyeccion-cuotas/domain/request/reporte-request';

@Injectable({
  providedIn: 'root'
})
export class ReportesService extends ReportesRepository {

  constructor(
    private readonly http: HttpClient
  ) {
    super();
  }

  ListaReporteVentasI(request: ReporteVentasIRequest): Observable<ReporteVentasIResponse> {
    return this.http.post<ReporteVentasIResponse>(`${environment.PATH_API}/reporteG/reporteventastabla1`, request);
  }
  ListaReporteVentasIII(request: ReporteVentasIRequest): Observable<ReporteVentasIIIResponse> {
    return this.http.post<ReporteVentasIIIResponse>(`${environment.PATH_API}/reporteG/reporteventastabla3`, request);
  }
  ListaReporteVentasII(request: ReporteVentasIRequest): Observable<ReporteVentasIIResponse> {
    return this.http.post<ReporteVentasIIResponse>(`${environment.PATH_API}/reporteG/reporteventastabla1`, request);
  }
  ReporteVentasGraficos(request: ReporteVentasIRequest): Observable<ReporteVentasGraficosResponse> {
    return this.http.post<ReporteVentasGraficosResponse>(`${environment.PATH_API}/reporteG/reporteventasgraficos`, request);
  }
  ReporteTeusFechaEta(request: ReporteTeusFechaEtaRequest): Observable<ReporteTeusFechaEtaResponse> {
    return this.http.post<ReporteTeusFechaEtaResponse>(`${environment.PATH_API}/reporteG/reporteteusfechaeta`, request);
  }

  ReporteCicloEmbarque(request: ReporteCicloEmbarqueRequest): Observable<ReporteCicloEmbarqueResponse> {
    return this.http.post<ReporteCicloEmbarqueResponse>(`${environment.PATH_API}/reporteG/reportecicloembarque`, request);
  }

  ReporteCicloEmbarqueDetalle(request: ReporteCicloEmbarqueRequest): Observable<ReporteCicloEmbarqueDetalleResponse> {
    return this.http.post<ReporteCicloEmbarqueDetalleResponse>(`${environment.PATH_API}/reporteG/reportecicloembarque`, request);
  }


  NuevoReporteSLI(request: ReporteServicioContenedorRequest): Observable<ReporteServicioContenedorResponse> {
    return this.http.post<ReporteServicioContenedorResponse>(`${environment.PATH_API}/reporteG/NuevoReporteSLI`, request);
  }

  ListaMenuReportes(request: ListaMenuReportesRequest): Observable<ListaMenuReportesResponse> {
    return this.http.post<ListaMenuReportesResponse>(`${environment.PATH_API}/reporteG/MenuReportes`, request);
  }

  ReporteClientesAnioEtaResumen(request: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteClientesAnioEtaResumenResponse> {
    return this.http.post<ReporteClientesAnioEtaResumenResponse>(`${environment.PATH_API}/reporteG/ReporteClientesAnioEtaResumen`, request);
  }

  ReporteClientesAnioEtaDetalle(request: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteClientesAnioEtaDetalleResponse> {
    return this.http.post<ReporteClientesAnioEtaDetalleResponse>(`${environment.PATH_API}/reporteG/ReporteClientesAnioEtaDetalle`, request);
  }

  ReporteParetodeCarteraResumen(request: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteParetodeCarteraResumenResponse> {
    return this.http.post<ReporteParetodeCarteraResumenResponse>(`${environment.PATH_API}/reporteG/ReporteParetodeCarteraResumen`, request);
  }

  ReporteParetodeCarteraDetalle(request: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteParetodeCarteraDetalleResponse> {
    return this.http.post<ReporteParetodeCarteraDetalleResponse>(`${environment.PATH_API}/reporteG/ReporteParetodeCarteraDetalle`, request);
  }

  TransaccionEjecutivoCosto(request: TransaccionEjecutivoCostoRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reporteG/TransaccionEjecutivoCosto`, request);
  }

  ReporteClienteAnio03(request: ReporteClienteAnio03Request): Observable<ReporteClienteAnio03Response> {
    return this.http.post<ReporteClienteAnio03Response>(`${environment.PATH_API}/reporteG/ReporteClienteAnio03`, request);
  }
  ReporteClienteAnioTipo03(request: ReporteClienteAnio03Request): Observable<ReporteClienteAnioTipo03Response> {
    return this.http.post<ReporteClienteAnioTipo03Response>(`${environment.PATH_API}/reporteG/ReporteClienteAnio03`, request);
  }
  ReporteClienteAnioTipo04(request: ReporteClienteAnio03Request): Observable<ReporteClienteAnioTipo04Response> {
    return this.http.post<ReporteClienteAnioTipo04Response>(`${environment.PATH_API}/reporteG/ReporteClienteAnio03`, request);
  }


  //DETALLES
  ReporteFlujoProyectado07(request: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteFlujoProyectado07Response> {
    return this.http.post<ReporteFlujoProyectado07Response>(`${environment.PATH_API}/reporteG/ReporteFlujoProyectado`, request);
  }

  ReporteFrecuenciaClienteResumen(request: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteFrecuenciaClienteResumenResponse> {
    return this.http.post<ReporteFrecuenciaClienteResumenResponse>(`${environment.PATH_API}/reporteG/ReporteFrecuenciaCliente`, request);
  }
  ReporteFrecuenciaClienteDetalle(request: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteFrecuenciaClienteDetalleResponse> {
    return this.http.post<ReporteFrecuenciaClienteDetalleResponse>(`${environment.PATH_API}/reporteG/ReporteFrecuenciaCliente`, request);
  }
  ReporteFrecuenciaClienteResumen02(request: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteFrecuenciaClienteResumen02Response> {
    return this.http.post<ReporteFrecuenciaClienteResumen02Response>(`${environment.PATH_API}/reporteG/ReporteFrecuenciaCliente`, request);
  }
  operacionesGastoEmpresa(request: operacionesGastoEmpresaRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reporteG/opeGastoEmpresa`, request);
  }

  ListarGastoEmpresa(request: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteFlujoProyectado07Response> {
    return this.http.post<ReporteFlujoProyectado07Response>(`${environment.PATH_API}/reporteG/ListarGastoEmpresa`, request);
  }

  ResumenAvanceComercial(request: AvanceVentasRequest): Observable<ListaAvanceVentasResumenResponse> {
    return this.http.post<ListaAvanceVentasResumenResponse>(`${environment.PATH_API}/reporteG/ResumenAvanceComercial`, request);
  }


}

<form [formGroup]= "group">
  <h2>AGREGAR CONTACTO</h2>
  <div class="inputs">
    <!--Nombre-->
    <mat-form-field class="container_input" appearance="outline">
        <input matInput type="text" placeholder="Nombre" formControlName="nombre">
        <mat-error>Ingrese un nombre</mat-error>
    </mat-form-field>

    <!--Apellidos-->
    <mat-form-field class="container_input" appearance="outline">
        <input matInput type="text" placeholder="Apellidos" formControlName="apellido">
        <mat-error>Ingrese un apellido</mat-error>
      </mat-form-field>

    <!--Documento-->
    <mat-form-field class="container_input" appearance="outline">
        <input matInput type="text" placeholder="Documento" formControlName="documento">
      </mat-form-field>

      <!--Correo-->
      <mat-form-field class="container_input" appearance="outline">
          <input matInput type="text" placeholder="Correo" formControlName="correo">

        </mat-form-field>

      <!--Telefono1-->
      <mat-form-field class="container_input" appearance="outline">
          <input matInput type="text" placeholder="Telefono1" formControlName="telefono1">
          <mat-error>Ingrese un telefono</mat-error>
        </mat-form-field>

      <!--Telefono2-->
      <mat-form-field class="container_input" appearance="outline">
          <input matInput type="text" placeholder="Telefono2" formControlName="telefono2">
        </mat-form-field>
  </div>

  <div class="container_button">
    <button mat-stroked-button   [disabled] = "group.invalid" (click) =  "guardar()">GUARDAR</button>
    <button mat-stroked-button (click)="closeModal()">SALIR</button>
  </div>

</form>

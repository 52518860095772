import { guardarolrequest, rolrequest } from '../roles/domain/request/rol_request';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RolResponse } from '../roles/domain/response/rol_response';
import { StorageService } from './storage.service';
import { RolRepository } from '../roles/domain/rol.repository';
import { CobranzasRepository } from '../finanzas/cobranzas/domain/cobranzas_repository';
import { RequestAnularCobranza, RequestImportarCobranzas, RequestListarCobranzas, RequestTransaccionCobranza, RequestdashboardCobranza } from '../finanzas/cobranzas/domain/request/request_cobranzasdiarias';
import { ResponseAnularCobranza, ResponseImportarCobranzasDiarias, ResponseListadoCobranzasDiarias, ResponseListadoReporteEjecutivo, ResponseListadoReporteResumen, ResponseTransaccionCobranzasDiarias, ResponsedashboardCobranza, ResponsedashboardCobranzaEstado, ResponsedashboardCobranzacliente, ResponsedashboardMontoFecha } from '../finanzas/cobranzas/domain/response/response_cobranzasdiarias';
import { proformaRequest } from '../proforma/domain/request/proforma_request';
import { ProformaResponse } from '../proforma/domain/response/proforma_response';

@Injectable()
export class FinanzasService extends CobranzasRepository{

  constructor(private readonly http:HttpClient,
    private readonly storage: StorageService
    
    ){

    super();
  }
  listarCobranzasDiarias(request:RequestListarCobranzas): Observable<ResponseListadoCobranzasDiarias> 
    {
        return this.http.post<ResponseListadoCobranzasDiarias>(`${environment.PATH_API}/finanzas/listarcobranzasdiarias/`,request);
    

    }

    importarCobranzasDiarias(request:RequestImportarCobranzas): Observable<ResponseImportarCobranzasDiarias> 
    {
        return this.http.post<ResponseImportarCobranzasDiarias>(`${environment.PATH_API}/finanzas/importarcobranzas/`,request);
    

    }


    listardashboardcobranzas(request:RequestdashboardCobranza): Observable<ResponsedashboardCobranza> 
    {
        return this.http.post<ResponsedashboardCobranza>(`${environment.PATH_API}/finanzas/listardashboardcobranzas/`,request);
    

    }



    
    listardashboardcobranzascliente(request:RequestdashboardCobranza): Observable<ResponsedashboardCobranzacliente> 
    {
        return this.http.post<ResponsedashboardCobranzacliente>(`${environment.PATH_API}/finanzas/listardashboardcobranzasclientes/`,request);
    

    }
 

    listadashboardcobranzasestado(request:RequestdashboardCobranza): Observable<ResponsedashboardCobranzaEstado> 
    {
        return this.http.post<ResponsedashboardCobranzaEstado>(`${environment.PATH_API}/finanzas/listadashboardcobranzasestado/`,request);
    
    }
 

    listar(prolrequest: proformaRequest): Observable<ProformaResponse> {
      return this.http.post<ProformaResponse>(`${environment.PATH_API}/parametros/ListaParametros`, prolrequest);
    }

    transaccioncobranzas(request:RequestTransaccionCobranza): Observable<ResponseTransaccionCobranzasDiarias> 
    {
        return this.http.post<ResponseTransaccionCobranzasDiarias>(`${environment.PATH_API}/finanzas/transaccioncobranzas/`,request);
    }

    listadoDashboardMontoPorFecha(request:RequestdashboardCobranza): Observable<ResponsedashboardMontoFecha> 
    {
        return this.http.post<ResponsedashboardMontoFecha>(`${environment.PATH_API}/finanzas/listadashboardcobranzasmontoporfecha/`,request);
    }
 
    anularCobranza(request:RequestAnularCobranza): Observable<ResponseAnularCobranza> 
    {
        return this.http.post<ResponseAnularCobranza>(`${environment.PATH_API}/finanzas/AnularCobranza/`,request);
    }
    listarReporteEjecutivo(request:RequestListarCobranzas): Observable<ResponseListadoReporteEjecutivo> 
    {
        return this.http.post<ResponseListadoReporteEjecutivo>(`${environment.PATH_API}/finanzas/listarReporteEjecutivo/`,request);
    }
    listarReporteResumen(request:RequestListarCobranzas): Observable<ResponseListadoReporteResumen> 
    {
        return this.http.post<ResponseListadoReporteResumen>(`${environment.PATH_API}/finanzas/listarReporteResumen/`,request);
    }
}

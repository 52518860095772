// import { DialogConfig } from '@angular/cdk/dialog';
import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { newmetadate, style } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { CryptoRepository } from '../interfaces/crypto-repository';
import { DatosCompartidosService } from '../services/datos-compartidos.service';

@Component({
  selector: 'app-dialog-table',
  templateUrl: './dialog-table.component.html',
  styleUrls: ['./dialog-table.component.css']
})
export class DialogTableComponent {
  @Output() onDataSelect = new EventEmitter<any>();
  puertosFavoritos: any[] = [];
  estilos: style = {
    header: {
      align: 'left',
      justify: 'center',
      size: '10px'
    },
    body: {
      size: '9px'
    }
  }
  constructor(public readonly data: DynamicDialogConfig, public readonly ref: DynamicDialogRef, private datosCompartidosService: DatosCompartidosService) {

  }

  serviciosytarifas_NaveViajePorPuerto: any[] = []
  fila: any
  key: any
  row: any;

  ngOnInit() {
    console.log("dd:  ", this.data.data);
    if (this.data.data) {
      this.puertosFavoritos = this.datosCompartidosService.getlistaDatos();
      if (this.puertosFavoritos != null) {
        console.log('listaPuertosFavoritos JSON format:', this.puertosFavoritos);

        // Asignamos la data modificada a serviciosytarifas_NaveViajePorPuerto
        this.serviciosytarifas_NaveViajePorPuerto = this.data.data.map(item => {
          const newItem = { ...item };
          if (item.puerto) {
            const puertoEncontrado = this.puertosFavoritos.find(puerto => puerto.codigo === item.puerto);
            newItem.puerto = puertoEncontrado ? puertoEncontrado.label : item.puerto;
          }
          return newItem; // Retornamos el item modificado
        });

        console.log("Data con puertos actualizados:", this.serviciosytarifas_NaveViajePorPuerto);
      }
    }
  }


  metadatableNaveViajePorPuerto: newmetadate[] = [
    { title: 'CodigoViaje', field: 'codigoviaje', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
    { title: 'Nombre_Nave', field: 'nombrenave', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
    { title: 'Viaje_Vuelo', field: 'viaje_vuelo', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
    { title: 'Transportista', field: 'transportista', filterType: 'text', inputType: 'text', popup: 'y' },
    { title: 'Puerto', field: 'puerto', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
    { title: 'ETA_ETD', field: 'eta_etd', filterType: 'text', inputType: 'date', inputStatus: 'lock' },
    { title: 'Fecha_Cierre_Direccionamiento', field: 'fechacierredireccionamiento', filterType: 'text', inputType: 'date' },
    { title: 'Fecha_Nave_Entregada', field: 'fechanaveentregada', filterType: 'text', inputType: 'date' },
    { title: 'Estado', field: 'estado', filterType: 'text', inputType: 'text' },
    { title: 'Feeder', field: 'feeder', filterType: 'boolean', inputType: 'checkbox' },
  ]

  close() {
    if (this.row) {
      const selectedData = {
        feeder: this.row['feeder'],
        codigoviaje: this.row['codigoviaje'],
        nombrenave: this.row['nombrenave'],
        viaje_vuelo: this.row['viaje_vuelo'] || null,
        eta_etd: this.row['eta_etd'] || null,
        puerto: this.row['puerto'] || null
      };

      this.ref.close(selectedData);
      this.onDataSelect.emit(selectedData);
      console.log("Datos en el row: ", this.row);

      // console.log("Nueva NAVE:", this.fila["nombrenave"])
      // // Aquí asignas el valor de `key`
      // this.key = 13398; // Asegúrate de que este valor es el identificador correcto

      // // Encuentra la fila correspondiente usando `this.key`
      // const rowIndex = this.serviciosytarifas_NaveViajePorPuerto.findIndex(row => row.codigoviaje === this.key);

      // if (rowIndex > -1) {
      //   this.serviciosytarifas_NaveViajePorPuerto[rowIndex].nombrenave = nombreNaveValue;
      //   console.log(`Fila con codigoviaje ${this.key} actualizada con el nuevo nombre de nave: ${nombreNaveValue}`);
      // } else {
      //   console.log(`No se encontró una fila con codigoviaje ${this.key}`);
      // }
    }
  }


}

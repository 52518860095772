import { Injectable } from "@angular/core";
import { ComisionesRepository } from "../comisiones/domain/Comisiones-repository";
import { ComisionesRequest, GuardarComisionesSLRequest, ImportarExcelRequest, ListadoExcel, ListadoReporteComisionesRequest, ListadoRequestBandejaFlete, ReservaSalaCorreo, requestcierremensual } from "../comisiones/domain/request/comisiones-request";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ListaSeguimientoCargaResponse } from "../dashboardVentas/domain/response/dashboard.response";
import { StorageService } from "./storage.service";
import { ComisionesResponse1, ComisionesResponse2, ImportarComisionesResponse, ListadoBandejaComisionesFlete, ListadoBandejaComisionesFleteResponse, ListadoComisionesSegurasResponse, ListadoReporteComisionesFleteResponse, ListadoReporteComisionesFleteServiciosLocalesResponse, ResponseEnvioCorreo, ResponseListadoComisionFleteExcelInsert, ResponseListadoComisionSLExcelInsert, registrarcierremensualresponse } from "../comisiones/domain/response/comisiones-response";
import { ComisionesServiciosRequest, GuardarComisionesServicioLocalesRequest } from "../comisiones-servicios/domain/request/comisiones-servicios-request";
import { ComisionesDetalladoResponse, ComisionesResumenResponse, GuardarComisionesServicioLocalesResponse } from "../comisiones-servicios/domain/response/comisiones-servicios-response";
import { ActualizarComisionesBrokerIResponse, DescargarArchivosResponse, GuardarArchivoResponse, GuardarComisionesBrokerResponse, ListarClientesBrokerResponse, ListarComisionesBrokerIResponse, ListarComisionesBrokerXmlResponse, ModificarClienteBrokerResponse } from "../comision-broker/domain/response/Comisiones-Broker-response";
import { ActualizarComisionesBrokerIRequest, DescargarArchivoRequest, DescargarArchivosRequest, GuardarComisionesBrokerRequest, ListarClientesBrokerRequest, ListarComisionesBrokerIRequest, ListarComisionesBrokerXmlRequest, ModificarClienteBrokerRequest } from "../comision-broker/domain/request/Comisiones-Broker-Request";
import { ComisionesSegurasRequest } from "../dashboard-comisiones-seguros/domain/request/request";
import { ComisionesSegurasResponse } from "../dashboard-comisiones-seguros/domain/response/response";
import { ConfirmarPagoComprobateRequest } from "../comision-broker-hbl-pagadas/domain/request/pago-comprobante-request";
import { ConfirmarPagoComprobanteResponse } from "../comision-broker-hbl-pagadas/domain/response/pago-comprobante-response";
import { ConfirmarPagoHblResponse } from "../reporte-comision-broker-hbl/domain/response/confirmar-pago-hbl-response";
import { ConfirmarPagoHblRequest } from "../reporte-comision-broker-hbl/domain/request/confirmar-pago-hbl-request";
import { ActualizarBrokerXmlRequest } from "../reporte-comision-broker/presentacion/domain/request/reporte-broker-bandeja-request";
import { ActualizarBrokerXmlResponse } from "../reporte-comision-broker/presentacion/domain/response/reporte-broker-bandeja-response";
import { ActualizarStatementAvisoRequest, ImportarStatementLineasRequest, ListarObservacionesStatementRequest, ListarStatementAvisoRequest, ListarStatementLineasRequest, ListarTiposRequest, ObservacionesStatementRequest } from "../statement-carrier/domain/request/statement-carrier-request";
import { ActualizarStatementAvisoResponse, ImportarStatementLineasResponse, ListarObservacionesStatementResponse, ListarStatementAvisoResponse, ListarStatementLineasResponse, ListarTiposResponse, ObservacionesStatementResponse } from "../statement-carrier/domain/response/statement-carrier-response";
import { ReporteGerenciaComercialChinaRequest } from "../reporte-gc-china/domain/request/request-reporte-gchina";
import { ReporteGerenciaComercialChinaResponse } from "../reporte-gc-china/domain/response/response-reporte-gcchina";
import { RequestImportarReporteSeguros, RequestReporteSeguros } from "../reporte-seguros/domain/request/reporte-seguros-request";
import { ResponseImportarReporteSeguros, ResponseReporteSeguros } from "../reporte-seguros/domain/response/reporte-seguros-response";

@Injectable()
export class ComisionesService extends ComisionesRepository {

    constructor(private readonly http: HttpClient,
        private readonly storage: StorageService

    ) {

        super();
    }

    listaComisiones1(prolrequest: ComisionesRequest): Observable<ComisionesResponse1> {
        return this.http.post<ComisionesResponse1>(`${environment.PATH_API}/comisiones/comisiones1`, prolrequest);
    }
    listaComisiones2(prolrequest: ComisionesRequest): Observable<ComisionesResponse2> {
        return this.http.post<ComisionesResponse2>(`${environment.PATH_API}/comisiones/comisiones2`, prolrequest);
    }

    listaComisionesServiciosDetallado(prolrequest: ComisionesServiciosRequest): Observable<ComisionesDetalladoResponse> {
        return this.http.post<ComisionesDetalladoResponse>(`${environment.PATH_API}/comisiones/comisionesservicioslocalesdetalle`, prolrequest);
    }

    listaComisionesServiciosResumen(prolrequest: ComisionesServiciosRequest): Observable<ComisionesResumenResponse> {
        return this.http.post<ComisionesResumenResponse>(`${environment.PATH_API}/comisiones/comisionesservicioslocalesresumen`, prolrequest);
    }

    importacionflete(prolrequest: ImportarExcelRequest): Observable<ImportarComisionesResponse> {
        return this.http.post<ImportarComisionesResponse>(`${environment.PATH_API}/comisiones/importacionflete`, prolrequest);
    }

    GuardarComisionesServiciosLocales(prolrequest: GuardarComisionesServicioLocalesRequest): Observable<GuardarComisionesServicioLocalesResponse> {
        return this.http.post<GuardarComisionesServicioLocalesResponse>(`${environment.PATH_API}/comisiones/importarcomisionesservicioslocales`, prolrequest);
    }


     GuardarComisionesSL(fd: GuardarComisionesSLRequest): Observable<GuardarComisionesServicioLocalesResponse>{ 
    return this.http.post<GuardarComisionesServicioLocalesResponse>(`${environment.PATH_API}/comisiones/importarcomisionesservicioslocales`, fd);
      }

    ListarReporteComisionesFlete(prolrequest: ListadoReporteComisionesRequest): Observable<ListadoReporteComisionesFleteResponse> {
        return this.http.post<ListadoReporteComisionesFleteResponse>(`${environment.PATH_API}/comisiones/listarreportecomisionesflete`, prolrequest);
    }

    ListarReporteComisionesServiciosLocales(prolrequest: ListadoReporteComisionesRequest): Observable<ListadoReporteComisionesFleteServiciosLocalesResponse> {
        return this.http.post<ListadoReporteComisionesFleteServiciosLocalesResponse>(`${environment.PATH_API}/comisiones/listarreportecomisionesflete`, prolrequest);
    }

    //#region COMISIONES BROKER

    ListarComisionesBrokerI(prolrequest: ListarComisionesBrokerIRequest): Observable<ListarComisionesBrokerIResponse> {
        return this.http.post<ListarComisionesBrokerIResponse>(`${environment.PATH_API}/comisiones/listarcomisionesbroker`, prolrequest);
    }

    ActualizarComisionesBrokerI(prolrequest: ActualizarComisionesBrokerIRequest): Observable<ActualizarComisionesBrokerIResponse> {
        return this.http.post<ActualizarComisionesBrokerIResponse>(`${environment.PATH_API}/comisiones/actualizarcomisionesbroker`, prolrequest);
    }

    ListarComisionesBrokerXml(prolrequest: ListarComisionesBrokerXmlRequest): Observable<ListarComisionesBrokerXmlResponse> {
        return this.http.post<ListarComisionesBrokerXmlResponse>(`${environment.PATH_API}/comisiones/listarcomisionesbrokerxml`, prolrequest);
    }

    ListadoComisionesBroker(prolrequest: ListarComisionesBrokerIRequest): Observable<ListarComisionesBrokerIResponse> {
        return this.http.post<ListarComisionesBrokerIResponse>(`${environment.PATH_API}/comisiones/listadocomisionesbroker`, prolrequest);
    }


    GuardarComisionesBroker(prolrequest: GuardarComisionesBrokerRequest): Observable<GuardarComisionesBrokerResponse> {
        return this.http.post<GuardarComisionesBrokerResponse>(`${environment.PATH_API}/comisiones/guardarcomisionesbroker`, prolrequest);
    }

    ConfirmarComprobanteBroker(prolrequest: ConfirmarPagoComprobateRequest): Observable<ConfirmarPagoComprobanteResponse> {
        return this.http.post<ConfirmarPagoComprobanteResponse>(`${environment.PATH_API}/comisiones/confirmarpagocomprobantebroker`, prolrequest);
    }

    ConfirmarComprobanteHbl(prolrequest: ConfirmarPagoHblRequest): Observable<ConfirmarPagoHblResponse> {
        return this.http.post<ConfirmarPagoHblResponse>(`${environment.PATH_API}/comisiones/confirmarpagohblbroker`, prolrequest);
    }

    ListarClientesBroker(prolrequest: ListarClientesBrokerRequest): Observable<ListarClientesBrokerResponse> {
        return this.http.post<ListarClientesBrokerResponse>(`${environment.PATH_API}/comisiones/listaclientesbroker`, prolrequest);
    }

    ModificarClienteBroker(prolrequest: ModificarClienteBrokerRequest): Observable<ModificarClienteBrokerResponse> {
        return this.http.post<ModificarClienteBrokerResponse>(`${environment.PATH_API}/comisiones/modificarclientebroker`, prolrequest);
    }
    //#endregion

    GuardarComisionesSeguras(prolrequest: ComisionesSegurasRequest): Observable<ComisionesSegurasResponse> {
        return this.http.post<ComisionesSegurasResponse>(`${environment.PATH_API}/comisiones/importarcomisionesseguros`, prolrequest);
    }

    GuardarArchivos(prolrequest: FormData): Observable<GuardarArchivoResponse> {
        return this.http.post<GuardarArchivoResponse>(`${environment.PATH_API}/integracion/guardarimagenes`, prolrequest);
    }

    DescargarArchivos(prolrequest: DescargarArchivosRequest): Observable<DescargarArchivosResponse> {
        return this.http.post<DescargarArchivosResponse>(`${environment.PATH_API}/integracion/descargararchivos`, prolrequest);
    }

    DescargarArchivo(prolrequest: DescargarArchivoRequest): Observable<DescargarArchivosResponse> {
        return this.http.post<DescargarArchivosResponse>(`${environment.PATH_API}/integracion/descargararchivo`, prolrequest);
    }


    listaComisionesSeguras(prolrequest: ListadoReporteComisionesRequest): Observable<ListadoComisionesSegurasResponse> {
        return this.http.post<ListadoComisionesSegurasResponse>(`${environment.PATH_API}/comisiones/reportecomisionesseguro`, prolrequest);
    }

    ActualizarBrokerXml(prolrequest: ActualizarBrokerXmlRequest): Observable<ActualizarBrokerXmlResponse> {
        return this.http.post<ActualizarBrokerXmlResponse>(`${environment.PATH_API}/comisiones/actualizarbrokerxml`, prolrequest);
    }

    ImportarStatementLineas(prolrequest: ImportarStatementLineasRequest): Observable<ImportarStatementLineasResponse> {
        return this.http.post<ImportarStatementLineasResponse>(`${environment.PATH_API}/operaciones/importarstatementlineas`, prolrequest);
    }

    ListarStatementLineas(prolrequest: ListarStatementLineasRequest): Observable<ListarStatementLineasResponse> {
        return this.http.post<ListarStatementLineasResponse>(`${environment.PATH_API}/operaciones/listarstatementlineas`, prolrequest);
    }

    ListarStatementAviso(prolrequest: ListarStatementAvisoRequest): Observable<ListarStatementAvisoResponse> {
        return this.http.post<ListarStatementAvisoResponse>(`${environment.PATH_API}/operaciones/listarstatementaviso`, prolrequest);
    }
    ActualizarStatementAviso(prolrequest: ActualizarStatementAvisoRequest): Observable<ActualizarStatementAvisoResponse> {
        return this.http.post<ActualizarStatementAvisoResponse>(`${environment.PATH_API}/operaciones/actualizarstatementaviso`, prolrequest);
    }
    ReporteGerenciaComercialChina(prolrequest: ReporteGerenciaComercialChinaRequest): Observable<ReporteGerenciaComercialChinaResponse> {
        return this.http.post<ReporteGerenciaComercialChinaResponse>(`${environment.PATH_API}/reporteG/reportegcchina`, prolrequest);
    }
    ObservacionesStatement(prolrequest: ObservacionesStatementRequest): Observable<ObservacionesStatementResponse> {
        return this.http.post<ObservacionesStatementResponse>(`${environment.PATH_API}/operaciones/observacionesstatement`, prolrequest);
    }
    ListarObservacionesStatement(prolrequest: ListarObservacionesStatementRequest): Observable<ListarObservacionesStatementResponse> {
        return this.http.post<ListarObservacionesStatementResponse>(`${environment.PATH_API}/operaciones/listarobservacionesstatement`, prolrequest);
    }

    ListarTipos(prolrequest: ListarTiposRequest): Observable<ListarTiposResponse> {
        return this.http.post<ListarTiposResponse>(`${environment.PATH_API}/Proformaexterna/listartipos`, prolrequest);
    }

    ListarComisionesBandejaFlete(prolrequest: ListadoRequestBandejaFlete): Observable<ListadoBandejaComisionesFleteResponse> {
        return this.http.post<ListadoBandejaComisionesFleteResponse>(`${environment.PATH_API}/comisiones/listardatoscomisionesfletes`, prolrequest);
    }


    confirmarpagohbl(prolrequest: ListadoRequestBandejaFlete): Observable<ListadoBandejaComisionesFleteResponse> {
        return this.http.post<ListadoBandejaComisionesFleteResponse>(`${environment.PATH_API}/comisiones/confimardatosbl`, prolrequest);
    }

    confimarcierremensual(prolrequest: requestcierremensual): Observable<registrarcierremensualresponse> {
        return this.http.post<registrarcierremensualresponse>(`${environment.PATH_API}/comisiones/registrarcierreproceso`, prolrequest);
    }


     ListarComisionesFleteExcelInsert(prolrequest: ListadoRequestBandejaFlete): Observable<ResponseListadoComisionFleteExcelInsert> {

        return this.http.post<ResponseListadoComisionFleteExcelInsert>(`${environment.PATH_API}/comisiones/listarcomisionesfleteconfirmados`, prolrequest);


     }
    

     ListarComisionesSLExcelInsert(prolrequest: ListadoRequestBandejaFlete): Observable<ResponseListadoComisionSLExcelInsert> {

        return this.http.post<ResponseListadoComisionSLExcelInsert>(`${environment.PATH_API}/comisiones/listarcomisionesSLconfirmados`, prolrequest);

     }



     EnviarCorreo(prolrequest: ReservaSalaCorreo): Observable<ResponseEnvioCorreo> {
        return this.http.post<ResponseEnvioCorreo>(`${environment.PATH_API}/integracion/enviarCorreoApi`, prolrequest);
    }
    EnviarCorreo2(prolrequest: ReservaSalaCorreo): Observable<ResponseEnvioCorreo> {
        return this.http.post<ResponseEnvioCorreo>(`${environment.PATH_API}/integracion/enviarCorreoApi2`, prolrequest);
    }
    
    override ListarReporteSeguro(prolrequest: RequestReporteSeguros): Observable<ResponseReporteSeguros> {
        return this.http.post<ResponseReporteSeguros>(`${environment.PATH_API}/comisiones/ReporteSeguros`, prolrequest);
    }
    override ImportarReporteSeguro(prolrequest: RequestImportarReporteSeguros): Observable<ResponseImportarReporteSeguros> {
        return this.http.post<ResponseImportarReporteSeguros>(`${environment.PATH_API}/comisiones/ImportarSeguros`, prolrequest);
    }

}
<div class="tituloPantalla">
    <mat-toolbar style="height: 33px;" fxLayout class="tituloPantalla">
        <h3 class="title">Bandeja de Tareas</h3>
    </mat-toolbar>
</div>

        <form [formGroup]="group"  class="a-example-full-width-alterno-content" style="margin: 0px !important; padding: 4vh 0vh;">

            <div class="a-example-full-width-alterno-content-column">

                <div class=" a-example-full-width-alterno-content">

                    <div class="a-example-full-width-alterno-content-row">
                        <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label">
                                <input pInputText
                                    formControlName="nombreproyecto" appendTo="body" />
                                <label>Nombre de proyecto</label>
                            </span>
                        </div>


                        <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label">
                                <p-calendar formControlName="fechaInicio"
                                    appendTo="body" [showIcon]="true" [showWeek]="false" class="p-inputtext-sm"
                                    dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="float-label">Fecha Desde</label>
                            </span>
                        </div>
    
                        <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label">
                                <p-calendar formControlName="fechaFin"
                                    appendTo="body" [showIcon]="true" [showWeek]="false" class="p-inputtext-sm"
                                    dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="float-label">Fecha Hasta</label>
                            </span>
                        </div>

                        <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label">
                                <p-dropdown [options]="ListaEstadosProyecto"
                                    placeholder="‎ " optionLabel="nombre" formControlName="estado" optionValue="codigo"
                                    appendTo="body" class="p-inputtext-sm">
                                </p-dropdown>
                                <label for="float-label">Estado</label><!--[options]="ListaEstadosProyecto"-->
                            </span>
                        </div>


                        <div class="a-example-full-width-alterno-content-row-buttons">

                            <div class="a-example-full-width-alterno-content-3">
                                <div class="a-example-full-width-alterno-content-2" style="display: flex; justify-content: center;">                            
                                    <p-button icon="pi pi-search" styleClass="p-button-rounded p-button-info p-button-outlined"
                                        (click)="buscar()" pTooltip="Buscar" tooltipPosition="bottom"></p-button>
                                </div>
                            </div>
        
        
                            <div class="a-example-full-width-alterno-content-3">
                                <div class="a-example-full-width-alterno-content-2" style="display: flex; justify-content: center;">                            
                                    <p-button icon="pi pi-file" styleClass="p-button-rounded p-button-info p-button-outlined"
                                        (click)="agregarTarea()" pTooltip="Nueva Tarea" tooltipPosition="bottom"></p-button>
                                </div>
                            </div>

                            <div class="a-example-full-width-alterno-content-3">
                                <div class="a-example-full-width-alterno-content-2" style="display: flex; justify-content: center;">                            
                                    <p-button icon="pi pi-sign-out" styleClass="p-button-rounded p-button-info p-button-outlined"
                                        (click)="salir('')" pTooltip="Salir" tooltipPosition="bottom"></p-button>
                                </div>
                            </div>
                        </div>

                    </div>



                </div>



            </div>
            <!-- <div fxLayoutGap="20px" class="label">
                <mat-form-field class="example-full-width-alterno3" appearance="outline">
                    <mat-label>Nombre de proyecto</mat-label>
                    <input matInput type="text" placeholder="Nombre de proyecto" formControlName="nombreproyecto">
                </mat-form-field>
                <mat-form-field appearance="outline" class="example-full-width-alterno3">
                    <mat-label>Fecha Desde</mat-label>
                    <input formControlName="fechaInicio" matInput [matDatepicker]="picker1" autocomplete="off" readonly
                        [disabled]="true" (dateChange)="onDateChangeDesde($event,'fechaInicio')">
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 color="primary"></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" class="example-full-width-alterno3">
                    <mat-label>Fecha Hasta</mat-label>
                    <input formControlName="fechaFin" matInput [matDatepicker]="picker2" autocomplete="off" readonly
                        [disabled]="true" (dateChange)="onDateChangeDesde($event,'fechaFin')">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 color="primary"></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="example-full-width-alterno3" appearance="outline">
                    <mat-label>Estado</mat-label>
                    <mat-select placeholder="Estado" formControlName="estado">
                        <mat-option value="">TODOS</mat-option>
                        <mat-option value="N">NUEVO</mat-option>
                        <mat-option value="P">PLANIFICADO</mat-option>
                        <mat-option value="E">EN CURSO</mat-option>
                        <mat-option value="R">EN REVISION</mat-option>
                        <mat-option value="C">COMPLETADO</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button class="button1" (click)="buscar()">Buscar</button>
                <button mat-raised-button class="button1" (click)="agregarTarea()">Nueva tarea</button>
                <button mat-raised-button class="button1" (click)="salir('')">Salir</button>
            </div> -->
        </form>

        <div class="a-example-full-width-alterno-content">
            <app-datatable [dataTable]="eventostareas_dataeventos" [metadataTable]="metadatableDataEventos"
                [filter]="true" [addRow]="false" [rowDialog]="true" [rowEdit]="false" [key]="'id'" >
                <ng-template #addHeaderStart>
                    <th class="header">
                        <div class="items-header">
                            <span>Acción</span>
                        </div>
                    </th>
                </ng-template>
                <ng-template #addBodyStart let-row="row">
                        <td class="a-datatable-datos-body-accion row-space" >
                            <p-confirmPopup></p-confirmPopup>


                            <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-success p-button-outlined"
                            (onClick)="agregarSubTareas('',row)" pTooltip="Agregar" tooltipPosition="top"></p-button>

                            <p-button icon="pi pi-pencil" styleClass="p-button-rounded p-button-secondary p-button-outlined"
                            (onClick)="editar('',row)" pTooltip="Editar" tooltipPosition="top"></p-button>
                            
                           



                            <!-- <button mat-raised-button class="button2 button-spacing" (click)="agregarSubTareas('',row)">Agregar Tarea</button>
                            <button mat-raised-button class="button2 button-spacing" (click)="editar('',row)">Editar</button> -->


                            
                            <!-- <button mat-raised-button class="button2" (click)="editarEstado('',row)">Estado</button> -->

                        </td>
                </ng-template>
            </app-datatable>
        </div>
<footer>
    <div class="contenido-pie">
      <p></p>
    </div>
  </footer>




  <style>
    .p-inputtext {
     width: 100%;
    }
    
    ::ng-deep .p-calendar {
        width: 100%;
        /* height: 47px; */
    }
    /* .button-width{
        width: 100%;
    } */

    .button-width{
        width: 100%;
        display: flex;
        justify-content: center;
        
    }
    ::ng-deep .p-button{
        width: 50%;
        margin: auto;
    }
    :host ::ng-deep .p-dropdown {
        width: 100%;
        height: 100%;
        /* height: 47px; */
    }


    :host ::ng-deep .p-tabview .p-tabview-panels {
        padding: 5px;
    }

    :host ::ng-deep .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        padding: 7px;
    }

    p-tabView>p-tabPanel {
        width: 500px;
    }
</style>


import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArchivosService {
  archivosSeleccionados: File[] = [];

  agregarArchivo(archivo: File) {
    this.archivosSeleccionados.push(archivo);
  }

  quitarArchivo(index: number) {
    this.archivosSeleccionados.splice(index, 1);
  }
}

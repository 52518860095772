import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appLineBreak]'
})
export class LineBreakDirective implements AfterViewInit {
  @Input() appLineBreak: number = 45; // Número de caracteres antes de aplicar el salto de línea
  @Input() wordsPerLine: number = 4; // Cantidad de palabras por línea

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const text = this.el.nativeElement.innerText; // Obtener el texto contenido en el elemento HTML
    if (text) { // Verificar que haya texto
      const words = text.split(' '); // Dividir el texto en palabras, separadas por espacios
      let line = ''; // Inicializar una línea vacía para construir las líneas
      let lines = []; // Inicializar un arreglo para almacenar las líneas de texto
      let wordCount = 0; // Contador de palabras en la línea actual

      words.forEach(word => { // Recorrer cada palabra en el texto
        if (wordCount < this.wordsPerLine) { // Comprobar si la cantidad de palabras en la línea actual es menor que el límite
          if (line === '') {
            line = word; // Si la línea está vacía, asignar la palabra actual
          } else {
            line += ' ' + word; // Si la línea no está vacía, agregar la palabra actual con un espacio
          }
          wordCount++; // Incrementar el contador de palabras en la línea
        } else {
          lines.push(line); // Agregar la línea actual al arreglo de líneas
          line = word; // Iniciar una nueva línea con la palabra actual
          wordCount = 1; // Reiniciar el contador de palabras en la línea
        }
      });

      if (line !== '') {
        lines.push(line); // Agregar la última línea al arreglo de líneas
      }

      this.el.nativeElement.innerHTML = lines.join('<br>'); // Establecer el contenido del elemento HTML con las líneas unidas por saltos de línea
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OperacionesRepository } from '../mantenimientos/domain/operaciones.repository';
import { ListarClientesTarifasSLRespose, ListarLayoutRespose, ModificarClientesTarifasSLResponse } from '../mantenimientos/domain/operaciones-response';
import { ModificarClientesTarifasSLRequest } from '../mantenimientos/domain/operaciones-request';

@Injectable({
  providedIn: 'root'
})
export class OperacionesService extends OperacionesRepository {

  constructor(
    private readonly http: HttpClient
  ) {
    super();
  }

  ListarLayout(): Observable<ListarLayoutRespose> {
    return this.http.post<ListarLayoutRespose>(`${environment.PATH_API}/operaciones/listarlayout`, '');
  }
  ListarClientesTarifasSL(): Observable<ListarClientesTarifasSLRespose> {
    return this.http.post<ListarClientesTarifasSLRespose>(`${environment.PATH_API}/operaciones/listarclientestarifassl`, '');
  }
  ModificarClientesTarifasSL(request: ModificarClientesTarifasSLRequest): Observable<ModificarClientesTarifasSLResponse> {
    return this.http.post<ModificarClientesTarifasSLResponse>(`${environment.PATH_API}/operaciones/modificarclientestarifassl`, request);
  }

}

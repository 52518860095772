import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { ClienteRepository } from "../visitascliente/dominio/cliente.repository";
import { requestcarteracliente, requestlistadoprogramacionxdia, requestlistarprogramacioncab, requestlistarprogramaciondet, requestmantprogramacion } from "../visitascliente/dominio/request/visitacliente-request";
import { reponselistadoprogramacionxdia, reponselistarprogramacioncab, reponselistarprogramaciondet, reponsemantprogramacion, responsecartera } from "../visitascliente/dominio/response/visitacliente-response";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { requestlistarcarteraxejecutivo, requestlistarejecutivo, requesttransaccionclienteprospecto, requestupdateprogramacionvisita, requestvisitasconfirmadas } from "../gestionvisitas/domain/request/requestgestionvisitas";
import { responselistadocarteraxejecutivo, responselistarejecutivo, responsetransaccionclienteprospecto, responseupdateprogramacionvisita, responsevisitasconfirmadas } from "../gestionvisitas/domain/response/response/response-llistadocarteraxejecutivo";


@Injectable()
export class ClienteService extends ClienteRepository{

  constructor(private readonly http:HttpClient,
    private readonly storage: StorageService

    ){

    super();
  }

  listarcartera(prolrequest:requestcarteracliente): Observable<responsecartera>
  {
      return this.http.post<responsecartera>(`${environment.PATH_API}/cliente/listarcarteraejecutivo`,prolrequest);
  }


  mantprogramacion(prolrequest:requestmantprogramacion): Observable<reponsemantprogramacion>
  {
      return this.http.post<reponsemantprogramacion>(`${environment.PATH_API}/cliente/mantclienteprogramacion`,prolrequest);
  }


  
  listarprogramacioncab(prolrequest:requestlistarprogramacioncab): Observable<reponselistarprogramacioncab>
  {
      return this.http.post<reponselistarprogramacioncab>(`${environment.PATH_API}/cliente/listarprogramacioncab`,prolrequest);
  }

  
  listarprogramaciondet(prolrequest:requestlistarprogramaciondet): Observable<reponselistarprogramaciondet>
  {
      return this.http.post<reponselistarprogramaciondet>(`${environment.PATH_API}/cliente/listarprogramaciondet`,prolrequest);
  }


  listarcarteraxejecutivo(prolrequest:requestlistarcarteraxejecutivo): Observable<responselistadocarteraxejecutivo>
  {
      return this.http.post<responselistadocarteraxejecutivo>(`${environment.PATH_API}/cliente/listarcarteraxejecutivo`,prolrequest);
  }


  updatevisitaprogramada(prolrequest:requestupdateprogramacionvisita): Observable<responseupdateprogramacionvisita>
  {
      return this.http.post<responseupdateprogramacionvisita>(`${environment.PATH_API}/cliente/updateclienteprogramacionvisita`,prolrequest);
  }

  listarvisistasconfirmadas(prolrequest:requestvisitasconfirmadas): Observable<responsevisitasconfirmadas>
  {
      return this.http.post<responsevisitasconfirmadas>(`${environment.PATH_API}/cliente/listarvisistasconfirmadasxejecutivo`,prolrequest);
  }

  listadoprogramacionxdia(prolrequest:requestlistadoprogramacionxdia): Observable<reponselistadoprogramacionxdia>
  {
      return this.http.post<reponselistadoprogramacionxdia>(`${environment.PATH_API}/cliente/listarprogramacionxdia`,prolrequest);
  }


  listarejecutivo(prolrequest:requestlistarejecutivo): Observable<responselistarejecutivo>
  {
      return this.http.post<responselistarejecutivo>(`${environment.PATH_API}/cliente/litarejecutivo`,prolrequest);
  }

  transaccionclienteprospecto(prolrequest:requesttransaccionclienteprospecto): Observable<responsetransaccionclienteprospecto>
  {
      return this.http.post<responsetransaccionclienteprospecto>(`${environment.PATH_API}/cliente/transaccionclienteprospecto`,prolrequest);
  }
  

  

}

<div class="main">

    <p-table #dt1 [value]="dataTable" [dataKey]="key" [rows]="rowsOption[0]" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="rowsOption" [paginator]="paginator" currentPageReportTemplate=" {first} de {totalRecords}"
        [globalFilterFields]="filtrosGlobal" selectionMode="single" styleClass="p-datatable-sm"
        (onRowSelect)="onRowSelect($event)">


        <ng-template pTemplate="header">
            <button (click)="viewData()">VIEW</button>
            <!-- <tr *ngFor="let item of formatHeaders(), let i = index " class="header-table">

                <ng-container *ngFor="let item2 of metadataTable">
                    <ng-container *ngIf="item === 0">
                        <th [attr.colspan]="colspanGenerate(item2.groupby, item)"
                            *ngIf="(item2.positiongroup !== undefined ? item2.positiongroup : 0) === item"
                            class="header">
                            <div class="flex align-items-center items-header">
                                {{ item2.title }} {{ item2.positiongroup !== undefined ? item2.positiongroup : item }}
                                {{ '
                                MI ITEM: ' + item }}
                            </div>
                        </th>
                    </ng-container>
                    <ng-container *ngIf="item !== 0">
                        <th [attr.colspan]="colspanGenerate(item2.groupby,item)">
                            <div class="flex align-items-center items-header"
                                *ngIf="(item2.positiongroup !== undefined ? item2.positiongroup : 0) === item"
                                class="header">
                                {{item2.title}}{{item2.positiongroup }}{{' MI ITEM: ' + item}}
                            </div>
                        </th>
                    </ng-container>
                </ng-container>

            </tr> -->

            <tr *ngFor="let item of formatHeaders(); let i = index" class="header-table">
                <ng-container *ngFor="let item2 of metadataTable">
                    <th *ngIf="(item2.positiongroup !== undefined ? item2.positiongroup : 0) === item && item === 0"
                        class="header">
                        <div class="flex align-items-center items-header">
                            {{ item2.title }} {{ item2.positiongroup !== undefined ? item2.positiongroup : item }} {{ '
                            MI ITEM: ' + item }}
                        </div>
                    </th>
                    <th [attr.colspan]="colspanGenerate(item2.groupby, item)" *ngIf="item>0" class="header">
                        <div class="flex align-items-center items-header">
                            {{ (item2.positiongroup !== undefined ? item2.positiongroup : 0) === item ? item2.title:''}}
                        </div>
                    </th>
                </ng-container>
            </tr>

            <!-- <tr *ngFor="let item of formatHeaders(), let i = index " class="header-table">

                <ng-container *ngFor="let item2 of metadataTable">
                    <th [attr.colspan]="colspanGenerate(item2.groupby)" *ngIf="item2.positiongroup  == item"
                        class="header">
                        <div class="flex align-items-center items-header">
                            {{ item2.title }}
                        </div>
                    </th>
                </ng-container>

            </tr> -->

            <!-- <tr class="header-table">
                <th *ngFor="let item of metadataTable" class="header">
                    <div class="flex align-items-center items-header">
                        {{item.title}}
                    </div>
                </th>
            </tr> -->
        </ng-template>

        <ng-template pTemplate="body" let-rowdata let-i="rowIndex" let-editing="editing">

            <tr class="body-table" [pSelectableRow]="rowdata" [pEditableRow]="rowdata">
                <td *ngFor="let item2 of metadata" class="body">
                    {{rowdata[item2.field]}}
                </td>
            </tr>

        </ng-template>
    </p-table>
</div>
import { DatosCompartidosService } from 'src/app/services/datos-compartidos.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { OperacionRepository } from 'src/app/repositorio/operacion.repository';
import { UtilService } from 'src/app/services/util.service';
import { FormControl, FormGroup } from '@angular/forms';
import { newmetadate, style } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { Subject } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-buscar-contrato',
  templateUrl: './buscar-contrato.component.html',
  styleUrls: ['./buscar-contrato.component.css']
})
export class BuscarContratoComponent {
  contrato: any;
  @Output() dataUpdated = new EventEmitter<any>();
  fila: any
  key: any
  row: any;

  estilos: style = {
    header: {
      align: 'left',
      justify: 'center',
      size: '10px'
    },
    body: {
      size: '9px'
    }
  }

  constructor(public dialogRef: MatDialogRef<BuscarContratoComponent>,
    private readonly servOperaciones: OperacionRepository,
    private util: UtilService,
    private datosCompartidosService: DatosCompartidosService
    //@Inject(MAT_DIALOG_DATA) public data: any
    //public readonly config: DynamicDialogConfig,
    //@Inject(MAT_DIALOG_DATA) private data: any,
    //public readonly ref: DynamicDialogRef
  ) {
    this.contratos = this.datosCompartidosService.getlistaDatosContratos();
  }

  protected _onDestroy = new Subject<void>();
  //@Output() dataUpdated = new EventEmitter<any>();
  group_contrato: FormGroup
  codtransportista: number
  dataTableContrato: any[] = []
  dataEnviada: any[] = [];
  contratos: any;

  metadataTableContrato: newmetadate[] = [
    { field: 'codigo', title: 'CÓDIGO', inputType: 'text' },
    { field: 'contrato', title: 'CONTRATO', inputType: 'text' },
    { field: 'vigencia', title: 'VIGENCIA', inputType: 'text' },
    { field: 'descripcion', title: 'DESCRIPCIÓN', inputType: 'text' },
    { field: 'puertoOrigen', title: 'PUERTO ORIGEN', inputType: 'text' },
    { field: 'puertoDestino', title: 'PUERTO DESTINO', inputType: 'text' },
    { field: 'cont_FecEmi', title: 'CONT_FecEmi', inputType: 'date' },
    { field: 'cont_FecFin', title: 'CONT_FecFin', inputType: 'date' }
    // { field: 'Standar_20', title: 'STANDAR 20', inputType: 'decimal' },
    // { field: 'Hc_40', title: 'HC 40', inputType: 'decimal' },
    // { field: 'Nor_40', title: 'NOR 40', inputType: 'decimal' },
    // { field: 'Standar_40', title: 'STANDAR 40', inputType: 'decimal' }
  ]

  ngOnInit(): void {
    const screenHeight = window.innerHeight;
    // console.log(screenHeight - 170)
    // console.log('Datos recibidos en el modal:', this.contratos);
    if (this.contratos) {
      this.dataTableContrato = [...this.contratos];
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  aceptar() {
    //const dataToReturn = { success: true, info: 'Algunos datos' };
    if (this.row) {
      const dataToReturn = {
        codigo: this.row['codigo'],
        descripcion: this.row['descripcion']
      };
      //console.log("Datos en el row: ", this.row);
      this.dialogRef.close(dataToReturn);
      this.dataUpdated.emit(dataToReturn);
      this.ngOnDestroy()
    }else{
      this.cancelar()
    }
  }

  cancelar() {
    this.dialogRef.close(null)
    this.ngOnDestroy()
  }

}

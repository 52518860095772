<mat-nav-list>
    <a href="#" mat-list-item (click)="export($event,'pdf')">
      <span mat-line>Exportar a PDF</span>
     
    </a>
  
    <a href="#" mat-list-item (click)="export($event,'xls')">
      <span mat-line>Exportar a Excel</span>
      
    </a>
  
  
  </mat-nav-list>
  
<div class="tituloPantalla">
    <mat-toolbar style="height: 33px;" fxLayout class="tituloPantalla">
        <h3 class="title">Estado</h3>
    </mat-toolbar>
</div>
<div class="Principal">
    <div class="formulario">
        <form [formGroup]="group">
            <div class="column">
                <h4>Estado</h4>
                <mat-form-field class="example-full-width-alterno3" appearance="outline">
                    <mat-label>Estado</mat-label>
                    <mat-select placeholder="Estado" formControlName="estado">
                        <mat-option value="N">NUEVO</mat-option>
                        <mat-option value="A">APROBADO</mat-option>
                        <mat-option value="P">PLANIFICADO</mat-option>
                        <mat-option value="E">EN CURSO</mat-option>
                        <mat-option value="R">EN REVISION</mat-option>
                        <mat-option value="C">COMPLETADO</mat-option>
                        <mat-option value="S">STAMPBY</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayoutGap="20px" class="label">
                <button mat-raised-button class="button2" (click)="actualizarEstado()">Actualizar</button>
                <button mat-raised-button class="button2" (click)="salir()">Cancelar</button>
            </div>
        </form>
    </div>
</div>
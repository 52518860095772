import { Component, OnInit } from '@angular/core';


//@ts-ignore
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  message: string = "Estas seguro de continuar la operación";
  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InsertItinerarioRefferRequest, ListarServiciosRefferRequest, ListaSemana } from '../../../domain/reffer-request';
import { ListBasico } from 'src/app/proyeccion-cuotas/domain/response/reporte_response';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { ComercialRepository } from 'src/app/repositorio/comercial.repository';
import { UtilService } from 'src/app/services/util.service';
import { StorageService } from 'src/app/services/storage.service';
import { ActivatedRoute } from '@angular/router';
import { CryptoRepository } from 'src/app/interfaces/crypto-repository';

@Component({
  selector: 'app-nuevo-itinerario-reffer',
  templateUrl: './nuevo-itinerario-reffer.component.html',
  styleUrls: ['./nuevo-itinerario-reffer.component.css']
})
export class NuevoItinerarioRefferComponent {

  constructor(
    private readonly reference: MatDialogRef<NuevoItinerarioRefferComponent>,
    private readonly proforma: ProformaRepository,
    private readonly comercial: ComercialRepository,
    private readonly util: UtilService,
    private readonly storage: StorageService,
    private readonly crypto: CryptoRepository,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) { }

  group: FormGroup

  inicializarFormulario() {
    this.group = new FormGroup({
      anio: new FormControl(null, null),
      semana: new FormControl(null, null),
      servicio: new FormControl(null, null),
      etd: new FormControl(null, null),
      plazo: new FormControl(null, null),
      nave: new FormControl(null, null),
      viaje: new FormControl(null, null),
    })
  }

  ListarAnios() {
    this.proforma.ListarAnio('').subscribe(response => {
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListBasico = {
          nombre: response.datos.result[i].anio.toString(),
          codigo: response.datos.result[i].anio.toString()
        }
        this.ListaAnios.push(item);
      }
    });
  }

  listarNave() {
    this.comercial.ListarNaves().subscribe(response => {
      this.ListaNave = response.datos.result
    })
  }

  listarServicios() {
    const request = <ListarServiciosRefferRequest>{}
    request.codigo = 0
    this.comercial.ListarServiciosReffer(request).subscribe(response => {
      if (response.datos.status = 200) {
        response.datos.result.forEach(r => {
          const item: ListBasico = {
            nombre: r.nombre.toString(),
            codigo: r.codigo
          }
          this.ListaServicios.push(item);
        })
      }
    })
  }

  ListaNave: any[] = []
  ListaAnios: any[] = []
  ListaSemanas: any[] = ListaSemana
  ListaServicios: any[] = []
  transaccion: string = ''
  dataEdit: any
  ngOnInit(): void {
    this.ListarAnios()
    this.listarServicios()
    this.inicializarFormulario()
    this.listarNave()

    // const dataEdit = JSON.parse(this.crypto.decrypt(this.data, 'Lim@2023'))
    this.dataEdit = this.data != undefined ? JSON.parse(this.crypto.decrypt(this.data, 'Lim@2023')) : null

    if (this.dataEdit) {
      setTimeout(() => {
        this.group.get('anio').setValue(this.ListaAnios.find(option => option.codigo === this.dataEdit.anio.toString()))
        this.group.get('semana').setValue(this.ListaSemanas.find(option => option.codigo === this.dataEdit.semana))
        this.group.get('servicio').setValue(this.ListaServicios.find(option => option.codigo === this.dataEdit.servicio))
        this.group.get('nave').setValue(this.ListaNave.find(option => option.codigo === this.dataEdit.codnave.toString()))
        this.group.get('etd').setValue(new Date(this.dataEdit.zarpe))
        this.group.get('plazo').setValue(this.dataEdit.plazo)
        this.group.get('viaje').setValue(this.dataEdit.viaje)
        const item = {
          codigo: this.dataEdit.servicio,
          nombre: '',
          codorigen: this.dataEdit.codorigen,
          origen: this.dataEdit.origen,
          codnaviera: this.dataEdit.codnaviera,
          naviera: this.dataEdit.naviera,
          coddestino: this.dataEdit.coddestino,
          destino: this.dataEdit.destino,
          transito: this.dataEdit.transito,
        }

        this.NaveServicio.push(item)
      }, 500);
      this.transaccion = 'EDIT'
    } else {
      this.transaccion = 'NUEVO'
    }
  }

  agregar(tabla: any, control: FormGroup, tipo: string) {
    if (control.valid) {
      const values = control.value
      let item: any
      if (tipo == 'shipper') {
        item = {
          ruc: values.ruc,
          empresa: values.empresa,
          direccion: values.direccion
        }
      } else if (tipo == 'contacto') {
        item = {
          telefono: values.telefono,
          correo: values.correo,
          direccion: values.direccion
        }
      } else {
        item = {
          ruc: values.ruc,
          empresa: values.empresa,
          direccion: values.direccion
        }
      }



      tabla.push(item)
      control.reset()
    } else {
      control.markAsTouched()
    }
  }

  quitar(table: any, item: any) {
    table.splice(item, 1);
  }

  OrigenServicio: number
  NavierasServicio: any[] = []
  DestinosServicio: any[] = []
  NaveServicio: any[] = []
  cargarServicio() {
    this.NavierasServicio = []
    this.DestinosServicio = []
    this.NaveServicio = []
    const request = <ListarServiciosRefferRequest>{}
    request.codigo = this.group.value.servicio.codigo
    this.comercial.ListarServiciosReffer(request).subscribe(response => {
      if (response.datos.status = 200) {
        response.datos.result.slice().forEach(r => {
          const item_naviera = {
            codigo: r.codigo,
            entc_codigo: r.codnaviera,
            nombre: r.naviera
          }

          const item_destino = {
            codigo: r.codigo,
            puer_codigo: r.coddestino,
            nombre: r.destino,
            transito: r.transito
          }

          // // Verificar si el código de naviera ya existe en NavierasServicio
          if (!this.NavierasServicio.some(item => item.entc_codigo === item_naviera.entc_codigo)) {
            this.NavierasServicio.push(item_naviera);
          }

          // Verificar si el código de destino ya existe en DestinosServicio
          if (!this.DestinosServicio.some(item => item.puer_codigo === item_destino.puer_codigo)) {
            this.DestinosServicio.push(item_destino);
          }


          this.OrigenServicio = r.codorigen
        })

        this.NaveServicio = response.datos.result.slice()

      }
    })
  }
  onTransitoChange(event: any, index: number) {
    this.NaveServicio[index].transito = event.target.value; // Actualizar el valor en puertos
  }

  NavesSeleccionadas: any[] = []
  onCheckboxChange(event: any, product: any) {
    if (event.checked) {
      // Agregar el elemento al array si está marcado
      this.NavesSeleccionadas.push(product);
    } else {
      // Eliminar el elemento del array si está desmarcado
      this.NavesSeleccionadas = this.NavesSeleccionadas.filter(item => item.codigo !== product.codigo);
    }
  }


  guardar() {

    if (this.transaccion == 'NUEVO') {
      let i = 0
      if (this.NavesSeleccionadas.length > 0) {

        this.NavesSeleccionadas.forEach(r => {
          const values = this.group.value
          const request = <InsertItinerarioRefferRequest>{}
          request.codigo = values.servicio ? values.servicio.codigo : 0
          request.anio = values.anio.codigo
          request.semana = values.semana.codigo

          request.naviera = r.codnaviera
          request.origen = r.codorigen
          request.destino = r.coddestino
          request.transito = r.transito
          request.etd = values.etd
          request.zarpe
          request.arribo = this.sumarDias(values.etd, parseInt(r.transito));
          request.plazo = values.plazo
          request.nave = values.nave.codigo
          request.usuario = this.storage.get('usuario')
          request.tipo = 'I'
          request.itinerario = 0
          request.estado = ''
          request.viaje = values.viaje

          this.comercial.InsertItinerarioReffer(request).subscribe(response => {
            if (response.datos.status = 200) {
              i++
              if (i == this.NavesSeleccionadas.length) { this.util.ShowMessage({ mensaje: 'Servicio Registrado.', tipo: 'ok' }) }
              this.salir()
            }
          }, (errorServicio) => {
            i++
            if (i == this.NavesSeleccionadas.length) { this.util.ShowMessage({ mensaje: 'Error Registro Servicio.', tipo: 'warning' }) }
          })
        })
      } else {
        this.util.ShowMessage({ mensaje: 'Seleccione los servicios.' })
      }
    } else {

      const values = this.group.value
      const request = <InsertItinerarioRefferRequest>{}
      request.codigo = values.servicio ? values.servicio.codigo : 0
      request.anio = values.anio.codigo
      request.semana = values.semana.codigo

      request.naviera = this.NaveServicio[0].codnaviera
      request.origen = this.NaveServicio[0].codorigen
      request.destino = this.NaveServicio[0].coddestino
      request.transito = this.NaveServicio[0].transito
      request.etd = values.etd
      request.zarpe
      request.arribo = this.sumarDias(values.etd, parseInt(this.NaveServicio[0].transito));
      request.plazo = values.plazo
      request.nave = values.nave.codigo
      request.usuario = this.storage.get('usuario')
      request.tipo = 'U'
      request.itinerario = this.dataEdit.codigo
      request.estado = 'A'
      request.viaje = values.viaje

      this.comercial.InsertItinerarioReffer(request).subscribe(response => {
        if (response.datos.status = 200) {
          this.util.ShowMessage({ mensaje: 'Servicio Actualizado.', tipo: 'ok' })
          this.salir()
        }
      }, (errorServicio) => {
        this.util.ShowMessage({ mensaje: 'Error Actualizar Servicio.', tipo: 'warning' })
      })
    }
  }

  sumarDias(fecha: Date, dias: number): Date {
    const resultado = new Date(fecha); // Crea una copia de la fecha original
    resultado.setDate(resultado.getDate() + dias); // Suma los días
    return resultado;
  }

  salir() {
    this.reference.close()
  }
}

import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput } from '@fullcalendar/core';


import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import { FormControl, FormGroup } from '@angular/forms';
import { listadoprogracion, requestcarteracliente, requestlistarprogramacioncab, requestlistarprogramaciondet, requestmantprogramacion } from 'src/app/visitascliente/dominio/request/visitacliente-request';
import { StorageService } from 'src/app/services/storage.service';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { ClienteRepository } from 'src/app/visitascliente/dominio/cliente.repository';
import { UtilService } from 'src/app/services/util.service';
import { ListadoCarteraCliente, listadoprogramacioncab, reponsemantprogramacion, responsecartera, respuestamantprogramacion } from 'src/app/visitascliente/dominio/response/visitacliente-response';
import { MetadataTable } from 'src/app/interfaces/metada-table.interface';
import { DatePipe } from '@angular/common';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatTabGroup } from '@angular/material/tabs';

import { MAT_DATE_FORMATS } from '@angular/material/core';



export const MY_DATE_FORMATS = {

  parse: {

    dateInput: 'DD/MM/YYYY',

  },

  display: {

    dateInput: 'DD/MM/YYYY',

    monthYearLabel: 'MMMM YYYY',

    dateA11yLabel: 'LL',

    monthYearA11yLabel: 'MMMM YYYY'

  },

};


@Component({
  selector: 'app-visitacliente',
  templateUrl: './visitacliente.component.html',
  styleUrls: ['./visitacliente.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class VisitaclienteComponent {

  latitude: number
  longitude: number
  group: FormGroup
  requestcartera: requestcarteracliente;
  listarcartera: ListadoCarteraCliente[];
  listacarterageneral: ListadoCarteraCliente[] = []
  dataTable: any// ListadoCarteraCliente[]
  modo: string

  constructor(private readonly storage: StorageService, private readonly clienteService: ClienteRepository, private util: UtilService, private router: Router) { }


  columnsToView: string[] = [];
  metadataTable: MetadataTable[] = []
  metadataTableGeneral: MetadataTable[] = []

  idprogramacionglobal: number

  isnuevo: boolean

  primerDiaMes: Date
  ultimoDiaMes: Date

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('tabGroup') tabGroup: MatTabGroup;


  ngOnInit(): void {



    // Ejemplo de uso


    const datosSerializados = localStorage.getItem('dato')

    const datos = JSON.parse(datosSerializados);;
    localStorage.removeItem('dato');

    if (datos != null) {
      this.modo = 'U'

      this.isnuevo = false
      const periodo = datos.periodo.toString();
      const anio = periodo.slice(0, 4)
      const mes = periodo.slice(-2)
      this.inicializaFormulario(datos, mes, anio);
      this.idprogramacionglobal = datos.idprogramacion
      this.listarcarteraclienteBD(datos.idprogramacion)

      let porcentaje = (Number(datos.forecast == null ? 0 : datos.forecast) / datos.monto) * 100
      this.group.controls['porcentaje'].setValue(porcentaje.toFixed(0))


      this.seleccionarPestana(1)

    } else {

      this.modo = 'I'
      this.isnuevo = true
      this.inicializaFormulario(datos, '', '');
    }


    // this.listarcarteracliente();
    const fechaActual = new Date();
    // Calcula el primer día del mes actual
    this.primerDiaMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);

    // Calcula el último día del mes actual
    this.ultimoDiaMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);



  }



  seleccionarPestana(pestanaIndex: number) {
    this.tabGroup.selectedIndex = pestanaIndex;
  }

  onCheckboxChange(tipo: string, event: any, row: ListadoCarteraCliente, fieldName: string) {
    const isChecked = event.checked;
    // Aquí puedes hacer lo que necesites con el valor del checkbox

    const dia = Number(fieldName.substring(0, 2))


    row['Dia' + dia] = isChecked;

    if (isChecked === true) {

      this.listadoprogramacion = <listadoprogracion>{}
      this.listadoprogramacion.idprogramacion = 0
      this.listadoprogramacion.item = row.item
      this.listadoprogramacion.codejecutivo = row.codigoejecutivo
      this.listadoprogramacion.codcliente = row.codigocliente
      this.listadoprogramacion.fechavisitaprogramada = this.formatearFecha(fieldName)
      this.listadoprogramacion.cons_tabvispro = 'VIS'
      if (tipo === 'F') {
        this.listadoprogramacion.cons_codvispro = '001'
      } else {
        this.listadoprogramacion.cons_codvispro = '002'
      }


      this.listadoprogramacion.fecharealvisitareal = null
      this.listadoprogramacion.cons_tabvisreal = ''
      this.listadoprogramacion.cons_codvisreal = ''
      this.listadoprogramacion.visito = false
      this.listadoprogramacion.reprogramado = false
      this.listadoprogramacion.fecha_reprogramacion = null
      this.listadoprogramacion.audi_feccre = this.util.formatearfecha(new Date(), 'yyyy-MM-ddTHH:mm:ss')
      this.listadoprogramacion.audi_usrcre =
        this.listadoprogramacion.audi_usract = null
      this.listadoprogramacion.audi_fecact = null
      this.listadoprogramacion.accion = "I"
      this.listadoprogramacion.forecast = 0.00

      this.listadoprogramacionguardar.push(this.listadoprogramacion)

    }

    if (isChecked == false) {

      const usuario = this.storage.get('codusuario');

      this.listadoprogramacion = <listadoprogracion>{}
      this.listadoprogramacion.idprogramacion = 0
      this.listadoprogramacion.item = row.item
      this.listadoprogramacion.codejecutivo = row.codigoejecutivo
      this.listadoprogramacion.codcliente = row.codigocliente
      this.listadoprogramacion.fechavisitaprogramada = this.formatearFecha(fieldName)
      this.listadoprogramacion.cons_tabvispro = 'VIS'
      this.listadoprogramacion.cons_codvispro = '001'
      this.listadoprogramacion.fecharealvisitareal = null
      this.listadoprogramacion.cons_tabvisreal = ''
      this.listadoprogramacion.cons_codvisreal = ''
      this.listadoprogramacion.visito = false
      this.listadoprogramacion.reprogramado = false
      this.listadoprogramacion.fecha_reprogramacion = null
      this.listadoprogramacion.audi_usrcre = usuario
      this.listadoprogramacion.audi_feccre = this.util.formatearfecha(new Date(), 'yyyy-MM-ddTHH:mm:ss')
      this.listadoprogramacion.audi_usract = null
      this.listadoprogramacion.audi_fecact = null
      this.listadoprogramacion.accion = "D"

      this.listadoprogramacionguardar.push(this.listadoprogramacion)



    }





    // alert(`El checkbox en la fila ${row} y columna ${fieldName} fue seleccionado: ${isChecked}  dia nro ${fieldName.substring(0,2)}`);
  }
  totalSum = 0
  @ViewChildren('inputValue', { read: ElementRef }) inputValues: QueryList<ElementRef>;

  /*ngAfterViewInit(): void {
    this.calculateTotalSum();
    this.paginator.page.subscribe((pageEvent: PageEvent) => {
      this.calculateTotalSum();
    });
  }
  */

  calculateTotalSum(): void {
    this.totalSum = this.dataTable.reduce((acc, row) => acc + +row.forecast, 0);
  }

  onInputBlur(event: any, row: any, metadataField: string) {
    const inputValue = event.target.value;

    let sumaforecast = 0

    const index = this.listadoprogramacionguardar.findIndex(x => (x.codcliente + x.codejecutivo) === (row.codigocliente + row.codigoejecutivo) && ((x.accion == 'U') || (x.accion == 'I')));

    if (index !== -1) {

      this.listadoprogramacionguardar[index].forecast = inputValue;


      this.listarcartera.forEach(element => {

        sumaforecast += Number(element.forecast)
      });


      const totalSum = this.inputValues
        .map(input => +input.nativeElement.value)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      //alert('Total sum: '+ totalSum.toString());

      //this.totalSum = this.dataTable.reduce((acc, row) => acc + +row.valor, 0);
      sumaforecast = Number(this.group.controls['forecast'].value) + Number(inputValue)

      this.group.controls['forecast'].setValue(this.totalSum)

      if (this.group.get('cuota').value > 0) {
        let porcentaje = ((sumaforecast / this.group.get('cuota').value) * 100)

        this.group.controls['porcentaje'].setValue(porcentaje)
      }


    }

  }


  formatearFecha(fechaString: string): string {
    // Dividimos la cadena por las barras "/" para obtener el día, mes y año
    const partes = fechaString.split("/");

    // Creamos un nuevo objeto Date proporcionando los valores de año, mes (restado 1 porque en Date los meses van de 0 a 11) y día
    const fecha = new Date(Number(partes[2]), Number(partes[1]) - 1, Number(partes[0]));

    // Obtenemos los componentes de la fecha (año, mes, día, horas, minutos y segundos)
    const anio = fecha.getFullYear();
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0"); // Aseguramos que tenga 2 dígitos
    const dia = fecha.getDate().toString().padStart(2, "0"); // Aseguramos que tenga 2 dígitos
    const horas = "00";
    const minutos = "00";
    const segundos = "00";

    // Concatenamos los componentes de la fecha en el formato deseado
    const fechaFormateada = `${anio}-${mes}-${dia}T${horas}:${minutos}:${segundos}`;

    return fechaFormateada;
  }



  inicializaFormulario(row: any, mes, anio) {


    if (row === null) {

      this.group = new FormGroup({
        mes: new FormControl(null, null),
        forecast: new FormControl(0, null),
        estado: new FormControl(null, null),
        anio: new FormControl(null, null),
        cuota: new FormControl(0, null),
        porcentaje: new FormControl(0, null),
        fechainicio: new FormControl(null, null),
        fechafin: new FormControl(null, null),
        clientefind: new FormControl("", null)

      });
    } else {

      this.group = new FormGroup({
        mes: new FormControl(mes, null),
        forecast: new FormControl(row.forecast, null),
        estado: new FormControl(null, null),
        anio: new FormControl(anio, null),
        cuota: new FormControl(row.monto, null),
        porcentaje: new FormControl(null, null),
        fechainicio: new FormControl(null, null),
        fechafin: new FormControl(null, null),
        clientefind: new FormControl("", null)

      });

    }


  }


  requestmantprogramacion: requestmantprogramacion
  listadoprogramacion: listadoprogracion;
  listadoprogramacionguardar: listadoprogracion[] = [];
  listadorespuestaguardar: respuestamantprogramacion[]



  guardarprogramacion() {


    const usuario = this.storage.get('codusuario');
    const groupvalues = this.group.value



    this.requestmantprogramacion = <requestmantprogramacion>{}

    const periodo = groupvalues['anio'] + groupvalues["mes"]

    this.requestmantprogramacion.idprogramacion = this.idprogramacionglobal === undefined || this.idprogramacionglobal == null ? 0 : this.idprogramacionglobal
    this.requestmantprogramacion.periodo = periodo
    this.requestmantprogramacion.NombreMes = ""
    this.requestmantprogramacion.activo = true
    this.requestmantprogramacion.forecast = groupvalues['forecast']
    this.requestmantprogramacion.usuario = usuario
    this.requestmantprogramacion.accion = this.modo
    this.requestmantprogramacion.ListadoProgramacion = this.listadoprogramacionguardar






    this.clienteService.mantprogramacion(this.requestmantprogramacion).subscribe
      ((response) => {


        if (response.datos.status === 200) {


          this.util.showMessage('Se registro correctamente');
          this.listadorespuestaguardar = response.datos.result;
          /*     this.dataTable =  this.listarcartera 
               this.dataTable.paginator = this.paginator;
               this.paginator.firstPage()
               */

          this.listarcarteraclienteBD(this.idprogramacionglobal)


          this.listarcarteraclientecab()


          this.listadoprogramacionguardar = []



        }


        this.listarcartera.forEach(element => {

          this.listacarterageneral.push(element)
        });


      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);

      });



  }


  listarcarteracliente() {


    const groupvalues = this.group.value
    const correo = this.storage.get('correo');

    const periodo = groupvalues['anio'] + groupvalues['mes']


    const mesSeleccionado = groupvalues['mes']; // Agosto
    const anioSeleccionado = groupvalues['anio'];
    this.generarFechasPorMes(mesSeleccionado, anioSeleccionado);

    this.columnsToView = this.metadataTable.map(
      (el: MetadataTable) => el.field);


    this.requestcartera = <requestcarteracliente>{}
    this.requestcartera.codEjecutivo = 0,
      this.requestcartera.correousuario = correo,
      this.requestcartera.periodo = periodo


    this.clienteService.listarcartera(this.requestcartera).subscribe
      ((response) => {


        if (response.datos.status === 200) {



          this.listarcartera = response.datos.result.map(registro => {

            const defecto = '001'




            return {
              ...registro,


            };


          });



          this.dataTable = new MatTableDataSource<any>(this.listarcartera);
          // this.dataTable =  this.listarcartera 
          if (this.paginator) {
            this.dataTable.paginator = this.paginator;
            this.paginator.firstPage()
          }

          this.group.controls['cuota'].setValue(this.listarcartera[0].monto)


          this.seleccionarPestana(1)


        }


        this.listarcartera.forEach(element => {

          this.listacarterageneral.push(element)
        });


      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);

      });



  }



  requestlistarprogramacioncab: requestlistarprogramacioncab;
  listatoprogramamcioncab: listadoprogramacioncab[];

  listarcarteraclientecab() {

    const groupvalues = this.group.value
    const correo = this.storage.get('correo');

    const periodo = groupvalues['anio'] + groupvalues['mes']

    const mesSeleccionado = groupvalues['mes']; // Agosto
    const anioSeleccionado = groupvalues['anio'];


    this.requestlistarprogramacioncab = <requestlistarprogramacioncab>{}

    this.requestlistarprogramacioncab.correousuario = correo

    if (periodo > 0) {
      this.requestlistarprogramacioncab.periodo = periodo
    } else {
      this.requestlistarprogramacioncab.periodo = 0
    }



    this.clienteService.listarprogramacioncab(this.requestlistarprogramacioncab).subscribe
      ((response) => {


        if (response.datos.status === 200) {

          this.listatoprogramamcioncab = response.datos.result;

          this.group.controls['forecast'].setValue(this.listatoprogramamcioncab[0].forecast)


        }


      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);

      });

  }

  /*
  ngAfterViewInit() {
    this.dataTable.paginator = this.paginator;
    //this.dataTable.sort = this.sort;
  }
  */


  limpiar(value) {
    this.group.get(value).reset();
    if (value == 'clientefind') {
      // this.codigoTransportistaSeleccionado = 0;

    }
  }

  textoFiltro: string = '';
  codigoTransportistaSeleccionado: number = 0
  clienteSeleccionado: any;
  opcionSeleccionada(event: MatAutocompleteSelectedEvent) {
    this.clienteSeleccionado = this.dataTable.find(option => option.nombrecliente === event.option.value);
    //this.codigoTransportistaSeleccionado = this.transportistaSeleccionado.codigoCliente;
  }


  formaterfecha(pfecha) {

    let fechaFormateada

    let fecha = new Date(pfecha)

    const dia = fecha.getDate().toString().padStart(2, '0'); // Obtener el día y asegurarse de que tenga 2 dígitos (agregando un 0 si es necesario)
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Obtener el mes y asegurarse de que tenga 2 dígitos (agregando un 0 si es necesario)
    const año = fecha.getFullYear(); // Obtener el año en formato de 4 dígitos

    // Paso 3: Crear la cadena de fecha formateada
    return fechaFormateada = `${dia}/${mes}/${año}`;


  }
  buscar() {

    let values = this.group.value

    var fechainicio = this.formaterfecha(values['fechainicio'])
    var fechafin = this.formaterfecha(values['fechafin'])


    var camposCumplenConCondiciones = [];
    camposCumplenConCondiciones.push({ field: 'nombrecliente', title: 'Cliente', type: 'text' });

    this.metadataTable = this.metadataTableGeneral
    this.metadataTable.forEach(item => {
      if (item.title >= fechainicio && item.title <= fechafin) {

        camposCumplenConCondiciones.push(item);

      }

    });


    this.metadataTable = camposCumplenConCondiciones

    this.metadataTable.push({ field: 'forecast', title: 'Forecast', type: 'number' });

    this.columnsToView = this.metadataTable.map(
      (el: MetadataTable) => el.field);


    let clientebusqueda = values['clientefind'] == null ? "" : values['clientefind']
    if (clientebusqueda != "") {

      let listafiltrada = this.listarcartera.filter(x => x.nombrecliente == values['clientefind'])
      this.listarcartera = listafiltrada
    } else {

      this.listarcartera = this.listacarterageneral
    }

    this.dataTable = new MatTableDataSource<any>(this.listarcartera);
    // this.dataTable =  this.listarcartera 
    if (this.paginator) {
      this.dataTable.paginator = this.paginator;

      this.paginator.pageIndex = this.storage.get("indice");
    }

    /*this.listarcartera.forEach(element => {
    
    this.listacarterageneral.push(element)
    });*/


    this.seleccionarPestana(1)


  }

  requestprogramaciondet: requestlistarprogramaciondet
  listarcarteraclienteBD(idprogramamcion: any) {


    const groupvalues = this.group.value

    this.requestprogramaciondet = <requestlistarprogramaciondet>{}

    this.requestprogramaciondet.idprogramacion = idprogramamcion



    this.clienteService.listarprogramaciondet(this.requestprogramaciondet).subscribe
      ((response) => {


        if (response.datos.status === 200) {

          //   this.listarcartera = response.datos.result;

          this.listarcartera = response.datos.result.map(registro => {

            const Accion = 'U'



            return {
              ...registro,
              accion: Accion,


            };
          });


          //   this.listadoprogramacionguardar =  this.listarcartera

          const mesSeleccionado = groupvalues['mes']; // Agosto
          const anioSeleccionado = groupvalues['anio'];
          this.generarFechasPorMes(mesSeleccionado, anioSeleccionado);

          this.columnsToView = this.metadataTable.map(
            (el: MetadataTable) => el.field);

          this.metadataTableGeneral = this.metadataTable


          this.dataTable = new MatTableDataSource<any>(this.listarcartera);
          // this.dataTable =  this.listarcartera 
          if (this.paginator) {
            this.dataTable.paginator = this.paginator;

            this.paginator.pageIndex = this.storage.get("indice");
          }


          // this.dataTable = Array.from( this.listarcartera )
          //  this.dataTable.paginator = this.paginator;
          //   this.paginator.firstPage()

        }

        this.listacarterageneral = []
        this.listarcartera.forEach(element => {

          this.listacarterageneral.push(element)
        });


      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);

      });



  }


  onPageChange(event: PageEvent): void {
    const pageIndex = event.pageIndex;
    if (pageIndex > 0) {

      this.storage.save("indice", pageIndex.toString())
    }

    console.log('Índice de la página actual:', pageIndex);

    // Lógica para cambiar de página si lo necesitas
    // Aquí puedes actualizar tus datos y mantener el índice del paginador
  }


  // Función para generar todas las fechas de un mes (excepto sábados y domingos)
  generarFechasPorMes(mes, anio) {


    this.metadataTable = [];
    this.metadataTable.push({ field: 'nombrecliente', title: 'Cliente', type: 'text' });

    const numDias = new Date(anio, mes, 0).getDate();

    for (let dia = 1; dia <= numDias; dia++) {
      const fechaActual = new Date(anio, mes - 1, dia);
      const diaSemana = fechaActual.getDay();

      // Agregamos la fecha a la tabla solo si no es sábado (6) ni domingo (0)
      if (diaSemana !== 0 && diaSemana !== 6) {
        const nombreDia = fechaActual.toLocaleString('es-ES', { weekday: 'long' }); // Nombre del día de la semana
        const fechaFormateada = fechaActual.toLocaleDateString('en-GB'); // Formato: DD/MM/YYYY
        this.metadataTable.push({ field: "dia" + dia, title: fechaFormateada, type: 'checkbox' });
      }
    }

    this.metadataTable.push({ field: 'forecast', title: 'Forecast', type: 'number' });




    //return metadataTable;
  }


  extractNumberFromField(field: string): string {
    // Utiliza expresiones regulares para extraer el número de la cadena
    // const matches = field.match(/\d+/);
    const texto = field.replace("dia", "tipo")
    return texto;
  }



  salir() {

    this.storage.save("indice", "0")
    this.router.navigate(['/Visitas']);
  }



  onDateChangeDesde(event: any, campo: string) {
    const selectedDate = event.value; // Obtener la fecha seleccionada
    selectedDate.setHours(0, 0, 0, 0);
    // Formatear la fecha en el formato deseado y actualizar el valor del formulario
    this.group.get(campo).setValue(selectedDate.toISOString());
  }




}

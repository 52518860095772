import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
@Component({
  selector: 'app-message',
  template: `
  <div>{{ data.message }}</div>
`,
styles: [`
div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: purple;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
`]
})
export class MessageNewComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}

import { Component, ContentChildren, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';

import { MetadataTable, TotalesInterface } from '../../../interfaces/metada-table.interface';
import { MatTable, MatColumnDef, MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

//@ts-ignore
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() dataTable: any[];
  @Input() metadataTable: MetadataTable[] = [];
  @Input() columnasConTotales: string[] = [];

  @ViewChild(MatTable, { static: true }) table: MatTable<any>  //contiene una referencia al componente mat-table ,static: true que haga una excepcion
  @ContentChildren(MatColumnDef) columnsDef: QueryList<MatColumnDef>// contieen referencia a mas de un elemento
  columnsToView: string[] = [];

  @Input() exportar: boolean = false;
  @Input() footer: boolean = false;
  @Input() visiblepaginacion: boolean = true;
  @Input() filtro: boolean = false;
  @Input() AlingTotales: TotalesInterface[] = [];
  @Input() totalstyle: string = 'center'

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: any;
  constructor(private _liveAnnouncer: LiveAnnouncer, private fb: FormBuilder) {

  }

  getAling(field: string): string {
    const totalItem = this.AlingTotales.find(item => item.field === field);
    return totalItem ? totalItem.aling : '';
  }


  nameFilter = new FormControl('');


  ngOnChanges() {

    this.columnsToView = this.metadataTable.map(
      (el: MetadataTable) => el.field);

    this.loadData();
  }

  ngAfterContentInit() {
    this.loadData();
  }



  loadData(): any {
    if (!this.columnsDef) return false;
    this.dataSource = new MatTableDataSource<any>(this.dataTable);
    this.columnsDef.forEach((columnsDef) => this.table.addColumnDef(columnsDef));
    if (this.columnsDef.length) {
      this.columnsToView.push('actions')
    }

    this.dataSource.sort = this.sort;

    if (this.paginator) {
      this.dataSource.paginator = this.paginator
      this.paginator.firstPage()

    }

  }

  ngOnInit(): void {

  }


  getFileName = (name: string) => {
    let timeSpan = new Date().toISOString();
    let sheetName = name || "ExportResult";
    let fileName = `${sheetName}-${timeSpan}`;
    return {
      sheetName,
      fileName
    };
  };

  exportToExcel(): void {


    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataTable);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'data.xlsx');




  }



  aplicarSaltosDeLinea(texto: string): string {
    const longitudMaxima = 30;
    if (texto.length > longitudMaxima) {
      const palabras = texto.split('');
      let lineaActual = '';
      const lineas = [];

      palabras.forEach(palabra => {
        if ((lineaActual + ' ' + palabra).trim().length <= longitudMaxima) {
          // Agrega la palabra a la línea actual si no excede la longitud máxima
          lineaActual += (lineaActual === '' ? '' : ' ') + palabra;
        } else {
          // Agrega la línea actual al arreglo de líneas y comienza una nueva línea con la palabra actual
          lineas.push(lineaActual);
          lineaActual = palabra;
        }
      });

      // Agrega la última línea al arreglo de líneas
      lineas.push(lineaActual);

      // Une las líneas con saltos de línea
      return lineas.join('');
    } else {
      return texto; // No es necesario aplicar saltos de línea
    }
  }

  filaSeleccionada: any = null;

  cambiarColor(fila: any) {
    // Comprueba si la fila actual ya está seleccionada
    if (this.filaSeleccionada === fila) {
      // Si está seleccionada, deselecciónala
      this.filaSeleccionada = null;

    } else {
      // Si no está seleccionada, selecciona la fila actual
      this.filaSeleccionada = fila;


    }


  }


  announceSortChange(sortState: any) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  cantidadTotal = 0
  montoTotal = 0
  cantidad = false
  monto = false
  i = 1
  j = 0
  getTotalCost(columnName: string): string {
    let totalporciento = ''
    let total = 0
    let condicion = 0
    let porcentaje = 0
    let i = 0
    let j = 0
    // Filtra los datos para obtener solo la columna específica
    // if (this.dataSource!=null || this.dataSource!= undefined){


    const columnData = (this.dataSource.data.map(row => this.parseNumber(row[columnName] == null ? row[columnName] : row[columnName].toString())));

    // Calcula el total sumando todos los valores en la columna
    total = columnData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    //}
    if (columnName == 'entC_NomCompleto' || columnName.toLowerCase() === 'ejecutivo' || columnName.toLowerCase() === 'lineanegocio') {
      if (isNaN(total)) {
        condicion = 1
      }
    }

    if (columnName == 'cantidad' && i == 0) {
      this.cantidadTotal = total
      i = 1
    }
    if (columnName == 'monto' && j == 0) {
      this.montoTotal = total
      j = 1
    }

    if (columnName == 'porcentajeFormateado') {
      if (this.cantidadTotal > 0 && this.montoTotal > 0) {
        let vari = (this.cantidadTotal / this.montoTotal) * 100
        totalporciento = Math.round(vari) + '%';
        porcentaje = 1
      }
    }


    if (condicion == 1) {
      return 'TOTALES'
    } else if (porcentaje == 1) {
      return totalporciento
    } else {
      return total.toLocaleString('es-PE');
    }
  }


  generarEspacios(cantidad) {
    if (cantidad <= 0) {
      return ''; // Devuelve una cadena vacía si la cantidad es menor o igual a cero.
    }

    // Usa el método String.prototype.repeat para repetir el espacio en blanco.
    return ' _'.repeat(cantidad);
  }

  parseNumber(numberString: string): number {
    // Reemplazar las comas por puntos para el separador decimal
    let sanitizedString
    if (numberString != null) {
      const formattedString = numberString.replace(/,/g, '&');

      // Eliminar los puntos como separadores de miles
      sanitizedString = formattedString.replace(/&/g, '');

      // Convertir la cadena a número
    }
    return parseFloat(sanitizedString);
  }

  // click


  columnFilters: { [key: string]: string } = {};

  applyFilter(event: any, column: string) {

    let filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    this.columnFilters[column] = filterValue;

    // Aplicar los filtros acumulativos
    this.dataSource.filterPredicate = (data: any) => {
      for (const key in this.columnFilters) {
        if (this.columnFilters.hasOwnProperty(key)) {
          const value = data[key] ? data[key].toString().toLowerCase() : ' ';
          const filter = this.columnFilters[key];
          if (!value.includes(filter)) {
            return false; // Si algún filtro no coincide, se excluye el elemento
          }
        }
      }
      return true; // Si todos los filtros coinciden, se incluye el elemento
    };

    this.dataSource.filter = 'applied';
  }
}

<ul id="accordion" class="accordion">
  <li *ngFor="let menu of menus; let i = index" [class.active]="menu.active">
    <div class="menu" (click)="toggle(i)">
      <i [class]="menu.title"></i>
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon></mat-icon>

        {{ menu.title }}
      </div>
      <i class="fa fa-chevron-down">

      </i>


    </div>
    <ul class="submenu" #submenu3 [style.height.px]="menu.active ? submenu3?.scrollHeight : 0">

      <li *ngFor="let submenu of menu.children;let i = index" (click)="goto(submenu.link)">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="title">{{submenu.icon}}</mat-icon>
          <a> {{ submenu.title }}</a>
        </div>
        <ul class="" #submenu3 [style.height.px]="menu.active ? submenu3?.scrollHeight : 0">
          <li *ngFor="let submenu2 of submenu.children;let i = index" (click)="goto(submenu2.link)">
            <div fxLayout="row" fxLayoutAlign="start center" class="title">
              <mat-icon>{{submenu2.icon}}</mat-icon>
              <a> {{ submenu2.title }}</a>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>

<!--matTreeNodePadding-->

<!-- <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

  //SELECCIONABLES
  <mat-tree-node *matTreeNodeDef="let node" (click)="goto(node.link)" class="seleccionar">
    <button mat-icon-button disabled></button>
    <mat-icon>{{node.icon}}</mat-icon> {{node.name}}
  </mat-tree-node>

  //ENCABEZADOS
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" class="encabezados">
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" class="icono-desplegable">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    {{node.name}}
  </mat-tree-node>
</mat-tree> -->
import { seguimientoRutaGeoResponse, seguimientoRutaGeotrackResponse } from './../seguimientoRuta/domain/response/seguimientoRutaResponse';
import { ActualizaClienteResponse, ClienteResponse2, ContactoResponse, GuardaRequisisionesResponse, ImoResponse, InsertaClienteResponse, ProformaDetalleResponse, ProformaInternaArchivosDescargaResponse, ProformaInternaArchivosResponse, ProformaInternaBultosResponse, ProformaInternaCostoResponse, ProformaInternaGastoResponse, ProformaInternaResponse, ProformaMantResponse, ResponseContacto, TransportistaResponse, responseAlmacen, response_clienteupdate, response_serviciolocales, responseejecutivo, responsegastoslocales, responseimagen, responseinsertarcotizacion, responselistadonaves, responseproformaupdate, responseservicio, ClientesEfectivosResponse, ExisteUsuarioResponse, CambiaContraResponse, RequisicionesNuevasResponse, CierraCotizacionResponse, clientesservicioslocales, ListaReporteExcel, ReporteExcelResponse, ValidarProfitResponse, responseDepartamento, responseProvincia, responseDistrito, ContenedorTerminarResponse, DistritosZonasResponse, RucInternoPromocionResponse, TipoContenedorResponse, ListaTipoContenedorResponse, validacionTransaccionResponse, ListarTodosWebResponse } from './../proforma/domain/response/proforma_response';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { ProformaRepository } from '../proforma/domain/proforma.repository';
import { ListarTodosWebRequest, Request_Cotizacion, ValidarProfitRequest, actualizaCliente, contactoRequest, contenedorRequest, guardaRequisisionesRequest, imoRequest, incoterRequest, insertaCienteRequest, listaClienteRequest, listaProformaInternaRequest, mantProformaRequest, proformaDetalleRequest, proformaGuardaRequest, proformaRequest, proformaupdate, puertoRequest, requestAlmacen, requestBultos, requestCambiarContra, requestCierraCotizacion, requestClientesEfectivos, requestContenedorTerminar, requestDepartamento, requestDistrito, requestDistritosZonas, requestExisteUsuario, requestGenerico, requestProvincia, requestRequisicionesNuevas, requestTransportista, request_serviciolocal, requestcontacto, requestejecutivo, requestimagen, requestnaves, requestservicios, requestservicioslocales, requestupdatecliente, rucInternoRequest, tarifaPromocionRequest, tarifaRequest } from '../proforma/domain/request/proforma_request';
import { sunatRequest } from 'src/app/proforma/domain/request/proforma_request';
import { ContenedorResponse, GuardaResponse, IncoterResponse, ProformaResponse, PuertoResponse, RucInternoResponse, SunatResponse, TarifaResponse } from '../proforma/domain/response/proforma_response';
import { ContactoComponent } from '../proforma/presentacion/components/contacto/contacto.component';
import { requestrequerimiento } from '../requerimiento/domain/request/request_requerimiento';
import { responserequerimientodet } from '../requerimiento/domain/response/response_requerimiento';
import { AvanceVentasRequest, ProyeccionCuotasRequest, requestejecutivosanualizados } from '../proyeccion-cuotas/domain/request/reporte-request';
import { ListarAnioResponse, ListarMesResponse, ProyeccionCuotasResponse, ProyeccionVisitasResponse } from '../proyeccion-cuotas/domain/response/reporte_response';
import { ListaAvanceVentasResponse, ListaCotizacionesCerradasResponse, ListaReporteEjecutivoAnualizadosResponse, PricingAtendidoResponse } from '../efectividad-ventas/domain/response/efectividad-ventas-response';
import { ListarEfectividadVisitasResponse } from '../efectividad-visitas/domain/response/reporte-efectividadVisitas-response';
import { ProyeccionVisitasRequest } from '../efectividad-visitas/domain/request/reporte-efectividad-visitas-request';
import { requestPricingAtendido } from '../dashboardVentas/domain/request/dashboard.request';
import { requestCotiCerrada } from '../reporte-cotizaciones-cerradas_v2/domain/request/request-coti-cerrada';
import { RCotiCerradaResponse } from '../reporte-cotizaciones-cerradas_v2/domain/response/response-coti-cerrada';
import { seguimientoRutaGeotrackRequest, seguimientoRutaRequest } from '../seguimientoRuta/domain/request/seguimientoRutaRequest';
import { seguimientoRutaResponse } from '../seguimientoRuta/domain/response/seguimientoRutaResponse';
import { EjemploResponse } from '../ejemplo/domain/response/ejemploresponse';
import { EjemploRequest } from '../ejemplo/domain/request/ejemplorequest';
import { responseOCR, responseOCRHojas, responseOCR_HAPAG, responseOCR_descripcion_hapag } from '../ocr_pdf_2/domain/response/ocr_pdf.response';
import { requestGuardaBL, requestListaTipoContenedor, requestOCR, requestOCR_HAPAG, requestOCR_Hojas, requestOCR_Hojas_HAPAG, requestTipoContenedor, requestTransaccionValidacion } from '../ocr_pdf_2/domain/request/ocr_pdf.request';
import { response_guarda_servicio, response_servicios, response_servicios_conceptos } from '../mantenimiento_servicios/domain/response/response_servicios.response';
import { registra_servicios_request } from '../mantenimiento_servicios/domain/request/agrega_servicios.request';
import { ListaPricingResumenResponse } from '../dashboardVentas/domain/response/dashboard.response';
// import { seguimientoRutaRequest } from '../seguimientoRuta/domain/request/seguimientoRutaRequest';
// import { seguimientoRutaResponse } from '../seguimientoRuta/domain/response/seguimientoRutaResponse';

@Injectable()
export class ProformaService extends ProformaRepository {

  constructor(private readonly http: HttpClient,
    private readonly storage: StorageService

  ) {

    super();
  }

  listar(prolrequest: proformaRequest): Observable<ProformaResponse> {
    return this.http.post<ProformaResponse>(`${environment.PATH_API}/parametros/ListaParametros`, prolrequest);
  }

  listarSunat(prolrequest: sunatRequest): Observable<SunatResponse> {
    return this.http.post<SunatResponse>(`${environment.PATH_API}/integracion/ConsultaSunat`, prolrequest);
  }
  listarSunat2(prolrequest: sunatRequest): Observable<SunatResponse> {
    return this.http.post<SunatResponse>(`${environment.PATH_API}/integracion/ConsultaSunat2`, prolrequest);
  }


  listarRucInterno(prolrequest: rucInternoRequest): Observable<RucInternoResponse> {
    return this.http.post<RucInternoResponse>(`${environment.PATH_API}/cliente/ConsultaCliente`, prolrequest);
  }

  listarRucInternoPromocion(prolrequest: rucInternoRequest): Observable<RucInternoPromocionResponse> {
    return this.http.post<RucInternoPromocionResponse>(`${environment.PATH_API}/cliente/ConsultaClientePromocion`, prolrequest);
  }

  listarPuerto(prolrequest: puertoRequest): Observable<PuertoResponse> {
    return this.http.post<PuertoResponse>(`${environment.PATH_API}/puerto/consultapuerto`, prolrequest);
  }

  listarIncoter(prolrequest: incoterRequest): Observable<IncoterResponse> {
    return this.http.post<IncoterResponse>(`${environment.PATH_API}/tipo/ConsultaTipo`, prolrequest);
  }

  listarContenedor(prolrequest: contenedorRequest): Observable<ContenedorResponse> {
    return this.http.post<ContenedorResponse>(`${environment.PATH_API}/contenedor/consultacontenedor`, prolrequest);
  }

  listarTarifa(prolrequest: tarifaRequest): Observable<TarifaResponse> {
    return this.http.post<TarifaResponse>(`${environment.PATH_API}/contenedor/consultarTarifa`, prolrequest);
  }
  //TARIFA PROMOCION
  listarTarifaPromocion(prolrequest: tarifaPromocionRequest): Observable<TarifaResponse> {
    return this.http.post<TarifaResponse>(`${environment.PATH_API}/contenedor/consultarTarifaPromocion`, prolrequest);
  }

  guardarProforma(prolrequest: proformaGuardaRequest): Observable<GuardaResponse> {
    return this.http.post<GuardaResponse>(`${environment.PATH_API}/Proformaexterna/MantProformaExterna`, prolrequest);
  }
  mantProforma(prolrequest: mantProformaRequest): Observable<ProformaMantResponse> {
    return this.http.post<ProformaMantResponse>(`${environment.PATH_API}/Proformaexterna/ConsultaProformaCab`, prolrequest);
  }
  detalleProforma(prolrequest: proformaDetalleRequest): Observable<ProformaDetalleResponse> {
    return this.http.post<ProformaDetalleResponse>(`${environment.PATH_API}/Proformaexterna/ConsultaProformaDet`, prolrequest)
  }

  //PROMOOCION
  detalleProformaPromocion(prolrequest: proformaDetalleRequest): Observable<ProformaDetalleResponse> {
    return this.http.post<ProformaDetalleResponse>(`${environment.PATH_API}/Proformaexterna/ConsultaProformaDetPromocion`, prolrequest)
  }


  listaContacto(prolrequest: contactoRequest): Observable<ContactoResponse> {
    return this.http.post<ContactoResponse>(`${environment.PATH_API}/Proformaexterna/ConsultaContactoRelacionado`, prolrequest)
  }
  listaImo(prolrequest: imoRequest): Observable<ImoResponse> {
    return this.http.post<ImoResponse>(`${environment.PATH_API}/tipo/ConsultaTipo`, prolrequest)
  }
  guardaRequisisiones(prolrequest: guardaRequisisionesRequest): Observable<GuardaRequisisionesResponse> {
    return this.http.post<GuardaRequisisionesResponse>(`${environment.PATH_API}/proformainterna/MantProformaInterna`, prolrequest)
  }
  listaProformaInterna(prolrequest: listaProformaInternaRequest): Observable<ProformaInternaResponse> {
    return this.http.post<ProformaInternaResponse>(`${environment.PATH_API}/proformainterna/ConsultaProformaInternaCab`, prolrequest)
  }
  listaCliente(prolrequest: listaClienteRequest): Observable<ClienteResponse2> {
    return this.http.post<ClienteResponse2>(`${environment.PATH_API}/cliente/ListarCliente`, prolrequest)
  }
  insertaCliente(prolrequest: insertaCienteRequest): Observable<InsertaClienteResponse> {
    return this.http.post<InsertaClienteResponse>(`${environment.PATH_API}/cliente/InsertarCliente`, prolrequest)
  }


  insertarcotizacion(prolrequest: Request_Cotizacion): Observable<responseinsertarcotizacion> {
    return this.http.post<responseinsertarcotizacion>(`${environment.PATH_API}/integracion/insertarcotizacion`, prolrequest)
  }

  updateproforma(prolrequest: proformaupdate): Observable<responseproformaupdate> {
    return this.http.post<responseproformaupdate>(`${environment.PATH_API}/Proformaexterna/updateproformageneracion`, prolrequest)
  }

  listarequerimientodetalle(prolrequest: requestrequerimiento): Observable<responserequerimientodet> {
    return this.http.post<responserequerimientodet>(`${environment.PATH_API}/proformainterna/ConsultaProformaInternaDet`, prolrequest)
  }
  actualizaCliente(prolrequest: actualizaCliente): Observable<ActualizaClienteResponse> {
    return this.http.post<ActualizaClienteResponse>(`${environment.PATH_API}/proformainterna/ConsultaProformaInternaDet`, prolrequest)
  }


  mancotacto(prolrequest: requestcontacto): Observable<ResponseContacto> {
    return this.http.post<ResponseContacto>(`${environment.PATH_API}/cliente/mantcontacto`, prolrequest)
  }

  listarcontacto(prolrequest: requestcontacto): Observable<ResponseContacto> {
    return this.http.post<ResponseContacto>(`${environment.PATH_API}/cliente/listarcontacto`, prolrequest)
  }


  listarserviciolocales(prolrequest: request_serviciolocal): Observable<response_serviciolocales> {
    return this.http.post<response_serviciolocales>(`${environment.PATH_API}/Proformaexterna/ConsultarServicioLocales`, prolrequest)
  }

  listarserviciolocalesPromocion(prolrequest: request_serviciolocal): Observable<response_serviciolocales> {
    return this.http.post<response_serviciolocales>(`${environment.PATH_API}/Proformaexterna/ConsultarServicioLocalesPromocion`, prolrequest)
  }

  listadoproformadetservlocales(prolrequest: proformaDetalleRequest): Observable<response_serviciolocales> {
    return this.http.post<response_serviciolocales>(`${environment.PATH_API}/Proformaexterna/ConsultaProformaDetServicioLocales`, prolrequest)
  }




  listaTransportista(prolrequest: requestTransportista): Observable<TransportistaResponse> {
    return this.http.post<TransportistaResponse>(`${environment.PATH_API}/Proformaexterna/consultatransportista`, prolrequest)
  }
  listaAlmacen(prolrequest: requestAlmacen): Observable<responseAlmacen> {
    return this.http.post<responseAlmacen>(`${environment.PATH_API}/cliente/ConsultaCliente`, prolrequest)
  }
  listaProformaInternaCosto(prolrequest: requestGenerico): Observable<ProformaInternaCostoResponse> {
    return this.http.post<ProformaInternaCostoResponse>(`${environment.PATH_API}/proformainterna/ConsultaProformaInternaCostoServicio`, prolrequest)
  }
  listaProformaInternaGasto(prolrequest: requestGenerico): Observable<ProformaInternaGastoResponse> {
    return this.http.post<ProformaInternaGastoResponse>(`${environment.PATH_API}/proformainterna/ConsultaProformaInternaGastosAdicionales`, prolrequest)
  }
  listaProformaInternaBultos(prolrequest: requestBultos): Observable<ProformaInternaBultosResponse> {
    return this.http.post<ProformaInternaBultosResponse>(`${environment.PATH_API}/proformainterna/ConsultaProformaInternaBultos`, prolrequest)
  }



  updatecliente(prolrequest: requestupdatecliente): Observable<response_clienteupdate> {
    return this.http.post<response_clienteupdate>(`${environment.PATH_API}/cliente/updatecliente`, prolrequest)
  }



  listarnaves(prolrequest: requestnaves): Observable<responselistadonaves> {
    return this.http.post<responselistadonaves>(`${environment.PATH_API}/Proformaexterna/consultanaves`, prolrequest)
  }

  listaProyeccionCuotas(prolrequest: ProyeccionCuotasRequest): Observable<ProyeccionCuotasResponse> {
    return this.http.post<ProyeccionCuotasResponse>(`${environment.PATH_API}/reportes/ProyeccionCuotas`, prolrequest)
  }

  listaProyeccionVisitas(prolrequest: ProyeccionCuotasRequest): Observable<ProyeccionVisitasResponse> {
    return this.http.post<ProyeccionVisitasResponse>(`${environment.PATH_API}/reportes/ProyeccionVisitas`, prolrequest)
  }

  ListarAnio(): Observable<ListarAnioResponse> {
    return this.http.post<ListarAnioResponse>(`${environment.PATH_API}/reportes/ListadoAnios`, '')
  }

  ListarMes(): Observable<ListarMesResponse> {
    return this.http.post<ListarMesResponse>(`${environment.PATH_API}/reportes/ListadoMeses`, '')
  }
  listaCotizacionesCerradas(prolrequest: ProyeccionCuotasRequest): Observable<ListaCotizacionesCerradasResponse> {
    return this.http.post<ListaCotizacionesCerradasResponse>(`${environment.PATH_API}/reportes/reportecotizacionescerradas`, prolrequest)
  }
  listaAvanceVentas(prolrequest: AvanceVentasRequest): Observable<ListaAvanceVentasResponse> {
    return this.http.post<ListaAvanceVentasResponse>(`${environment.PATH_API}/reportes/reportecantidadovcerradasvscuota`, prolrequest)
  }

  listaEfectividadVisitas(prolrequest: ProyeccionVisitasRequest): Observable<ListarEfectividadVisitasResponse> {
    return this.http.post<ListarEfectividadVisitasResponse>(`${environment.PATH_API}/reportes/reporteefectividadvisitas`, prolrequest)
  }

  listaClientesEfectivos(prolrequest: requestClientesEfectivos): Observable<ClientesEfectivosResponse> {
    return this.http.post<ClientesEfectivosResponse>(`${environment.PATH_API}/reportes/reporteclienteefectivo`, prolrequest)
  }



  subirarchivo(prolrequest: FormData): Observable<responseimagen> {
    return this.http.post<responseimagen>(`${environment.PATH_API}/proformainterna/cargarimagenes`, prolrequest)
  }

  listaProformaInternaArchivos(prolrequest: requestBultos): Observable<ProformaInternaArchivosResponse> {
    return this.http.post<ProformaInternaArchivosResponse>(`${environment.PATH_API}/proformainterna/ConsultaProformaInternaArchivo`, prolrequest)
  }

  listaProformaInternaArchivosDescarga(prolrequest: requestBultos): Observable<ProformaInternaArchivosDescargaResponse> {
    return this.http.post<ProformaInternaArchivosDescargaResponse>(`${environment.PATH_API}/proformainterna/bajararchivos`, prolrequest)
  }


  listarejecutivo(prolrequest: requestejecutivo): Observable<responseejecutivo> {
    return this.http.post<responseejecutivo>(`${environment.PATH_API}/cliente/litarejecutivo`, prolrequest);
  }

  listarservicio(prolrequest: requestservicios): Observable<responseservicio> {
    return this.http.post<responseservicio>(`${environment.PATH_API}/Proformaexterna/ListarGastosLocales`, prolrequest);
  }
  //ESTAS LLAMANDO AL API PARA MOSTRAR EN LA GRILLA DE TRANSPORTE
  listarservicioZonaPromocion(prolrequest: requestservicios): Observable<responseservicio> {
    return this.http.post<responseservicio>(`${environment.PATH_API}/Proformaexterna/ConsultaTransportePromocion`, prolrequest);
  }


  listadoproformadetgastoslocales(prolrequest: proformaDetalleRequest): Observable<responsegastoslocales> {
    return this.http.post<responsegastoslocales>(`${environment.PATH_API}/Proformaexterna/ConsultaProformaGastosLocales`, prolrequest)
  }

  listaUsuarioExiste(prolrequest: requestExisteUsuario): Observable<ExisteUsuarioResponse> {
    return this.http.post<ExisteUsuarioResponse>(`${environment.PATH_API}/contrasena/verificaUsuario`, prolrequest)
  }

  cambiaContrasena(prolrequest: requestCambiarContra): Observable<CambiaContraResponse> {
    return this.http.post<CambiaContraResponse>(`${environment.PATH_API}/contrasena/cambiaContrasena`, prolrequest)
  }

  listaPricingAtendido(prolrequest: requestPricingAtendido): Observable<PricingAtendidoResponse> {
    return this.http.post<PricingAtendidoResponse>(`${environment.PATH_API}/dashboard/pricingCotizacionesAtendidas`, prolrequest)
  }
  // cambiaContrasena(prolrequest:requestExisteUsuario):Observable<ExisteUsuarioResponse>
  // {
  //   return this.http.post<ExisteUsuarioResponse>(`${environment.PATH_API}/contrasena/verificaUsuario`,prolrequest)
  // }   

  //LLAMADO A LA API
  requisicionesNuevas(prolrequest: requestRequisicionesNuevas): Observable<RequisicionesNuevasResponse> {
    return this.http.post<RequisicionesNuevasResponse>(`${environment.PATH_API}/proformainterna/ConsultaRequisicionNuevas`, prolrequest)
  }

  cierraCotizaciones(prolrequest: requestCierraCotizacion): Observable<CierraCotizacionResponse> {
    return this.http.post<CierraCotizacionResponse>(`${environment.PATH_API}/proformainterna/cierraCotizacion`, prolrequest)
  }


  clientesservicioslocales(prolrequest: requestservicioslocales): Observable<clientesservicioslocales> {
    return this.http.post<clientesservicioslocales>(`${environment.PATH_API}/reportes/reporteclientesservicioslocales`, prolrequest)
  }

  reporteCotizacionCerrada(prolrequest: requestCotiCerrada): Observable<RCotiCerradaResponse> {
    return this.http.post<RCotiCerradaResponse>(`${environment.PATH_API}/proformainterna/cierraCotizacionr`, prolrequest)
  }

  seguimientoRuta(prolrequest: seguimientoRutaRequest): Observable<seguimientoRutaResponse> {
    return this.http.post<seguimientoRutaResponse>(`${environment.PATH_API}/cliente/listaSeguimientoRuta`, prolrequest)
  }

  seguimientoRutaGeo(): Observable<seguimientoRutaGeoResponse> {
    return this.http.get<seguimientoRutaGeoResponse>(`${environment.PATH_API}/integracion/ObtenerInformacion`)
  }


  seguimientoRutaGeotrack(prolrequest: seguimientoRutaGeotrackRequest): Observable<seguimientoRutaGeotrackResponse> {
    return this.http.post<seguimientoRutaGeotrackResponse>(`${environment.PATH_API}/cliente/listaSeguimientoRutaGeotrack`, prolrequest)
  }



  listaAvanceVentas_otros(prolrequest: AvanceVentasRequest): Observable<ListaAvanceVentasResponse> {
    return this.http.post<ListaAvanceVentasResponse>(`${environment.PATH_API}/reportes/reporteregistroembarques`, prolrequest)
  }


  listaEjecutivosAnualizados(prolrequest: requestejecutivosanualizados): Observable<ListaReporteEjecutivoAnualizadosResponse> {
    return this.http.post<ListaReporteEjecutivoAnualizadosResponse>(`${environment.PATH_API}/reportes/reporterejecutivosanulaizados`, prolrequest)
  }

  listaReporteExcel(prolrequest: AvanceVentasRequest): Observable<ReporteExcelResponse> {
    return this.http.post<ReporteExcelResponse>(`${environment.PATH_API}/reporteG/avanceMensualExcel`, prolrequest)
  }

  ValidarCreacionForecast(prolrequest: ValidarProfitRequest): Observable<ValidarProfitResponse> {
    return this.http.post<ValidarProfitResponse>(`${environment.PATH_API}/proformainterna/ValidarCreacionForecast`, prolrequest)
  }

  listarDatos(prolrequest: EjemploRequest): Observable<EjemploResponse> {
    return this.http.post<EjemploResponse>(`${environment.PATH_API}/Proformaexterna/ConsultarDatos`, prolrequest)
  }
  listardepartamentos(prolrequest: requestDepartamento): Observable<responseDepartamento> {
    return this.http.post<responseDepartamento>(`${environment.PATH_API}/tipo/ListarDepartamentos`, prolrequest)
  }
  listarprovincias(prolrequest: requestProvincia): Observable<responseProvincia> {
    return this.http.post<responseProvincia>(`${environment.PATH_API}/tipo/ListarProvincias`, prolrequest)
  }
  override listardistritos(prolrequest: requestDistrito): Observable<responseDistrito> {
    return this.http.post<responseDistrito>(`${environment.PATH_API}/tipo/ListarDistritos`, prolrequest)
  }


  //eso es de operaciones--------------------------------------
  procesaOCR(prolrequest: requestOCR): Observable<responseOCR> {
    return this.http.post<responseOCR>(`${environment.PATH_API}/documentacion/procesaArchivo`, prolrequest)
  }

  procesaOCR_Hojas(prolrequest: requestOCR_Hojas): Observable<responseOCRHojas> {
    return this.http.post<responseOCRHojas>(`${environment.PATH_API}/documentacion/descripcionHojas`, prolrequest)
  }

  procesaOCR_HAPAG(prolrequest: requestOCR_HAPAG): Observable<responseOCR_HAPAG> {
    return this.http.post<responseOCR_HAPAG>(`${environment.PATH_API}/documentacion/ProcesaArchivoHAPAG`, prolrequest)
  }

  listaContenedorTerminar(prolrequest: requestContenedorTerminar): Observable<ContenedorTerminarResponse> {
    return this.http.post<ContenedorTerminarResponse>(`${environment.PATH_API}/reporteG/reporteContenedorTerminar`, prolrequest)
  }

  listaDistritosZonas(prolrequest: requestDistritosZonas): Observable<DistritosZonasResponse> {
    return this.http.post<DistritosZonasResponse>(`${environment.PATH_API}/Proformaexterna/ConsultaDistritosZonas`, prolrequest)
  }



  //LISTAR TARIFA SERVICIOS LOCALES PROMOCION
  listarTarifaServiciosLocalesPromocion(prolrequest: requestDistritosZonas): Observable<DistritosZonasResponse> {
    return this.http.post<DistritosZonasResponse>(`${environment.PATH_API}/Proformaexterna/ConsultaDistritosZonas`, prolrequest)
  }
  guardaTipoContenedorPDF(prolrequest: requestTipoContenedor): Observable<TipoContenedorResponse> {
    return this.http.post<TipoContenedorResponse>(`${environment.PATH_API}/documentacion/guardaTipoContenedor`, prolrequest)
  }

  listarTipoContenedor(prolrequest: requestListaTipoContenedor): Observable<ListaTipoContenedorResponse> {
    return this.http.post<ListaTipoContenedorResponse>(`${environment.PATH_API}/documentacion/listaTipoContenedores`, prolrequest)
  }

  guardaTransaccionValidacionPDF(prolrequest: requestTransaccionValidacion): Observable<validacionTransaccionResponse> {
    return this.http.post<validacionTransaccionResponse>(`${environment.PATH_API}/documentacion/transaccionvalidacionpdf`, prolrequest)
  }
  guarda_BL_PDF(prolrequest: requestGuardaBL): Observable<validacionTransaccionResponse> {
    return this.http.post<validacionTransaccionResponse>(`${environment.PATH_API}/documentacion/guardaBL`, prolrequest)
  }

  procesaOCR_Descripcion_HAPAG(prolrequest: requestOCR_Hojas_HAPAG): Observable<responseOCR_descripcion_hapag> {
    return this.http.post<responseOCR_descripcion_hapag>(`${environment.PATH_API}/documentacion/descripcionHojas_Hapag`, prolrequest)
  }

  listaServiciosOperaciones(): Observable<response_servicios> {
    return this.http.post<response_servicios>(`${environment.PATH_API}/operaciones/listaServicios`, '')
  }

  guardaServicioOperaciones(prolrequest: registra_servicios_request): Observable<response_guarda_servicio> {
    return this.http.post<response_guarda_servicio>(`${environment.PATH_API}/operaciones/guardaServicios`, prolrequest)
  }

  listaServiciosConceptos(): Observable<response_servicios_conceptos> {
    return this.http.post<response_servicios_conceptos>(`${environment.PATH_API}/operaciones/listaConceptosServicios`, '')
  }
  
  ListarTodosWeb(prolrequest: ListarTodosWebRequest): Observable<ListarTodosWebResponse> {
    return this.http.post<ListarTodosWebResponse>(`${environment.PATH_API}/parametros/listartodosweb`, prolrequest)
  }

}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as pdfobject from 'pdfobject';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-vizorEmergente',
  templateUrl: './vizorEmergente.component.html',
  styleUrls: ['./vizorEmergente.component.css']
})
export class VizorEmergenteComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer,private route: ActivatedRoute,private readonly storage: StorageService) {}
  base64: string;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.base64 = this.storage.get("dato")//params['data']; // Asigna directamente el valor
      console.log(this.base64);
    });
  }

  getSafeUrl(base64: string): SafeResourceUrl {
    try {
      if (!base64) {
        throw new Error('Base64 content is empty.');
      }
      const url = base64;
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } catch (error) {
      console.error('Error creating safe URL:', error);
      return null;
    }
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const base64String = event.target.result as string;
        resolve(base64String);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsDataURL(file);
    });
  }
  
  // ngAfterViewInit(): void {
  //   const pdfUrl = 'https://www.turnerlibros.com/wp-content/uploads/2021/02/ejemplo.pdf;'
  //   const containerId = 'pdf-container'; // ID del elemento HTML donde se mostrará el PDF

  //   pdfobject.embed(pdfUrl, `#${containerId}`);
  // }

}

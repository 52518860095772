import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { requestcontacto } from 'src/app/proforma/domain/request/proforma_request';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent {
  group: FormGroup;
CodCliente:number 

  constructor(private readonly  reference: MatDialogRef<ContactoComponent>, private readonly proformaService : ProformaRepository,private util: UtilService,@Inject(MAT_DIALOG_DATA) private  data:any,){}
  inicializaFormulario(){
    this.group = new FormGroup({
    nombre : new FormControl (null,[Validators.required]),
    apellido : new   FormControl(null,[Validators.required]),
    documento : new   FormControl(null,null),
    correo : new   FormControl(null,null),
    telefono1 : new   FormControl(null,[Validators.required]),
    telefono2 : new   FormControl(null,null),
   });
   }


   ngOnInit(): void {
    this.inicializaFormulario();
  }

  closeModal(){
    this.reference.close();
  }


  requestcontacto : requestcontacto

  guardar(){

this.requestcontacto = <requestcontacto>{}

    const  values = this.group.value



    this.requestcontacto.ENTC_CodCliente = this.data
    this.requestcontacto.ENTC_CodContacto = 0
    this.requestcontacto.Nombre = values['nombre']
    this.requestcontacto.Apellidos= values['apellido']
    this.requestcontacto.NroDocumento= values['documento']
    this.requestcontacto.correos= values['correo']
    this.requestcontacto.telefono1= values['telefono1']
    this.requestcontacto.telefono2= values['telefono2']
    this.requestcontacto.TIPO_TABEST= "EST"
    this.requestcontacto.TIPO_CODEST= "001"
    this.requestcontacto.AUDI_UsrCrea="administrador"
    this.requestcontacto.AUDI_FecCrea= this.util.formatearfecha(new Date(), "yyyy-MM-ddTHH:mm:ss")
    this.requestcontacto.AUDI_UsrMod= ""
    this.requestcontacto.AUDI_FecMod	= ""
    this.requestcontacto.tipo = "I"



    
    this.proformaService.mancotacto(this.requestcontacto).subscribe
    ((response) =>{
 
 
 
        if ( response.datos.status===200){
             
               //  this.listContacto = response.datos.result;

               this.util.showMessage("guardado correctamente")
               this.reference.close();
 
          }
 
        }, (errorServicio)=>{

          this.util.showMessage( errorServicio.error.meta.mensaje);
 
 
  });




    






  }
}

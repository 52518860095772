import { Component, Input, SimpleChanges } from '@angular/core';
import { DataRow, readonlyMetadataTable } from './domain/readonly-data-table.domain';
import { UtilService } from 'src/app/services/util.service';
import { es } from 'date-fns/locale';
import { text } from 'body-parser';
import { ConfirmationService } from 'primeng/api';
import { style } from './domain/readonly-data-table.domain';
@Component({
  selector: 'app-readonly-data-table',
  templateUrl: './readonly-data-table.component.html',
  styleUrls: ['./readonly-data-table.component.css']
})
export class ReadonlyDataTableComponent {
  bResult: any;
  isHovered = false;//Es para la simulaciond de hover de un checkbox

  constructor(public readonly util: UtilService, private confirmationService: ConfirmationService,) { }

  @Input() dataTable: any[];
  @Input() title: string = ''
  @Input() metadataTable: readonlyMetadataTable[] = [];
  @Input() key: string = ''
  @Input() rows: any[] = [20, 30, 50]
  @Input() filter: boolean = true;
  data: any[] = []
  datatreeview: any[] = []
  metadatatable: readonlyMetadataTable[] = []
  paginator: boolean = true
  metadatechildren: any[] = []
  metadatepather: any[] = []
  valorResize = false
  @Input() style: style;
  // selectedDates: { [key: string]: Date } = {};

  Valor:string
  styleHeader: { [key: string]: string } = {}
  styleBody: { [key: string]: string } = {}
  ngOnInit() {
    this.Valor="7";
    // const textarea = document.querySelector('textarea');
    // textarea.style.height = null; // Reset the height style
    //   textarea.rows = 1;
    if (this.style) {
      if (this.style.header) {
        const r = this.style.header
        this.styleHeader = {};
        if (r.align) { this.styleHeader['text-align'] = r.align }
        if (r.size) { this.styleHeader['font-size'] = r.size }
        if (r.justify) { this.styleHeader['justify-content'] = r.justify }
      }
      if (this.style.body) {
        const r = this.style.body
        this.styleBody = {};
        if (r.align) { this.styleBody['text-align'] = r.align }
        if (r.size) { this.styleBody['font-size'] = r.size }
        if (r.justify) { this.styleBody['justify-content'] = r.justify }
      }
    }

  }
  prueba(evento){
    console.log(evento)
}
  ngOnChanges(changes: SimpleChanges): void {

    // this.footer = false
    this.metadatatable = this.metadataTable

    const metadatepather = this.metadatatable.filter(r => r.type != 'array')
    this.metadatechildren = this.metadatatable.filter(r => r.type == 'array')
    console.log(this.metadatechildren)
    this.data = this.dataTable
    // metadatechildren.forEach(r => {

    //   datatreeview
    // })


    if (this.data.length > 20 && this.paginator == true) { this.paginator = true }

  }

  // Actualizar($event){
  //   console.log($event.checked)


  //   if (this.ActualizarFecha($event.checked)){

  //   }

  // }

  // ActualizarFecha(estado:boolean){
  //   bResult: true
  //   if (estado) {
  //     //Entra al store y devuelve true
  //     console.log("Va a actualizar")
  //   } else {
  //     //Entra al store pero con valor de fecha null sea devuelve false
  //     console.log("NO Va a actualizar")

  //   }
  //   return this.bResult
  // }

  ver(dato) {
    console.log("asd", dato)
  }
  selectedDate: Date;
  handleDateSelect(event: any) {
    this.selectedDate = event;
  }
  selectDropdown:string
  handleDropdownSelect(event:any){
    this.selectDropdown=event
    console.log(event)
  }
//   handleDateSelect(event: any, key: string) {
//     this.selectedDates[key] = event;
// }
// handleDateSelect(event, rowdata) {
//   rowdata.selectedDate = event; // Enable the button only for the current row
// }
  visible: boolean;
  toggleState: boolean = true;

  // toggleState: boolean = true;

  // showDialog() {
  //   const textarea = document.querySelector('textarea');
  //   if (this.toggleState) {
  //     // Funcion 1
  //     textarea.style.height = 'auto';
  //     textarea.style.height = textarea.scrollHeight + 'px';
  //     this.valorResize = true
  //   } else {
  //     // Funcion 2
  //     textarea.style.height = null; // Reset the height style
  //     textarea.rows = 1;
  //     this.valorResize = false

  //   }
  //   this.toggleState = !this.toggleState; // toggle the state
  // }
  visibleP_Dialog_General:boolean;
  visibleDialog: boolean;
  visibleTextArea:boolean;
  visibleTextAreaEdit:boolean;
  datoTextArea:string
  tituloP1_Dialog:string

  funcionx: any;
selectedRow: any; // Variable para guardar el row seleccionado
  showDialog(row: any, title: string, field: string, modal:string, funcion: Function, dato:string) {
    this.visibleP_Dialog_General=true
    this.visibleDialog=true
    this.visibleTextAreaEdit = true;
    this.visibleTextArea=true;
    this.tituloP1_Dialog=title
    if (modal =="textArea"){
      console.log(row)
      this.visibleDialog = false
      this.visibleTextArea=true
      this.visibleTextAreaEdit=false
      this.datoTextArea=dato

      // this.datoTextArea = row.doov_mbl;
        this.funcionx = funcion; // Guardar la función
        this.selectedRow = row; // Guardar el row seleccionado
      // this.mensajeDialog = { datoTextArea: row.doov_mbl}
    }else if(modal =="textAreaEdit"){
      console.log(row)
      this.visibleDialog = false
      this.visibleTextArea=false
      this.visibleTextAreaEdit=true
      this.datoTextArea=row.nvia_usercomments

      // this.datoTextArea = row.doov_mbl;
        this.funcionx = funcion; // Guardar la función
        this.selectedRow = row; // Guardar el row seleccionado
      // this.mensajeDialog = { datoTextArea: row.doov_mbl}
    }

  }

  onConfirm() {
    if (this.funcionx && this.selectedRow) {
        this.funcionx({ event: {}, row: this.selectedRow, dato: this.datoTextArea });
    }
}

  // showDialog() {
  //   const textarea = document.querySelector('textarea');
  //     textarea.rows = 10;
  // }



  // showDialog() {
  //   // this.visible = true;
  //   const textarea = document.querySelector('textarea');
  // this.showAllRows = !this.showAllRows;
  // textarea.rows = this.showAllRows ? null : 1;
  // }

  isDisabled() {
    this.selectedDate = null;
  }
  isDisabledDropdwon(){
    this.selectDropdown=null;
  }
//   isDisabled(key: string) {
//     this.selectedDates[key] = null;
// }


  // PanelConfirmacion(funcion: (dato: any) => void) {//mensaje:string,
  //   this.confirmationService.confirm({
  //     target: event.target,
  //     message: "ABCD",
  //     icon: 'pi pi-exclamation-triangle',

  //     accept: () => {        
  //       funcion(dato);
  //       console.log("Se hace")
  //     },
  //     reject: () => {
  //     }
  //   });
  // }

  // des(): string {
  //   alert('zzz')
  //   return ''
  // }

  generateStyle(data: any): { [key: string]: string } {
    let value = {}
    if (data) {
      if (data.align) { value['text-align'] = data.align }
      if (data.size) { value['font-size'] = data.size }
      if (data.justify) { value['justify-content'] = data.justify }
    }
    return value
  }

  viewData() {
    console.log('DATATABLE', this.dataTable)
    // console.log('PRECARGA', this.precarga)
    console.log('KEY', this.key)
    // console.log('COLS', this.cols)
    // console.log('FILTRO GLOBAL', this.filtrosGlobal)
    // console.log('METADATATABLE', this.metadataTable)
    // console.log('CAPTION', this.caption)
    // console.log('TABLE PRIME', this.table)
    // if (this.group) {
    //   console.log('GROUP', this.group);
    //   console.log('GROUP VALUE', this.group.value);
    // }

  }

  isPopupVisible: boolean = false;

// Método para mostrar el popup
showPopup() {
    this.isPopupVisible = true;
}

// Método para ocultar el popup
hidePopup() {
    this.isPopupVisible = false;
}

bool(value: any): boolean {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return !!value; // Convierte a booleano para otros casos (como null, undefined, 0, etc.)
}

handleClick(){
  console.log("ohala")
}

getButtonStyle(isActive: boolean): any {
  if (isActive) {
    return {}; // Estilo por defecto cuando es true
  } else {
    return {
      'background-color': 'white',
      'border': '2px solid #ced4da',
      'transition': 'background-color .2s,color .2s,border-color .2s,box-shadow .2s'
    };
  }
}
}

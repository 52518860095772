import { Injectable } from '@angular/core';
import { CryptoRepository } from '../interfaces/crypto-repository';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class CryptoService extends CryptoRepository {

    constructor() {
        super();
    }

    encrypt(data: string, key: string): string {
        return CryptoJS.AES.encrypt(data, key).toString();
    }

    decrypt(encryptedData: string, key: string): string {
        const bytes = CryptoJS.AES.decrypt(encryptedData, key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}
<div class="dialog-content">
    <!-- <h3 *ngIf="header">{{ header }}</h3> -->
    <div *ngIf="rowData" style="max-height: 90%;">
        <ng-container *ngIf="isEditing; else viewMode">
            <div style="margin-top: 10px;"></div>
            <form>
                <div class="card flex ">
                    <textarea rows="10" pInputTextarea [(ngModel)]="rowData"
                        style="resize: none; width: 100%;"></textarea>
                </div>
                <div style="display: flex; justify-content: center; margin-top: 10px;">
                    <p-button icon="pi pi-check" (click)="save(rowData)"
                        style="margin-right: 10px; padding: 0.5em 1em; font-size: 1em; min-width: 120px;">
                        Guardar
                    </p-button>
                    <p-button icon="pi pi-times" (click)="close()"
                        style="padding: 0.5em 1em; font-size: 1em; min-width: 120px;">
                        Cerrar
                    </p-button>
                </div>
            </form>
        </ng-container>

        <ng-template #viewMode>
            <div style="margin-top: 10px;"></div>
            <pre style="max-height: 90%">{{ rowData }}</pre>
            <p-button style="display: flex; justify-content: center; align-items: center;" (click)="close()">
                Cerrar
            </p-button>
        </ng-template>
    </div>
</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutAlign="center" style="margin-top: 0px;" class="contenedor">
    <div fxFlex="100" style="padding: 0px;" class="general">

                <form [formGroup]="group">
                    <div  fxLayoutGap="20px" class="label">

                        <mat-form-field class="example-full-width-alterno3" appearance="outline">
                            <mat-label>Cliente</mat-label>
                            <input
                            type="text"
                            matInput
                            formControlName="cliente"
                            [matAutocomplete]="autoTransportista"

                            [value]="cliente"
                          >
                          <mat-autocomplete #autoTransportista="matAutocomplete" (optionSelected)="opcionSeleccionada($event)">
                            <ng-container *ngFor="let option of listaTodosclientes">
                              <mat-option [value]="option.cliente" *ngIf="option.cliente?.toLowerCase().includes(textoFiltro?.toLowerCase())">
                                {{ option.cliente }}
                              </mat-option>
                            </ng-container>
                          </mat-autocomplete>
                          </mat-form-field>



                        <mat-form-field class="example-full-width-alterno3" appearance="outline">
                            <mat-label>Fecha Visita</mat-label>
                            <input matInput  formControlName = "fechavisita" [matDatepicker]="picker1" autocomplete="off" (dateChange)="onDateChange($event)" >
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 color="primary"></mat-datepicker>
                        </mat-form-field>


                    </div>

                    <div fxLayoutGap="20px" class="label">


                        <mat-radio-group aria-label="Seleciona una opcion" class="radio" formControlName='radio' >
                            <mat-radio-button value="001" color="primary">Fisica</mat-radio-button>
                            <mat-radio-button value="002" color="primary">Telefono</mat-radio-button>
                          </mat-radio-group>



                    </div>

                    <div fxLayoutGap="20px" class="label">

                    </div>

                    <div fxLayoutGap="20px" class="label">

                    </div>

                    <div fxLayoutGap="20px" class="label">

                    </div>

                    <div fxLayoutGap="20px" class="label">

                    </div>


                 </form>
      <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px">
        <div fxFlex="50" style="background-color: rgb(219, 217, 217); padding: 1px;"></div>
      </div>

      <div fxLayoutGap="20px" class="label">
        <!--CONTAINER-BUTTONS-->
        <div class="container_buttons">


            <button mat-raised-button  (click) = "guardarvisita()" class="button1" >Guardar</button>
            <button mat-raised-button class="button1" (click) = "salir()">Salir</button>



        </div>
    </div>



    </div>
  </div>

import { Observable } from "rxjs";
import { ListarArchivoRequest, ListarDatosSoporteRequest, ListarDetalleRequest, ListarFasesTrackingRequest, ListarTipoRequest, ListarUsrSolicitanteRequest, RegistrarTipoIncidenciaRequest, actualizarDatosTicketRequest, enviaCorreoSoporteRequest, guardarDetalleTicketRequest, guardarTicketRequest, listarActividadRequest, listarAreaRequest } from "./request/soporte_request";
import { ActualizarTicketResponse, DetalleResponse, GuardaDetalleTicketResponse, GuardaTicketResponse, ListarActividadResponse, ListarArchivoResponse, ListarAreaResponse, ListarFasesTrackingResponse, SoporteResponse, TipoResponse, UsrAsigandoResponse } from "./response/soporte_response";

export abstract class SoporteRepository{
    abstract listarTipo(prolrequest: ListarTipoRequest): Observable<TipoResponse>
    abstract listarDatosSoporte(prolrequest: ListarDatosSoporteRequest): Observable<SoporteResponse>
    abstract listarUsrAsignado(prolrequest: ListarUsrSolicitanteRequest): Observable<UsrAsigandoResponse>
    abstract guardarDatosTicket(prolrequest: guardarTicketRequest): Observable<GuardaTicketResponse>
    abstract listarDetalleTicket(prolrequest: ListarDetalleRequest): Observable<DetalleResponse>
    abstract guardarDetalleTicket(prolrequest: guardarDetalleTicketRequest): Observable<GuardaDetalleTicketResponse>
    abstract listarArchivo(prolrequest: ListarArchivoRequest): Observable<ListarArchivoResponse>
    abstract actualizarDatosTicket(prolrequest: actualizarDatosTicketRequest): Observable<ActualizarTicketResponse>
    abstract listarFasesTracking (prolrequest: ListarFasesTrackingRequest): Observable<ListarFasesTrackingResponse>
    abstract listarActividad (prolrequest: listarActividadRequest): Observable<ListarActividadResponse>
    abstract listarAreaPorUsuario (prolrequest: listarAreaRequest): Observable<ListarAreaResponse>
    abstract RegistrarTipoIncidencia (prolrequest: RegistrarTipoIncidenciaRequest): Observable<ActualizarTicketResponse>
    abstract enviaCorreoSoporte(fd:enviaCorreoSoporteRequest);
}
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, switchMap } from 'rxjs';
import { BuscarNavesItinerarioRequest, ListarDropdownRequest, NuevaReservaRequest, updateReservaRefferRequest } from 'src/app/comercial/reffer/domain/reffer-request';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { proformaRequest, puertoRequest, requestTransportista } from 'src/app/proforma/domain/request/proforma_request';
import { ListIncoter } from 'src/app/proforma/domain/response/proforma_response';
import { ComercialRepository } from 'src/app/repositorio/comercial.repository';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';
import { ListarTipoRequest } from 'src/app/soporte/domain/request/soporte_request';
import { SoporteRepository } from 'src/app/soporte/domain/soporte.repository';
import jsPDF from 'jspdf';
import { VisorComponent } from 'src/app/comercial/reffer/visor-pdf/presentacion/visor/visor.component';
import { CryptoRepository } from 'src/app/interfaces/crypto-repository';

@Component({
  selector: 'app-nueva-reserva',
  templateUrl: './nueva-reserva.component.html',
  styleUrls: ['./nueva-reserva.component.css']
})
export class NuevaReservaComponent {

  constructor(
    private readonly router: Router,
    private readonly soporte: SoporteRepository,
    private readonly proforma: ProformaRepository,
    private readonly comercial: ComercialRepository,
    private readonly util: UtilService,
    private readonly storage: StorageService,
    private route: ActivatedRoute,
    private readonly crypto: CryptoRepository,
    // private location: Location
  ) { }

  group: FormGroup
  inicializarFormulario() {
    this.group = new FormGroup({
      shipper: new FormControl(null, null),
      origen: new FormControl(null, null),
      destino: new FormControl(null, null),
      via: new FormControl(null, null),
      naviera: new FormControl(null, null),
      semana: new FormControl(null, null),
      nave: new FormControl(null, null),
      commodity: new FormControl(null, null),
      neto: new FormControl(null, null),
      temperatura: new FormControl(null, null),
      ventilacion: new FormControl(null, null),
      humedad: new FormControl(null, null),
      consignatario: new FormControl(null, null),
      remarks: new FormControl(null, null),
      flete: new FormControl(null, null),
      reseteotemp: new FormControl(0, null),
      atmcontrolada: new FormControl(0, null),
      coldtreatment: new FormControl(0, null),
      pagadero: new FormControl(null, null),
      restemperatura: new FormControl(null, null),
      cantidad: new FormControl(null, null),
      tecnologia: new FormControl(null, null),
      o2: new FormControl(null, null),
      co2: new FormControl(null, null),
      booking: new FormControl('', null),
      skids: new FormControl('', null),
      bl: new FormControl('', null),
      contenedores: new FormControl('', null),
      sli: new FormControl(0, null),
    })
  }

  readonlyinput: boolean = false

  ListaSemanas: any[] = [
    { codigo: 1 },
    { codigo: 2 },
    { codigo: 3 },
    { codigo: 4 },
    { codigo: 5 },
    { codigo: 6 },
    { codigo: 7 },
    { codigo: 8 },
    { codigo: 9 },
    { codigo: 10 },
    { codigo: 11 },
    { codigo: 12 },
    { codigo: 13 },
    { codigo: 14 },
    { codigo: 15 },
    { codigo: 16 },
    { codigo: 17 },
    { codigo: 18 },
    { codigo: 19 },
    { codigo: 20 },
    { codigo: 21 },
    { codigo: 22 },
    { codigo: 23 },
    { codigo: 24 },
    { codigo: 25 },
    { codigo: 26 },
    { codigo: 27 },
    { codigo: 28 },
    { codigo: 29 },
    { codigo: 30 },
    { codigo: 31 },
    { codigo: 32 },
    { codigo: 33 },
    { codigo: 34 },
    { codigo: 35 },
    { codigo: 36 },
    { codigo: 37 },
    { codigo: 38 },
    { codigo: 39 },
    { codigo: 40 },
    { codigo: 41 },
    { codigo: 42 },
    { codigo: 43 },
    { codigo: 44 },
    { codigo: 45 },
    { codigo: 46 },
    { codigo: 47 },
    { codigo: 48 },
    { codigo: 49 },
    { codigo: 50 },
    { codigo: 51 },
    { codigo: 52 },
    { codigo: 53 }
  ]

  ListaVia: any[] = [
    { codigo: '001', nombre: 'MARÍTIMA' },
    { codigo: '002', nombre: 'AÉREA' }
  ]

  ListaNave: any[] = []

  //   { codigo: 1, nombre: 'NAVE 1' },
  //   { codigo: 2, nombre: 'NAVE 2' }
  // ]

  ListaNaviera: any[] = []
  cargaTodosTransportista() {
    const request: requestTransportista = <requestTransportista>{}

    request.pintENTC_Codigo = 1;
    request.pintENTC_Razonsocial = "";
    this.proforma.listaTransportista(request).subscribe(response => {
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListIncoter = {
          nombre: response.datos.result[i].descripcionTransportista,
          codigo: response.datos.result[i].codigoTransportista.toString(),
        }
        this.ListaNaviera.push(item);
      }
    });
  }


  ListaTecnologias: any[] = []
  ListaFlete: any[] = []
  ListaCommodity: any[] = []
  listarTipos(tipo: string, tabla: any[]) {
    const request = <ListarTipoRequest>{}
    request.TIPO_CodTabla = tipo



    this.soporte.listarTipo(request).subscribe(response => {
      response.datos.result.map(r => {
        const item = {
          codigo: r.tipO_CodTipo,
          nombre: r.tipO_Desc1
        }
        tabla.push(item)
      })
    })
  }

  listarConstantes(tipo: string, tabla: any[]) {
    const request2 = <proformaRequest>{}
    request2.pchrCONS_CodTabla = tipo

    this.proforma.listar(request2).subscribe(response => {
      response.datos.result.map(r => {
        const item = {
          codigo: r.conS_CodTipo,
          nombre: r.conS_Desc_SPA
        }
        tabla.push(item)
      })
    })
  }
  ListaShipper: any[] = []
  listarDropdown() {
    const request = <ListarDropdownRequest>{}
    request.tipo = 'shipper'
    request.cliente = this.storage.get('rol') == '4000' ? this.storage.get('usuario') : ''
    request.shipper = ''
    this.comercial.ListarDropdown(request).subscribe(response => {
      response.datos.result.map(r => {
        const item = {
          codigo: r.codigo,
          nombre: r.nombre
        }
        this.ListaShipper.push(item)
      })
    })
  }

  ListaPuertoOrigen: any[] = []
  ListaPuertoDestino: any[] = []
  textoFiltro: string = '';
  onFilter(event: any, tipo): void {
    this.textoFiltro = event.filter;
    if (tipo == 'origen') {
      this.fetchPuertos(this.textoFiltro);
    } else {
      this.fetchPuertosDestino(this.textoFiltro);
    }

  }

  fetchPuertos(filtro: string): void {
    const request = {
      pchrCONS_CodVia: '001',
      pchrCONS_TabVia: 'VIA',
      pchrPUER_CodEstandar: '',
      pintPUER_Codigo: '',
      pvchPUER_Nombre: filtro
    };

    this.proforma.listarPuerto(request).subscribe(response => {
      this.ListaPuertoOrigen = response.datos.result.map(r => ({
        nombre: r.pueR_Nombre,
        codigo: r.pueR_Codigo
      }));
    });
  }

  fetchPuertosDestino(filtro: string): void {
    const request = {
      pchrCONS_CodVia: '001',
      pchrCONS_TabVia: 'VIA',
      pchrPUER_CodEstandar: '',
      pintPUER_Codigo: '',
      pvchPUER_Nombre: filtro
    };

    this.proforma.listarPuerto(request).subscribe(response => {
      this.ListaPuertoDestino = response.datos.result.map(r => ({
        nombre: r.pueR_Nombre,
        codigo: r.pueR_Codigo
      }));
    });
  }

  listarCommodity() {
    this.ListaCommodity = []
    const values = this.group.value
    const request = <ListarDropdownRequest>{}
    request.cliente = this.storage.get('rol') == '4000' ? this.storage.get('usuario') : ''
    request.tipo = 'commodity'
    request.shipper = this.storage.get('rol') == '4000' ? (values.shipper ? values.shipper.codigo : '') : ''
    this.comercial.ListarDropdown(request).subscribe(response => {
      response.datos.result.map(r => {
        const item = {
          codigo: r.codigo,
          nombre: r.nombre
        }
        this.ListaCommodity.push(item)
      })
    })
  }

  conexion: string = ''
  transaccion: string = ''
  changeData: any
  ngOnInit(): void {
    this.inicializarFormulario()
    this.group.get('booking')?.disable();
    this.listarTipos('RTE', this.ListaTecnologias)
    this.listarConstantes('RFL', this.ListaFlete)
    this.listarDropdown()
    this.cargaTodosTransportista()
    this.listarCommodity()

    this.changeData = JSON.parse(localStorage.getItem('Reserva')) != undefined ? JSON.parse(localStorage.getItem('Reserva')) : null


    if (this.changeData) {
      this.readonlyinput = true
      this.transaccion = 'editar'
      setTimeout(() => {

        this.group.get('booking')?.enable();

        console.log(this.changeData)
        this.ListaNave = []

        const request = <BuscarNavesItinerarioRequest>{}
        request.destino = this.changeData.coddestino
        request.origen = this.changeData.codorigen
        request.naviera = this.changeData.codnaviera
        request.semana = this.changeData.semana
        this.comercial.BuscarNavesItinerario(request).subscribe(response => {
          console.log(response)
          this.ListaNave = response.datos.result
        })


        this.changeReserva(this.changeData)

      }, 2000);
    } else {
      this.transaccion = 'nuevo'
    }

    this.route.queryParamMap.subscribe(params => {
      const data = this.crypto.decrypt(params.get('data'), 'Lim@2023');
      if (data) {
        //this.row = JSON.parse(data); // Deserializa el objeto JSON
        console.log('Row:', JSON.parse(data));
        const row = JSON.parse(data)
        this.fetchPuertos(row.origen)
        this.fetchPuertosDestino(row.destino)

        this.ListaNave = []

        this.conexion = '/itinerarios'
        const request = <BuscarNavesItinerarioRequest>{}
        request.destino = row.coddestino
        request.origen = row.codorigen
        request.naviera = row.codnaviera
        request.semana = row.semana
        this.comercial.BuscarNavesItinerario(request).subscribe(response => {
          console.log(response)
          this.ListaNave = response.datos.result
        })

        setTimeout(() => {
          this.group.get('origen').setValue(this.ListaPuertoOrigen.find(option => option.codigo === row.codorigen))
          this.group.get('destino').setValue(this.ListaPuertoDestino.find(option => option.codigo === row.coddestino))
          this.group.get('naviera').setValue(this.ListaNaviera.find(option => option.codigo === (row.codnaviera).toString()))
          this.group.get('semana').setValue(this.ListaSemanas.find(option => option.codigo === (row.semana)))
          this.group.get('nave').setValue(this.ListaNave.find(option => option.codigo === (row.codnave)))


        }, 1500);
      }
    });

    console.log(this.transaccion)
  }

  changeReserva(row: any) {
    console.log(row)
    this.fetchPuertos(row.origen)
    this.fetchPuertosDestino(row.destino)
    setTimeout(() => {
      this.group = new FormGroup({
        shipper: new FormControl(this.ListaShipper.find(option => option.codigo === (row.codshipper).toString()), null),
        origen: new FormControl(this.ListaPuertoOrigen.find(option => option.codigo === row.codorigen), null),
        destino: new FormControl(this.ListaPuertoDestino.find(option => option.codigo === row.coddestino), null),
        via: new FormControl(this.ListaVia.find(option => option.codigo === row.tipo_codvia), null),
        naviera: new FormControl(this.ListaNaviera.find(option => option.codigo === (row.codnaviera).toString()), null),
        semana: new FormControl(this.ListaSemanas.find(option => option.codigo === (row.semana)), null),
        nave: new FormControl(this.ListaNave.find(option => option.codigo === (row.nave_codigo)), null),
        commodity: new FormControl(this.ListaCommodity.find(option => option.codigo === (row.tipo_codrco)), null),
        neto: new FormControl(row.pesoneto, null),
        temperatura: new FormControl(row.temperatura, null),
        ventilacion: new FormControl(row.ventilacion, null),
        humedad: new FormControl(row.humedad, null),
        consignatario: new FormControl(row.entc_codconsignatario, null),
        remarks: new FormControl(row.remarks, null),
        flete: new FormControl(this.ListaFlete.find(option => option.codigo === row.cons_codrfl), null),
        reseteotemp: new FormControl(row.isreseteotemp, null),
        atmcontrolada: new FormControl(row.isatmosferacontrol, null),
        coldtreatment: new FormControl(row.coldtrea, null),
        pagadero: new FormControl(row.pagadero, null),
        restemperatura: new FormControl(row.reseteotemp, null),
        cantidad: new FormControl(row.cantidadtemp, null),
        tecnologia: new FormControl(row.tipo_codrte ? this.ListaTecnologias.find(option => option.codigo === row.tipo_codrte) : row.tipo_codrte, null),
        o2: new FormControl(row.o2, null),
        co2: new FormControl(row.co2, null),
        booking: new FormControl(row.booking, null),
        skids: new FormControl(row.skids, null),
        bl: new FormControl(row.emisionbl, null),
        contenedores: new FormControl(row.contenedores, null),
        sli: new FormControl(row.sli, null),
      })

      // this.group.get('reseteotemp').disable()
      // this.group.get('atmcontrolada').disable()
      // this.group.get('coldtreatment').disable()
      // // this.group.get('sli').disable()
    }, 2000);
  }

  guardar() {
    console.log(this.transaccion)
    if (this.transaccion == 'nuevo') {
      const values = this.group.value
      const request = <NuevaReservaRequest>{}
      request.shipper = values.shipper.codigo
      request.origen = values.origen.codigo
      request.destino = values.destino.codigo
      request.via = values.via.codigo
      request.naviera = values.naviera.codigo
      request.semana = values.semana.codigo
      request.nave = values.nave.codigo
      request.commodity = values.commodity.codigo
      request.neto = values.neto
      request.temperatura = values.temperatura
      request.ventilacion = values.ventilacion
      request.humedad = values.humedad
      request.consignatario = values.consignatario
      request.remarks = values.remarks
      request.flete = values.flete.codigo
      request.reseteotemp = values.reseteotemp == 1 ? true : false
      request.atmcontrolada = values.atmcontrolada == 1 ? true : false
      request.coldtreatment = values.coldtreatment == 1 ? true : false
      request.pagadero = values.pagadero
      request.restemperatura = values.restemperatura
      request.cantidad = values.cantidad
      request.tecnologia = values.tecnologia ? values.tecnologia.codigo : null
      request.o2 = values.o2
      request.co2 = values.co2
      request.estado = 'I'
      request.audi_usrcrea = this.storage.get('usuario')
      request.tipotransaccion = 'I'

      request.sli = values.sli == 1 ? true : false
      request.skids = values.skids
      request.contenedores = values.contenedores
      request.booking = values.booking
      request.emisionbl = values.bl
      request.tipo = 'I'
      request.codigo = 0
      request.itinerario = values.nave.itinerario
      console.log(request)

      this.comercial.NuevaReserva(request).subscribe(response => {
        if (response.datos.status == 200) {
          this.util.ShowMessage({ mensaje: 'Reserva Guardada Correctamente', tipo: 'ok' })
          this.router.navigate(['/reserva']);

        }
      }, (errorServicio) => {
        this.util.ShowMessage({ mensaje: 'Error Guardar Reserva', tipo: 'warning' })
      })
    } else {
      const values = this.group.value
      const request = <NuevaReservaRequest>{}
      request.shipper = values.shipper.codigo
      request.origen = values.origen.codigo
      request.destino = values.destino.codigo
      request.via = values.via.codigo
      request.naviera = values.naviera.codigo
      request.semana = values.semana.codigo
      request.nave = values.nave.codigo
      request.commodity = values.commodity.codigo
      request.neto = values.neto
      request.temperatura = values.temperatura
      request.ventilacion = values.ventilacion
      request.humedad = values.humedad
      request.consignatario = values.consignatario
      request.remarks = values.remarks
      request.flete = values.flete.codigo
      request.reseteotemp = values.reseteotemp == 1 ? true : false
      request.atmcontrolada = values.atmcontrolada == 1 ? true : false
      request.coldtreatment = values.coldtreatment == 1 ? true : false
      request.pagadero = values.pagadero
      request.restemperatura = values.restemperatura
      request.cantidad = values.cantidad
      request.tecnologia = values.tecnologia ? values.tecnologia.codigo : null
      request.o2 = values.o2
      request.co2 = values.co2
      request.estado = 'I'
      request.audi_usrcrea = this.storage.get('usuario')
      request.tipotransaccion = 'I'

      request.sli = values.sli == 1 ? true : false
      request.skids = values.skids
      request.contenedores = values.contenedores
      request.booking = values.booking
      request.emisionbl = values.bl
      request.tipo = 'U'
      request.codigo = this.changeData.codigo
      request.itinerario = values.nave.itinerario
      console.log(request)

      this.comercial.NuevaReserva(request).subscribe(response => {
        if (response.datos.status == 200) {

          if (values.booking != '' && values.booking != null) {
            const request = <updateReservaRefferRequest>{}
            request.reserva = this.changeData.codigo
            request.tipo = 'B'
            request.usuario = this.storage.get('usuario')
            request.comentario = null
            console.log(request)
            this.comercial.updateReservaReffer(request).subscribe(response => {
              if (response.datos.status == 200) {
                this.util.ShowMessage({ mensaje: 'Reserva Modificada Correctamente', tipo: 'ok' })
                this.router.navigate(['/reserva']);

              }
            })
          } else {
            this.util.ShowMessage({ mensaje: 'Reserva Modificada Correctamente', tipo: 'ok' })
            this.router.navigate(['/reserva']);
          }


        }
      }, (errorServicio) => {
        this.util.ShowMessage({ mensaje: 'Error Modificar Reserva', tipo: 'warning' })
      })


    }
  }

  async viewPDF() {

    const datos = (await this.generarPDF()).toString()

    if (datos != '') {
      const options = {
        disableClose: true,
        panelClass: 'style-visor',
        data: datos
      };

      const reference = this.util.openModal(
        VisorComponent,
        options,
      );

      reference.subscribe((response) => {
        if (response) {
        }
      });
    }
  }

  listarNave() {
    this.ListaNave = []
    const values = this.group.value
    if (values.origen && values.destino && values.naviera && values.semana) {
      const request = <BuscarNavesItinerarioRequest>{}
      request.destino = values.destino.codigo
      request.origen = values.origen.codigo
      request.naviera = values.naviera.codigo
      request.semana = values.semana.codigo
      this.comercial.BuscarNavesItinerario(request).subscribe(response => {
        console.log(response)
        this.ListaNave = response.datos.result
      })
    }

  }

  async generarPDF(): Promise<string> {

    const doc = new jsPDF({ compress: true });

    doc.setFontSize(9);

    const imageURL = '../../../../../assets/Img/FONDOBLANCO.png';
    const imageWidth = 60; // Ancho de la imagen en el PDF
    const imageHeight = 25; // Alto de la imagen en el PDF

    doc.setFontSize(8);

    const space = 4
    var line = 23
    var font = 8
    const values = this.group.value

    doc.addImage(imageURL, 'JPEG', 10, 1, imageWidth, imageHeight, undefined, 'FAST');


    doc.setLineWidth(0.4);
    doc.setDrawColor(0, 0, 0);
    doc.text('Calle Antequera Nro 777', 160, 15)
    doc.text('Piso 12 - San Isidro, Lima', 160, 18)
    doc.text('www.delfingroupco.com.pe', 160, 21)
    doc.line(10, 23, 200, 23);
    doc.setFont(undefined, 'bold');
    doc.setFontSize(12);
    doc.text('SOLICITUD DE RESERVA', 80, line += space + space)
    doc.setFont(undefined, 'normal');
    doc.line(10, line += space, 200, line);
    doc.setFont(undefined, 'bold');
    doc.setFont(undefined, 'normal');

    var rec_y = 50
    const space_rec = 10
    const left = 13
    var space_line = 0

    function spaces(): number {
      const numero = (font / 3) * 2
      return numero

    }

    doc.setDrawColor(0, 0, 0)
    doc.rect(10, rec_y, 190, space_rec, 'S')

    doc.setFontSize(9);
    font = 9
    space_line = spaces()

    doc.text('Shipper: ' + (values.shipper ? values.shipper.nombre : ''), left, rec_y + space_line) //(space_rec / 2 + 1)

    doc.rect(10, rec_y += space_rec, 90, space_rec, 'S')
    doc.rect(100, rec_y, 100, space_rec, 'S')

    doc.text('Puerto de embarque: ' + (values.origen ? values.origen.nombre : ''), left, rec_y + space_line)
    doc.text('Puerto de descarga: ' + (values.destino ? values.destino.nombre : ''), 90 + left, rec_y + space_line)

    doc.rect(10, rec_y += space_rec, 90, space_rec, 'S')
    doc.rect(100, rec_y, 100, space_rec, 'S')

    doc.text('Semana: ' + (values.semana ? values.semana.codigo : ''), left, rec_y + space_line)
    doc.text('Commodity: ' + (values.commodity ? values.commodity.nombre : ''), 90 + left, rec_y + space_line)

    doc.rect(10, rec_y += space_rec, 90, space_rec * 2, 'S')
    doc.rect(100, rec_y, 100, space_rec * 2, 'S')

    doc.text('Naviera: ' + (values.naviera ? values.naviera.nombre : ''), left, rec_y + space_line, { maxWidth: 80 - left })
    doc.text('Nave / Viaje / ETD: ' + (values.nave ? values.nave.nombre : ''), 90 + left, rec_y + space_line)

    doc.rect(10, rec_y += (space_rec * 2), 90, space_rec * 2, 'S')
    doc.rect(100, rec_y, 100, space_rec * 2, 'S')

    doc.text('Via de embarque: ' + (values.via ? values.via.nombre : ''), left, rec_y + space_line)
    doc.text('Cantidad de contenedores: ' + (values.contenedores), 90 + left, rec_y + space_line)
    doc.text('SKIDS: ' + (values.skids || ''), 90 + left, rec_y + space_line + space_line)

    doc.rect(10, rec_y += (space_rec * 2), 90, space_rec, 'S')
    doc.rect(100, rec_y, 100, space_rec, 'S')

    doc.text('Temperatura: ' + (values.temperatura || ''), left, rec_y + space_line)
    doc.text('Cold Treadment: : ' + (values.coldtrea == false ? 'NO' : 'SI' || ''), 90 + left, rec_y + space_line)

    doc.rect(10, rec_y += space_rec, 90, space_rec, 'S')
    doc.rect(100, rec_y, 100, space_rec, 'S')

    doc.text('Requiere reseteo de temperatura: ' + (values.reseteotemp == 1 ? 'SI' : 'NO'), left, rec_y + space_line)
    doc.text((values.reseteotemp == 1 ? 'Indicar nueva temperatura: ' + (values.restemperatura || '') + '    Dias: ' + (values.cantidad || '') : 'Indicar nueva temperatura:  _____    Dias: _____'), 90 + left, rec_y + space_line)

    doc.rect(10, rec_y += space_rec, 90, space_rec, 'S')
    doc.rect(100, rec_y, 100, space_rec, 'S')

    doc.text('Ventilación y humedad: ', left, rec_y + space_line)
    doc.text((values.ventilacion || '    ') + '%  &  ' + (values.humedad || '    ') + ' CBM', 90 + left, rec_y + space_line)

    doc.rect(10, rec_y += space_rec, 90, space_rec, 'S')
    doc.rect(100, rec_y, 100, space_rec, 'S')

    doc.text('Consignatario: ' + (values.consignatario || ''), left, rec_y + space_line)
    doc.text('Flete: ' + (values.flete ? values.flete.nombre : '' || ''), 90 + left, rec_y + space_line)

    doc.rect(10, rec_y += space_rec, 90, space_rec, 'S')
    doc.rect(100, rec_y, 100, space_rec, 'S')

    doc.text('Emisión de B/L: ' + (values.bl || ''), left, rec_y + space_line)
    doc.text('SLI: ' + (values.sli == 0 ? 'NO' : 'SI' || ''), 90 + left, rec_y + space_line)

    doc.rect(10, rec_y += space_rec, 190, space_rec * 3, 'S')

    doc.text('Remarks: ' + (values.remarks || ''), left, rec_y + space_line)

    // doc.save('Proforma.pdf')

    const pdfBase64 = doc.output('datauristring');

    return pdfBase64
  }

  salir() {
    const path = this.conexion == '' ? '/reserva' : this.conexion
    this.router.navigate([path]);
    //this.router.dispose()
    localStorage.removeItem('Reserva')
  }
}

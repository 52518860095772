import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { SoporteRepository } from '../soporte/domain/soporte.repository';
import { ListarArchivoRequest, ListarDatosSoporteRequest, ListarDetalleRequest, ListarFasesTrackingRequest, ListarTipoRequest, ListarUsrSolicitanteRequest, RegistrarTipoIncidenciaRequest, actualizarDatosTicketRequest, enviaCorreoSoporteRequest, guardarDetalleTicketRequest, guardarTicketRequest, listarActividadRequest, listarAreaRequest } from '../soporte/domain/request/soporte_request';
import { ActualizarTicketResponse, DetalleResponse, GuardaDetalleTicketResponse, GuardaTicketResponse, ListarActividadResponse, ListarArchivoResponse, ListarAreaResponse, ListarDatosSoporte, ListarFasesTrackingResponse, SoporteResponse, TipoResponse, UsrAsigandoResponse } from '../soporte/domain/response/soporte_response';

@Injectable()
export class SoporteService extends SoporteRepository {

  constructor(private readonly http: HttpClient,
    private readonly storage: StorageService

  ) {

    super();
  }
  override listarFasesTracking(prolrequest: ListarFasesTrackingRequest): Observable<ListarFasesTrackingResponse> {
    return this.http.post<ListarFasesTrackingResponse>(`${environment.PATH_API}/soporte/ListarFasesTracking/`, prolrequest);
  }
  listarTipo(prolrequest: ListarTipoRequest): Observable<TipoResponse> {
    return this.http.post<TipoResponse>(`${environment.PATH_API}/soporte/ListarTipo/`, prolrequest);
  }
  override listarDatosSoporte(prolrequest: ListarDatosSoporteRequest): Observable<SoporteResponse> {
    return this.http.post<SoporteResponse>(`${environment.PATH_API}/soporte/ListarDatosSoporte/`, prolrequest);
  }
  listarUsrAsignado(prolrequest: ListarUsrSolicitanteRequest): Observable<UsrAsigandoResponse> {
    return this.http.post<UsrAsigandoResponse>(`${environment.PATH_API}/soporte/ListarUsrAsignado/`, prolrequest);
  }
  guardarDatosTicket(prolrequest: guardarTicketRequest): Observable<GuardaTicketResponse> {
    return this.http.post<GuardaTicketResponse>(`${environment.PATH_API}/soporte/GuardarDatosTicket/`, prolrequest);
  }
  listarDetalleTicket(prolrequest: ListarDetalleRequest): Observable<DetalleResponse> {
    return this.http.post<DetalleResponse>(`${environment.PATH_API}/soporte/ListarDetalle/`, prolrequest);
  }
  guardarDetalleTicket(prolrequest: guardarDetalleTicketRequest): Observable<GuardaDetalleTicketResponse> {
    return this.http.post<GuardaDetalleTicketResponse>(`${environment.PATH_API}/soporte/GuardarDetalleTicket/`, prolrequest);
  }
  listarArchivo(prolrequest: ListarArchivoRequest): Observable<ListarArchivoResponse> {
    return this.http.post<ListarArchivoResponse>(`${environment.PATH_API}/soporte/ListarArchivo/`, prolrequest);
  }
  actualizarDatosTicket(prolrequest: actualizarDatosTicketRequest): Observable<ActualizarTicketResponse> {
    return this.http.post<ActualizarTicketResponse>(`${environment.PATH_API}/soporte/ActualizarDatosTicket/`, prolrequest);
  }
  listarActividad(prolrequest: listarActividadRequest): Observable<ListarActividadResponse> {
    return this.http.post<ListarActividadResponse>(`${environment.PATH_API}/soporte/ListarActividad/`, prolrequest);
  }
  listarAreaPorUsuario(prolrequest: listarAreaRequest): Observable<ListarAreaResponse> {
    return this.http.post<ListarAreaResponse>(`${environment.PATH_API}/soporte/ListarAreaPorUsuario/`, prolrequest);
  }
  RegistrarTipoIncidencia(prolrequest: RegistrarTipoIncidenciaRequest): Observable<ActualizarTicketResponse> {
    return this.http.post<ActualizarTicketResponse>(`${environment.PATH_API}/soporte/registrartipoincidencia/`, prolrequest);
  }
  enviaCorreoSoporte(fd:enviaCorreoSoporteRequest){
    return this.http.post(`${environment.PATH_API}/autenticacion/EnviarCorreo`,fd);
  }
}


import { ListaCliente2, ListaContactoJ,lisTipoDocumento, listDepartamento, listDistrito, listProvincia } from './../../../../proforma/domain/response/proforma_response';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { contactoRequest, incoterRequest, insertaCienteRequest, listaClienteRequest, requestDepartamento, requestDistrito, requestProvincia, requestupdatecliente, sunatRequest } from 'src/app/proforma/domain/request/proforma_request';
import { lisHolding, lisPais, lisSegmentos } from 'src/app/proforma/domain/response/proforma_response';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';


@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent {
  groupDatos: FormGroup
  groupRelacionados: FormGroup
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  estadoPersona: boolean = false

  DatosRecibidos: ListaCliente2;
  //LISTAS DE TIPOS
  listHolding: lisHolding[] = [];
  listPais: lisPais[] = [];
  listDepartamento: listDepartamento[] = [];
  listProvincia: listProvincia[] = [];
  listDistrito: listDistrito[] = [];
  listSegmentos: lisSegmentos[] = [];
  listTipoDocumento: lisTipoDocumento[] = [];
  condicion: boolean = false

  iniciaFormulario() {
    this.groupDatos = new FormGroup({
      codigo: new FormControl(null, null),
      activo: new FormControl(null, null),
      servicioLogistico: new FormControl(null, null),
      juridica: new FormControl(true),
      natural: new FormControl(false),
      tipoDocumento: new FormControl(null, [Validators.required]),
      nroDocumento: new FormControl(null, [Validators.required]),
      masculino: new FormControl(null, null),
      femenino: new FormControl(null, null),
      razonSocial: new FormControl(null, null),
      razonComercial: new FormControl(null, null),
      apellidoPaterno: new FormControl(null, null),
      apellidoMaterno: new FormControl(null, null),
      nombres: new FormControl(null, null),
      area: new FormControl(null, null),
      cargo: new FormControl(null, null),
      fechaNacimiento: new FormControl(null, null),
      fechaIngreso: new FormControl(null, null),
      correoContratoMandato: new FormControl(null, [Validators.required]),
      correoOtrosTraficos: new FormControl(null, null),
      contactoNombre: new FormControl(null, [Validators.required]),
      apellidos: new FormControl(null, [Validators.required]),
      correoExportaciones: new FormControl(null, null),
      correoFacturacion: new FormControl(null, null),
      segmento: new FormControl(null, null),
      web: new FormControl(null, null),
      telefonoFijo: new FormControl(null, null),
      telefonoMovil: new FormControl(null, [Validators.required]),
      direccionFiscal: new FormControl(null, null),
      pais1: new FormControl(null, null),
      departamento1: new FormControl(null, null),
      provincia1: new FormControl(null, null),
      distrito1: new FormControl(null, null),
      direccionComercial: new FormControl(null, null),
      pais2: new FormControl(null, null),
      departamento2: new FormControl(null, null),
      provincia2: new FormControl(null, null),
      distrito2: new FormControl(null, null),
      pais3: new FormControl(null, null),
      holding: new FormControl(null, null),
      nroDomiciliado: new FormControl(null, null),
      ocultaShipper: new FormControl(null, null),
      fechaCredito: new FormControl(null, null),
      diasCredito: new FormControl(null, null),
      toOrder: new FormControl(null, null),
      observaciones: new FormControl(null, null),
      genero: new FormControl(null, null),
      estadoPersona: new FormControl('J', null),
      generoPersona: new FormControl('M', null),
      nombreGerente: new FormControl(null, null),
      fechaNacimientoGerente: new FormControl(null, null),
      fehcaAniversarioGerente: new FormControl(null, null),
    })
  }

  constructor(private util: UtilService, private readonly proformaService: ProformaRepository, private route: ActivatedRoute, private readonly storage: StorageService, private readonly router: Router) { }

  accionJuridica() {
    this.groupDatos.patchValue({
      estadoPersona: 'J'
    });
  }

  accionNatural() {
    this.groupDatos.patchValue({
      estadoPersona: 'N'
    });
  }

  receiveMessage($event) {
    this.DatosRecibidos = $event;
  }

  codcliente: number


  ngOnInit(): void {
    this.iniciaFormulario()
    this.cargaIncoter('SEG')
    this.cargaIncoter('HOL')
    this.cargaIncoter('TDI')
    this.cargaIncoter('PAI')
    if (localStorage.getItem('dato') != null) {

      this.route.queryParams.subscribe(params => {
        const datosSerializados = localStorage.getItem('dato')
     
        const datos = JSON.parse(datosSerializados);
        localStorage.removeItem('dato');
        this.cargaContacto(datos.entC_Codigo)

        this.codcliente = datos.entC_Codigo
        this.groupDatos.get('codigo').setValue(datos.entC_Codigo)
        this.groupDatos.get('activo').setValue(datos.entC_Activo)
        this.groupDatos.get('servicioLogistico').setValue(datos.entC_ServicioLogistico)
        this.groupDatos.get('tipoDocumento').setValue(datos.tipO_CodTDI)
        this.groupDatos.get('nroDocumento').setValue(datos.entC_DocIden)
        this.groupDatos.get('razonSocial').setValue(datos.entC_RazonSocial)
        this.groupDatos.get('razonComercial').setValue(datos.entC_NomComercial)
        this.groupDatos.get('apellidoPaterno').setValue(datos.entC_ApePat)
        this.groupDatos.get('apellidoMaterno').setValue(datos.entC_ApeMat)
        this.groupDatos.get('nombres').setValue(datos.entC_Nombres)
        this.groupDatos.get('area').setValue(datos.entC_Area)
        this.groupDatos.get('cargo').setValue(datos.entC_Cargo)
        this.groupDatos.get('fechaNacimiento').setValue(datos.entC_FecNac)
        this.groupDatos.get('fechaIngreso').setValue(datos.entC_FecIng)
        this.groupDatos.get('correoContratoMandato').setValue(datos.entC_EMail)
        this.groupDatos.get('correoOtrosTraficos').setValue(datos.entC_EMail2)
        this.groupDatos.get('correoExportaciones').setValue(datos.entC_EMail2)
        this.groupDatos.get('correoFacturacion').setValue(datos.entC_EmailReceptorFE)
        this.groupDatos.get('segmento').setValue('')
        this.groupDatos.get('web').setValue(datos.entC_Web)
        this.groupDatos.get('contactoNombre').setValue(datos.entC_NombreContacto)
        this.groupDatos.get('apellidos').setValue(datos.entC_ApellidoContacto)
        this.groupDatos.get('correoExportaciones').setValue(datos.entC_NombreContacto)
        this.groupDatos.get('telefonoFijo').setValue(datos.entC_Telef2)
        this.groupDatos.get('telefonoMovil').setValue(datos.entC_Telef1)
        this.groupDatos.get('direccionFiscal').setValue(datos.dirE_Direccion)
        this.groupDatos.get('pais1').setValue(datos.tipO_CodPai)
        this.groupDatos.get('departamento1').setValue(datos)
        this.groupDatos.get('provincia1').setValue(datos)
        this.groupDatos.get('distrito1').setValue(datos)
        this.groupDatos.get('direccionComercial').setValue(datos)
        this.groupDatos.get('pais2').setValue(datos.tipO_CodPai)
        this.groupDatos.get('departamento2').setValue(datos)
        this.groupDatos.get('provincia2').setValue(datos)
        this.groupDatos.get('distrito2').setValue(datos)
        this.groupDatos.get('pais3').setValue(datos.tipO_CodPai)
        this.groupDatos.get('holding').setValue(datos.tipO_HOL)
        this.groupDatos.get('nroDomiciliado').setValue(datos.entC_Domiciliado)
        this.groupDatos.get('ocultaShipper').setValue(datos.entC_OcultarShipper)
        this.groupDatos.get('fechaCredito').setValue(datos.entC_FechaCredito)
        this.groupDatos.get('diasCredito').setValue(datos.entC_DiasCredito)
        this.groupDatos.get('toOrder').setValue(datos.entC_ToOrder)
        this.groupDatos.get('observaciones').setValue(datos.entC_Obser)
        this.groupDatos.get('generoPersona').setValue(datos.entC_Sexo)

        this.groupDatos.get('estadoPersona').setValue(datos.entC_TipEnt)
      })
    } else {

    }
  }

  listaContactoJ: ListaContactoJ[] = []
  autocompleteTrigger: MatAutocompleteTrigger;
  cargaContacto(value) {
    const requestContacto: contactoRequest = <contactoRequest>{}
    requestContacto.pintENTC_CodPadre = value
    requestContacto.pvchENT_NombreCompleto = ""
    this.proformaService.listaContacto(requestContacto).subscribe(response => {
      this.listaContactoJ.splice(0, this.listaContactoJ.length)
      for (let i = 0; i < response.datos.result.length; i++) {
        if (response.datos.result[i].relA_Tipos == "CON") {
          this.groupDatos.get('contactoNombre').setValue(response.datos.result[i].entC_Nombres) //AVERIGUAR DE DONDE VIENE
          this.groupDatos.get('apellidos').setValue(response.datos.result[i].entC_ApePat)
        }
      }
    });

  }

  //CARGA TIPOS
  cargaIncoter(value) {
    
    const requestIncoter: incoterRequest = <incoterRequest>{}
    const razonSocialControl = this.groupDatos.get('incoter');
    requestIncoter.TIPO_CodTabla = value
    this.proformaService.listarIncoter(requestIncoter).subscribe(response => {
      if (value == 'HOL') {
        this.listHolding.splice(0, this.listHolding.length)
        for (let i = 0; i < response.datos.result.length; i++) {
          const item: lisHolding = {
            nombre: response.datos.result[i].tipO_Desc1,
            codigo: response.datos.result[i].tipo_CodTipo
          }
          this.listHolding.push(item)
        }
      }
      if (value == 'PAI') {
        this.listPais.splice(0, this.listPais.length)
        for (let i = 0; i < response.datos.result.length; i++) {
          const item: lisPais = {
            nombre: response.datos.result[i].tipO_Desc1,
            codigo: response.datos.result[i].tipo_CodTipo
          }
          this.listPais.push(item)
        }
   
      }
      if (value == 'SEG') {
        this.listSegmentos.splice(0, this.listSegmentos.length)
        for (let i = 0; i < response.datos.result.length; i++) {
          const item: lisSegmentos = {
            nombre: response.datos.result[i].tipO_Desc1,
            codigo: response.datos.result[i].tipo_CodTipo
          }
          this.listSegmentos.push(item)
        }
      }
      if (value == 'TDI') {
        this.listTipoDocumento.splice(0, this.listTipoDocumento.length)
        for (let i = 0; i < response.datos.result.length; i++) {
          const item: lisTipoDocumento = {
            nombre: response.datos.result[i].tipO_Desc1,
            codigo: response.datos.result[i].tipo_CodTipo
          }
          this.listTipoDocumento.push(item)
        }
      }
    })
  }
  onPaisSeleccionado(event: any) {
    const paisSeleccionado = event.value;
    const request: requestDepartamento = <requestDepartamento>{}
    request.id_pais = event.value
    this.proformaService.listardepartamentos(request).subscribe(response => {
      this.listDepartamento = response.datos.result;
    });
  }
  onDepartamentoSeleccionado(event: any) {
    const departamentoSeleccionado = event.value;
    const request: requestProvincia = <requestProvincia>{}
    request.id_departamento = event.value
    this.proformaService.listarprovincias(request).subscribe(response => {
      this.listProvincia = response.datos.result;
    });
  }
  onprovinciaSeleccionada(event:any){
    const provinciaseleccionada = event.value;
    const request: requestDistrito = <requestDistrito>{}
    request.id_provincia = event.value
    this.proformaService.listardistritos(request).subscribe(response => {
      this.listDistrito = response.datos.result;
    });
  }

  selectedTipoDocumento: any;
  insertaCliente() {
    const valores = this.groupDatos.value;
    const requestInsertaCliente: insertaCienteRequest = <insertaCienteRequest>{}
    requestInsertaCliente.pintENTC_Codigo = '0';

    if (valores.tipoDocumento != null) {
      this.selectedTipoDocumento = this.listTipoDocumento.find((item: any) => item.codigo === valores.tipoDocumento);
      requestInsertaCliente.pchrTIPO_TabTDI = this.selectedTipoDocumento.nombre;
      requestInsertaCliente.pchrTIPO_CodTDI = this.selectedTipoDocumento.codigo;
    } else {
      this.util.showMessage('Falta el Tipo de Documento');
    }
    if (this.estadoPersona) {
      requestInsertaCliente.pchrENTC_TipEnt = 'N';
    } else {
      requestInsertaCliente.pchrENTC_TipEnt = 'J';
    }
    if (valores.nroDocumento != null) {
      requestInsertaCliente.pvchENTC_DocIden = valores.nroDocumento;
    } else {
      this.util.showMessage('Falta el numero Documento')
    }


    if (this.estadoPersona) {
      if (valores['nombres'] != null) {
        requestInsertaCliente.pvchENTC_Nombres = valores['nombres'];
      } else {
        requestInsertaCliente.pvchENTC_Nombres = ''
        this.util.showMessage('Falta el Nombre');
      }

      if (valores['apellidoPaterno'] != null) {
        requestInsertaCliente.pvchENTC_ApePat = valores['apellidoPaterno'];
      } else {
        requestInsertaCliente.pvchENTC_ApePat = ''
        this.util.showMessage('Falta el Apellido Paterno');
      }

      if (valores['apellidoMaterno'] != null) {
        requestInsertaCliente.pvchENTC_ApeMat = valores['apellidoMaterno'];
      } else {
        requestInsertaCliente.pvchENTC_ApeMat = ''
        this.util.showMessage('Falta el apellido Materno')
      }
    }

    if (!this.estadoPersona) {
      requestInsertaCliente.pvchENTC_Nombres = ''
      requestInsertaCliente.pvchENTC_ApePat = ''
      requestInsertaCliente.pvchENTC_ApeMat = ''
      if (valores.razonSocial != null) {
        requestInsertaCliente.pvchENTC_NomCompleto = valores.razonComercial;
        requestInsertaCliente.pvchENTC_NomComercial = valores.razonComercial;
        requestInsertaCliente.pvchENTC_RazonSocial = valores.razonComercial;
      } else {
        requestInsertaCliente.pvchENTC_NomCompleto = ''
        this.util.showMessage('Falta el nombre completo')
      }

      if (valores.fechaNacimiento != null) {
        requestInsertaCliente.pdtmENTC_FecNac = valores.fechaNacimiento;
      } else {
        requestInsertaCliente.pdtmENTC_FecNac = '';
      }

      if (valores.fechaIngreso != null) {
        requestInsertaCliente.pdtmENTC_FecIng = valores.fechaIngreso;
      } else {
        requestInsertaCliente.pdtmENTC_FecIng = '';
      }

      if (valores['genero'] != null) {
        requestInsertaCliente.pchrENTC_Sexo = this.groupDatos.get('genero').value; //PUEDE GENERAR ERROR
      } else {
        requestInsertaCliente.pchrENTC_Sexo = 'M';
      }

      if (valores.area != null) {
        requestInsertaCliente.pvchENTC_Area = valores.area;
      } else {
        requestInsertaCliente.pvchENTC_Area = '';
      }

      if (valores.cargo != null) {
        requestInsertaCliente.pvchENTC_Cargo = valores.cargo;
      } else {
        requestInsertaCliente.pvchENTC_Cargo = '';
      }

    }

    if (valores.activo != null) {
      requestInsertaCliente.pbitENTC_Activo = valores.activo; //puede generar error
    } else {
      requestInsertaCliente.pbitENTC_Activo = false; //puede generar error
    }


    requestInsertaCliente.pbitENTC_VIP = false;
    requestInsertaCliente.pvchENTC_Notas = '';
    requestInsertaCliente.pvchENTC_EMail = '';

    if (valores.web != null) {
      requestInsertaCliente.pvchENTC_Web = valores.web;
    } else {
      requestInsertaCliente.pvchENTC_Web = '';
    }

    requestInsertaCliente.pbitENTC_Propio = false;

    if (valores.telefonoFijo != null) {
      requestInsertaCliente.pvchENTC_Telef1 = valores.telefonoFijo;
    } else {
      requestInsertaCliente.pvchENTC_Telef1 = '';
    }

    requestInsertaCliente.pvchENTC_Telef2 = '';
    requestInsertaCliente.pvchENTC_Telef3 = '';
    requestInsertaCliente.pvchENTC_Telef4 = '';
    requestInsertaCliente.pvchENTC_Beneficiario = '';
    requestInsertaCliente.pvchUSER_CodUsr = '';


    requestInsertaCliente.pchrCONS_CodTFT = '',
    requestInsertaCliente.pchrCONS_TabTFT = '',
    requestInsertaCliente.pchrTIPO_TabCiu = '',
    requestInsertaCliente.pchrTIPO_CodCiu = '',
    requestInsertaCliente.pbitENTC_Prospecto = false,
    requestInsertaCliente.pchrTIPO_TabDTM = '';
    requestInsertaCliente.pchrTIPO_CodDTM = '';
    requestInsertaCliente.pdecENTC_ValRebate = '';
    requestInsertaCliente.pchrTIPO_TabMnd = '';
    requestInsertaCliente.pchrTIPO_CodMnd = '';
    requestInsertaCliente.pdecENTC_ValTarja = '';

    if (valores.diasCredito != null) {
      requestInsertaCliente.psinENTC_DiasCredito = valores.diasCredito;
    } else {
      requestInsertaCliente.psinENTC_DiasCredito = '0';
    }

    if (valores['fechaCredito'] != null) {
      requestInsertaCliente.pdtmENTC_FechaCredito = valores['fechaCredito'];
    } else {
      requestInsertaCliente.pdtmENTC_FechaCredito = '';
    }
    requestInsertaCliente.pbitENTC_Tarja = false;
    requestInsertaCliente.pvchENTC_CodAduana = '';
    requestInsertaCliente.pchrCONS_CodCre = '';
    requestInsertaCliente.pchrCONS_TabCre = '';

    if (valores.correoContratoMandato != null) {
      requestInsertaCliente.pvchENTC_EMail2 = valores.correoContratoMandato;
    } else {
      this.util.showMessage('Falta el correo de Contraro Mandato')
    }

    if (valores.observaciones != null) {
      requestInsertaCliente.pvchENTC_Obser = valores.observaciones;
    } else {
      requestInsertaCliente.pvchENTC_Obser = '';
    }

    requestInsertaCliente.pvchENTC_InterbancarioSol = '';
    requestInsertaCliente.pvchENTC_InterbancarioDol = '';
    requestInsertaCliente.pvchENTC_CuentaDetraccion = '';

    if (valores.servicioLogistico != null) {
      requestInsertaCliente.pbitENTC_ServicioLogistico = valores.servicioLogistico.value;
    } else {
      requestInsertaCliente.pbitENTC_ServicioLogistico = false;
    }

    if (valores.ocultaShipper != null) {
      requestInsertaCliente.pbitENTC_OcultarShipper = valores.ocultaShipper.value;
    } else {
      requestInsertaCliente.pbitENTC_OcultarShipper = false;
    }
    requestInsertaCliente.pvchENTC_ObsFinanzasCliente = '';
    requestInsertaCliente.pbitENTC_BloqueoFinanzasCliente = false;
    requestInsertaCliente.pvchENTC_UsrBloqueoCliente = '';
    requestInsertaCliente.pdtmENTC_FecBloqueoCliente = '';

    if (valores.correoFacturacion != null) {
      requestInsertaCliente.pvchENTC_EmailFinanzas = valores.correoFacturacion;
    } else {
      this.util.showMessage('Falta el correo de Facturacion')
    }
    requestInsertaCliente.pvchENTC_ObsBloqueoCliente = '',
      requestInsertaCliente.pbitENTC_OtrosTraficos = false,
      requestInsertaCliente.pbitENTC_Mandato = false,
      requestInsertaCliente.pbitENTC_SLI = false,
      requestInsertaCliente.pbitENTC_Exportacion = false,
      requestInsertaCliente.pvchENTC_UsrBloqueoProveedor = '',
      requestInsertaCliente.pdtmENTC_FecBloqueoProveedor = '',
      requestInsertaCliente.pbitENTC_BloqueoFinanzasProveedor = false,
      requestInsertaCliente.pvchENTC_ObsFinanzasProveedor = '',
      requestInsertaCliente.pvchENTC_ObsBloqueoProveedor = '',
      requestInsertaCliente.pbitENTC_ProvAsumeDetraccion = false,
      requestInsertaCliente.psmlTIPE_Codigo = '1';
    requestInsertaCliente.psinDIRE_Codigo = '0';
    if (valores.direccionFiscal != null) {
      requestInsertaCliente.pvchDIRE_Direccion = valores.direccionFiscal;
    } else {
      requestInsertaCliente.pvchDIRE_Direccion = '';
    }
    requestInsertaCliente.pvchUBIG_Codigo = '15.01.20'; // ?

    if (valores.pais1 != null) {
      requestInsertaCliente.pchrTIPO_TabPais = valores.pais1.nombre;
      requestInsertaCliente.pchrTIPO_CodPais = valores.pais1.nombre;
    } else {
      requestInsertaCliente.pchrTIPO_TabPais = '';
      requestInsertaCliente.pchrTIPO_CodPais = '000';
    }
    if (valores.direccionComercial != null) {
      requestInsertaCliente.pvchDIRE_DireccionComercial = valores.direccionComercial;
    } else {
      requestInsertaCliente.pvchDIRE_DireccionComercial = '';
    }

    requestInsertaCliente.psinDIRE_CodigoComercial = '';
    requestInsertaCliente.pvchUBIG_CodigoComercial = '';

    if (valores.toOrder != null) {
      requestInsertaCliente.pbitENTC_ToOrder = valores.toOrder;
    } else {
      requestInsertaCliente.pbitENTC_ToOrder = false;
    }
    requestInsertaCliente.pvchAUDI_UsrCrea = 'ext_ichumpitaz',
      requestInsertaCliente.pchrTIPO_CodSEG = '',
      requestInsertaCliente.pchrENTC_NombreContacto = '',
      requestInsertaCliente.pchrENTC_ApellidoContacto = '',
      requestInsertaCliente.pchrENTC_CorreoContactoExpo = '',
      requestInsertaCliente.pchrENTC_FecNacGerente = '',
      requestInsertaCliente.pvchENTC_EmailReceptorFE = '',
      requestInsertaCliente.pchrENTC_Origen = 'TDI',
      requestInsertaCliente.pchrENTC_FecAniGerente = ''

    this.proformaService.insertaCliente(requestInsertaCliente).subscribe(response => {
      if (response.datos.status === 200) {

        this.util.showMessage("Guardado correctamente")

      }


    }, (errorServicio) => {
      this.util.showMessage(errorServicio.error.meta.mensaje);
    });
  }

  razonSocial: string
  distrito: string
  direccion: string
  cargaSunat() {
    const values = this.groupDatos.value
    const requestSunat: sunatRequest = <sunatRequest>{}
    const razonSocialControl = this.groupDatos.get('razonSocial');
    const rucControl = this.groupDatos.get('razonComercial');
    const direccionCControl = this.groupDatos.get('direccionComercial');
    const direccionFControl = this.groupDatos.get('direccionFiscal');
    requestSunat.razonsocial = values["razonSocial"]
    requestSunat.ruc = values["nroDocumento"]

    this.proformaService.listarSunat(requestSunat).subscribe(response => {
      if (response.datos.result.length > 0) {
        this.razonSocial = response.datos.result[0].nombre_o_razon_social
        this.distrito = response.datos.result[0].direccion_completa
    
        razonSocialControl.setValue(this.razonSocial);
        rucControl.setValue(this.razonSocial);
        direccionCControl.setValue(this.distrito);
        direccionFControl.setValue(this.distrito);
     
      } else {
       
      }
    },
  
    )
  }

  //LIMPIAR CONTROLES
  limpiar(value) {
    this.groupDatos.get(value).reset();
  }

  mantenimientoCliente() {

    const values = this.groupDatos.value
    const requestCliente: insertaCienteRequest = <insertaCienteRequest>{}

    requestCliente.pintENTC_Codigo = values['correoContratoMandato']
    requestCliente.pchrENTC_TipEnt = values['tipoDocumento']
    requestCliente.pvchENTC_DocIden = values['nroDocumento']
    requestCliente.pvchENTC_RazonSocial = values['razonSocial']
    requestCliente.pvchENTC_NomComercial = values['razonComercial']
    requestCliente.pbitENTC_Mandato = values['correoContratoMandato']
    requestCliente.pbitENTC_OtrosTraficos = values['correoOtrosTraficos']
    requestCliente.pvchENTC_NomCompleto = values['nombreGerente']
    requestCliente.pdtmENTC_FecNac = values['fechaNacimientoGerente']
    //fecha aniversario
    requestCliente.pchrENTC_NombreContacto = values['contactoNombre']
    requestCliente.pchrENTC_ApellidoContacto = values['apellidos']
    requestCliente.pchrENTC_CorreoContactoExpo = values['correoExportaciones']
    requestCliente.pvchENTC_EmailFinanzas = values['correoFacturacion']
    requestCliente.pvchENTC_Telef1 = values['telefonoFijo']
    requestCliente.pvchENTC_Telef2 = values['telefonoMovil']
    requestCliente.pvchDIRE_Direccion = values['direccionFiscal']
 
    requestCliente.pvchDIRE_DireccionComercial = values['direccionComercial']
    requestCliente.pvchENTC_Obser = values['observaciones']

    this.proformaService.insertaCliente(requestCliente).subscribe(response => {

      this.util.showMessage("Guardado correctamente")
    });

  }

  updatecliente() {

    const codusuario = this.storage.get('codusuario')

    const values = this.groupDatos.value
    const requestcliente: requestupdatecliente = <requestupdatecliente>{}

    requestcliente.ENTC_CODIGO = this.codcliente

    requestcliente.ENTC_EMail = values['correoContratoMandato']
    requestcliente.ENTC_Web = values['web']
    requestcliente.entc_telef1 = values['telefonoMovil']
    requestcliente.entc_telef2 = values['telefonoFijo']
    requestcliente.ENTC_CORREOCONTACTOEXPO = values['correoExportaciones']
    requestcliente.ENTC_APELLIDOCONTACTO = values['apellidos']
    requestcliente.ENTC_NOMBRECONTACTO = values['contactoNombre']
    requestcliente.TIPO_ENTIDAD = 1
    requestcliente.cod_Usuario = codusuario



    this.proformaService.updatecliente(requestcliente).subscribe(response => {

      if (response.datos.status === 200) {

        this.util.showMessage("Actualizado correctamente")

      }


    }, (errorServicio) => {

      this.util.showMessage(errorServicio.error.meta.mensaje);


    });

  }

  salir() {

    this.router.navigate(['/MantClientes']);
  }

  clear() {
    this.groupDatos.reset({ estadoPersona: 'N' })
  }

}

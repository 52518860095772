import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alertdialog',
  templateUrl: './alertdialog.component.html',
  styleUrls: ['./alertdialog.component.css']
})
export class AlertdialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  information: boolean = false
  viewDetail: boolean = false
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.information = this.data.detalle == '' ? false : true
    console.log(this.information)
  }
  view() {
    this.viewDetail = !this.viewDetail
  }
}

<form [formGroup]="groupDatos" class="groupDatos">
  <div class="container">
  
  <!--  <mat-form-field class="example-full-width-alterno3" appearance="outline">
      <mat-label>Nave</mat-label>
      <input
      type="text"
      matInput
      formControlName="nave"
      [matAutocomplete]="autoTransportista"
  
    >
    <mat-autocomplete #autoTransportista="matAutocomplete" (optionSelected)="opcionSeleccionada($event)">
      <ng-container *ngFor="let option of listadonaves">
        <mat-option [value]="option.nave_codigo" *ngIf="option.nave_nombre?.toLowerCase().includes(textoFiltro?.toLowerCase())">
          {{ option.nave_nombre }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    </mat-form-field>
-->

<mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
  <mat-label>Nave</mat-label>
  <mat-select  formControlName="nave">
    <mat-option *ngFor="let reg of listadonaves" [value]="reg.nave_codigo">{{reg.nave_nombre}}</mat-option>
  </mat-select>
</mat-form-field>
   
   
   
    <mat-form-field class="example-full-width-alterno3" appearance="outline" class="input">
      <mat-label>ETD</mat-label>
      <input matInput formControlName="etd" [matDatepicker]="picker4" autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
      <mat-datepicker #picker4 color="primary"></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="container">
    <mat-form-field class="example-full-width-alterno3" appearance="outline" class="input">
      <mat-label>ETA</mat-label>
      <input matInput formControlName="eta" [matDatepicker]="picker1" autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1 color="primary"></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="example-full-width-alterno3" appearance="outline" class="input">
      <input matInput type="number" min="0" placeholder="Tiempo Transito" formControlName="tiempotransito">
    </mat-form-field>
  </div>
  <div class="container">
    <mat-form-field class="example-full-width-alterno3" appearance="outline" class="input">
      <mat-label>Cut Off</mat-label>
      <input matInput formControlName="cutOff" [matDatepicker]="picker3" autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
      <mat-datepicker #picker3 color="primary"></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="container">
    <mat-form-field class="example-full-width-alterno3" appearance="outline" class="input">
      <mat-label>Dias Libres Sugerido</mat-label>
      <input matInput type="number" placeholder="Monto Ajuste" min="0" formControlName="diaslibres">
    </mat-form-field>
  </div>

  <div class="container">
    <mat-form-field class="example-full-width-alterno3" appearance="outline" class="input">
      <mat-label>OWS</mat-label>
      <input matInput type="number" min="0" placeholder="OWS" formControlName="ows">
    </mat-form-field>
  </div>


  <div class="container">
    <mat-form-field class="example-full-width-alterno3" appearance="outline" class="input">
      <mat-label>Monto Sugerido</mat-label>
      <input matInput type="number" placeholder="Monto Ajuste" formControlName="monto">
    </mat-form-field>
  </div>

  <div class="container"  *ngIf="mostrarcontrol">
    <mat-form-field class="example-full-width-alterno3" appearance="outline" class="input">
      <mat-label>Costo unitario</mat-label>
      <input matInput type="number" placeholder="Costo" formControlName="costo">
    </mat-form-field>
  </div>

  <div class="container"  *ngIf="mostrarcontrol">
  
      <mat-checkbox  #checkboxstatus ng-model="checked"  formControlName = "autorizar">Autorizar Ajuste</mat-checkbox>
  
  </div>




  <div class="container_botones">
    <button mat-stroked-button class="button" (click)="closeModal()">Guardar</button>
    <button mat-stroked-button class="button" (click)="closeModal()">Salir</button>
  </div>
</form>

import { ListaResumenMensualComercial, ResumenMensualComercial02Response, ResumenMensualComercialResponse } from './../ResumenMenusalComercial/domain/response/resumenMensualComercial.response';
import { requestCierreMensual, requestcierremensualfinal, requestClientesRecuperados, requestPricingAtendido, requestreportecomercialxmes, requesttodoslosanios } from './../dashboardVentas/domain/request/dashboard.request';
import { Observable } from "rxjs";
import { requestSeguimientoCarga } from "../dashboardVentas/domain/request/dashboard.request";
import { ClientesRecuperadosResponse, ListaPricingResumenResponse, ListaPricingTablaResponse, ListarCierreMensual, ListarCierreMensualResponse, ListaSeguimientoCargaResponse, ListaTodosAniosResponse, registrarcierremensualfinalresponse } from "../dashboardVentas/domain/response/dashboard.response";
import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DashBoardRepository } from "../dashboardVentas/domain/dashboard.repository";
import { environment } from "src/environments/environment";
import { Response_ExComar, listaExcomarFechas_Response } from '../excomar/domain/response/excomar-response';
import { Request_ExComar, listaExcomarFechas_Request } from '../excomar/domain/request/excomar-request';
import { requestRentabilidadComercial } from '../dashboard-comercial-rentabilidad/domain/request/request-rentabilidad-comercial';
import { RentabilidadComercialResponse, RentabilidadComercialResponse2, RentabilidadComercialResponse3, RentabilidadComercialResponse6 } from '../dashboard-comercial-rentabilidad/domain/response/response-rentabilidad-comercial';
import { requestProvicion, requestProvicionOrigen } from '../dashboard-provicion-origen/domain/request/request-provicion-origen';
import { ProvicionOrigenResponse, ProvicionOrigenResponse2, ProvicionOrigenResponse3, ProvicionOrigenResponse4, ProvicionOrigenResponse5, ProvicionOrigenResponse7, reporteProvicionResponse, reporteProvicionResponse2, resposereportecomercialmes } from '../dashboard-provicion-origen/domain/response/response-provicion-origen';
import { requestReporteRentabilidad } from '../reporte-rentabilidad-lnegocio/domain/request/reporte-rentabilidad-lnegocio-request';
import { ListaRentabilidadResumenTablaTR, ListaReporteRentabilidadResponse, ListaReporteRentabilidadxMesResponse, ListaReporteRentabilidadxMesResponseTR } from '../reporte-rentabilidad-lnegocio/domain/response/reporte-rentabilidad-lnegocio-response';
import { ClienteProfitResponse } from '../reporte-cliente-profit/domain/response/cliente-profit-response';
import { ClienteProfitRequest } from '../reporte-cliente-profit/domain/request/cliente-profit-request';
import { ListaConsignarioResponse, ListaConsignatarioExcomarvsDelfinTotalesResponse, ListarankingxconsignatarioResponse, ListatiporankingxconsignatarioResponse, listaConsignatariosDelfinvsExcomarResponse, listaExcomarFinalesResponse } from '../dashboard-gestion-excomar/domain/response/gestion-excomar-response';
import { ListaConsignarioRequest, ListarankingxconsignatarioRequest } from '../dashboard-gestion-excomar/domain/request/gestion-excomar-request';
import { requestRepRentPorEjecutivo } from '../reporte-rentaxejecutivo/domain/request/repRentPorEjecutivo.request';
import { ListaRepRentPorEjecutivoResponse, responselistaEntidad, responselistpaises } from '../reporte-rentaxejecutivo/domain/response/repRentPorEjecutivo.response';
import { requestlistatracking } from '../tracking/domain/request/trackingrequest';
import { responselistatracking, responselistatrackingtabla } from '../tracking/domain/response/trackingresponse';
import { ListaejecutivosTeusCerradosRequest } from '../dashboard-teus-cerrados/domain/request/teuscerradosRequest';
import { ListaeTeusLineaResponse, ListaejecutivosTeusCerradosResponse } from '../dashboard-teus-cerrados/domain/response/teuscerradosResponse';
import { fasesPorOv_Response } from '../fasesPorOv/domain/response/fasesPorOv.response';
import { fasesPorOvRequest } from '../fasesPorOv/domain/request/fasesPorOv.request';
import { TablaReporteServiciosLocalesRequest } from '../reporte-servicios-locales/domain/request/reporte-servicio-local-request';
import { TablaReporteServiciosLocales03Response, TablaReporteServiciosLocales04Response, TablaReporteServiciosLocalesResponse } from '../reporte-servicios-locales/domain/response/reporte-servicio-local-response';
import { ResumenMensualComercialRequest } from '../ResumenMenusalComercial/domain/request/resumenMensualComercial.request';
import { listareporteunoRequest, reporteunoRequest } from '../reporte-uno/domain/request/reporte-uno-request';
import { ListadoAvisoEspacioResponse, registroreporteunoResponse } from '../reporte-uno/domain/response/reporte-uno-response';
import { ReporteListaCtnrAlmacenConsolidadoRequest, ReporteListaCtnrAlmacenRequest } from '../reporte-ctnr-almacen/domain/request/reporte-ctnr-almacen-request';
import { GraficoGestionSliResponse, GraficoGestionSliTipo8Response, ReporteListaCtnrAlmacenConsolidadoResponse, ReporteListaCtnrAlmacenResponse } from '../reporte-ctnr-almacen/domain/response/reporte-ctnr-almacen-response';
import { ReporteClienteContactoResponse } from '../reporte-cliente-contacto/domain/response/reporte-cliente-contacto-response';
import { ReporteClienteContactoRequest } from '../reporte-cliente-contacto/domain/request/reporte-cliente-contacto-request';
import { InsertarPlantillasAcosIIRequest, InsertarPlantillasAcosRequest, ListarAcosRequest, ValidarAcosImportacionRequest } from '../reporte-acos/domain/request/reporte-acos-request';
import { InsertarPlantillasAcosResponse, ListarAcosResponse, ValidarAcosImportacionResponse } from '../reporte-acos/domain/response/reporte-acos-response';
import { DashboardSoporteRequest } from '../dashboard-soporte/domain/request/dashboard-soporte-request';
import { DashboardSoporteObservacionesResponse, DashboardSoporteResponse, DashboardSoporteResponse2 } from '../dashboard-soporte/domain/response/dashboard-soporte-response';
import { listarReporteNivelesCreditoResponse } from '../dashboard-finanzas/domain/response/dashboard-finanzas-response';
import { listarReporteNivelesCreditoRequest } from '../dashboard-finanzas/domain/request/dashboard-finanzas-request';
import { listaTransmisionesRequest } from '../dashboard-transmision/domain/request/dashboard-transmision-request';
import { listaDetalleTransmisionResponse, listaTransmisionResponse } from '../dashboard-transmision/domain/response/dashboard-transmision-response';
import { listaStatmentRequest } from '../dashboard-statement/domain/request/dashboard-statement-request';
import { listaDetalleStatmentResponse, listaStatmentResponse } from '../dashboard-statement/domain/response/dashboard-statement-response';
import { AvisoLlegadaRequest } from '../dashboard-aviso-llegada/domain/request/dashboard-aviso-llegada-request';
import { AvisoLlegadaResponse, DetalleAvisoLlegadaResponse } from '../dashboard-aviso-llegada/domain/response/dashboard-aviso-llegada-response';
import { DesgloseRequest } from '../dashboard-desglose/domain/request/dashboard-desglose-request';
import { DesgloseResponse, DetalleDesgloseResponse } from '../dashboard-desglose/domain/response/dashboard-desglose-response';
import { DireccionamientoRequest } from '../dashboard-direccionamiento/domain/request/dashboard-direccionamiento-request';
import { DetalleDireccionamientoResponse, DireccionamientoResponse } from '../dashboard-direccionamiento/domain/response/dashboard-direccionamiento-response';
// import { RentabilidadComercialResponse2 } from '../dashboard-comercial-rentabilidad/domain/response/response-rentabilidad-comercial';
// import { requestRentabilidadComercial } from '../dashboard-comercial-rentabilidad/domain/request/request-rentabilidad-comercial';
// import { RentabilidadComercialResponse, RentabilidadComercialResponse2 } from '../dashboard-comercial-rentabilidad/domain/response/response-rentabilidad-comercial';


@Injectable()
export class DashBoardService extends DashBoardRepository {

    constructor(private readonly http: HttpClient,
        private readonly storage: StorageService

    ) {

        super();
    }

    listaSeguimientoCarga(prolrequest: requestSeguimientoCarga): Observable<ListaSeguimientoCargaResponse> {
        return this.http.post<ListaSeguimientoCargaResponse>(`${environment.PATH_API}/dashboard/seguimientoCarga`, prolrequest);
    }

    listaTodosAnios(request: requesttodoslosanios): Observable<ListaTodosAniosResponse> {
        return this.http.post<ListaTodosAniosResponse>(`${environment.PATH_API}/dashboard/todosAnios`, request);
    }

    listaPricingTabla(prolrequest: requestPricingAtendido): Observable<ListaPricingTablaResponse> {
        return this.http.post<ListaPricingTablaResponse>(`${environment.PATH_API}/dashboard/pricingCotizacionesAtendidasTabla`, prolrequest);
    }

    listaClientesRecuperados(prolrequest: requestClientesRecuperados): Observable<ClientesRecuperadosResponse> {
        return this.http.post<ClientesRecuperadosResponse>(`${environment.PATH_API}/reportes/clientesRecuperados`, prolrequest);
    }

    insertarExcomar(prolrequest: Request_ExComar): Observable<Response_ExComar> {
        return this.http.post<Response_ExComar>(`${environment.PATH_API}/reportes/guardaDatosExcomar`, prolrequest)
    }

    listaExcomarFechas(prolrequest: listaExcomarFechas_Request): Observable<listaExcomarFechas_Response> {
        return this.http.post<listaExcomarFechas_Response>(`${environment.PATH_API}/reportes/listarDatosExcomar`, prolrequest)
    }
    // listaClientesRecuperados(prolrequest:requestClientesRecuperados):Observable<ClientesRecuperadosResponse>
    // {
    //     return this.http.post<ClientesRecuperadosResponse>(`${environment.PATH_API}/reportes/clientesRecuperados`,prolrequest);
    // }

    listaRentabilidadComercial2(prolrequest: requestRentabilidadComercial): Observable<RentabilidadComercialResponse2> {
        return this.http.post<RentabilidadComercialResponse2>(`${environment.PATH_API}/dashboard/rentabilidadComercial2`, prolrequest);
    }

    listaRentabilidadComercial3(prolrequest: requestRentabilidadComercial): Observable<RentabilidadComercialResponse3> {
        return this.http.post<RentabilidadComercialResponse3>(`${environment.PATH_API}/dashboard/rentabilidadComercial3`, prolrequest);
    }


    listaRentabilidadComercial6(prolrequest: requestRentabilidadComercial): Observable<RentabilidadComercialResponse6> {
        return this.http.post<RentabilidadComercialResponse6>(`${environment.PATH_API}/dashboard/rentabilidadComercial6`, prolrequest);
    }

    listaProvicionOrigen(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse> {
        return this.http.post<ProvicionOrigenResponse>(`${environment.PATH_API}/dashboard/provicionOrigen`, prolrequest);
    }

    listaProvicionOrigen3(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse3> {
        return this.http.post<ProvicionOrigenResponse3>(`${environment.PATH_API}/dashboard/provicionOrigen3`, prolrequest);
    }

    listaProvicionOrigen5(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse5> {
        return this.http.post<ProvicionOrigenResponse5>(`${environment.PATH_API}/dashboard/provicionOrigen5`, prolrequest);
    }
    listaProvicionOrigen4(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse4> {
        return this.http.post<ProvicionOrigenResponse4>(`${environment.PATH_API}/dashboard/provicionOrigen4`, prolrequest);
    }
    listaProvicionOrigen2(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse2> {
        return this.http.post<ProvicionOrigenResponse2>(`${environment.PATH_API}/dashboard/provicionOrigen2`, prolrequest);
    }
    listaProvicionOrigen7(prolrequest: requestProvicionOrigen): Observable<ProvicionOrigenResponse7> {
        return this.http.post<ProvicionOrigenResponse7>(`${environment.PATH_API}/dashboard/provicionOrigen7`, prolrequest);
    }

    //NUEVO GRAFICO DE REPORTE PROVICION
    listaReporteProvicion(prolrequest: requestProvicion): Observable<reporteProvicionResponse> {
        return this.http.post<reporteProvicionResponse>(`${environment.PATH_API}/reporteG/reporteProvicion`, prolrequest);
    }
    listaReporteProvicion2(prolrequest: requestProvicion): Observable<reporteProvicionResponse2> {
        return this.http.post<reporteProvicionResponse2>(`${environment.PATH_API}/reporteG/reporteProvicion2`, prolrequest);
    }
    //FIN GRAFICO DE REPORTE PROVICION (AURICH)

    listaReporteRentabilidad(prolrequest: requestReporteRentabilidad): Observable<ListaReporteRentabilidadResponse> {
        return this.http.post<ListaReporteRentabilidadResponse>(`${environment.PATH_API}/dashboard/ReporteLineaNegocioTabla`, prolrequest);
    }

    listaReporteRentabilidadxMes(prolrequest: requestReporteRentabilidad): Observable<ListaReporteRentabilidadxMesResponse> {
        return this.http.post<ListaReporteRentabilidadxMesResponse>(`${environment.PATH_API}/dashboard/ReporteLineaNegocioTablaxMes`, prolrequest);
    }

    listaReporteRentabilidadxMes3(prolrequest: requestReporteRentabilidad): Observable<ListaReporteRentabilidadxMesResponseTR> {
        return this.http.post<ListaReporteRentabilidadxMesResponseTR>(`${environment.PATH_API}/dashboard/ReporteLineaNegocioTablaxMes3`, prolrequest);
    }

    listaReporteRentabilidadxMes4(prolrequest: requestReporteRentabilidad): Observable<ListaReporteRentabilidadxMesResponseTR> {
        return this.http.post<ListaReporteRentabilidadxMesResponseTR>(`${environment.PATH_API}/dashboard/ReporteLineaNegocioTablaxMes4`, prolrequest);
    }

    listareportecomercialxmes(prolrequest: requestreportecomercialxmes): Observable<resposereportecomercialmes> {
        return this.http.post<resposereportecomercialmes>(`${environment.PATH_API}/dashboard/reportecomercialteusxmes`, prolrequest);
    }

    listaReporteClienteProfit(prolrequest: ClienteProfitRequest): Observable<ClienteProfitResponse> {
        return this.http.post<ClienteProfitResponse>(`${environment.PATH_API}/reportes/clienteProfit`, prolrequest)
    }

    listarconsignatarios(prolrequest: ListaConsignarioRequest): Observable<ListaConsignarioResponse> {
        return this.http.post<ListaConsignarioResponse>(`${environment.PATH_API}/excomar/listaConsignatarios`, prolrequest);
    }

    listaRankingxConsignatarios(prolrequest: ListarankingxconsignatarioRequest): Observable<ListarankingxconsignatarioResponse> {
        return this.http.post<ListarankingxconsignatarioResponse>(`${environment.PATH_API}/excomar/listaRankingxConsignatarios`, prolrequest);
    }

    listaTipoRankingxConsignatarios(prolrequest: ListarankingxconsignatarioRequest): Observable<ListatiporankingxconsignatarioResponse> {
        return this.http.post<ListatiporankingxconsignatarioResponse>(`${environment.PATH_API}/excomar/listaTipoRankingxConsignatarios`, prolrequest);
    }

    listaConsignatariosDelfinvsExcomar(prolrequest: ListarankingxconsignatarioRequest): Observable<listaConsignatariosDelfinvsExcomarResponse> {
        return this.http.post<listaConsignatariosDelfinvsExcomarResponse>(`${environment.PATH_API}/excomar/ListadoConsignatariosDelfinvsExcomar`, prolrequest);
    }

    listaExcomarfinales(prolrequest: ListarankingxconsignatarioRequest): Observable<listaExcomarFinalesResponse> {
        return this.http.post<listaExcomarFinalesResponse>(`${environment.PATH_API}/excomar/ListadoConsignatariosDelfinvsExcomar`, prolrequest);
    }

    ListaConsignatarioExcomarvsDelfinTotales(prolrequest: ListarankingxconsignatarioRequest): Observable<ListaConsignatarioExcomarvsDelfinTotalesResponse> {
        return this.http.post<ListaConsignatarioExcomarvsDelfinTotalesResponse>(`${environment.PATH_API}/excomar/ListadoConsignatarioExcomarvsDelfinTotales`, prolrequest);
    }

    listaRepRentPorEjecutivo(prolrequest: requestRepRentPorEjecutivo): Observable<ListaRepRentPorEjecutivoResponse> {
        return this.http.post<ListaRepRentPorEjecutivoResponse>(`${environment.PATH_API}/reportes/reporteRentPorEjecutivo`, prolrequest);
    }


    listapaises(prolrequest: ListarankingxconsignatarioRequest): Observable<responselistpaises> {
        return this.http.post<responselistpaises>(`${environment.PATH_API}/excomar/listapaises`, prolrequest);
    }

    listaEntidad(prolrequest: ListarankingxconsignatarioRequest): Observable<responselistaEntidad> {
        return this.http.post<responselistaEntidad>(`${environment.PATH_API}/excomar/listaEntidad`, prolrequest);
    }

    Listatracking(prolrequest: requestlistatracking): Observable<responselistatracking> {
        return this.http.post<responselistatracking>(`${environment.PATH_API}/tracking/listatracking`, prolrequest);
    }

    Listatrackingtabla(prolrequest: requestlistatracking): Observable<responselistatrackingtabla> {
        return this.http.post<responselistatrackingtabla>(`${environment.PATH_API}/tracking/listatrackingtabla`, prolrequest);
    }

    ListaejecutivosTeusCerrados(prolrequest: ListaejecutivosTeusCerradosRequest): Observable<ListaejecutivosTeusCerradosResponse> {
        return this.http.post<ListaejecutivosTeusCerradosResponse>(`${environment.PATH_API}/dashboard/DashboardTeusCerrados`, prolrequest);
    }

    ListateusLinea(prolrequest: ListaejecutivosTeusCerradosRequest): Observable<ListaeTeusLineaResponse> {
        return this.http.post<ListaeTeusLineaResponse>(`${environment.PATH_API}/dashboard/DashboardTeusCerrados`, prolrequest);
    }

    ListaFasesOvCustomer(prolrequest: fasesPorOvRequest): Observable<fasesPorOv_Response> {
        return this.http.post<fasesPorOv_Response>(`${environment.PATH_API}/reporteG/fasesOV`, prolrequest);
    }

    TablaReporteServiciosLocales(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocalesResponse> {
        return this.http.post<TablaReporteServiciosLocalesResponse>(`${environment.PATH_API}/reporteG/tablareportesservicioslocales`, prolrequest);
    }

    TablaReporteServiciosLocales02(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocalesResponse> {
        return this.http.post<TablaReporteServiciosLocalesResponse>(`${environment.PATH_API}/reporteG/tablareportesservicioslocales02`, prolrequest);
    }

    TablaReporteServiciosLocales03(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocales03Response> {
        return this.http.post<TablaReporteServiciosLocales03Response>(`${environment.PATH_API}/reporteG/tablareportesservicioslocales03`, prolrequest);
    }

    ListaResumenMensualComercial(prolrequest: ResumenMensualComercialRequest): Observable<ResumenMensualComercialResponse> {
        return this.http.post<ResumenMensualComercialResponse>(`${environment.PATH_API}/dashboard/resumenMensualComercial`, prolrequest);
    }

    ListaResumenMensualComercial02(prolrequest: ResumenMensualComercialRequest): Observable<ResumenMensualComercial02Response> {
        return this.http.post<ResumenMensualComercial02Response>(`${environment.PATH_API}/dashboard/resumenMensualComercial`, prolrequest);
    }

    InsertarAvisoEspacio(prolrequest: reporteunoRequest): Observable<registroreporteunoResponse> {
        return this.http.post<registroreporteunoResponse>(`${environment.PATH_API}/reporteG/guardarreporteuno`, prolrequest);
    }

    ListadoAvisoEspacio(prolrequest: listareporteunoRequest): Observable<ListadoAvisoEspacioResponse> {
        return this.http.post<ListadoAvisoEspacioResponse>(`${environment.PATH_API}/reporteG/listarsemaforoavisoespacio`, prolrequest);
    }

    TablaReporteServiciosLocales04(prolrequest: TablaReporteServiciosLocalesRequest): Observable<TablaReporteServiciosLocales04Response> {
        return this.http.post<TablaReporteServiciosLocales04Response>(`${environment.PATH_API}/reporteG/tablareportesservicioslocales04`, prolrequest);
    }

    ReporteListaCtnrAlmacen(prolrequest: ReporteListaCtnrAlmacenRequest): Observable<ReporteListaCtnrAlmacenResponse> {
        return this.http.post<ReporteListaCtnrAlmacenResponse>(`${environment.PATH_API}/reporteG/listarreportectnralmacen`, prolrequest);
    }

    ReporteListaCtnrAlmacenNew(prolrequest: ReporteListaCtnrAlmacenConsolidadoRequest): Observable<ReporteListaCtnrAlmacenConsolidadoResponse> {
        return this.http.post<ReporteListaCtnrAlmacenConsolidadoResponse>(`${environment.PATH_API}/reporteG/listarreportectnralmacennew`, prolrequest);
    }

    ReporteClienteContacto(prolrequest: ReporteClienteContactoRequest): Observable<ReporteClienteContactoResponse> {
        return this.http.post<ReporteClienteContactoResponse>(`${environment.PATH_API}/reporteG/listadoclientecontacto`, prolrequest);
    }

    
    GraficoGestionSli(prolrequest: ReporteListaCtnrAlmacenConsolidadoRequest): Observable<GraficoGestionSliResponse> {
        return this.http.post<GraficoGestionSliResponse>(`${environment.PATH_API}/reporteG/graficogestionsli`, prolrequest);
    }

        
    GraficoGestionSliTipo8(prolrequest: ReporteListaCtnrAlmacenConsolidadoRequest): Observable<GraficoGestionSliTipo8Response> {
        return this.http.post<GraficoGestionSliTipo8Response>(`${environment.PATH_API}/reporteG/graficogestionsli`, prolrequest);
    }

    ValidarAcosImportacion(prolrequest: ValidarAcosImportacionRequest): Observable<ValidarAcosImportacionResponse> {
        return this.http.post<ValidarAcosImportacionResponse>(`${environment.PATH_API}/dashboard/validaracosimportacion`, prolrequest);
    }
    
    ListarAcos(prolrequest: ListarAcosRequest): Observable<ListarAcosResponse> {
        return this.http.post<ListarAcosResponse>(`${environment.PATH_API}/dashboard/listaracos`, prolrequest);
    }

    InsertarPlantillasAcos(prolrequest: InsertarPlantillasAcosRequest): Observable<InsertarPlantillasAcosResponse> {
        return this.http.post<InsertarPlantillasAcosResponse>(`${environment.PATH_API}/dashboard/insertarplantillasacos`, prolrequest);
    }

    InsertarPlantillasAcosII(prolrequest: InsertarPlantillasAcosIIRequest): Observable<InsertarPlantillasAcosResponse> {
        return this.http.post<InsertarPlantillasAcosResponse>(`${environment.PATH_API}/dashboard/insertarplantillasacos2`, prolrequest);
    }

    savePlantillaAcosEjecutivos(prolrequest: InsertarPlantillasAcosIIRequest): Observable<InsertarPlantillasAcosResponse> {
        return this.http.post<InsertarPlantillasAcosResponse>(`${environment.PATH_API}/dashboard/savePlantillaAcosEjecutivos`, prolrequest);
    }

    DashboardSoporte(prolrequest: DashboardSoporteRequest): Observable<DashboardSoporteResponse> {
        return this.http.post<DashboardSoporteResponse>(`${environment.PATH_API}/dashboard/dashboardsoporte`, prolrequest);
    }
    DashboardSoporte2(prolrequest: DashboardSoporteRequest): Observable<DashboardSoporteResponse2> {
        return this.http.post<DashboardSoporteResponse2>(`${environment.PATH_API}/dashboard/dashboardsoporte`, prolrequest);
    }

    DashboardSoporteObservaciones(prolrequest: DashboardSoporteRequest): Observable<DashboardSoporteObservacionesResponse> {
        return this.http.post<DashboardSoporteObservacionesResponse>(`${environment.PATH_API}/dashboard/dashboardsoporteobservaciones`, prolrequest);
    }
    
    confimarcierremensual(prolrequest: requestcierremensualfinal): Observable<registrarcierremensualfinalresponse> {
        return this.http.post<registrarcierremensualfinalresponse>(`${environment.PATH_API}/comisiones/registrarcierreproceso`, prolrequest);
    }


    listarcierremensual(prolrequest: requestCierreMensual): Observable<registrarcierremensualfinalresponse> {
        return this.http.post<registrarcierremensualfinalresponse>(`${environment.PATH_API}/dashboard/listarcierremensual`, prolrequest);
    }

    listaCierreMensual(prolrequest: requestCierreMensual):Observable<ListarCierreMensualResponse>{
        return this.http.post<ListarCierreMensualResponse>(`${environment.PATH_API}/reporteG/listarcierremensual`, prolrequest);
    }

      
  listaPricingResumen(prolrequest: requestPricingAtendido):Observable<ListaPricingResumenResponse>{
    return this.http.post<ListaPricingResumenResponse>(`${environment.PATH_API}/dashboard/pricingCotizacionesAtendidasResumen`, prolrequest);
}
listaReporteNivelesCredito(prolrequest: listarReporteNivelesCreditoRequest):Observable<listarReporteNivelesCreditoResponse>{
    return this.http.post<listarReporteNivelesCreditoResponse>(`${environment.PATH_API}/reporteG/listarReporteNivelesCredito`, prolrequest);
}
listarReporteTransmision(prolrequest: listaTransmisionesRequest): Observable<listaTransmisionResponse> {
    return this.http.post<listaTransmisionResponse>(`${environment.PATH_API}/operaciones/IndicadoresTransmision`, prolrequest);
}
listarReporteDetalleTransmision(prolrequest: listaTransmisionesRequest): Observable<listaDetalleTransmisionResponse> {
    return this.http.post<listaDetalleTransmisionResponse>(`${environment.PATH_API}/operaciones/IndicadoresTransmision`, prolrequest);
}
listarReporteStatment(prolrequest: listaStatmentRequest): Observable<listaStatmentResponse> {
    return this.http.post<listaStatmentResponse>(`${environment.PATH_API}/operaciones/IndicadoresStatment`, prolrequest);
}
listarReporteDetalleStatment(prolrequest: listaStatmentRequest): Observable<listaDetalleStatmentResponse> {
    return this.http.post<listaDetalleStatmentResponse>(`${environment.PATH_API}/operaciones/IndicadoresStatment`, prolrequest);
}
listarReporteAvisoLlegada(prolrequest: AvisoLlegadaRequest): Observable<AvisoLlegadaResponse> {
    return this.http.post<AvisoLlegadaResponse>(`${environment.PATH_API}/operaciones/IndicadoresAvisoLlegada`, prolrequest);
}
listarReporteDetalleAvisoLlegada(prolrequest: AvisoLlegadaRequest): Observable<DetalleAvisoLlegadaResponse> {
    return this.http.post<DetalleAvisoLlegadaResponse>(`${environment.PATH_API}/operaciones/IndicadoresAvisoLlegada`, prolrequest);
}
listarReporteDesglose(prolrequest: DesgloseRequest): Observable<DesgloseResponse> {
    return this.http.post<DesgloseResponse>(`${environment.PATH_API}/operaciones/IndicadoresDesglose`, prolrequest);
}
listarReporteDetalleDesglose(prolrequest: DesgloseRequest): Observable<DetalleDesgloseResponse> {
    return this.http.post<DetalleDesgloseResponse>(`${environment.PATH_API}/operaciones/IndicadoresDesglose`, prolrequest);
}
listarReporteDireccionamiento(prolrequest: DireccionamientoRequest): Observable<DireccionamientoResponse> {
    return this.http.post<DireccionamientoResponse>(`${environment.PATH_API}/operaciones/IndicadoresDireccionamiento`, prolrequest);
}
listarReporteDetalleDireccionamiento(prolrequest: DireccionamientoRequest): Observable<DetalleDireccionamientoResponse> {
    return this.http.post<DetalleDireccionamientoResponse>(`${environment.PATH_API}/operaciones/IndicadoresDireccionamiento`, prolrequest);
}
}
<div class="titulo">REGISTRO DE ITINERARIOS</div>
<div class="main">
    <form [formGroup]="group" class="formulario example-full-width-alterno-content-column">
        <div class="example-full-width-alterno-content-row center buttons-mantenimientos">
            <p-button label="Guardar" icon="pi pi-save" styleClass="p-button-info p-button-sm"
                (onClick)="guardar()"></p-button>
            <p-button label="Salir" icon="pi pi-external-link" styleClass="p-button-info p-button-sm"
                (onClick)="salir()"></p-button>
        </div>
        <div class="example-full-width-alterno-content-row">
            <div class="example-full-width-alterno-content-2">
                <span class="p-float-label">
                    <p-dropdown formControlName="anio" [options]="ListaAnios" placeholder="Año" [showClear]="true"
                        optionLabel="nombre" inputId="origenid" [filter]="true" filterBy="nombre"
                        class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Año</label>
                </span>
            </div>
            <div class="example-full-width-alterno-content-2">
                <span class="p-float-label">
                    <p-dropdown formControlName="semana" [options]="ListaSemanas" placeholder="Semana"
                        [showClear]="true" optionLabel="codigo" [filter]="true" filterBy="codigo"
                        class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Semana</label>
                </span>
            </div>

        </div>
        <div class="example-full-width-alterno-content-row">
            <div class="example-full-width-alterno-content-2">
                <span class="p-float-label">
                    <p-dropdown formControlName="servicio" [options]="ListaServicios" placeholder="Servicios"
                        [showClear]="true" optionLabel="nombre" inputId="origenid" [filter]="true" filterBy="nombre"
                        class="p-inputtext-sm" (onChange)="cargarServicio()">
                    </p-dropdown>
                    <label for="float-label">Servicios</label>
                </span>
            </div>
            <div class="example-full-width-alterno-content-2">
                <span class="p-float-label">
                    <p-dropdown formControlName="nave" [options]="ListaNave" placeholder="Nave" [showClear]="true"
                        optionLabel="nombre" inputId="origenid" [filter]="true" filterBy="nombre"
                        class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Nave</label>
                </span>
            </div>
        </div>
        <div class="example-full-width-alterno-content table">
            <p-table [value]="NaveServicio" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr class="header-table">
                        <th>Navieras</th>
                        <th>POL</th>
                        <th>POD</th>
                        <th>Tiempo</th>
                        <th *ngIf="transaccion == 'NUEVO'"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product let-i="rowIndex">
                    <tr class="body-table">
                        <td>{{ product.naviera }}</td>
                        <td>{{ product.origen }}</td>
                        <td>{{ product.destino }}</td>
                        <td>
                            <input class="p-inputtext-sm" type="text" formControlName="transito" pInputText
                                placeholder="Tiempo de tránsito" (input)="onTransitoChange($event, i)"
                                [value]="product.transito" />
                        </td>
                        <td *ngIf="transaccion == 'NUEVO'">
                            <p-checkbox [binary]="true" [inputId]="'checkbox-' + i"
                                (onChange)="onCheckboxChange($event, product)"></p-checkbox>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!-- <div class="example-full-width-alterno-content table">
            <p-table [value]="NavierasServicio" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr class="header-table">
                        <th>Navieras</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product let-i="rowIndex">
                    <tr class="body-table">
                        <td>{{ product.nombre }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="example-full-width-alterno-content table">
            <p-table [value]="DestinosServicio" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr class="header-table">
                        <th>POD</th>
                        <th>Tiempo</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product let-i="rowIndex">
                    <tr class="body-table">
                        <td>{{ product.nombre }}</td>
                        <td><input class="p-inputtext-sm" type="text" formControlName="transito" pInputText
                                placeholder="Tiempo de tránsito" [value]="product.transito" /></td>
                    </tr>
                </ng-template>
            </p-table>
        </div> -->
        <div class="example-full-width-alterno-content-row">
            <div class="example-full-width-alterno-content-2">
                <span class="p-float-label ">
                    <p-calendar formControlName="etd" dateFormat="dd/mm/yy" class="p-inputtext-sm"></p-calendar>
                    <label for="float-label">ETD</label>
                </span>
            </div>
            <div class="example-full-width-alterno-content-2">
                <span class="p-float-label">
                    <input type="text" formControlName="plazo" pInputText class="p-inputtext-sm" />
                    <label for="float-label">Plazos</label>
                </span>
            </div>
        </div>
        <div class="example-full-width-alterno-content-row">
            <div class="example-full-width-alterno-content-2">
                <span class="p-float-label">
                    <input type="text" formControlName="viaje" pInputText class="p-inputtext-sm" />
                    <label for="float-label">Viaje</label>
                </span>
            </div>
        </div>
    </form>
</div>
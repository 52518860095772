import { ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IMenu } from 'src/app/interfaces/menu.interface';
import { MenuService } from 'src/app/services/menu.service';
import { Config, Menu } from '../accordion/accordion/type';
import { MatListModule } from '@angular/material/list'
import { AuthService } from 'src/app/services/auth.service';
import { GroupMenu } from 'src/app/interfaces/tokens.interface';
import { ResponseLogin } from 'src/app/usuario/domain/user-entity';
import { LoginUserCU } from 'src/app/usuario/application/user-logincu';
import { StorageService } from 'src/app/services/storage.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';

interface FoodNode {
  name: string;
  children?: FoodNode[];
  link: string;
  icon: string
}



/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  template: `
    <h1>Opciones Produccion</h1>
    <accordion 
      [options]="options" 
      [menus]="menus">
    </accordion>
  `
})
export class MenuComponent {

  //code
  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      link: node.link, // Agrega el enlace aquí
      icon: node.icon,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  //...

  @Output() toggleSidenavEvent = new EventEmitter<void>();

  nombreUsuario: string;
  toggleSidenav() {
    this.toggleSidenavEvent.emit();
  }

  listMenu: IMenu[] = [];
  @Input() options;
  @Input() menus: GroupMenu[];
  config: Config;

  constructor(
    private readonly menu: MenuService,
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly serviciologin: LoginUserCU,
    private readonly storage: StorageService,
  ) {
    // this.menus = this.menus.map(menu => ({ ...menu, active: false }));

  }

  responselogin: ResponseLogin = <ResponseLogin>{}


  TREE_DATA: FoodNode[] = [];

  ngOnInit(): void {
    // this.menus = this.menus.map(menu => ({ ...menu, active: false }));
    this.nombreUsuario = this.storage.get("usuario")

    this.config = this.mergeConfig(this.options);
    this.listMenu = this.menu.Menu;
    // this.menus = this.menu.menus;
    //  this.menus = this.menus.map(menu => ({ ...menu, active: true}));
    this.responselogin = this.auth.getListaMenu()
    if (this.menus === undefined) {

      this.menus = this.auth.getMenuF5().grupomenu;


    } else {

      this.menus = this.responselogin.datos.result.menu.grupomenu;
    }

    this.menus.forEach(element => {
      element.active = false
    });


    this.TREE_DATA = this.convertirATreeData(this.menus)


    this.dataSource.data = this.TREE_DATA;
  }

  convertirATreeData(data: any[]): FoodNode[] {
    const treeData: FoodNode[] = [];

    // Itera sobre los elementos del array
    data.forEach(item => {
      const node: FoodNode = {
        name: item.title,
        icon: item.icon,
        link: item.link,
        // active: item.active,
      };

      // Si el elemento tiene hijos, conviértelos también
      if (item.children && item.children.length > 0) {
        node.children = this.convertirATreeData(item.children);
      }

      // Agrega el nodo al árbol
      treeData.push(node);
    });

    return treeData;
  }

  mergeConfig(options: Config) {
    // 기본 옵션
    const config = {
      // selector: '#accordion',
      multi: true
    };

    return { ...config, ...options };
  }

  toggle(index: number) {
    // 멀티 오픈을 허용하지 않으면 타깃 이외의 모든 submenu를 클로즈한다.


    if (!this.config.multi) {
      this.menus.filter(
        (menu, i) => i !== index && menu.active
      ).forEach(menu => menu.active = !menu.active);
    }

    // Menu의 active를 반전
    this.menus[index].active = !this.menus[index].active;

  }

  goto(path: string): void {


    if (path != '#') {
      this.router.navigate([path])

      this.toggleSidenavEvent.emit();
    }

  }


  logout() {

    this.serviciologin.logout();

  }




}

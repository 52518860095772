import { ProformaService } from './../../../../services/proforma.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, } from '@angular/forms';
import { UserEntity } from 'src/app/usuario/domain/user-entity';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { CambiaContrasenaComponent } from '../cambiaContrasena/cambiaContrasena.component';
import { requestExisteUsuario } from 'src/app/proforma/domain/request/proforma_request';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  //Captcha
  nombreUsuario:string;
  protected aFormGroup: FormGroup;
  //Modificado
  hide = true;
  color: string = 'red';

  @Output() outputLogin: EventEmitter<UserEntity> = new EventEmitter<UserEntity>();

  group: FormGroup;

  usuario: "";

  constructor(private router: Router, private util: UtilService, private readonly proformaService: ProformaRepository) {}

  

  ngOnInit(): void {
    this.initializeForm();
  }

  //siteKey:string = "6Ld1fEYkAAAAACmZpBYQtpgqldykOgN7m9qU2aku";

  registrar(){
    this.router.navigate(['BandejaItinerarios'])
  }
  initializeForm() {
    this.group = new FormGroup({
      usuario: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  login() {
    const user: UserEntity = this.group.value;
    this.outputLogin.emit(this.group.value);
    // console.log(this.group);
    // console.log(user);
  }

  handleKeyDown(event: any) {
    if (event.keyCode == 13) {
      // action
      this.login();
    }
    else if (event.keyCode == 40) {
      // action
    }
    else if (event.keyCode == 38) {
      // action
    }
  }

  capturar(value) {

    //this.usuario = evt.target.value;
    console.log(value.target.value)
  }
  
  validarUser(){

  }

  OpenModal(value,user) {
    const options = {
      disableClose: true,
      panelClass: 'panel',
      data: user
    };
      
    const reference = this.util.openModal(
      CambiaContrasenaComponent,
      options,
    );
  }

  ingresaUsuario(){
    const value = this.group.value;
    this.nombreUsuario = value.usuario;
  }
}

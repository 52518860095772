import { Observable } from "rxjs";
import { ActualizarEstadoRequest, ActualizarProyectoRequest, ActualizarTareaRequest, AdjuntarArchivoRequest, AprobarProyectoRequest, DescargarArchivoPryRequest, EliminarArchivoPryRequest, InsertarNuevoProyectoRequest, InsertarTareaRequest, ListarProyectosRequest, ListarRecursoGanttRequest, ListarRecursoRequest, ListarTareaRequest, ListarTareasGanttRequest, quitarRecursoRequest, TransaccionTareaGanttRequest } from "./request/gestionProyectos-request";
import { ActualizarEstadoResponse, ActualizarProyectoResponse, ActualizarTareaResponse, AdjuntarArchivoResponse, AprobarProyectoResponse, DescargarArchivoPryResponse, EliminarArchivoPryResponse, InsertarNuevoProyectoResponse, InsertarTareaResponse, ListarProyectosResponse, ListarRecursoResponse, ListarRecursosGanttResponse, ListarTareaResponse, ListarTareasGanttResponse, quitarRecursoResponse } from "./response/gestionProyectos-response";
import { listarAreaRequest } from "src/app/soporte/domain/request/soporte_request";
import { ListarAreaResponse } from "src/app/soporte/domain/response/soporte_response";


export abstract class GestionProyectosRepository {
    abstract insertarNuevoProyecto(prolrequest: InsertarNuevoProyectoRequest): Observable<InsertarNuevoProyectoResponse>
    abstract listarProyectos(prolrequest: ListarProyectosRequest): Observable<ListarProyectosResponse>
    abstract listarAreaPorUsuario(prolrequest: listarAreaRequest): Observable<ListarAreaResponse>
    abstract aprobarProyecto(prolrequest: AprobarProyectoRequest): Observable<AprobarProyectoResponse>
    abstract listarTarea(prolrequest: ListarTareaRequest): Observable<ListarTareaResponse>
    abstract insertarTarea(prolrequest: InsertarTareaRequest): Observable<InsertarTareaResponse>
    abstract actualizarTarea(prolrequest: ActualizarTareaRequest): Observable<ActualizarTareaResponse>
    abstract actualizarEstado(prolrequest: ActualizarEstadoRequest): Observable<ActualizarEstadoResponse>
    abstract listarRecurso(prolrequest: ListarRecursoRequest): Observable<ListarRecursoResponse>
    abstract listarTareasGantt(prolrequest: ListarTareasGanttRequest): Observable<ListarTareasGanttResponse>
    abstract TransaccionTareaGantt(prolrequest: TransaccionTareaGanttRequest): Observable<ActualizarTareaResponse>
    abstract adjuntarArchivo(prolrequest: AdjuntarArchivoRequest): Observable<AdjuntarArchivoResponse>
    abstract actualizarProyecto(prolrequest: ActualizarProyectoRequest): Observable<ActualizarProyectoResponse>
    abstract listarRecursosGantt(prolrequest: ListarRecursoGanttRequest): Observable<ListarRecursosGanttResponse>
    abstract quitarRecurso(prolrequest: quitarRecursoRequest): Observable<quitarRecursoResponse>
    abstract listarArchivosProyecto(prolrequest: DescargarArchivoPryRequest): Observable<DescargarArchivoPryResponse>
    abstract eliminarArchivoProyecto(prolrequest: EliminarArchivoPryRequest): Observable<EliminarArchivoPryResponse>
}


import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { requestlistarcarteraxejecutivo, requestupdateprogramacionvisita } from 'src/app/gestionvisitas/domain/request/requestgestionvisitas';
import { listadocarteraxejecutivo, listadoupdateprogramacionvisita } from 'src/app/gestionvisitas/domain/response/response/response-llistadocarteraxejecutivo';

import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';
import { ClienteRepository } from 'src/app/visitascliente/dominio/cliente.repository';



export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-gestionvisitas',
  templateUrl: './gestionvisitas.component.html',
  styleUrls: ['./gestionvisitas.component.css'],
  providers : [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
] // O el locale de tu preferencia]
})
export class GestionvisitasComponent {

  constructor(private readonly storage :StorageService,private readonly clienteService : ClienteRepository,private util: UtilService, private router: Router) {}

  
  
  group : FormGroup
  listaTodosclientes: listadocarteraxejecutivo[] = [];
  transportistaSeleccionado: any;
  textoFiltro: string = '';
  modo:string 
  itemglobal :number
  cliente:string 
  @ViewChild('autoTransportista') autoTransportista: MatAutocomplete;


  ngOnInit(): void {


    const datosSerializados = localStorage.getItem('dato')

      const datos =JSON.parse(datosSerializados); ;
      localStorage.removeItem('dato');

  if (datos!= null){
        this.modo = 'U'


        this.cargarCliente('');
        this.inicializaFormulario(datos,this.modo);
        this.cliente = datos.cliente
       
      
       }else {

        this.modo = 'I'
        this.inicializaFormulario(datos,this.modo);
        this.cargarCliente('');
        
      }


    
   
   
    }

    
  
  inicializaFormulario(datos:any,modo:string){

    if (modo =='U'){

       this.group = new FormGroup({
      cliente : new FormControl(datos.cliente,null),
      fechavisita:new FormControl(datos.fecharealvisitareal,null),
      radio : new FormControl(datos.cons_codvisreal,null)
    
  
    })
    }else {

      this.group = new FormGroup({
        cliente : new FormControl(null,null),
        fechavisita:new FormControl(this.util.formatearfecha(new Date(),'yyyy-MM-ddTHH:mm:ss'),null),
        radio : new FormControl(null,null)
      })

    }

   
  
  }

  opcionSeleccionada(event: MatAutocompleteSelectedEvent) {
    this.transportistaSeleccionado = this.listaTodosclientes.find(option => option.cliente === event.option.value);
  }

  
  cargarCliente(value) {
    const values = this.group.value;
    const request: requestlistarcarteraxejecutivo = <requestlistarcarteraxejecutivo>{};
    
    //this.razonSocialControl =  this.group.get('razonSocial').toString() ;
    const usuario = this.storage.get('codusuario');
    const correo = this.storage.get('correo');
    request.correousuario = correo
   

    //if(this.razonSocialControl.length > 3){
      this.clienteService.listarcarteraxejecutivo(request).subscribe(response => {
    
    
        this.listaTodosclientes = response.datos.result
    //    this.listRazonSocial.splice(0, this.listRazonSocial.length);
     //   for (let i = 0; i < response.datos.result.length; i++) {
     //     this.listRazonSocial.push(response.datos.result[i].cliente);
    //    }
       
        //this.codigoRazonSocial = (response.datos.result[0].codcliente)
 
      });
   // }


  }

  onDateChange(event: any) {
    const selectedDate = event.value; // Obtener la fecha seleccionada
    selectedDate.setHours(0, 0, 0, 0);
    // Formatear la fecha en el formato deseado y actualizar el valor del formulario
    this.group.get('fechavisita').setValue(selectedDate.toISOString());
  }


  latitude :number;
  longitude : number;

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resp => {
                resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
            },
            err => {
                reject(err);
          });
    });
}

/*
getLocation() {
  this.locationService.getPosition().then(pos => {
      this.latitude = pos.lat;
      this.longitude = pos.lng;
  });
}
*/
requestupdateprogramacionvisita :  requestupdateprogramacionvisita
listadoupdateprogramacionvisita: listadoupdateprogramacionvisita[]


guardarvisita()
{

  this.getPosition().then(pos => {

    this.latitude = pos.lat;
    this.longitude = pos.lng;

    const usuario = this.storage.get('codusuario');
    const correo = this.storage.get('correo');
     const  groupvalues = this.group.value
    
    
     
    
      this.requestupdateprogramacionvisita =  <requestupdateprogramacionvisita>{}
    
      const fechavisita = groupvalues['fechavisita']
      const tipovisita = groupvalues['radio'] 

      // Establecemos las horas, minutos, segundos y milisegundos a cero
 

// Ahora la fecha tiene el formato "2023-08-17T00:00:00.000Z"


      
   // const fechaformateada = this.formatearFecha(fechavisita)
  
      this.requestupdateprogramacionvisita.fecharealvisitareal = fechavisita //this.idprogramacionglobal ===undefined || this.idprogramacionglobal ==null?0:this.idprogramacionglobal 
      this.requestupdateprogramacionvisita.fecharealvisitareal = this.requestupdateprogramacionvisita.fecharealvisitareal.replace("T05","T00")
      this.requestupdateprogramacionvisita.cons_tabvisreal = 'VIS'
      this.requestupdateprogramacionvisita.cons_codvisreal = tipovisita
      this.requestupdateprogramacionvisita.visito = true 
      this.requestupdateprogramacionvisita.latitud =this.latitude
      this.requestupdateprogramacionvisita.longitud =this.longitude
      
      this.requestupdateprogramacionvisita.audi_usract = usuario
      this.requestupdateprogramacionvisita.codcliente = Number(this.transportistaSeleccionado.codcliente)
      this.requestupdateprogramacionvisita.correousuario  = correo
    
    
    
      this.clienteService.updatevisitaprogramada(this.requestupdateprogramacionvisita).subscribe
       ((response) =>{
      
      
           if ( response.datos.status===200){
      
    
            this.util.showMessage( 'Se registro correctamente');
                  this.listadoupdateprogramacionvisita = response.datos.result;
                  this.util.showMessage( "Se registro correctamente");
                
             }
      
    
       
    
           }, (errorServicio)=>{
             this.util.showMessage( errorServicio.error.meta.mensaje);
      
      });

      
});




}




formatearFecha(fechaString: string): string {
  // Dividimos la cadena por las barras "/" para obtener el día, mes y año
  const partes = fechaString.split("/");

  // Creamos un nuevo objeto Date proporcionando los valores de año, mes (restado 1 porque en Date los meses van de 0 a 11) y día
  const fecha = new Date(Number(partes[2]), Number(partes[1]) - 1, Number(partes[0]));

  // Obtenemos los componentes de la fecha (año, mes, día, horas, minutos y segundos)
  const anio = fecha.getFullYear();
  const mes = (fecha.getMonth() + 1).toString().padStart(2, "0"); // Aseguramos que tenga 2 dígitos
  const dia = fecha.getDate().toString().padStart(2, "0"); // Aseguramos que tenga 2 dígitos
  const horas = "00";
  const minutos = "00";
  const segundos = "00";

  // Concatenamos los componentes de la fecha en el formato deseado
  const fechaFormateada = `${anio}-${mes}-${dia}T${horas}:${minutos}:${segundos}`;

  return fechaFormateada;
}









  salir(){

    this.router.navigate(['/GestionVisitas']);


  }


}

<div class="tituloPantalla">
    <mat-toolbar  style="height: 33px;" fxLayout  class="tituloPantalla" >
      <h3 class="title">Programacion de Visitas</h3>
  </mat-toolbar>
</div>

<div fxLayoutGap="20px" class="container_botones" style="margin-top: 10px;">

  <button mat-stroked-button (click)="guardarprogramacion()" class="button1" >Guardar</button>
  <button mat-stroked-button (click) ="salir()" class="button1" >Salir</button>

</div>
  <!-- APARTADO DE REQUERIMIENTO -->
  <form [formGroup]="group" class="">
<mat-tab-group  #tabGroup>
  <mat-tab>
    <ng-template mat-tab-label>
      Filtros
    </ng-template>
	

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutAlign="center" style="margin-top: 0px; overflow: hidden;">
      <div fxFlex="100" style="padding: 0px;" class="general">
       
          <div fxLayoutGap="20px" class="label" *ngIf="isnuevo">
             
              <mat-form-field class="list"  class="example-full-width-alterno3" appearance="outline">
                  <mat-label>Mes</mat-label>
                  <mat-select  formControlName="mes">
                    <!-- <mat-option  disabled value="usuario">seleccione un mes</mat-option> -->
                    <mat-option value="01">ENERO</mat-option>
                    <mat-option value="02">FEBRERO</mat-option>
                    <mat-option value="03">MARZO</mat-option>
                    <mat-option value="04">ABRIL</mat-option>
                    <mat-option value="05">MAYO</mat-option>
                    <mat-option value="06">JUNIO</mat-option>
                    <mat-option value="07">JULIO</mat-option>
                    <mat-option value="08">AGOSTO</mat-option>
                    <mat-option value="09">SEPTIEMBRE</mat-option>
                    <mat-option value="10">OCTUBRE</mat-option>
                    <mat-option value="11">NOVIEMBRE</mat-option>
                    <mat-option value="12">DICIEMBRE</mat-option>
                  </mat-select>
               </mat-form-field>
  
  
               <mat-form-field class="list"  class="example-full-width-alterno3" appearance="outline">
                  <mat-label>Año</mat-label>
                  <mat-select  formControlName="anio">
                    <!-- <mat-option  disabled value="anio">seleccione un año</mat-option> -->
                    <mat-option value="2023">2023</mat-option>
                    <mat-option value="2024">2024</mat-option>
                    <mat-option value="2025">2025</mat-option>
                    <mat-option value="2026">2026</mat-option>
                    <mat-option value="2027">2027</mat-option>
                    <mat-option value="2028">2028</mat-option>
                    <mat-option value="2029">2029</mat-option>
                    <mat-option value="2030">2030</mat-option>
                    <mat-option value="2031">2031</mat-option>
                    <mat-option value="2032">2032</mat-option>
                    <mat-option value="2033">2033</mat-option>
                    <mat-option value="2034">2034</mat-option>
                    <mat-option value="2035">2035</mat-option>
                    <mat-option value="2036">2036</mat-option>
                    <mat-option value="2037">2037</mat-option>
                    <mat-option value="2038">2038</mat-option>
                    <mat-option value="2039">2039</mat-option>
                    <mat-option value="2040">2040</mat-option>
                  </mat-select>
               </mat-form-field>
  
  
               <button mat-raised-button (click) = "listarcarteracliente()" class="button" >Procesar</button>
              </div>
  
  
           <!-- <div fxLayoutGap="20px" class="label" >
        
               <mat-form-field class="list"  class="example-full-width-alterno3" appearance="outline">
                <mat-label>Forecast</mat-label>
                  <input matInput type="number" placeholder="Forecast" formControlName="forecast"  readonly>
                </mat-form-field>
  
  
                <mat-form-field class="list"  class="example-full-width-alterno3" appearance="outline">
                  <mat-label>Cuota Mensual</mat-label>
                  <input matInput type="number" placeholder="Cuota" formControlName="cuota" readonly>
                </mat-form-field>
  
                <mat-form-field class="list"  class="example-full-width-alterno" appearance="outline">
                  <mat-label>Porcentaje</mat-label>
                  <input matInput type="number" placeholder="porcentaje" formControlName="porcentaje" readonly>
                </mat-form-field> %
               
            </div>-->
    
          <div fxLayoutGap="20px" class="label" *ngIf="!isnuevo">
    
            
              <mat-form-field class="example-full-width-alterno3" appearance="outline" style=" background-color: rgb(255, 255, 255);z-index: 7; ">
                 <mat-label>Fecha Desde</mat-label>
                 <input matInput  formControlName = "fechainicio"  [matDatepicker]="picker1" autocomplete="off" (dateChange)="onDateChangeDesde($event,'fechainicio')" readonly [disabled]="true" [(ngModel)]="primerDiaMes" >
                 <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                 <mat-datepicker #picker1 color="primary"></mat-datepicker>
             </mat-form-field> 
   
            
              <mat-form-field class="example-full-width-alterno3" appearance="outline" style=" background-color: rgb(255, 255, 255);z-index: 7; ">
                 <mat-label>Fecha Hasta</mat-label>
                 <input matInput  formControlName = "fechafin"  [matDatepicker]="picker2" autocomplete="off" (dateChange)="onDateChangeDesde($event,'fechafin')" readonly [disabled]="true" [(ngModel)]="ultimoDiaMes" >
                 <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                 <mat-datepicker #picker2 color="primary"></mat-datepicker>
             </mat-form-field> 
   
         
    
          </div>
  
          <div fxLayoutGap="20px" class="label" *ngIf="!isnuevo">
  
          <mat-form-field class="example-full-width-alterno3" appearance="outline">
            <mat-label>Cliente</mat-label>
            <input type="text" matInput formControlName="clientefind" [matAutocomplete]="autoCliente"
              [(ngModel)]="textoFiltro" >
              <mat-icon matSuffix (click)="limpiar('clientefind')">close</mat-icon>
            <mat-autocomplete #autoCliente="matAutocomplete" (optionSelected)="opcionSeleccionada($event)">
              <ng-container *ngFor="let option of listarcartera">
                <mat-option [value]="option.nombrecliente"
                  *ngIf="option.nombrecliente.toLowerCase().includes(textoFiltro?.toLowerCase())">
                  {{option.nombrecliente}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
    
          <button mat-raised-button (click)="buscar()" class="button" >Filtrar</button>

        </div>
     
    
        
          <!--CONTAINER-BUTTONS-->
          <div fxLayoutGap="20px"  class="container_buttons">
        
          </div>
  
      
         
    
    
      
      
      </div>
    </div>

	</mat-tab>

  <!-- APARTADO DE CONTENEDOR -->



  <mat-tab>
    <ng-template mat-tab-label>
      Consignatario
    </ng-template>
    <div class="indicadores" fxLayoutGap="20px" style="margin-top: 10px">
      <mat-form-field class="list"  class="example-full-width-alterno" appearance="outline">
        <mat-label>Forecast</mat-label>
          <input matInput type="number" placeholder="Forecast" formControlName="forecast"  readonly>
        </mat-form-field>


        <mat-form-field class="list"  class="example-full-width-alterno" appearance="outline">
          <mat-label>Cuota Mensual</mat-label>
          <input matInput type="number" placeholder="Cuota" formControlName="cuota" readonly>
        </mat-form-field>

        <mat-form-field class="list"  class="example-full-width-alterno" appearance="outline">
          <mat-label>Porcentaje</mat-label>
          <input matInput type="number" placeholder="porcentaje" formControlName="porcentaje" readonly>
        </mat-form-field> %
    </div>

    <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px">
      <div fxFlex="50" style="background-color: rgb(219, 217, 217); padding: 1px;" class="component data" >
       <!--CONTAINER TABLE-->
       
       <mat-table matTableExporter [dataSource]="dataTable" #exporter="matTableExporter"  class="table">
          <!-- Recibe los datos -->
          <ng-container *ngFor="let metadata of metadataTable;let isFirstCol = first; index as i;let columnIndex = index" [matColumnDef]="metadata.field" class="container" [sticky]="isFirstCol">
          
            <mat-header-cell  mat-sort-header  *matHeaderCellDef class="centro" class="header-cell"  class="centro header-cell sticky-header">
              {{ metadata.title }}
            </mat-header-cell >
            <mat-cell *matCellDef="let row" data-label="metadata.field" class="fila" class="cell">
              <ng-container *ngIf="metadata.type == 'checkbox'" >
                  <mat-checkbox  color="primary"  [checked]="row[extractNumberFromField(metadata.field)]=='001'?true :false"  (change)="onCheckboxChange('F',$event, row, metadata.title)" ><mat-icon>person</mat-icon></mat-checkbox>
                  <mat-checkbox  color="primary"  [checked]="row[extractNumberFromField(metadata.field)]=='002'?true :false"  (change)="onCheckboxChange('T',$event, row, metadata.title)">   <mat-icon>local_phone</mat-icon></mat-checkbox>
              </ng-container>
              <ng-container   *ngIf="metadata.type == 'text'" >
                {{ row[metadata.field] }}
           
              </ng-container>

              <ng-container *ngIf="metadata.type == 'number'"  >
  
                <input matInput type="number" #inputValue  [value]="row[metadata.field]" (change)="onInputBlur($event, row, metadata.field)"  >
              </ng-container>
      
            </mat-cell>
        </ng-container>
      
       
      
          <mat-header-row *matHeaderRowDef="columnsToView" class="header-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columnsToView;" class="row" ></mat-row>
        </mat-table>


      </div>

      <mat-paginator [pageSizeOptions]="[ 6,10,15,25,50,75,100]" [length]="listarcartera?.length"   (page)="onPageChange($event)"></mat-paginator>
    </div>

  
    </mat-tab>

</mat-tab-group>

  
</form>

  <footer>
    <div class="contenido-pie">
        <p></p>
    </div>
  </footer>
  <!-- <button mat-button  type="button"     matTooltip="Generar PDF" (click)="generatePDF()"><img style="height: 22px;width: 22px;" src="../../../../../assets/iconos/pdf.svg" alt="Icono PDF"></button> -->
  
  
  
  
  
  
  
  
  
  
  
  
  
  
<mat-dialog-content>

    <div class="contenedor">
        <p class="titulo">{{message}}</p>

        <div fxLayout fxLayoutAlign="center" class="contenido">
            <button mat-flat-button mat-dialog-close="si" class="si">Si</button>
            <button mat-button mat-dialog-close class="no">No</button>
        </div>
    </div>


</mat-dialog-content>


<mat-sidenav-container class="mat-drawer-content">
  <mat-sidenav >Menu</mat-sidenav>

  <mat-sidenav-content class="mat-drawer-content" >

     <!--<app-geolocalizacion></app-geolocalizacion>-->
   
    <app-header *ngIf="userLogged" ></app-header>
    
    <div class="mat-drawer-content">

       <router-outlet></router-outlet>
      
       
    </div>

  </mat-sidenav-content>
 
</mat-sidenav-container>






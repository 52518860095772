import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';

import { MetadataTable } from 'src/app/interfaces/metada-table.interface';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { ListarTodosWebRequest, requestTransportista, rucInternoRequest } from 'src/app/proforma/domain/request/proforma_request';
import { ListParametros } from 'src/app/proforma/domain/response/proforma_response';
import { listaClienteInterno } from 'src/app/requerimiento/domain/response/response_requerimiento';
import { CryptoService } from 'src/app/services/crypto.service';
import { StorageService } from 'src/app/services/storage.service';
import {
  DetalleServiciosLocalesResponse,
  ServiciosLocalesResponse,
  BuscarFavoritosPorViaResponse,
  BuscarServicio_ByViaRegimenResponse, ConsultarContratoAyudaPivotOVoResponse, ConsultarTarifaResponse, InsertarServiciosLocalesResponse, ItemsListarOrdenesVentaResponse,/*, ItemListarFleteOrdenVentaResponse*/
  ItemsServicio_ByViaRegimenResponse,
  ItemsServiciosCalculadosTarifaResponse,
  ItemsTarifaDetalleResponse,
  ConsultarEntidadServicioResponse,
  ConsultarEntidadRelacionadosResponse,
  BuscarPorEntidad_Response,
  GetCotizacionOVArchivoResponse,
  ItemsCotizacionOVArchivoResponse
} from '../../domain/response/comercial-orden-venta-response'; //Daniel
import { DetalleServiciosLocalesRequest, ServiciosLocalesRequest, BuscarFavoritosPorViaRequest, BuscarServicio_ByViaRegimenRequest, ConsultarContratoAyudaPivotOVRequest, ConsultarTarifaRequest, ConsultarTariffDetailByIdRequest, CrudOrdenesVentaRequest, GetServiceCalculatedByTariffRequest, ListaDetCotizacionOVArchivoRequest, ListaDetCotizacionOVEventoActualizarRequest, ListaDetCotizacionOVEventoRequest, ListaDetCotizacionOVFleteActualizarRequest, ListaDetCotizacionOVFleteRequest, ListaDetCotizacionOVItinerarioRequest, ListaDetCotizacionOVServicioActualizarRequest, listNaveViajePorPuertoRequest, EvenSiRequest, ConsultarEntidadServicio_Request, ConsultarEntidadRelacionadosRequest, BuscarPorEntidad_Request, GetCotizacionOVArchivoRequest } from '../../domain/request/comercial-orden-venta-request';//DANIEL 26
// import { ComercialRepository } from '../../domain/comercial-repository'; //daniel 26
import { UtilService } from 'src/app/services/util.service'; //dANIEL26
import { newmetadate, style } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { MatSelectChange } from '@angular/material/select';
import { ComercialRepository } from 'src/app/repositorio/comercial.repository';
import { Parametros } from 'src/app/services/domain/services-domain';
import { Title } from '@angular/platform-browser';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { dbmetadata } from 'src/app/shared/components/db-table/domain/dbTable-domain';
import { MantenimientoOrdenVentaValidacion } from './mantenimiento-orden-venta-validacion';
import { id } from 'date-fns/locale';
import { catchError, concatMap, forkJoin, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { CryptoRepository } from 'src/app/interfaces/crypto-repository';
import { DatosCompartidosService } from 'src/app/services/datos-compartidos.service';
import { BuscarContratoComponent } from '../buscar-contrato/buscar-contrato.component';


@Component({
  selector: 'app-mantenimiento-orden-venta',
  templateUrl: './mantenimiento-orden-venta.component.html',
  styleUrls: ['./mantenimiento-orden-venta.component.css']
})

export class MantenimientoOrdenVentaComponent {
  date: Date = new Date();
  binary: boolean = true;
  uploadedFiles: any[] = [];
  datosRecibidos: any = [];
  estilos: style = {
    header: {
      align: 'left',
      justify: 'center',
      size: '10px'
    },
    body: {
      size: '9px'
    }
  }


  ListaTodosPuertoOrigenyDestino: any[] = []
  insertActualizarOVArchivo = {
    items: []
  };
  insertActualizarOVEvento = {
    items: []
  };
  insertActualizarOVItinerario = {
    items: []
  };
  slrequest = <ServiciosLocalesRequest>{};
  sldetrequest: DetalleServiciosLocalesRequest[] = [];
  slevenrequest: EvenSiRequest[] = [];

  listarServTariff: ItemsServiciosCalculadosTarifaResponse[] = [];
  listarTarifaDetalle: ItemsTarifaDetalleResponse[] = [];


  datosViajePorPuerto2: any[] = []
  seguimiento_Embarque: any[] = []

  //Cambiar Estado si es "NUEVO" o "EDITAR"
  //  --Datos Generales  
  readOnlyNumeroDGDG: boolean;
  readOnlyNumeroCotizacionDG: boolean;
  readOnlyEstadoDG: boolean;
  readOnlyNoCRMDG: boolean;
  readonlyOrdenCompraDG: boolean;
  readonlyRegimenDG: boolean;
  readonlyViaDG: boolean;
  readonlyLineaNegocioDG: boolean;
  readonlyFEmisionDG: boolean;
  readonlyFVencimientoDG: boolean;
  readonlyFAprobacionDG: boolean;
  readonlyFEmbarqueDG: boolean;
  readOnlyClienteDG: boolean;  //PENDIENTE
  readOnlyCustomerDG: boolean;
  readOnlyAgenteDG: boolean;
  readOnlyEjecutivoDG: boolean;
  readonlyIncotermDG: boolean;
  readonlyServLogisticoDG: boolean;
  readonlyServicioDG: boolean;
  readonlyCotPropiaDG: boolean;
  visibledCotPropiaDG: boolean; //Cambia segun otras funciones
  readonlyAvisoLl_zDG: boolean;


  //  --Servicios y Tarifa
  readonlyTransportisaSTST: boolean;
  readonlyPuertoTransbordoST: boolean;
  readonlydTraficoST: boolean;
  readonlyPuertoOrigenST: boolean;
  readonlyPuertoDestinoST: boolean;
  readonlyMonedaST: boolean;
  readonlyTipoFechaTarifaST: boolean;
  readonlyFechaTarifaST: boolean;
  readonlyCondicionST: boolean;
  readonlyNumeroContratoST: boolean;
  readonlyAdicionalST: boolean;
  readonlyPCMblST: boolean;
  readonlyPCHblST: boolean;
  readOnlyFleteTotalST: boolean;
  readonlyDiasLibresSobreeestadiaST: boolean;


  // --Datos Embarque
  readonlyCargaRefrigeradaDE: boolean;
  readOnlyTemperaturaDE: boolean;
  readonlyCargaPeligrosaDE: boolean;
  readonlyImoClassDE: boolean;
  readonlyRoundTripDE: boolean;
  readOnlyImoUnDE: boolean;
  readonlyShipperDE: boolean;
  readonlyReferenciaDE: boolean;
  readonlyConsigneeDE: boolean;
  readonlyNotifyDE: boolean;

  // --Observaciones
  readonlyAlmacenajeObs: boolean;
  readonlySobreestadiaObs: boolean;
  readonlyTiempoViajeObs: boolean;
  readonlyValidezOfertaObs: boolean;
  readonlyInstruccionesEmbarqueObs: boolean;

  // --Novedades
  readonlyNroBookingNov: boolean;
  readonlyDepositoNov: boolean;
  readonlyBlNietoNov: boolean;
  readonlyBlHijoNov: boolean;
  readonlyBlMasterNov: boolean;
  readonlyContenedorNov: boolean;
  readOnlyViajeNov: boolean;
  readonlyFZarpeNov: boolean;
  readonlyFEtaEtdNov: boolean;
  readOnlyNaveNov: boolean;

  // --EventosTareas
  readonlyTipoEventoTareaET: boolean;
  readonlyFechaYHoraET: boolean;
  readonlyEstadoET: boolean;
  readonlyModuloET: boolean;
  readonlyUsuarioET: boolean;
  readonlyObservacionesET: boolean;

  // --Servicio Locales
  readOnlyCodigoOperacionDSL: boolean;
  readOnlyNumeroOpeacionDSL: boolean;
  readonlyFechaEmisionDSL: boolean;
  readOnlyClienteDSL: boolean;
  readOnlyTransportistaAMDSL: boolean;
  readOnlyAlmacenDSL: boolean;
  readOnlyAgenciaMaritimaDSL: boolean;
  readOnlyTerminalPortuarioDSL: boolean;
  readOnlyCondicionEmbarqueDSL: boolean;
  readonlyTransportistaDSL: boolean;
  readonlySADADSL: boolean;
  readonlyIMODSL: boolean;
  readonlyTarifaDSL: boolean;
  readonlyTipoDSL: boolean;
  readonlyVigenciaDesdeDSL: boolean;
  readonlyVigenciaHastaDSL: boolean;
  readonlyDescripcionDSL: boolean;//PENDIENTE
  readonlyObservacionesDSL: boolean;//PENDIENTE
  readOnlyVentaTotalDSL: boolean;

  //Variables que cambian segun otras acciones
  avisoLl_z: string = 'Aviso de llegada '; // Valor inicial del título
  fechaEm_z: string = 'F. Embarque';

  blockedColumnsArchivos: string[] = ['ovar_eliminar', 'ovar_fecemi', 'ovar_descrip', 'ovar_descargar'];
  blockedColumnsEventos: string[] = ['even_observaciones'];
  blockedColumnsSL: string[] = ['resumen_eliminar'];
  // , 'even_observaciones_detalle'

  // isDrawerOpen = false;
  row: any;

  isColumnBlocked(field: string): boolean {
    return this.blockedColumnsArchivos.includes(field) || this.blockedColumnsEventos.includes(field) || this.blockedColumnsSL.includes(field);
  }


  constructor(//Daniel01-08
    private readonly router: Router,
    private readonly proformaService: ProformaRepository,
    private readonly ordenventaService: ComercialRepository, // Daniel 26
    private readonly util: UtilService, // DANIEL 26
    private readonly title: Title,
    private readonly storage: StorageService,
    private datosCompartidosService: DatosCompartidosService,
    private confirmationService: ConfirmationService
  ) {
    this.title.setTitle('Nueva Orden Venta')
    const datosViajePorPuerto = JSON.parse(localStorage.getItem('datoViajePorPuerto'))
    this.datosViajePorPuerto2 = datosViajePorPuerto
    console.log('LLEGA VACIO ESTO', this.datosViajePorPuerto2)
  }

  // codRegimen: string;
  onRegimenChange(event: any) { //Cambio de otros elementos
    // var nombre: string = this.group_datosgenerales.value["regimen"]
    // this.codRegimen = this.ListaTodosRegimen.find(item => item.nombre === nombre)?.codigo
    // console.log("codRegimen:", this.codRegimen)

    const selectedRegimen = event.value;
    if (selectedRegimen === '001') {
      this.group_datosgenerales.get('avisodellegada').setValue(true)
      this.group_datosgenerales.get('lineanegocio')?.setValue('004');
      this.avisoLl_z = 'Aviso de llegada';
      this.fechaEm_z = 'F. Embarque';
      this.visibledCotPropiaDG = true;
    } else if (selectedRegimen === '002') {
      this.group_datosgenerales.get('lineanegocio')?.setValue('002');  //Establece el valor del segundo cbx
      this.avisoLl_z = 'Aviso de Zarpe';
      this.fechaEm_z = 'F. Zarpe';
      this.group_datosgenerales.get('avisodellegada').setValue(true)
      this.visibledCotPropiaDG = true;
    } else {
      this.group_datosgenerales.get('lineanegocio')?.setValue('');   //Limpia el valor si no se cumple la condición
      this.avisoLl_z = 'Aviso de llegada';
      this.fechaEm_z = 'F. Embarque';
      this.group_datosgenerales.get('avisodellegada').setValue(false)

      this.visibledCotPropiaDG = false;
    }
    this.buscarServicios();
  }

  // codVia: string;
  onViaChange(event: any) {
    this.buscarServicios();
    this.filtrarPuertosSegunVia()
    this.buscarPuertosByVia()
    // var nombre: string = this.group_datosgenerales.value["via"]
    // this.codVia = this.ListaTodosVia.find(item => item.nombre === nombre)?.codigo
    // console.log("codVia:", this.codVia)
  }

  codLineaNegocio: string;
  onLineaNegocioChange(event: any) {
    this.cargarServiciosEnTablaSegunLineaNegocio()
    this.buscarServicios();
    // var nombreLinN: string = this.group_datosgenerales.value["lineanegocio"]
    // this.codLineaNegocio = this.ListaTodosLineaNegocio.find(item => item.nombre === nombreLinN)?.codigo
    // console.log("codLineaNegocio:", this.codLineaNegocio)
  }

  codCliente: number;
  onClienteChange(event: any) {
    var nombreCliente: string = this.group_datosgenerales.value["cliente"]
    this.codCliente = Number(this.ListaTodosCliente.find(item => item.nombre === nombreCliente)?.codigo)
    this.group_servicioslocales.get('clienteDSL')?.setValue(nombreCliente);

    this.buscarCustomerEjecutivoSegunCliente()
    this.cargarServiciosEnTablaSegunLineaNegocio()
    // console.log("codcliente:", this.codCliente)
  }

  codTransporte: number;
  onTransporteChange(event: any) {
    var nombreTransportista: string = this.group_serviciostarifa.value["transportista"]
    this.codTransporte = Number(this.ListaTodosTransportista.find(item => item.nombre === nombreTransportista)?.codigo)
    this.group_servicioslocales.get('transportistaam')?.setValue(nombreTransportista);
    // console.log("codTransporte:", this.codTransporte)
  }

  codAgente: number;
  onAgenteChange(event: any) {
    var nombreAgente: string = this.group_datosgenerales.value["agente"]
    this.codAgente = Number(this.ListaTodosAgentes.find(item => item.nombre === nombreAgente)?.codigo)
    // console.log("codAgente:", this.codAgente)
  }

  // codMoneda: string;
  // onMonedaChange(event: any) {
  //   var nombreMoneda: string = this.group_serviciostarifa.value["moneda"]
  //   this.codMoneda = this.ListaTodosMoneda.find(item => item.nombre === nombreMoneda)?.codigo
  //   console.log("codMoneda:", this.codMoneda)
  // }

  codTransTerr: number;
  onTransTerrChange(event: any) {
    this.codTransTerr = this.group_servicioslocales.get('transportistaTerre')?.value;
    // console.log("this.codTransTerr", this.codTransTerr)
  }

  onFechaEmiChange(event: any) {
    this.group_servicioslocales.get('fechaemision')?.setValue(this.group_datosgenerales.value["fechaemision"]);
  }

  onDepositoChange(event: any) {
    this.group_servicioslocales.get('almacen')?.setValue(this.group_novedades.value["deposito"]);
  }

  onCondicionChange(event: any) {
    this.group_servicioslocales.get('condicion')?.setValue(this.group_serviciostarifa.get('condicion')?.value);
  }

  inicializarValoresSL() {
    var nombreCliente = this.group_datosgenerales.get('cliente')?.value
    this.codCliente = Number(this.ListaTodosCliente.find(item => item.nombre === nombreCliente)?.codigo)
    var nombreTransportista: string = this.group_serviciostarifa.value["transportista"]
    this.codTransporte = Number(this.ListaTodosTransportista.find(item => item.nombre === nombreTransportista)?.codigo)
    var nombreAgente: string = this.group_datosgenerales.value["agente"]
    this.codAgente = Number(this.ListaTodosAgentes.find(item => item.nombre === nombreAgente)?.codigo)
    this.codTransTerr = this.group_servicioslocales.get('transportistaTerre')?.value
    // console.log("codCliente", this.codCliente)
    // console.log("codTransporte", this.codTransporte)
    // console.log("codAgente", this.codAgente)
    // console.log("codTransTerr", this.codTransTerr)
  }

  transformData(data: any[]): any[] { //Para mapear los datos para que funcione en select
    return data.map(item => ({
      labely: item.nombre,
      valuey: item.nombre,
      codigoy: item.codigo,
    }));
  }

  onCargaPeligrosaChange(event: any) {
    const isChecked = event.checked;
    if (isChecked) {
      this.readonlyImoClassDE = false;
      this.readOnlyImoUnDE = false;
    } else {
      this.readonlyImoClassDE = true;
      this.readOnlyImoUnDE = true;
    }
  }

  pasarDataPuertosFavoritosByVia(list: any) {
    this.datosCompartidosService.setlistaDatos(list);
  }

  panelOpenState = false;
  parametros: Parametros[] = [] // any[] = []//Variable para guardar todos los datos de LTW

  group: FormGroup //No hace nada 

  //Declaracion de los FormGroup
  group_datosgenerales: FormGroup
  group_serviciostarifa: FormGroup
  group_datosembarque: FormGroup
  group_observaciones: FormGroup
  group_viaje: FormGroup
  group_novedades: FormGroup
  group_archivos: FormGroup
  group_eventostareas: FormGroup
  group_servicioslocales: FormGroup

  inicializarFormularios(row) {
    this.group_datosgenerales = new FormGroup({//30
      numero: new FormControl(row.ccot_numdoc, null),
      numerocotizacion: new FormControl(row.ccot_numdoccot, null),
      estado: new FormControl(row.cons_codest, null),
      cotizacionpropia: new FormControl(row.ccot_propia, null),
      avisodellegada: new FormControl(row.ccot_enviaavisollegada, null),
      serviciologistico: new FormControl(row.ccot_serviciologistico, null),
      servicio: new FormControl(row.ccot_serviciotransmision, null),
      regimen: new FormControl(row.cons_codrgm, null),
      via: new FormControl(row.cons_codvia, null),
      lineanegocio: new FormControl(row.cons_codlng, null),
      //fechaemision: new FormControl(row.ccot_fecemi, null),
      fechaemision: new FormControl(row.ccot_fecemi ? new Date(row.ccot_fecemi) : null, Validators.required),
      fechavencimiento: new FormControl(row.ccot_fecvcto ? new Date(row.ccot_fecvcto) : null, Validators.required),
      fechaaprobacion: new FormControl(row.ccoT_FecAprueba ? new Date(row.ccoT_FecAprueba) : null, Validators.required),
      fechaembarque: new FormControl(row.ddov_fecembarque ? new Date(row.ddov_fecembarque) : null, Validators.required),
      cliente: new FormControl(row.entc_nomcliente, null),
      customer: new FormControl(row.entC_NomCustomer, null),
      agente: new FormControl(row.entc_nomagente, null),
      incoterm: new FormControl(row.tipo_codinc, null),
      ejecutivo: new FormControl(row.entc_nomejecutivo, null),
      numerocrm: new FormControl(row.ccot_numpresupuesto, null),
      broker: new FormControl(null, null),//Se eliminó
      ordencompra: new FormControl(row.ccot_ordencompra, null),
    })

    this.group_serviciostarifa = new FormGroup({
      transportista: new FormControl(row.entc_nomtransportista, null),
      puertotransbordo: new FormControl(row.puer_codtrasbordo, null),
      trafico: new FormControl(row.tipo_codtrf, null),
      puertoorigen: new FormControl(row.puer_codorigen, null),
      puertodestino: new FormControl(row.puer_coddestino, null),
      moneda: new FormControl(row.tipo_codmnd, null),
      tipofechatarifa: new FormControl(row.cons_codtft, null),
      fechatarifa: new FormControl(row.ccot_fectarifa ? new Date(row.ccot_fectarifa) : null, null),
      condicion: new FormControl(row.cons_codfle, null),
      numerocontrato: new FormControl(row.cont_numero, null),
      adicional: new FormControl(row.cont_descrip, null),
      pcmbl: new FormControl(row.ccot_pagombl, null),
      pchbl: new FormControl(row.ccot_pagohbl, null),
      fletetotal: new FormControl(row.dcot_totaluniventa, null),
      diaslibres: new FormControl(row.ccot_sobreestadia, null),
    })

    this.group_datosembarque = new FormGroup({
      cargarefrigerada: new FormControl(row.ccot_cargarefrigerada, null),
      cargapeligrosa: new FormControl(row.ccot_cargapeligrosa, null),
      roundtrip: new FormControl(row.ccot_roundtrip, null),
      temperatura: new FormControl(row.ccot_temperatura, null),
      imoclass: new FormControl(row.tipo_codimo, null),
      imoun: new FormControl(row.ccot_imoun, null),
      shipper: new FormControl(row.entc_nomshipper, null),
      consignee: new FormControl(row.entc_nomconsignee, null),
      notify: new FormControl(row.entc_nomnotify, null),
      referencia: new FormControl(row.doov_codreferencia, null),
    })

    this.group_observaciones = new FormGroup({
      almacenaje: new FormControl(row.ccot_almacenaje, null),
      sobreestadia: new FormControl(row.ccot_sobreestadia, null),
      tiempoviaje: new FormControl(row.ccot_tiempoviaje, null),
      validezoferta: new FormControl(row.ccot_validezoferta, null),
      instrucciones: new FormControl(row.ccot_observaciones, null),
    })

    this.group_novedades = new FormGroup({
      numerobooking: new FormControl(row.ddov_nrobooking, null),
      fecharecepcion: new FormControl(null, null),//Se eliminó
      deposito: new FormControl(row.entc_nomdeptemporal, null),
      blnieto: new FormControl(row.ddov_hblnieto, null),
      blhijo: new FormControl(row.doov_hbl, null),
      blmaster: new FormControl(row.doov_mbl, null),
      contenedor: new FormControl(row.doov_cntr, null),
      viaje: new FormControl(row.nvia_codigo, null),
      zarpe: new FormControl(null, null),
      etaetd: new FormControl(null, null),
      nave: new FormControl(row.nave_nombre, null),
      tiponotificacion: new FormControl(null, null),//Se eliminó
      descripcion: new FormControl(null, null),//Se eliminó
    })

    this.group_archivos = new FormGroup({
      archivo: new FormControl(null, null),
    })

    this.group_eventostareas = new FormGroup({
      tipoevento: new FormControl(null, null),
      observaciones: new FormControl(null, null),
      fechahora: new FormControl(null, null),
      estado: new FormControl(null, null),
      modulo: new FormControl(null, null),
      usuario: new FormControl(null, null),
    })

    this.group_servicioslocales = new FormGroup({
      codigooperacion: new FormControl(row.cope_codigo, null),
      numerooperacion: new FormControl(row.cope_numdoc, null),
      fechaemision: new FormControl(row.cope_fecemi ? new Date(row.cope_fecemi) : null, Validators.required),
      clienteDSL: new FormControl(row.entc_nombrecliente, null),
      transportistaam: new FormControl(row.entc_nombretransportista, null),
      almacen: new FormControl(row.entc_nombrealmacen, null),
      agencia: new FormControl(row.entc_nombreagenciamaritima, null),
      terminal: new FormControl(null, null),
      condicion: new FormControl(row.descripcioncondicionembarque, null),
      transportista: new FormControl(null, null),
      sada: new FormControl(null, null),
      imo: new FormControl(null, null),
      tarifa: new FormControl(row.ctar_codigo, null),
      tipo: new FormControl(row.tarifftype, null),
      vigenciadesde: new FormControl(row.validfrom ? new Date(row.validfrom) : null, null),
      vigenciahasta: new FormControl(row.validto ? new Date(row.validto) : null, null),
      descripcion: new FormControl(row.remarks, null),
      observaciones: new FormControl(null, null),
      transportistaTerre: new FormControl(null, null)
    })

    //this.group_datosgenerales.get('estado')?.setValue('001');
  }


  item2: ItemsListarOrdenesVentaResponse;
  exit: string = 'nuevo'
  datos: any[] = []

  LTWPAQUETEnombres: any[] = []//No hace nada
  enviarDatos(datos: string) { //No hace nada
    return this.parametros
      .filter(param => param.tipo === datos)
      .map(param => param.nombre);
  }

  //Listas para mandar al componente datatable, es para los select:
  ListaTipoPaquete: SelectItem[] = []
  ListaServiciosByViaRegimen: SelectItem[] = []
  ListaPuertosByVia: SelectItem[] = []
  ListaServicio: SelectItem[] = []
  //ListaIngreEgre: any[]=[]
  ListaTipoEntidad: SelectItem[] = []
  ListaProveedorG: SelectItem[] = []
  ListaBase: SelectItem[] = []
  ListaMoneda: SelectItem[] = []
  ListaComodity: SelectItem[] = []
  ListaUnidadMedida: SelectItem[] = []
  ListaTipoDocumento: SelectItem[] = []


  ListadoOperaciones: any[] = []
  ListadoGrupoPaquete: any[] = []
  ListadoServicioContenedores: any[] = []
  ListadoTarifaAsociada: any[] = []
  // ListadoDetalleAsociada:any[]=[]
  // ListadoServiciosAsociados:any[]=[]
  ListadoControlAsociados: any[] = []
  // ListadoEventosAsociados:any[]=[]

  //Declaraciones de metaDatas:
  serviciosytarifas_dataflete: any[] = []
  // metadatableFlete: newmetadate[]

  serviciosytarifas_dataservicios: any[] = []
  // metadatableServicio: newmetadate[]

  serviciosytarifas_dataseguro: any[] = []
  // metadatableSeguro: newmetadate[]

  datosembarque_dataflete: any[] = []
  metadatableFleteEmbarque: newmetadate[]

  viajeitinerario_dataitinerario: any[] = []
  // metadatableItinerario: newmetadate[]

  viajeitinerario_datanave: any[] = []
  metadatableDataNave: newmetadate[]

  archivos_dataarchivos: any[] = []
  metadatableDataArchivos: newmetadate[]

  eventostareas_dataeventos: any[] = []
  metadatableDataEventos: newmetadate[]

  servicioslocales_dataservicios: any[] = []
  metadatableDataServicios: newmetadate[]

  servicioslocales_datadetalle: any[] = []
  metadatableDataDetalle: newmetadate[]

  servicioslocales_tarifa: any[] = []
  metadatableTarifa: newmetadate[]

  setFilterData(table: any[], filtro: string) {
    let tabla = []
    tabla = table.filter(r => r['tipo'] === filtro)
    //console.log(tabla)
    return tabla
  }

  metadatableFlete: newmetadate[] = [
    { title: 'ITEM', field: 'dcot_item', filterType: 'numeric', inputType: 'int' },
    { title: 'TIPO PAQUETE', field: 'pack_desc', inputType: 'autocomplete', dropdown: this.ListaTipoPaquete },
    { title: 'CANTIDAD', field: 'dcot_cantidad', filterType: 'numeric', inputType: 'int' },
    { title: 'P.U. COSTO', field: 'dcot_preciounicosto', filterType: 'numeric', inputType: 'decimal' },
    { title: 'P.U. VENTA', field: 'dcot_preciouniventa', filterType: 'numeric', inputType: 'decimal' },
    { title: 'TOTAL COSTO', field: 'dcot_totalunicosto', filterType: 'numeric', inputType: 'decimal', rowFooter: 'sum' },
    { title: 'TOTAL VENTA', field: 'dcot_totaluniventa', filterType: 'numeric', inputType: 'decimal', rowFooter: 'sum' },
  ]

  metadatableServicio: newmetadate[] = [
    { title: 'ITEM', field: 'scot_item', filterType: 'numeric', inputType: 'int' },
    { title: 'EXONERADO', field: 'scot_exonerado', filterType: 'boolean', inputType: 'checkbox' },
    { title: 'SERVICIO', field: 'serv_nombre', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaServiciosByViaRegimen },
    { title: 'INGRESO/EGRESO', field: 'ccot_ingresogasto', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaServicio },//?
    { title: 'TIPO ENTIDAD', field: 'tipedescripcion', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTipoEntidad, buscar: 'tipe_codigo', children: 'entc_nomcom' },
    { title: 'PROVEEDOR', field: 'entc_nomcom', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaProveedorG },
    { title: 'BASE', field: 'cons_desc_spa', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaBase },
    { title: 'CANTIDAD', field: 'scot_cantidad', filterType: 'text', inputType: 'int' },
    { title: 'MONEDA', field: 'tipo_descmnd', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaMoneda },
    { title: 'PRECIO UNITARIO', field: 'scot_preciouni', filterType: 'text', inputType: 'decimal' },
    { title: 'IMPORTE INGRESO', field: 'scot_importe_ingreso', filterType: 'text', inputType: 'decimal' },
    { title: 'IMPORTE EGRESO', field: 'scot_importe_egreso', filterType: 'text', inputType: 'decimal' },
    { title: 'AFECTO IGV', field: 'serv_afeigv', filterType: 'boolean', inputType: 'checkbox' },
    { title: 'AFECTO DETRACCIÓN', field: 'serv_afedet', filterType: 'boolean', inputType: 'checkbox' },
    //{ title: 'TIPO DOCUMENTO', field: null, filterType: 'text', inputType:'text' },//boton
    { title: 'TIPO DOCUMENTO', field: 'tipo_desctdo', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTipoDocumento },//Lo seleccionado, llega como null desde la BD
    { title: 'SERIE DOCUMENTO', field: 'scot_serietdo', filterType: 'text', inputType: 'text' },
    { title: 'NRO. DOCUMENTO', field: 'scot_numerotdo', filterType: 'text', inputType: 'text' },
    { title: 'FEC. EMISIÓN', field: 'audi_feccrea', filterType: 'text', inputType: 'date' },//Cambiar luego por "scot_fechaemision, usando: audi_feccrea; this.formatDate('audi_feccrea', 'MM/DD/YYYY HH:mm:ss')"
  ]

  metadatableDataObservaciones: newmetadate[] = []

  // datosViajePorPuerto2:any[]=[]

  metadatableSeguro: newmetadate[] = [
    { title: 'EXONERADO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'SERVICIO', field: null, filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaServiciosByViaRegimen },
    { title: 'INGRESO/EGRESO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'ENTIDAD', field: null, filterType: 'text', inputType: 'text' },
    { title: 'TIPO ENTIDAD', field: null, filterType: 'text', inputType: 'text' },
    { title: 'PROVEEDOR', field: null, filterType: 'text', inputType: 'text' },
    { title: 'BASE', field: null, filterType: 'text', inputType: 'text' },
    { title: 'CANTIDAD', field: null, filterType: 'text', inputType: 'text' },
    { title: 'MONEDA', field: null, filterType: 'text', inputType: 'text' },
    { title: 'PRECIO UNITARIO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'IMPORTE EGRESO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'AFECTO IGV', field: null, filterType: 'text', inputType: 'text' },
    { title: 'AFECTO DETRACCIÓN', field: null, filterType: 'text', inputType: 'text' },
    { title: 'TIPO DOCUMENTO', field: null, filterType: 'text', inputType: 'text' },//
    { title: 'SERIE DOCUMENTO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'NRO. DOCUMENTO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'FEC. EMISIÓNNEW', field: null, filterType: 'text', inputType: 'text' },
  ]


  metadatableItinerario: newmetadate[] = [
    { title: 'TRAMO', field: 'ovnv_item', filterType: 'numeric', inputType: 'int' },
    { title: 'FEEDER', field: 'nvia_esfeeder', filterType: 'boolean', inputType: 'checkbox' },
    { title: 'FINAL', field: 'ovnv_final', filterType: 'boolean', inputType: 'checkbox' },
    { title: 'CÓDIGO VIAJE', field: 'nvia_codigo', filterType: 'text', inputType: 'text', popup: 'y', datosDialogTable: this.datosViajePorPuerto2 },
    { title: 'NAVE', field: 'nave_nombre', filterType: 'text', inputType: 'text', },
    { title: 'VIAJE', field: 'nvia_nroviaje', filterType: 'text', inputType: 'text', },
    { title: 'ETD', field: 'etd', filterType: 'text', inputType: 'date' },
    { title: 'FECHA EMBARQUE', field: 'fechaembarque', filterType: 'text', inputType: 'date' },
    { title: 'ETA', field: 'eta', filterType: 'text', inputType: 'date' },
    { title: 'POL', field: 'nameportloading', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaPuertosByVia },
    { title: 'POD', field: 'nameportdischarge', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaPuertosByVia },
  ]

  async ngOnInit(): Promise<void> {

    this.inicializarFormularios([])
    this.parametros = await this.util.getParametros()

    this.ListarParametros()
    this.parametros.map(r => {
      if (r.tipo === 'PAQUETE') {
        const item: SelectItem = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTipoPaquete.push(item)
      }
      if (r.tipo === 'TMC') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaServicio.push(item)
      }
      if (r.tipo === 'BAS') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaBase.push(item)
      }
      if (r.tipo === 'MND') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaMoneda.push(item)
      }
      if (r.tipo === 'TDO') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTipoDocumento.push(item)
      }

      if (r.tipo === 'CDT') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaComodity.push(item)
      }
      if (r.tipo === 'UNM') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaUnidadMedida.push(item)
      }

      if (r.tipo === 'TIPE') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTipoEntidad.push(item)
      }
      if (r.tipo === 'CLIENTE' || r.tipo === 'SHIPP' || r.tipo === 'TRANSPO' || r.tipo === 'AGE') { //chiwa
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaProveedorG.push(item)
      }
      if (r.tipo === 'SERVICIO') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaServiciosByViaRegimen.push(item)
      }
      // if (r.tipo === 'EVE') {
      //   const item = {
      //     label: r.nombre,
      //     value: r.codigo
      //   }
      //   this.ListaTodosEventoTarea.push(item)
      // }
      if (r.tipo === 'DESCSERVL') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTodosDescripcionServiciosLocales.push(item)
      }
      if (r.tipo === 'CODBASE') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTodosCodBase.push(item)
      }
      // if (r.tipo === 'TRANSPORTISTA') {
      //   const item = {
      //     label: r.nombre,
      //     value: r.codigo
      //   }
      //   this.ListaTodosTransportista.push(item)
      // }

      if (r.tipo === 'FLE') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTodosCondicion.push(item)
      }
      if (r.tipo === 'EVE') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTodosEventoTarea.push(item)
      }
    })

    console.log("Lista de ProveedoresG: ", this.ListaBase)
    console.log("Tipos de entidad", this.ListaTipoEntidad)
    console.log("Lista de ServiciosByViaRegimen", this.ListaServiciosByViaRegimen)

    this.metadatableFleteEmbarque = [
      { title: 'ITEM', field: 'dcot_item', filterType: 'text', inputType: 'text' },
      { title: 'COMODITY', field: 'cdt_desc', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaComodity },//?
      { title: 'PRODUCTO', field: 'dcot_producto', filterType: 'text', inputType: 'text' },
      { title: 'BULTOS', field: 'dcot_bultos', filterType: 'numeric', inputType: 'decimal' },
      { title: 'UNIDAD MEDIDA', field: 'tipo_tabunm', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaUnidadMedida },
      { title: 'PESO (Kg)', field: 'dcot_peso', filterType: 'numeric', inputType: 'decimal' },
      { title: 'VOLUMEN (m3)', field: 'dcot_volumen', filterType: 'numeric', inputType: 'decimal' },
      { title: 'LARGO (cm)', field: 'dcot_largo', filterType: 'numeric', inputType: 'decimal' },
      { title: 'ANCHO (cm)', field: 'dcot_ancho', filterType: 'numeric', inputType: 'decimal' },
      { title: 'ALTO (cm)', field: 'dcot_alto', filterType: 'numeric', inputType: 'decimal' },
    ]

    this.metadatableItinerario = [
      { title: 'TRAMO', field: 'ovnv_item', filterType: 'numeric', inputType: 'int' },
      { title: 'FEEDER', field: 'nvia_esfeeder', filterType: 'boolean', inputType: 'checkbox' },
      { title: 'FINAL', field: 'ovnv_final', filterType: 'boolean', inputType: 'checkbox' },
      { title: 'CÓDIGO VIAJE', field: 'nvia_codigo', filterType: 'text', inputType: 'text', popup: 'y', datosDialogTable: this.datosViajePorPuerto2 },
      { title: 'NAVE', field: 'nave_nombre', filterType: 'text', inputType: 'text' },
      { title: 'VIAJE', field: 'nvia_nroviaje', filterType: 'text', inputType: 'text' },
      { title: 'ETD', field: 'etd', filterType: 'text', inputType: 'date' },
      { title: 'FECHA EMBARQUE', field: 'fechaembarque', filterType: 'text', inputType: 'date' },
      { title: 'ETA', field: 'eta', filterType: 'text', inputType: 'date' },
      { title: 'POL', field: 'nameportloading', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaPuertosByVia },
      { title: 'POD', field: 'nameportdischarge', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaPuertosByVia },
    ]

    this.metadatableDataNave = [
      { title: 'TRAMO', field: 'ovnv_item', filterType: 'text', inputType: 'text' },
      { title: 'ETA CONTABLE', field: 'eta_contable', filterType: 'text', inputType: 'date' },
      { title: 'ETA OPERATIVA', field: 'eta_operativo', filterType: 'text', inputType: 'date' },
      { title: 'FECHA CUTOFF', field: 'nvia_feccutoff_expo', filterType: 'text', inputType: 'date' },
      { title: 'FECHA LLEGADA', field: 'fechallegada', filterType: 'text', inputType: 'date' },
      { title: 'FECHA ZARPE', field: 'fechazarpe', filterType: 'text', inputType: 'date' },
      { title: 'FECHA ARRIBO', field: 'fechaarribo', filterType: 'text', inputType: 'date' },
      { title: 'FECHA CIERRE DIRECC / TERMINO DE E.', field: 'nvia_feccierredire_impo_termemba_expo', filterType: 'text', inputType: 'date' },
      { title: 'FECHA DESCARGA', field: 'nvia_fecdescarga_impo', filterType: 'text', inputType: 'date' },
      { title: 'FECHA PLAZO VOBO', field: 'nvia_fecplazovistobueno', filterType: 'text', inputType: 'date' },
      { title: 'NRO. MANIFIESTO', field: 'nvia_nromanifiesto', filterType: 'text', inputType: 'text' },
      { title: 'NRO. MANIFIESTO DESCON', field: 'nvia_normanifiestodescon', filterType: 'text', inputType: 'text' },
      { title: 'FECHA RECOGER DOCS', field: 'entc_fecrecogerdocs', filterType: 'text', inputType: 'date' },
      { title: 'TERMINAL PORTUARIO', field: 'terminalportuario', filterType: 'text', inputType: 'text' },
      { title: 'AGENTE PORTUARIO VOBO', field: 'agenteportuariovobo', filterType: 'text', inputType: 'text' },
    ]

    this.metadatableDataArchivos = [
      { title: 'FECHA', field: 'ovar_fecemi', filterType: 'text', inputType: 'date' },
      { title: 'DESCRIPCIÓN', field: 'ovar_descrip', filterType: 'text', inputType: 'text' },
      { title: 'OBSERVACIÓN', field: 'ovar_observacion', filterType: 'text', inputType: 'text' },
      { title: 'DESCARGAR', field: 'ovar_descargar', filterType: 'text', inputType: 'text', showDowndloadButton: true },
    ]

    this.metadatableDataEventos = [
      { title: 'ITEM', field: 'even_item', filterType: 'text', inputType: 'text' },
      { title: 'EVENTO/TAREA', field: 'tipo_desc1', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTodosEventoTarea },
      { title: 'MODULO', field: 'cons_desc_spa', filterType: 'text', inputType: 'text' },
      { title: 'FECHA', field: 'even_fecha', filterType: 'text', inputType: 'date' },
      { title: 'CUMPLIDA', field: 'even_cumplida', filterType: 'boolean', inputType: 'checkbox' },
      { title: 'USUARIO', field: 'even_usuario', filterType: 'text', inputType: 'text' },
      { title: 'OBSERVACIONES', field: 'even_observaciones', filterType: 'text', inputType: 'text', maxWidth: '300px' },
      { title: '', field: 'even_observaciones_detalle', filterType: 'text', inputType: 'text', maxWidth: '20px', popup: 'verDetalle', popupTitle: 'OBSERVACIONES DEL EVENTO' }
    ]

    this.metadatableDataServicios = [
      { title: 'SERVICIO', field: 'conceptdescription', filterType: 'text', inputType: 'text' },
      { title: 'UNIDAD', field: 'unitdescription', filterType: 'text', inputType: 'text' },
      { title: 'MONEDA', field: 'currency', filterType: 'text', inputType: 'text' },//???????
      { title: 'IMPORTE VENTA', field: 'salesamount', filterType: 'text', inputType: 'text' },
    ]

    this.metadatableDataObservaciones = [
      { title: 'FECHA', field: 'even_fecha', filterType: 'text', inputType: 'text' },
      { title: 'USUARIO', field: 'even_usuario', filterType: 'text', inputType: 'text' },
      { title: 'OBSERVACIONES', field: 'even_observaciones', filterType: 'text', inputType: 'text' },//??????
      { title: 'ARCHIVO', field: 'even_filename', filterType: 'text', inputType: 'text' },

    ]

    this.metadatableTarifa = [
      { title: 'TARIFA', field: 'idtariff', filterType: 'text', inputType: 'int' },
      { title: 'VIGENTE DESDE', field: 'vigente_desde', filterType: 'text', inputType: 'date' },
      { title: 'VIGENTE HASTA', field: 'vigente_hasta', filterType: 'text', inputType: 'date' },
      { title: 'DESCRIPCIÓN', field: 'descripcion', filterType: 'text', inputType: 'text' },
      { title: 'CONCEPTO', field: 'concepto', filterType: 'text', inputType: 'text' },
      { title: 'UNIDAD BASE', field: 'unidad_base', filterType: 'text', inputType: 'text' },
      { title: 'MONEDA', field: 'moneda', filterType: 'text', inputType: 'text' },
      { title: 'IMPORTE VENTA', field: 'importe_venta', filterType: 'text', inputType: 'decimal' },
      { title: 'UNIDAD NEGOCIO', field: 'unidad_negocio', filterType: 'text', inputType: 'text' },
      { title: 'PLANTILLA', field: 'plantilla', filterType: 'text', inputType: 'int' },
      { title: 'AGENTE PORTUARIO', field: 'agente_portuario', filterType: 'text', inputType: 'text' },
      { title: 'ALMACÉN', field: 'almacen', filterType: 'text', inputType: 'text' },
      { title: 'CLIENTE', field: 'cliente', filterType: 'text', inputType: 'text' },
      { title: 'RÉGIMEN', field: 'regimen', filterType: 'text', inputType: 'text' },
      { title: 'SEGMENTO DE CLIENTE', field: 'segmento_de_cliente', filterType: 'text', inputType: 'text' },
      { title: 'TERMINAL PORTUARIO', field: 'terminal_portuario', filterType: 'text', inputType: 'text' },
      { title: 'TRANSPORTISTA', field: 'transportista', filterType: 'text', inputType: 'text' },
      { title: 'VÍA', field: 'via', filterType: 'text', inputType: 'text' },
    ]

    this.metadatableDataDetalle = [
      { title: 'DESCRIPCIÓN', field: 'serv_nombre_spa', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTodosDescripcionServiciosLocales },
      { title: 'UNIDAD BASE', field: 'cons_desc_spa', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaBase },
      { title: 'TIPO ENTIDAD', field: 'tipoentidad', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTipoEntidad, },
      { title: 'RAZÓN SOCIAL', field: 'entc_razonsocial', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaProveedorG },
      { title: 'VENTA (UNIDAD)', field: 'dope_preciounitvta', filterType: 'text', inputType: 'decimal', operacion: 'multiplicar', rowFooter: 'sum' },
      { title: 'MONEDA', field: 'nombremnd', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaMoneda },
      { title: 'CANTIDAD', field: 'dope_cantidad', filterType: 'text', inputType: 'int', operacion: 'multiplicar' },
      { title: 'VENTA (TOTAL)', field: 'dope_preciototvta', filterType: 'text', inputType: 'decimalResultado', rowFooter: 'sum' },
    ]

    this.datosRecibidos = JSON.parse(localStorage.getItem('datoov'))
    this.datos = this.datosRecibidos

    if (this.datos) {
      console.log("Los datos traidos son: ", this.datos);
    } else {
      console.log("No hay datos de FLETE o this.datos es null");
    }


    if (this.datos && this.datos['listadoservicio']) {

      this.serviciosytarifas_dataservicios = this.datos['listadoservicio'];

      console.log("Los datos de Servicios: ", this.serviciosytarifas_dataservicios);

    } else {

      console.log("No hay datos de SERVICIOS o this.datos es null");

    }

    if (this.datos && this.datos['listadoseguro']) {

      this.serviciosytarifas_dataseguro = this.datos['listadoseguro'];

      console.log("Los datos de SEGURO: ", this.serviciosytarifas_dataseguro);

    } else {

      console.log("No hay datos de SEGURO o this.datos es null");

    }

    if (this.datos && this.datos['listadoviajeitinerario']) {

      this.viajeitinerario_dataitinerario = this.datos['listadoviajeitinerario'];
      // modificar aqui                          

      console.log("Los datos de  VIAJE ITINERARIO: ", this.viajeitinerario_dataitinerario);

    } else {

      console.log("No hay datos de VIAJE ITINERARIO o this.datos es null");

    }

    if (this.datos && this.datos['listadodetallenaveviaje']) {

      this.viajeitinerario_datanave = this.datos['listadoviajeitinerario'];

      console.log("Los datos de DETALLENAVEVIAJE: ", this.viajeitinerario_datanave);

    } else {

      console.log("No hay datos de DETALLENAVEVIAJE o this.datos es null");

    }

    if (this.datos && this.datos['listadoarchivo']) {

      this.archivos_dataarchivos = this.datos['listadoarchivo'];

      console.log("Los datos de ARCHIVO: ", this.archivos_dataarchivos);

    } else {

      console.log("No hay datos de ARCHIVO o this.datos es null");

    }

    if (this.datos && this.datos['listadoeventostareas']) {

      this.eventostareas_dataeventos = this.datos['listadoeventostareas'];

      console.log("Los datos de EVENTOS TAREAS: ", this.eventostareas_dataeventos);

    } else {

      console.log("No hay datos de EVENTOS TAREAS o this.datos es null");

    }

    if (this.datos && this.datos['listadoseguimientoembarque']) {

      this.seguimiento_Embarque = this.datos['listadoseguimientoembarque'];

      console.log("Los datos de SeguimientoEmbarque: ", this.seguimiento_Embarque);

    } else {

      console.log("No hay datos de SeguimientoEmbarque o this.datos es null");

    }

    if (this.datosViajePorPuerto2) {

      console.log("Los datos de NAVEVIAJEPORPUERTO: ", this.datosViajePorPuerto2);

    } else {

      console.log("No hay datos de NAVEVIAJEPORPUERTO o this.datos es null");

    }

    //-----------------------------------------

    if (this.datos && this.datos['listadooperaciones']) {

      this.ListadoOperaciones = this.datos['listadooperaciones'];

      console.log("Los datos de ListadoOperaciones: ", this.ListadoOperaciones);

    } else {

      console.log("No hay datos de ListadoOperaciones o this.datos es null");

    }

    if (this.datos && this.datos['listadogrupopaquete']) {

      this.ListadoGrupoPaquete = this.datos['listadogrupopaquete'];

      console.log("Los datos de ListadoGrupoPaquete: ", this.ListadoGrupoPaquete);

    } else {

      console.log("No hay datos de ListadoGrupoPaquete o this.datos es null");

    }

    if (this.datos && this.datos['listadoserviciocontenedores']) {

      this.ListadoServicioContenedores = this.datos['listadoserviciocontenedores'];

      console.log("Los datos de ListadoServicioContenedores: ", this.ListadoServicioContenedores);

    } else {

      console.log("No hay datos de ListadoServicioContenedores o this.datos es null");

    }

    if (this.datos && this.datos['listadotarifaasociada']) {

      this.ListadoTarifaAsociada = this.datos['listadotarifaasociada'];

      console.log("Los datos de ListadoTarifaAsociada: ", this.ListadoTarifaAsociada);

    } else {

      console.log("No hay datos de ListadoTarifaAsociada o this.datos es null");

    }

    if (this.datos && this.datos['listadodetalleasociada']) {//Es una tabla

      this.servicioslocales_dataservicios = this.datos['listadodetalleasociada'];

      console.log("Los datos de ListadoDetalleAsociada: ", this.servicioslocales_dataservicios);

    } else {

      console.log("No hay datos de ListadoDetalleAsociada o this.datos es null");

    }

    if (this.datos && this.datos['listadoserviciosasociados']) {//Es una tabla

      this.servicioslocales_datadetalle = this.datos['listadoserviciosasociados'];

      console.log("Los datos de ListadoServiciosAsociados: ", this.servicioslocales_datadetalle);

    } else {

      console.log("No hay datos de ListadoServiciosAsociados o this.datos es null");

    }

    if (this.datos && this.datos['listadocontrolasociados']) {

      this.ListadoControlAsociados = this.datos['listadocontrolasociados'];

      console.log("Los datos de ListadoControlAsociados: ", this.ListadoControlAsociados);

    } else {

      console.log("No hay datos de ListadoControlAsociados o this.datos es null");

    }

    if (this.datos && this.datos['listadoeventosasociados']) {//Es un tabla

      this.servicioslocales_dataObservaciones = this.datos['listadoeventosasociados'];

      console.log("Los datos de ListadoEventosAsociados: ", this.servicioslocales_dataObservaciones);

    } else {

      console.log("No hay datos de ListadoEventosAsociados o this.datos es null");

    }

    //console.log(this.datos)
    //localStorage.removeItem('datoov');
    const opcion = localStorage.getItem('Opcion')
    console.log("La opcion es: ", opcion)
    console.log("Son los datos Recibidos: ", this.datosRecibidos);


    // agregar llamado a la api de regimen aqui


    //console.log("FECHA ZZ es: ", datosRecibidos.ccot_fecemi)

    //Daniel


    if (opcion == 'EDITAR' || opcion === 'VISUALIZAR') {
      //console.log("Entró a EDITAR")
      if (this.datos && this.datos['listadofletes']) {
        this.serviciosytarifas_dataflete = this.datos['listadofletes'];
        this.datosembarque_dataflete = this.datos['listadofletes'];
        // this.serviciosytarifas_dataservicios = this.datos['listadoservicio'];

        console.log("Los datos de FLETES: ", this.serviciosytarifas_dataflete);
      } else {
        console.log("No hay datos de FLETE o this.datos es null");
      }
      //console.log(this.datosRecibidos["listadooperaciones"])

      this.item2 = {

        // para mostar datos traidos
        //=====DATOS GENERALES============
        ccot_numdoc: this.datosRecibidos.ccot_numdoc,
        ccot_numdoccot: this.datosRecibidos.ccot_numdoccot,
        cons_codest: this.datosRecibidos.cons_codest,
        cons_descest: this.datosRecibidos.cons_descest,
        cons_codrgm: this.datosRecibidos.cons_codrgm,
        cons_descrgm: this.datosRecibidos.cons_descrgm,
        cons_codvia: this.datosRecibidos.cons_codvia,
        cons_descvia: this.datosRecibidos.cons_descvia,
        cons_codlng: this.datosRecibidos.cons_codlng,
        cons_descfle: this.datosRecibidos.cons_descfle,
        ccot_propia: this.datosRecibidos.ccot_propia,
        ccot_enviaavisollegada: this.datosRecibidos.ccot_enviaavisollegada,
        ccot_serviciologistico: this.datosRecibidos.ccot_serviciologistico,
        ccot_serviciotransmision: this.datosRecibidos.ccot_serviciotransmision,
        ccot_fecemi: this.datosRecibidos.ccot_fecemi,
        ccot_fecemiformat: this.datosRecibidos.ccot_fecemiformat,
        ccot_fecvcto: this.datosRecibidos.ccot_fecvcto,
        ccot_fecaprueba: this.datosRecibidos.ccot_fecaprueba,
        ddov_fecembarque: this.datosRecibidos.ddov_fecembarque,
        entc_nomcliente: this.datosRecibidos.entc_nomcliente,
        entC_NomCustomer: this.datosRecibidos.entc_nomcustomer,
        entc_nomagente: this.datosRecibidos.entc_nomagente,
        tipo_codinc: this.datosRecibidos.tipo_codinc,
        ccot_ordencompra: this.datosRecibidos.ccot_ordencompra,
        entc_nomejecutivo: this.datosRecibidos.entc_nomejecutivo,
        ccot_numpresupuesto: this.datosRecibidos.ccot_numpresupuesto,

        //=====SERVICIOS Y TARIFA============================
        ///---datos de contrato
        entc_nomtransportista: this.datosRecibidos.entc_nomtransportista,
        puer_nombretrasbordo: this.datosRecibidos.puer_codtrasbordo,

        tipo_codtrf: this.datosRecibidos.tipo_codtrf,
        puer_nombreorigen: this.datosRecibidos.puer_codorigen,
        puer_nombredestino: this.datosRecibidos.puer_coddestino,
        tipo_codmnd: this.datosRecibidos.tipo_codmnd,
        cons_codtft: this.datosRecibidos.cons_codtft,
        ccot_fectarifa: this.datosRecibidos.ccot_fectarifa,
        cons_codfle: this.datosRecibidos.cons_codfle,//Es la condicion?
        cont_numero: this.datosRecibidos.cont_numero,
        cont_descrip: this.datosRecibidos.cont_descrip,
        ///--flete
        ccot_pagombl: this.tranformarDa(this.datosRecibidos.ccot_pagombl),
        ccot_pagohbl: this.tranformarDa(this.datosRecibidos.ccot_pagohbl),
        dcot_totaluniventa: this.datosRecibidos["listadofletes"].length > 0 ? this.datosRecibidos["listadofletes"][0].dcot_totaluniventa : null,
        //Dias Libres sobre Estadia es lo mimso que está en Observaciones
        //--servicios        
        dcot_preciounicosto: this.datosRecibidos.dcot_preciounicosto,
        //--seguro
        //DATOS EMBARQUE=============================
        ccot_cargarefrigerada: this.datosRecibidos.ccot_cargarefrigerada,
        ccot_cargapeligrosa: this.datosRecibidos.ccot_cargapeligrosa,
        ccot_roundtrip: this.datosRecibidos.ccot_roundtrip,
        ccot_temperatura: this.datosRecibidos.ccot_temperatura,
        tipo_codimo: this.datosRecibidos.tipo_codimo,
        ccot_imoun: this.datosRecibidos.ccot_imoun,
        entc_nomshipper: this.datosRecibidos.entc_nomshipper,
        entc_nomconsignee: this.datosRecibidos.entc_nomconsignee,
        entc_nomnotify: this.datosRecibidos.entc_nomnotify,
        doov_codreferencia: this.datosRecibidos.doov_codreferencia,
        //OBSERVACIONES-----------
        ccot_almacenaje: this.datosRecibidos.ccot_almacenaje,
        ccot_sobreestadia: this.datosRecibidos.ccot_sobreestadia,
        ccot_tiempoviaje: this.datosRecibidos.ccot_tiempoviaje,
        ccot_validezoferta: this.datosRecibidos.ccot_validezoferta,
        ccot_observaciones: this.datosRecibidos.ccot_observaciones,
        //VIAJE ITENERARIO------------------
        //NOVEDADES--------------------
        ddov_nrobooking: this.datosRecibidos.ddov_nrobooking,
        //fechaRecepcion
        entc_nomdeptemporal: this.datosRecibidos.entc_nomdeptemporal,
        ddov_hblnieto: this.datosRecibidos.ddov_hblnieto,
        doov_hbl: this.datosRecibidos.doov_hbl,
        doov_mbl: this.datosRecibidos.doov_mbl,
        doov_cntr: this.datosRecibidos.doov_cntr,
        nvia_codigo: this.datosRecibidos.nvia_codigo,
        //zARPE
        //ETA
        nave_nombre: this.datosRecibidos.nave_nombre,
        //ARCHIVO------------------
        //EVENTOS/TAREAS-------------



        //SERVICIOS LOCALES-----------
        cope_codigo: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].cope_codigo : 0,
        cope_numdoc: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].cope_numdoc : null,
        cope_fecemi: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].cope_fecemi : null,
        entc_nombrecliente: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].entc_nombrecliente : null,
        entc_nombretransportista: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].entc_nombretransportista : null,
        entc_nombrealmacen: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].entc_nombrealmacen : null,
        entc_nombreagenciamaritima: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].entc_nombreagenciamaritima : null,
        entc_nombreterminalportuario: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].entc_nombreterminalportuario : null,
        descripcioncondicionembarque: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].descripcioncondicionembarque : null,
        // descripcioncondicionembarque: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].terr : null,
        //Transportista (Jalado de arriba)?????
        //SADA???
        //IMO?????
        ctar_codigo: this.datosRecibidos["listadotarifaasociada"].length > 0 ? this.datosRecibidos["listadotarifaasociada"][0].ctar_codigo : null,
        tarifftype: this.datosRecibidos["listadotarifaasociada"].length > 0 ? this.datosRecibidos["listadotarifaasociada"][0].tarifftype : null,
        validfrom: this.datosRecibidos["listadotarifaasociada"].length > 0 ? this.datosRecibidos["listadotarifaasociada"][0].validfrom : null,
        validto: this.datosRecibidos["listadotarifaasociada"].length > 0 ? this.datosRecibidos["listadotarifaasociada"][0].validto : null,
        remarks: this.datosRecibidos["listadotarifaasociada"].length > 0 ? this.datosRecibidos["listadotarifaasociada"][0].remarks : null,
        //observaciones
      };
      this.filtrarPuertosSegunVia()
      console.log("Datos FORMATEADOS:", this.item2)
      this.inicializarFormularios(this.item2)
      console.log("ngoninit this.ListaTodosPuertoOrigenyDestino", this.ListaTodosPuertoOrigenyDestino)
      console.log("ngoninit this.datosRecibidos.puer_codorigen",this.datosRecibidos.puer_codorigen)

      this.group_serviciostarifa.get('puertoorigen').setValue(this.datosRecibidos.puer_codorigen);
      this.group_serviciostarifa.get('puertodestino').setValue(this.datosRecibidos.puer_coddestino);
      this.group_serviciostarifa.get('puertotransbordo').setValue(this.datosRecibidos.puer_codtrasbordo);
      
      // console.log("this.datosRecibidos.puer_coddestino", this.datosRecibidos.puer_coddestino)
      // console.log("this.datosRecibidos.puer_codorigen", this.datosRecibidos.puer_codorigen)
      // console.log("puer_nombretrasbordo: this.datosRecibidos.puer_codtrasbordo", this.datosRecibidos.puer_codtrasbordo)
      // console.log("this.ListaTodosPuertoOrigen", this.ListaTodosPuertoOrigen)
      this.obtenerArchivos()

      //this.group_serviciostarifa.get('puertoorigen').setValue(this.ListaTodosPuertoOrigen.find(option => option.nombre === this.datosRecibidos.puer_nombreorigen))

      //console.log(this.group_serviciostarifa.get('puertoorigen').value.nombre)
      this.date = this.group_datosgenerales.get('fechaemision')?.value;
      console.log("La fecha es<<<: ", this.date);

      this.readOnlyCodigoOperacionDSL = true
      this.readOnlyNumeroOpeacionDSL = true
      this.readonlyFechaEmisionDSL = true
      this.readOnlyClienteDSL = true
      this.readOnlyTransportistaAMDSL = true
      this.readOnlyAlmacenDSL = true
      this.readOnlyAgenciaMaritimaDSL = true
      this.readOnlyTerminalPortuarioDSL = true
      this.readOnlyCondicionEmbarqueDSL = true
      this.readonlySADADSL = true
      this.readonlyIMODSL = true
      this.readOnlyVentaTotalDSL = true

      this.inicializarValoresSL()





      //Cambiando el acceso a cada dato
      switch (opcion) {
        case 'EDITAR':
          if (this.datosRecibidos.cons_codest = "004") {
            console.log("Entro a 004")
            //Datos Generales
            this.readOnlyNumeroDGDG = true;
            this.readOnlyNumeroCotizacionDG = true;
            // this.readOnlyEstadoDG = true;
            // this.readonlyRegimenDG = true;
            // this.readonlyViaDG = true;
            // this.readonlyFEmisionDG = true;
            // // this.readonlyFVencimientoDG = true;
            // // this.readonlyFAprobacionDG = true;
            // this.readonlyFEmbarqueDG = true;
            // // this.readOnlyClienteDG = true;
            // this.readonlyAvisoLl_zDG = true;
            // this.readonlyServicioDG = true;
            // this.readOnlyCustomerDG = true;
            // this.readOnlyAgenteDG = true;
            // this.readOnlyEjecutivoDG = true;
            // this.readOnlyNoCRMDG = true;
            // this.readonlyCotPropiaDG = true;
            // this.readonlyLineaNegocioDG = true;

            // // servicios y tarifas
            // this.readonlyTipoFechaTarifaST = true;
            // this.disabledTrafico = true;
            // this.readOnlyFleteTotal = true;
            // this.readOnlyViaje = true;
            // this.disabledFZarpe = true;
            // this.disabledFEtaEtd = true;
            // this.readOnlyNave = true;
            // this.readOnlyUsuario = true;
            // this.readOnlyCodigoOperacion = true;
            // this.|Opeacion = true;
            // this.readOnlyClienteSL = true;
            // this.readOnlyTransportistaAM = true;
            // this.readOnlyAlmacen = true;
            // this.readOnlyAgenciaMaritima = true;
            // this.readOnlyTerminalPortuario = true;
            // this.readOnlyCondicionEmbarque = true;
            // this.group_serviciostarifa.get('puertoorigen')?.setValue(this.ListaTodosPuertoOrigen.find(option => option.nombre === this.datosRecibidos.puer_nombreorigen))

            // let puertos = this.ListaTodosPuertoOrigen.map(puerto => {
            //   return { codigo: puerto.codigo, nombre: puerto.nombre };
            // });

            // console.log("puertos mapeados:", puertos);

            // console.log(this.group_serviciostarifa.get('puertoorigen').value.nombre)
            // console.log("impresion lista puertos", this.ListaTodosPuertoOrigen)

          }
          // this.filtrarPuertosSegunVia()
          this.buscarServicios();
          // this.group_datosgenerales.get('estado')?.disable();
          this.buscarTarifas()
          this.buscarPuertosByVia()
          // this.filtrarPuertosSegunVia()
          break;
        case 'VISUALIZAR':
          this.readOnlyNumeroDGDG = true;
          this.readOnlyNumeroCotizacionDG = true;
          this.readOnlyEstadoDG = true;
          this.readOnlyNoCRMDG = true;
          this.readonlyOrdenCompraDG = true;
          this.readonlyRegimenDG = true;
          this.readonlyViaDG = true;
          this.readonlyLineaNegocioDG = true;
          this.readonlyFEmisionDG = true;
          this.readonlyFVencimientoDG = true;
          this.readonlyFAprobacionDG = true;
          this.readonlyFEmbarqueDG = true;
          this.readOnlyClienteDG = true;
          this.readOnlyCustomerDG = true;
          this.readOnlyAgenteDG = true;
          this.readOnlyEjecutivoDG = true;
          this.readonlyIncotermDG = true;
          this.readonlyServLogisticoDG = true;
          this.readonlyServicioDG = true;
          this.readonlyCotPropiaDG = true;
          this.visibledCotPropiaDG = true;
          this.readonlyAvisoLl_zDG = true;

          // --Servicios y Tarifa
          this.readonlyTransportisaSTST = true;
          this.readonlyPuertoTransbordoST = true;
          this.readonlydTraficoST = true;
          this.readonlyPuertoOrigenST = true;
          this.readonlyPuertoDestinoST = true;
          this.readonlyMonedaST = true;
          this.readonlyTipoFechaTarifaST = true;
          this.readonlyFechaTarifaST = true;
          this.readonlyCondicionST = true;
          this.readonlyNumeroContratoST = true;
          this.readonlyAdicionalST = true;
          this.readonlyPCMblST = true;
          this.readonlyPCHblST = true;
          this.readOnlyFleteTotalST = true;
          this.readonlyDiasLibresSobreeestadiaST = true;

          // --Datos Embarque
          this.readonlyCargaRefrigeradaDE = true;
          this.readOnlyTemperaturaDE = true;
          this.readonlyCargaPeligrosaDE = true;
          this.readonlyImoClassDE = true;
          this.readonlyRoundTripDE = true;
          this.readOnlyImoUnDE = true;
          this.readonlyShipperDE = true;
          this.readonlyReferenciaDE = true;
          this.readonlyConsigneeDE = true;
          this.readonlyNotifyDE = true;

          // --Observaciones
          this.readonlyAlmacenajeObs = true;
          this.readonlySobreestadiaObs = true;
          this.readonlyTiempoViajeObs = true;
          this.readonlyValidezOfertaObs = true;
          this.readonlyInstruccionesEmbarqueObs = true;

          // --Novedades
          this.readonlyNroBookingNov = true;
          this.readonlyDepositoNov = true;
          this.readonlyBlNietoNov = true;
          this.readonlyBlHijoNov = true;
          this.readonlyBlMasterNov = true;
          this.readonlyContenedorNov = true;
          this.readOnlyViajeNov = true;
          this.readonlyFZarpeNov = true;
          this.readonlyFEtaEtdNov = true;
          this.readOnlyNaveNov = true;

          // --EventosTareas
          this.readonlyTipoEventoTareaET = true;
          this.readonlyFechaYHoraET = true;
          this.readonlyEstadoET = true;
          this.readonlyModuloET = true;
          this.readonlyUsuarioET = true;
          this.readonlyObservacionesET = true;

          // --Servicio Locales
          this.readOnlyCodigoOperacionDSL = true;
          this.readOnlyNumeroOpeacionDSL = true;
          this.readonlyFechaEmisionDSL = true;
          this.readOnlyClienteDSL = true;
          this.readOnlyTransportistaAMDSL = true;
          this.readOnlyAlmacenDSL = true;
          this.readOnlyAgenciaMaritimaDSL = true;
          this.readOnlyTerminalPortuarioDSL = true;
          this.readOnlyCondicionEmbarqueDSL = true;
          this.readonlyTransportistaDSL = true;
          this.readonlySADADSL = true;
          this.readonlyIMODSL = true;
          this.readonlyTarifaDSL = true;
          this.readonlyTipoDSL = true;
          this.readonlyVigenciaDesdeDSL = true;
          this.readonlyVigenciaHastaDSL = true;
          this.readonlyDescripcionDSL = true;
          this.readonlyObservacionesDSL = true;
          // this.group_serviciostarifa.get('puertoorigen').setValue(this.ListaTodosPuertoOrigen.find(option => option.nombre === this.datosRecibidos.puer_nombreorigen))
          // let puertos = this.ListaTodosPuertoOrigen.map(puerto => {
          //   return { codigo: puerto.codigo, nombre: puerto.nombre };
          // });
          // console.log("puertos mapeados:", puertos);
          break;
      }




    } else if (opcion == 'NUEVO') {
      console.log("Entró a NUEVO")
      this.item2 = {
        //=====DATOS GENERALES============
        ccot_numdoc: null,
        ccot_numdoccot: null,
        cons_codest: null,
        cons_descest: null,
        cons_codrgm: null,
        cons_descrgm: null,
        cons_codvia: null,
        cons_descvia: null,
        cons_codlng: null,
        cons_descfle: null,
        ccot_propia: false,
        ccot_enviaavisollegada: false,
        ccot_serviciologistico: false,
        ccot_serviciotransmision: false,
        ccot_fecemi: new Date(),
        ccot_fecemiformat: null,
        ccot_fecvcto: new Date(),
        ccot_fecaprueba: null,
        ddov_fecembarque: null,
        entc_nomcliente: null,
        entC_NomCustomer: null,
        entc_nomagente: null,
        tipo_codinc: null,
        ccot_ordencompra: null,
        entc_nomejecutivo: null,
        ccot_numpresupuesto: null,
        //=====SERVICIOS Y TARIFA============================
        ///---datos de contrato
        entc_nomtransportista: null,
        puer_nombretrasbordo: null,
        tipo_codtrf: null,
        puer_nombreorigen: null,
        puer_nombredestino: null,
        tipo_codmnd: null,
        cons_codtft: null,
        ccot_fectarifa: null,
        cons_codfle: null,//Es la condicion?
        cont_numero: null,
        cont_descrip: null,
        ///--flete
        ccot_pagombl: null,
        ccot_pagohbl: null,
        dcot_totaluniventa: 0.00,
        //Dias Libres sobre Estadia es lo mimso que está en Observaciones
        //--servicios
        dcot_preciounicosto: null,
        //--seguro
        //DATOS EMBARQUE=============================
        ccot_cargarefrigerada: false,
        ccot_cargapeligrosa: false,
        ccot_roundtrip: false,
        ccot_temperatura: 0.00,
        tipo_codimo: null,
        ccot_imoun: null,
        entc_nomshipper: null,
        entc_nomconsignee: null,
        entc_nomnotify: null,
        doov_codreferencia: null,
        //OBSERVACIONES-----------
        ccot_almacenaje: 0,
        ccot_sobreestadia: 0,
        ccot_tiempoviaje: 0,
        ccot_validezoferta: 0,
        ccot_observaciones: " ",
        //VIAJE ITENERARIO------------------
        //NOVEDADES--------------------
        ddov_nrobooking: null,
        //fechaRecepcion
        entc_nomdeptemporal: null,
        ddov_hblnieto: false,
        doov_hbl: null,
        doov_mbl: null,
        doov_cntr: null,
        nvia_codigo: null,
        //zARPE
        //ETA
        nave_nombre: null,
        //ARCHIVO------------------
        //EVENTOS/TAREAS-------------
        //SERVICIOS LOCALES-----------
        cope_codigo: null,
        cope_numdoc: null,
        cope_fecemi: null,
        entc_nombrecliente: null,
        entc_nombretransportista: null,
        entc_nombrealmacen: null,
        entc_nombreagenciamaritima: null,
        entc_nombreterminalportuario: null,
        descripcioncondicionembarque: null,
        ctar_codigo: null,
        tarifftype: null,
        validfrom: null,
        validto: null,
        remarks: null,
      };

      this.group_serviciostarifa.get('diaslibres').setValue(0);
      console.log("Datos FORMATEADOS:", this.item2)
      this.inicializarFormularios(this.item2)
      this.readOnlyNumeroDGDG = true;
      this.readOnlyNumeroCotizacionDG = true;
      this.readOnlyEstadoDG = true;
      this.readOnlyNoCRMDG = true;
      this.readonlyFEmbarqueDG = true;
      this.group_datosgenerales.get('estado')?.setValue('001');
    }
    this.group_eventostareas.get('estado')?.setValue('1');
    //this.buscarServicios() ;

    console.log("this.servicioslocales_datadetalle:", this.servicioslocales_datadetalle)
    console.log("this.ListaTodosCliente", this.ListaTodosCliente)
  }



  //servicio: ItemsServicio_ByViaRegimenResponse[] = [];

  buscarServicios() {
    const prolrequest: BuscarServicio_ByViaRegimenRequest = {
      pchrCONS_TabVia: "VIA",
      pchrCONS_CodVia: this.group_datosgenerales.value["via"] || this.datosRecibidos?.cons_codvia || null,
      pchrCONS_TabRGM: "RGM",
      pchrCONS_CodRGM: this.group_datosgenerales.value["regimen"] || this.datosRecibidos?.cons_codrgm || null,
      pchrCONS_CodLNG: this.group_datosgenerales.value["lineanegocio"] || this.datosRecibidos?.cons_codlng || null,
      pchrCONS_TabLNG: "LNG"
    };

    if (Object.values(prolrequest).some(value => value === '')) {
      // console.warn('Algunos campos requeridos están vacíos o son nulos, no se ejecutará la solicitud.');
      return;
    }

    this.ordenventaService.BuscarServicio_ByViaRegimen(prolrequest).subscribe(
      (response: BuscarServicio_ByViaRegimenResponse) => {
        if (response.datos.status == "200") {
          this.ListaServiciosByViaRegimen = [];
          // console.log(response.datos.result)
          response.datos.result.forEach(item => {
            const servicioItem = {
              label: item.serV_Nombre_SPA,
              value: item.serV_Codigo.toString()
              // styleClass: null,
              // icon: null,
              // title: null,
              // disabled: false,
              // tipo: null
            };
            this.ListaServiciosByViaRegimen.push(servicioItem);
          });
          console.log('Servicios encontrados:', this.ListaServiciosByViaRegimen);
          // Actualiza el dropdown
          this.metadatableServicio[1].dropdown = this.ListaServiciosByViaRegimen;
        }
      }
    );
  }

  buscarPuertosByVia() {
    this.ListaPuertosByVia = this.ListaTodosPuertoOrigenyDestino.map(item => {
      return {
        value: item.codigo,
        label: item.nombre,
        nombre: item.adicional2,
        codigo: item.adicional1
      };
    });
    this.modificarNamePortLoadingyNamePortDischarge(this.ListaPuertosByVia);
    this.metadatableItinerario[9].dropdown = [...this.ListaPuertosByVia];
    this.metadatableItinerario[10].dropdown = [...this.ListaPuertosByVia];
    this.pasarDataPuertosFavoritosByVia(this.ListaPuertosByVia);
    this.metadatableItinerario = [
      { title: 'TRAMO', field: 'ovnv_item', filterType: 'numeric', inputType: 'int' },
      { title: 'FEEDER', field: 'nvia_esfeeder', filterType: 'boolean', inputType: 'checkbox' },
      { title: 'FINAL', field: 'ovnv_final', filterType: 'boolean', inputType: 'checkbox' },
      { title: 'CÓDIGO VIAJE', field: 'nvia_codigo', filterType: 'text', inputType: 'text', popup: 'y', datosDialogTable: this.datosViajePorPuerto2 },
      { title: 'NAVE', field: 'nave_nombre', filterType: 'text', inputType: 'text' },
      { title: 'VIAJE', field: 'nvia_nroviaje', filterType: 'text', inputType: 'text' },
      { title: 'ETD', field: 'etd', filterType: 'text', inputType: 'date' },
      { title: 'FECHA EMBARQUE', field: 'fechaembarque', filterType: 'text', inputType: 'date' },
      { title: 'ETA', field: 'eta', filterType: 'text', inputType: 'date' },
      { title: 'POL', field: 'nameportloading', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaPuertosByVia },
      { title: 'POD', field: 'nameportdischarge', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaPuertosByVia },
    ];
    // console.log('Puertos filtrados encontrados ListaPuertosByVia:', this.ListaPuertosByVia);
    // console.log('Puertos filtrados encontrados:', this.ListaTodosPuertoOrigenyDestino);
  }

  buscarContratoAyudaPivot() {
    const requiredFieldsGeneral = [
      "fechaemision",
      "regimen",
      "via"
    ];

    const requiredFieldsService = [
      "transportista",
      "fechatarifa"
    ];

    const prolrequest: ConsultarContratoAyudaPivotOVRequest = {
      EMPR_Codigo: 1,
      ENTC_CodTransportista: Number(this.ListaTodosTransportista.find(item => item.nombre === this.group_serviciostarifa.value["transportista"])?.codigo),
      CONT_FecDesde: this.util.formatearfecha(this.group_datosgenerales.value["fechaemision"], "yyyy-MM-ddTHH:mm:ss"),
      CONT_FecHasta: this.util.formatearfecha(this.group_serviciostarifa.value["fechatarifa"], "yyyy-MM-ddTHH:mm:ss"),
      CONS_TabRGM: "RGM",
      CONS_CodRGM: this.group_datosgenerales.value["regimen"],
      CONS_TabVIA: "VIA",
      CONS_CodVIA: this.group_datosgenerales.value["via"],
      PUER_CodOrigen: this.group_serviciostarifa.value["puertoorigen"] || null,
      PUER_CodDestino: this.group_serviciostarifa.value["puertodestino"] || null,
      CONT_Codigo: this.group_serviciostarifa.value["cont_numero"] || null
    };

    // console.log("this.group_serviciostarifa.value['transportista']", this.group_serviciostarifa.value["transportista"])
    const missingFieldsGeneral = requiredFieldsGeneral.filter(field => !this.group_datosgenerales.value[field]);
    const missingFieldsService = requiredFieldsService.filter(field => !this.group_serviciostarifa.value[field]);

    const emptyFields = Object.entries(prolrequest)
      .filter(([key, value]) =>
        !["PUER_CodOrigen", "PUER_CodDestino", "CONT_Codigo"].includes(key) && !value
      )
      .map(([key]) => key);

    if (missingFieldsGeneral.length || missingFieldsService.length || emptyFields.length) {
      this.util.ShowMessage({
        titulo: "ERROR",
        mensaje: `Faltan los siguientes campos: ${[...missingFieldsGeneral, ...missingFieldsService].join(", ")}`,
        tipo: "warning"
      });
      return;
    }

    this.ordenventaService.BuscarContratoAyudaPivot(prolrequest).subscribe(
      (response: ConsultarContratoAyudaPivotOVoResponse) => {
        if (response.datos.result.length <= 0) {
          this.util.ShowMessage({
            titulo: "ERROR",
            mensaje: "No se encontraron coincidencias.",
            tipo: "warning"
          });
        } else {
          if (response.datos.status == "200") {
            console.log('Contrato encontrado:', response);
            this.datosCompartidosService.setlistaDatosContratos(response.datos.result);

            const options = {
              disableClose: true,
              panelClass: 'panel'
            };

            const reference = this.util.openModal(BuscarContratoComponent, options);

            reference.subscribe((result) => {
              if (result) {
                //console.log('Datos devueltos del modal:', result);
                this.group_serviciostarifa.get('numerocontrato').setValue(result.codigo);
                this.group_serviciostarifa.get('adicional').setValue(result.descripcion);
              }
            });
          }
        }
      },
      error => {
        console.error('Error en la obtención del contrato:', error);
      }
    );

  }

  modificarNamePortLoadingyNamePortDischarge(listaPuertosByVia: SelectItem[]) {
    if (this.datos != undefined) { this.viajeitinerario_dataitinerario = this.datos['listadoviajeitinerario']; }

    function getUpdatedPortLabel(port, listaPuertos) {
      if (typeof port === 'string') {
        const puertoEncontrado = listaPuertos.find(puerto => puerto.codigo.trim() === port.trim());
        return puertoEncontrado ? puertoEncontrado.label : port;
      } else if (port && port.label) {
        const puertoEncontrado = listaPuertos.find(puerto => puerto.label === port.label);
        return puertoEncontrado ? puertoEncontrado.label : port.label;
      }
      return port;
    }

    if (this.viajeitinerario_dataitinerario && this.viajeitinerario_dataitinerario.length > 0) {
      // console.log("Datos originales:", this.viajeitinerario_dataitinerario);
      this.viajeitinerario_dataitinerario.forEach(itinerario => {
        itinerario.nameportloading = getUpdatedPortLabel(
          itinerario.portloading, listaPuertosByVia
        );
        //console.log("Nameportloading actualizado:", itinerario.nameportloading);
        itinerario.nameportdischarge = getUpdatedPortLabel(
          itinerario.portdischarge, listaPuertosByVia
        );
        //console.log("Nameportdischarge actualizado:", itinerario.nameportdischarge);
      });
    }
    // console.log("Datos actualizados de itinerario:", this.viajeitinerario_dataitinerario);
  }

  updateMetadata() {
    this.metadatableItinerario = this.metadatableItinerario.map(item => {
      if (item.field === 'nameportloading') {
        item.title = 'Nombre del Puerto de Carga';
      }
      if (item.field === 'nameportdischarge') {
        item.title = 'Nombre del Puerto de Descarga';
      }
      return item;
    });

    console.log("Metadata actualizada:", this.metadatableItinerario);
  }

  handleDataSelect(event: any) {
    this.viajeitinerario_dataitinerario.push({
      ovnv_item: event.codigoviaje,
      nvia_esfeeder: event.feeder,
      nave_nombre: event.nombrenave,
      nvia_nroviaje: event.viaje_vuelo,
      eta: event.eta_etd,
      nameportdischarge: event.puerto
    });
  }

  buscarTarifas() {
    const transportistaCodigo = this.group_serviciostarifa.value["transportista"];
    if (!transportistaCodigo) {
      this.util.ShowMessage({
        titulo: "ERROR",
        mensaje: `Debe elegir a un transportista.`,
        tipo: "warning"
      });
      return;
    }

    const prolrequest: ConsultarTarifaRequest = {
      IdBusinessUnit: null,
      DateFrom: this.group_servicioslocales.value["vigenciadesde"] || null,
      DateTo: this.group_servicioslocales.value["vigenciahasta"] || null,
      TariffStatus: null,
      ENTC_CodTransportista: Number(this.ListaTodosTransportista.find(item => item.nombre === this.group_serviciostarifa.value["transportista"])?.codigo),
    };

    this.ordenventaService.BuscarTarifasServiciosLocales(prolrequest).subscribe(
      (response: ConsultarTarifaResponse) => {
        if (response.datos.result.length <= 0) {
          this.util.ShowMessage({
            titulo: "ERROR",
            mensaje: "No se encontraron coincidencias.",
            tipo: "warning"
          });
        } else {
          if (response.datos.status == "200") {
            console.log('Tarifas encontradas:', response);
            if (response.datos.result.length > 0) { this.servicioslocales_tarifa = response.datos.result }
          }
        }
      },
      error => {
        console.error('Error en la obtención del contrato:', error);
      }
    );

  }

  //Para listar en cada cbx: 
  // --DatosGenerales:
  ListaTodosEstado: any[] = []
  ListaTodosRegimen: any[] = []
  ListaTodosVia: any[] = []
  ListaTodosLineaNegocio: any[] = []
  ListaTodosCliente: any[] = []
  ListaTodosCustomer: any[] = []
  ListaTodosAgentes: any[] = []
  ListaTodosIncoterm: any[] = []
  ListaTodosEjecutivos: any[] = []
  // --ServicioYTarifa
  ListaTodosServicios: any[] = []
  ListaTodosTransportista: any[] = []//dANIEL 24
  ListaTodosPuertoTransbordo: any[] = []
  ListaTodosTrafico: any[] = []
  ListaTodosPuertoOrigen: any[] = []
  ListaTodosPuertoDestino: any[] = []
  ListaTodosMoneda: any[] = []
  ListaTodosTipoFechaTarifa: any[] = []
  ListaTodosCondicion: any[] = []
  ListaTodosPCMBL: any[] = []
  ListaTodosPCHBL: any[] = []
  // --DatosEmbarque
  ListaTodosImoClass: any[] = []
  ListaTodosShipper: any[] = []
  ListaTodosConsignee: any[] = []
  ListaTodosNotify: any[] = []

  // --Novedades
  ListaTodosDeposito: any[] = []

  // --EventosTareas
  ListaTodosEventoTarea: any[] = []
  ListTodosModulo: any[] = []

  // --ServicioLocales

  ListaTodosTarifa: any[] = []
  ListaTodosDescripcionServiciosLocales: any[] = []
  ListaTodosCodBase: any[] = []

  // servicioslocales_dataservicios: any[] = []

  // servicioslocales_datadetalle: any[] = []

  servicioslocales_dataObservaciones: any[] = []

  //ListaTodosDepositoTemp: any[] = []
  ListarParametros() {
    // --DatosGenerales
    this.ListaTodosPuertoOrigen = this.parametros.slice()
      .filter(r => r.tipo === 'PUERTO')
      .map(item => ({
        ...item,
        codigo: Number(item.codigo)
      }));
    // console.log("luego de inicializar Puertos this.ListaTodosPuertoOrigen", this.ListaTodosPuertoOrigen)
    this.ListaTodosEstado = this.parametros.slice().filter(r => r.tipo === 'OVE')
    this.ListaTodosRegimen = this.parametros.slice().filter(r => r.tipo === 'RGM')
    this.ListaTodosVia = this.parametros.slice().filter(r => r.tipo === 'VIA')
    this.ListaTodosLineaNegocio = this.parametros.slice().filter(r => r.tipo === 'LNG')
    this.ListaTodosCliente = this.parametros.slice().filter(r => r.tipo === 'CLIENTE')
    this.ListaTodosCustomer = this.parametros.slice().filter(r => r.tipo === 'CUS')
    // console.log("ListaTodosCustomer", this.ListaTodosCustomer)
    this.ListaTodosAgentes = this.parametros.slice().filter(r => r.tipo === 'AGE')
    this.ListaTodosIncoterm = this.parametros.slice().filter(r => r.tipo === 'INC')
    this.ListaTodosEjecutivos = this.parametros.slice().filter(r => r.tipo === 'EJC')

    // --ServiciosYTarifa
    this.ListaTodosServicios = this.parametros.slice().filter(r => r.tipo === 'SERVICIO')
    this.ListaTodosTransportista = this.parametros.slice().filter(r => r.tipo === 'TRANSPORTISTA')//dANIEL 24
    this.ListaTodosTrafico = this.parametros.slice().filter(r => r.tipo === 'TRF')
    // console.log("ListaTodosTrafico", this.ListaTodosTrafico)

    this.ListaTodosMoneda = this.parametros.slice().filter(r => r.tipo === 'MND')//dANIEL 24
    this.ListaTodosTipoFechaTarifa = this.parametros.slice().filter(r => r.tipo === 'TFT')//dANIEL 24
    // this.ListaTodosCondicion = this.parametros.slice().filter(r => r.tipo === 'FLE')//dANIEL 24
    // console.log("this.ListaTodosCondicion",this.ListaTodosCondicion)
    this.ListaTodosPCMBL = this.parametros.slice().filter(r => r.tipo === 'CTP')//dANIEL 24
    this.ListaTodosPCHBL = this.parametros.slice().filter(r => r.tipo === 'CTP')//dANIEL 24

    // --Datos Embarque    
    this.ListaTodosImoClass = this.parametros.slice().filter(r => r.tipo === 'IMO')//dANIEL 24
    this.ListaTodosShipper = this.parametros.slice().filter(r => r.tipo === 'SHIPPER')//dANIEL 24
    this.ListaTodosConsignee = this.parametros.slice().filter(r => r.tipo === 'CLIENTE')//dANIEL 24
    this.ListaTodosNotify = this.parametros.slice().filter(r => r.tipo == 'CLIENTE')//DANIEL30

    // --Novedades
    this.ListaTodosDeposito = this.parametros.slice().filter(r => r.tipo === 'DepositoTemp')//dANIEL 24

    // --EventoTarea
    this.ListTodosModulo = this.parametros.slice().filter(r => r.tipo === 'MOD')//dANIEL 24

    // -ServicioLocales
    this.ListaTodosTarifa = this.parametros.slice().filter(r => r.tipo === 'WEB')//dANIEL 24
    // this.ListaTodosDescripcionServiciosLocales = this.parametros.slice().filter(r => r.tipo === 'DESCSERVL') // MARIA
    this.ListaTodosCodBase = this.parametros.slice().filter(r => r.tipo === 'CODBASE') // MARIA

  }

  limpiar(value) {
    this.group_datosgenerales.get(value).reset();
    this.CodigosFiltrosAutocomplete[0][value] = 0
  }

  limpiarST(value) {
    this.group_serviciostarifa.get(value).reset();
    this.CodigosFiltrosAutocomplete[0][value] = 0
  }

  limpiarDE(value) {
    this.group_datosembarque.get(value).reset();
    this.CodigosFiltrosAutocomplete[0][value] = 0
  }

  limpiarNovedades(value) {
    this.group_novedades.get(value).reset();
    this.CodigosFiltrosAutocomplete[0][value] = 0
  }

  // CLIENTES - DATOS GENERALES

  CodigosFiltrosAutocomplete = [{
    ejecutivo: 0,
    customer: 0,
    agente: 0,
    broker: 0,
    cliente: 0,
    transportista: 0,
    group_novedades: 0,    /*Daniel23 */
    shipper: 0,
    puertoOrigen: 0,
    puertoDestino: 0,
    puertoTransbordo: 0,
    consignee: 0,
    notify: 0,
    depositoTemp: 0,
  }]

  textoFiltro: string = '';
  // SERVICIOS Y TARIFAS



  showMessage(row: any) {
    console.log(row)
  }

  deleteRow(row: any): void {
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Está seguro de eliminar este item?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const index = this.serviciosytarifas_dataflete.indexOf(row);
        if (index > -1) {
          this.serviciosytarifas_dataflete.splice(index, 1);
        }
      },
      reject: () => {
      }
    });
  }


  //NOVEDADES
  novedades_datanovedades: any[] = [{ a1: '1' }]
  novedades_metadatanovedades: MetadataTable[] = [
    { field: 'a1', title: 'FECHA' },
    { field: '2', title: 'EMAIL' },
    { field: '3', title: 'DESCRIPCIÓN' },
  ]

  //ARCHIVOS
  formatObject(obj) {
    return {
      label: obj.nombre,
      value: obj.codigo
    };
  }

  //EVENTOS TAREAS
  tranformarDa(dato: string): string {
    if (dato === "P") {
      console.log("Entro a P")
      return "C  ";
    } else if (dato === "C") {
      console.log("Entro a C")
      return "P  ";
    }
    return "";
  }

  handleDropdownChange(event: MatSelectChange) {
    const selectedValue = event.value; // Obtiene el valor seleccionado
    console.log("Opción seleccionada:", selectedValue);

    // Si quieres obtener el objeto completo seleccionado en lugar del valor,
    // tendrás que buscarlo en tu lista de parámetros
    const selectedItem = this.parametros.find(param => param.codigo === selectedValue);
    if (selectedItem) {
      console.log('Nombre:', selectedItem.nombre);
      console.log('Código:', selectedItem.codigo);
    } else {
      console.log('No se encontró el ítem seleccionado.');
    }
  }



  list_menu: any = [
    { icon: 'exit_to_app', text: 'Salir' },
    { icon: 'save', text: 'Guardar' }
  ]

  operaciones_menu(tipo: string) {
    console.log(tipo)
    switch (tipo) {
      case 'Salir':
        localStorage.removeItem('datoov');
        localStorage.removeItem('listadoservicio');
        localStorage.removeItem('datoov');
        const rutaComponente = '/comercialordenventa';
        this.router.navigate([rutaComponente]);
        break;
      case 'Guardar':
        //Daniel26
        // this.actualizarCodigosFiltros()
        const datosGuardar = JSON.parse(localStorage.getItem('datoov'))

        const requestViajePorPuerto = <listNaveViajePorPuertoRequest>{}
        const valuesDatosGenerales = this.group_datosgenerales.value   //30
        const valuesServicioTarifa = this.group_serviciostarifa.value
        const valuesDatosEmbarque = this.group_datosembarque.value
        const valuesObservaciones = this.group_observaciones.value
        const valuesNovedades = this.group_novedades.value
        const valuesArchivos = this.group_archivos.value
        const valuesEventosTareas = this.group_eventostareas.value
        const valuesServiciosLocales = this.group_servicioslocales.value

        // obtener datos para el crud
        const request = <CrudOrdenesVentaRequest>{};
        request.pintCCOT_Numero = (this.datosRecibidos && this.datosRecibidos.ccot_numero != null) ? this.datosRecibidos.ccot_numero : 100;// 1. pintCCOT_Numero
        request.psinCCOT_Tipo = 2// PREGUNTAR va siempre como 2 o 1 ? 2. psinCCOT_Tipo
        request.psinEMPR_Codigo = 1//3. psinEMPR_Codigo
        request.psinSUCR_Codigo = 1// 4. psinSUCR_Codigo 1
        request.pvchCCOT_NumDoc = (this.datosRecibidos && this.datosRecibidos.ccot_numdoc != null) ? this.datosRecibidos.ccot_numdoc : "2024-3342"; // 5. pvchCCOT_NumDoc
        request.pintCCOT_NumPadre = null // PREGUNTAR va null o con numero de padre en la bd, se autogenera 6. pintCCOT_NumPadre SIEMPRE NULL
        request.psinCCOT_TipoPadre = null// se envia null pero en la bd sale como 1 7. psinCCOT_TipoPadre NULL
        request.psinCCOT_Version = 1// 8. psinCCOT_Version 1
        request.pbitCCOT_Propia = valuesDatosGenerales["cotizacionpropia"]// 9. pbitCCOT_Propia
        request.pintENTC_CodCliente = this.group_datosgenerales.get('cliente')?.value;
        request.pintENTC_CodTransportista = this.group_serviciostarifa.get('transportista')?.value;
        request.pintENTC_CodBroker = null // 12. pintENTC_CodBroker NULL
        request.pintENTC_CodAgente = this.group_datosgenerales.get('agente')?.value; // 13
        request.pintENTC_CodCustomer = this.group_datosgenerales.get('customer')?.value; // 14
        request.pintENTC_CodEjecutivo = this.group_datosgenerales.get('ejecutivo')?.value; // 15
        request.pintCCOT_NumPresupuesto = valuesDatosGenerales["numerocrm"]// PREGUNTAR como se genera este valor, en la bd a veces es null 16. pintCCOT_NumPresupuesto
        request.pdtmCCOT_FecEmi = this.util.formatearfecha(valuesDatosGenerales["fechaemision"], "yyyy-MM-ddTHH:mm:ss");// 17. pdtmCCOT_FecEmi
        request.pdtmCCOT_FecVcto = this.util.formatearfecha(valuesDatosGenerales["fechavencimiento"], "yyyy-MM-ddTHH:mm:ss");// 18. pdtmCCOT_FecVcto
        request.pbitCCOT_ServicioLogistico = valuesDatosGenerales["serviciologistico"]// 19. pbitCCOT_ServicioLogistico
        request.pbitCCOT_VoBoGateIn = false// PREGUNTAR siempre 0 en la bd 20. pbitCCOT_VoBoGateIn 
        request.pbitCCOT_CargaRefrigerada = valuesDatosEmbarque["cargarefrigerada"]// 21. pbitCCOT_CargaRefrigerada
        request.pnumCCOT_Temperatura = valuesDatosEmbarque["temperatura"]// PREGUNTAR sale siempre con 0.0000 22. pnumCCOT_Temperatura
        request.pbitCCOT_CargaPeligrosa = valuesDatosEmbarque["cargapeligrosa"]// 23. pbitCCOT_CargaPeligrosa
        request.pbitCCOT_Roundtrip = valuesDatosEmbarque["roundtrip"]// 24. pbitCCOT_Roundtrip
        request.pvchCCOT_Observaciones = valuesObservaciones["observaciones"] ? valuesObservaciones["observaciones"] : ""; // 25. pvchCCOT_Observaciones
        request.pdtmCCOT_FecCierre = null// 26. pdtmCCOT_FecCierre NULL 
        request.psinCCOT_Almacenaje = valuesObservaciones["almacenaje"]// 27. psinCCOT_Almacenaje
        request.psinCCOT_SobreEstadia = (valuesObservaciones["sobreestadia"] != null && valuesObservaciones["sobreestadia"] !== " ") ? valuesObservaciones["sobreestadia"] : 0; // 28. psinCCOT_SobreEstadia
        request.psinCCOT_TiempoViaje = valuesObservaciones["tiempoviaje"]// 29. psinCCOT_TiempoViaje
        request.psinCCOT_ValidezOferta = valuesObservaciones["validezoferta"]// 30. psinCCOT_ValidezOferta
        request.pchrTIPO_TabINC = "INC" //PREGUNTAR null o INC, con q esta relacionado // 31. pchrTIPO_TabINC
        request.pchrTIPO_CodINC = valuesDatosGenerales["incoterm"]// 32. pchrTIPO_CodINC
        request.pchrTIPO_TabImo = "IMO"// 33. pchrTIPO_TabImo
        request.pchrTIPO_CodImo = valuesDatosEmbarque["imoclass"]// 34. pchrTIPO_CodImo
        request.pvchCCOT_ImoUN = valuesDatosEmbarque["imoun"]// siempre es null, lo bloqueo ?35. pvchCCOT_ImoUN
        request.pintCONT_Codigo = (this.datosRecibidos && this.datosRecibidos.cont_codigo != null) ? this.datosRecibidos.cont_codigo : this.group_serviciostarifa.value["cont_numero"]; // 36. pintCONT_Codigo 
        request.pchrCONS_TabRGM = "RGM";//37. pchrCONS_TabRGM
        request.pchrCONS_CodRGM = valuesDatosGenerales["regimen"];//38. pchrCONS_CodRGM
        request.pchrCONS_TabVia = "VIA";// 39. pchrCONS_TabVia
        request.pchrCONS_CodVia = valuesDatosGenerales["via"];//40. pchrCONS_CodVia       
        request.pchrCONS_TabFLE = "FLE";// 41. pchrCONS_TabFLE
        request.pchrCONS_CodFLE = valuesServicioTarifa["condicion"]; // 42. pchrCONS_CodFLE
        request.pchrCONS_TabEST = "OVE";// PREGUNTAR es orden de venta y por eso siempre va con ove ? 43. pchrCONS_TabEST 
        request.pchrCONS_CodEST = valuesDatosGenerales["estado"];// PREGUNTAR en nextsoft aparece por defecto ingresado 44. pchrCONS_CodEST
        request.pintPUER_CodDestino = valuesServicioTarifa["puertodestino"];
        request.pintPUER_CodTrasbordo = valuesServicioTarifa["puertotransbordo"]
        request.pintPUER_CodOrigen = valuesServicioTarifa["puertoorigen"]
        request.pvchCCOT_UsrAprueba = null// 48. pvchCCOT_UsrAprueba n
        request.pdtmCCOT_FecAprueba = this.util.formatearfecha(valuesDatosGenerales["fechaaprobacion"], "yyyy-MM-ddTHH:mm:ss")// 49. pdtmCCOT_FecAprueba
        request.pvchCCOT_UsrAceptaRechaza = null// 50. pvchCCOT_UsrAceptaRechaza
        request.pdtmCCOT_FecAceptaRechaza = null// PREGUNTAR que es, no se q dato, no sale en el html va 51. pdtmCCOT_FecAceptaRechaza NULL
        request.pchrTIPO_TabMOT = null // 52. pchrTIPO_TabMOT tipo e y t
        request.pchrTIPO_CodMOT = null // 53. pchrTIPO_CodMOT
        request.pintENTC_CodShipper = valuesDatosEmbarque["shipper"];
        request.pintENTC_CodConsignee = valuesDatosEmbarque["consignee"];
        request.pintENTC_CodNotify = valuesDatosEmbarque["notify"];
        request.pchrCONS_TabTFT = "TFT"// PREGUNTAR siempre TFT o null en la bd 57. pchrCONS_TabTFT
        request.pchrCONS_CodTFT = valuesServicioTarifa["tipofechatarifa"]// 58. pchrCONS_CodTFT
        request.pdtmCCOT_FecTarifa = this.util.formatearfecha(valuesServicioTarifa["fechatarifa"], "yyyy-MM-ddTHH:mm:ss");//.toISOString().slice(0, 19).replace('T', ' ')// 59. pdtmCCOT_FecTarifa
        request.pchrCCOT_PagoMBL = valuesServicioTarifa["pcmbl"]// 60. pchrCCOT_PagoMBL pcmbl
        request.pchrCCOT_PagoHBL = valuesServicioTarifa["pchbl"]// 61. pchrCCOT_PagoHBL
        request.pchrTIPO_TabMND = "MND"// 62. pchrTIPO_TabMND
        request.pchrTIPO_CodMND = valuesServicioTarifa["moneda"]// 63. pchrTIPO_CodMND
        request.pvchDOOV_Cntr = valuesNovedades["contenedor"]// en la bd sale null o con data 64. pvchDOOV_Cntr contenedor
        request.pchrDOOV_HBL = valuesNovedades["blhijo"] //PREGUNTAR se genera automatica en la bd, lo bloqueo ? // 65. pchrDOOV_HBL
        request.pchrDOOV_MBL = valuesNovedades["blmaster"]// PREGUNTAR se genera automatica en la bd, lo bloqueo ? 66. pchrDOOV_MBL
        request.pvchDDOV_NroBooking = valuesNovedades["numerobooking"]// se genera automatico en la bd 67. pvchDDOV_NroBooking
        request.pdtmDDOV_FecRecBooking = null// PREGUNTAR no sale en el html 68. pdtmDDOV_FecRecBooking NULL
        request.pdtmDDOV_FecEmbarque = this.util.formatearfecha(valuesDatosGenerales["fechaembarque"], "yyyy-MM-ddTHH:mm:ss");// no se q valor es 69. pdtmDDOV_FecEmbarque
        request.pintENTC_CodDepTemporal = valuesNovedades["deposito"];
        request.pvchDOOV_CodReferencia = valuesDatosEmbarque["referencia"]// 71. pvchDOOV_CodReferencia
        request.pintNVIA_Codigo = Number(valuesDatosGenerales["via"])// 72. pintNVIA_Codigo
        request.pvchDOOV_Feeder_IMPO = null// PREGUNTAR de donde sale este dato, null o con data en la bd 73. pvchDOOV_Feeder_IMPO NULL
        request.pdtmDOOV_FecETDFeeder_IMPO = null// PREGUNTAR de donde sale este dato, null o con data en la bd 74. pdtmDOOV_FecETDFeeder_IMPO NULL
        request.pbitDDOV_HBLNieto = valuesNovedades["blnieto"]// 75. pbitDDOV_HBLNieto
        request.pbitCCOT_ServicioTransmision = valuesDatosGenerales["servicio"]// 76. pbitCCOT_ServicioTransmision NULL
        request.pchrTIPO_TabTRF = "TRF"// PREGUNTAR TRF o null 77. pchrTIPO_TabTRF
        request.pchrTIPO_CodTRF = valuesServicioTarifa["trafico"]// 78. pchrTIPO_CodTRF
        request.pbitCCOT_EnviaAvisoLlegada = valuesDatosGenerales["avisodellegada"]// 79. pbitCCOT_EnviaAvisoLlegada 
        request.pchrCONS_TabLNG = "LNG"// PREGUNTAR siempre LNG o null en la bd 80. pchrCONS_TabLNG
        request.pchrCONS_CodLNG = valuesDatosGenerales["lineanegocio"]// 81. pchrCONS_CodLNG
        request.pvchAUDI_UsrCrea = this.storage.get("usuario")// 82. pvchAUDI_UsrCrea
        request.pvchAUDI_UsrMod = this.storage.get("usuario")
        request.pvchCCOT_OrdenCompra = null // falta bloquear 83. pvchCCOT_OrdenCompra
        console.log("imprimir datos que se traen para el guardado :", request)

        // obtener datos para los fletes

        this.datosRecibidos = JSON.parse(localStorage.getItem('datoov'));
        const listadofletes = (this.datosRecibidos && this.datosRecibidos['listadofletes']) || [];
        console.log('Listado fletes traídos del store:', listadofletes);
        const listaServicio = (this.datosRecibidos && this.datosRecibidos['listadoservicio']) || [];
        console.log('Listado servicios traídos del store:', listaServicio);
        console.log("serviciosytarifas_dataflete", this.serviciosytarifas_dataflete);

        const insertActualizarOVFlete = {
          items: []
        };

        const datosembarqueMap = new Map<string, any>();
        this.datosembarque_dataflete.forEach(item => {
          if (item.dcot_item) {
            datosembarqueMap.set(item.dcot_item, item);
          }
        });

        request.tablaFlete = request.tablaFlete || [];
        this.serviciosytarifas_dataflete.forEach(item => {
          const datosembarqueItem = datosembarqueMap.get(item.dcot_item);
          const packCod = this.ListaTipoPaquete.find(r => r.label === item.pack_desc);
          const unmCod = this.ListaUnidadMedida.find(r => r.label === item.tipo_tabunm);
          // console.log("item.tipo_tabunm", item.tipo_tabunm)
          // console.log("unmCod", unmCod)
          const cdtCod = this.ListaComodity.find(r => r.label === datosembarqueItem?.cdt_desc);

          const estadoObj = item.ESTADO_OBJ !== undefined ? item.ESTADO_OBJ : "NA";
          // console.log("estadoObj", estadoObj)

          if (estadoObj === "I" || estadoObj === "U" || estadoObj === "D" || estadoObj === "NA") {
            const itemTablaFlete = {
              CCOT_Tipo: request.psinCCOT_Tipo || 2,
              CCOT_Numero: request.pintCCOT_Numero || 0,
              DCOT_Item: item.dcot_item || null,
              PACK_Codigo: packCod ? packCod.value : item.pack_desc.value,
              DCOT_Producto: datosembarqueItem ? datosembarqueItem.dcot_producto || " " : " ",
              DCOT_Cantidad: item.dcot_cantidad || 1.0000,
              DCOT_PrecioUniCosto: item.dcot_preciounicosto || 0.00,
              DCOT_TotalUniCosto: item.dcot_totalunicosto || 0.00,
              DCOT_TotalUniVenta: item.dcot_totaluniventa || 0.00,
              DCOT_PrecioUniVenta: item.dcot_preciouniventa || 0.00,
              DCOT_Importe: item.dcot_importe || 0.00,
              DCOT_Peso: datosembarqueItem ? datosembarqueItem.dcot_peso || 0.00 : 0.00,
              DCOT_Volumen: datosembarqueItem ? datosembarqueItem.dcot_volumen || 0.00 : 0.00,
              DCOT_Bultos: datosembarqueItem ? datosembarqueItem.dcot_bultos || 0.00 : 0.00,
              DCOT_Largo: datosembarqueItem ? datosembarqueItem.dcot_largo || 0.00 : 0.00,
              DCOT_Ancho: datosembarqueItem ? datosembarqueItem.dcot_ancho || 0.00 : 0.00,
              DCOT_Alto: datosembarqueItem ? datosembarqueItem.dcot_alto || 0.00 : 0.00,
              TIP_TabUnm: item.tipo_tabunm || "UNM",
              TIP_CodUnm: unmCod ? unmCod.value : null,
              TIP_TabCDT: item.tipo_tabcdt || "CDT",
              TIP_CodCDT: cdtCod ? cdtCod.value : null,
              TARI_Codigo: item.tari_codigo || null,
              AUDI_UsrMod: this.storage.get("usuario") || null,
              AUDI_UsrCrea: this.storage.get("usuario") || null,
              SERV_Codigo: null,
              ESTADO_OBJ: estadoObj,
            };
            request.tablaFlete.push(itemTablaFlete);
            insertActualizarOVFlete.items.push(itemTablaFlete);
          }
        });

        console.log("data traída para los fletes", insertActualizarOVFlete);

        // obtener datos servicio

        //console.log("data traída para los servicios antes", this.serviciosytarifas_dataservicios);
        const insertActualizarOVServicio = {
          items: []
        };
        const serviciosPrevios = this.serviciosytarifas_dataservicios || [];
        console.log("data traída para los servicios previos", serviciosPrevios);

        request.tablaServicio = request.tablaServicio || [];
        this.serviciosytarifas_dataservicios.forEach((item) => {
          const servicioPrevio = serviciosPrevios.find(prev => prev.scot_item === item.scot_item);
          const servicio = this.ListaServiciosByViaRegimen.find(r => r.label === item.serv_nombre);
          const moneda = this.ListaMoneda.find(r => r.label === item.tipo_descmnd || r.value === item.tipo_descmnd);
          const entidad = this.ListaTipoEntidad.find(r => r.label === item.tipedescripcion);
          const proveedor = this.ListaProveedorG.find(r => r.label === item.entc_nomcom);
          const base = this.ListaBase.find(r => r.label === item.cons_desc_spa || r.value === item.cons_desc_spa);
          const ingeg = this.ListaServicio.find(r => r.label === item.ccot_ingresogasto || r.value === item.ccot_ingresogasto);
          const codTDO = this.ListaTipoDocumento.find(r => r.label === item.tipo_desctdo);

          const estadoObj = item.ESTADO_OBJ !== undefined ? item.ESTADO_OBJ : "NA";

          if (estadoObj === "I" || estadoObj === "U" || estadoObj === "D" || estadoObj === "NA") {
            const itemTablaServicio = {
              CCOT_Tipo: request.psinCCOT_Tipo || 2,
              CCOT_Numero: request.pintCCOT_Numero || null,
              SCOT_Item: item.scot_item,
              CCOT_IngresoGasto: (ingeg && ingeg.value === '001') || item.ccot_ingresogasto.value === '001' ? "I" :
                (ingeg && ingeg.value === '002') || item.ccot_ingresogasto.value === '002' ? "E" :
                  ingeg ? ingeg.value : item.ccot_ingresogasto.value,
              SERV_Codigo: servicio ? Number(servicio.value) : item.serv_nombre.value,
              SCOT_Origen: "D",
              SCOT_Cantidad: item.scot_cantidad || null,
              SCOT_PrecioUni: item.scot_preciouni || null,
              SCOT_Importe_Ingreso: item.scot_importe_ingreso || 0.00,
              SCOT_Importe_Egreso: item.scot_importe_egreso || 0.00,
              TIPO_TabMnd: "MND",
              TIPO_CodMnd: item.tipo_descmnd.value ?? moneda.value ?? moneda.label,
              SCOT_PreSugerido: 0.00,
              TIPE_Codigo: entidad ? entidad.value : item.tipedescripcion.value,
              ENTC_Codigo: proveedor ? proveedor.value : item.entc_nomcom.value,
              TARI_Codigo: item.tari_codigo || null,
              CONS_TabBas: item.cons_desc_spa.nombre || "BAS",
              CONS_CodBas: item.cons_desc_spa.value ?? base.value,
              SCOT_Exonerado: item.scot_exonerado || false,
              SCOT_ChageControl: false,
              PFAC_Numero: null,
              TIPO_TabTDO: "TDO",
              TIPO_CodTDO: codTDO ? (codTDO.value || null) : null,
              SCOT_NumeroTDO: item.scot_numerotdo || " ",
              SCOT_SerieTDO: item.scot_serietdo || null,
              AUDI_UsrMod: this.storage.get("usuario") || null,
              AUDI_UsrCrea: this.storage.get("usuario") || null,
              SCOT_FechaOperacion: this.util.formatearfecha(new Date(), "yyyy-MM-ddTHH:mm:ss") || null,
              SCOT_FechaEmision: this.util.formatearfecha(item.audi_feccrea, "yyyy-MM-ddTHH:mm:ss") || null,
              EMPR_Codigo: 1,
              CCCT_Codigo: 0,
              SCOT_FechaPrefactura: this.util.formatearfecha(valuesEventosTareas["fechahora"], "yyyy-MM-ddTHH:mm:ss") || null,
              SCOT_ItemChangeControl: 1,
              SCOT_CodMotivo: null,
              SCOT_Solicitante: " ",
              SCOT_Autoriza: " ",
              AUDI_FecCrea: this.util.formatearfecha(new Date(), "yyyy-MM-ddTHH:mm:ss") || null,
              ESTADO_OBJ: estadoObj,
            };

            request.tablaServicio.push(itemTablaServicio);
            insertActualizarOVServicio.items.push(itemTablaServicio);
          }
        });

        console.log("data traída para los servicios después", insertActualizarOVServicio);

        // obtener datos archivos

        this.datosRecibidos = JSON.parse(localStorage.getItem('datoov'));
        const archivosPrevios = this.archivos_dataarchivos || [];
        // console.log("archivosPrevios", archivosPrevios);

        this.archivos_dataarchivos.forEach(archivo => {
          const estadoObj = archivo.ESTADO_OBJ !== undefined ? archivo.ESTADO_OBJ : "NA";
          if (estadoObj === "I" || estadoObj === "U" || estadoObj === "D" || estadoObj === "NA") {
            const itemTablaArchivo = {
              CCOT_Tipo: archivo.ccot_tipo,
              CCOT_Numero: archivo.ccot_numero,
              OVAR_Codigo: archivo.ovar_codigo,
              OVAR_Descrip: archivo.ovar_descrip,
              OVAR_FecEmi: archivo.ovar_fecemi,
              OVAR_Archivo: archivo.ovar_archivo,
              OVAR_Observacion: archivo.ovar_observacion,
              AUDI_UsrMod: this.storage.get("usuario") || null,
              AUDI_UsrCrea: archivo.audi_usrcrea,
              ESTADO_OBJ: estadoObj,
            };
            this.insertActualizarOVArchivo.items.push(itemTablaArchivo);
          }
        });
        console.log("Datos de los archivos a enviar a la API:", this.insertActualizarOVArchivo);

        // obtener datos eventos

        request.tablaEvento = request.tablaEvento || [];
        this.eventostareas_dataeventos.forEach((item) => {
          const estadoObj = item.ESTADO_OBJ !== undefined ? item.ESTADO_OBJ : "NA";
          if (estadoObj === "I" || estadoObj === "U" || estadoObj === "D" || estadoObj === "NA") {
            const itemTablaEvento = {
              CCOT_Numero: request.pintCCOT_Numero || null,
              CCOT_Tipo: request.psinCCOT_Tipo || 2,
              EVEN_Item: item.even_item,
              EVEN_Fecha: this.util.formatearfecha(item.even_fecha, "yyyy-MM-ddTHH:mm:ss") || null,
              EVEN_Cumplida: item.even_cumplida,
              EVEN_Usuario: item.even_usuario || null,
              EVEN_Observaciones: item.even_observaciones || " ",
              TIPO_TabEVE: "EVE",
              TIPO_CodEVE: item.tipo_desc1.value || "1",
              CONS_TabMOD: "MOD",
              CONS_CodMOD: "001",
              EVEN_Manual: item.even_manual || false,
              AUDI_UsrMod: this.storage.get("usuario") || null,
              AUDI_UsrCrea: this.storage.get("usuario") || null,
              ESTADO_OBJ: estadoObj,
            };
            request.tablaEvento.push(itemTablaEvento);
            this.insertActualizarOVEvento.items.push(itemTablaEvento);
          }
        });
        console.log("data traída para los eventos", this.insertActualizarOVEvento);

        // Obtener datos itinerario

        console.log("this.viajeitinerario_dataitinerario", this.viajeitinerario_dataitinerario);

        this.viajeitinerario_dataitinerario.forEach((item) => {
          const estadoObj = item.ESTADO_OBJ !== undefined ? item.ESTADO_OBJ : "NA";
          const codDestino = this.ListaPuertosByVia.find(r => r.label === item.nameportdischarge);
          const codOrigen = this.ListaPuertosByVia.find(r => r.label === item.nameportloading);
          if (estadoObj === "I" || estadoObj === "U" || estadoObj === "D" || estadoObj === "NA") {
            const itemTablaItinerario = {
              CCOT_Tipo: request.psinCCOT_Tipo || 2,
              CCOT_Numero: request.pintCCOT_Numero || null,
              OVNV_Item: item.ovnv_item,
              NVIA_Codigo: item.nvia_codigo,
              PUER_CodDestino: codDestino ? codDestino.value : item.nameportdischarge.value,
              PUER_CodOrigen: codOrigen ? codOrigen.value : item.nameportloading.value,
              OVNV_Final: item.ovnv_final || true,
              DDOV_FecEmbarque: item.fechaembarque || null,
              AUDI_UsrMod: this.storage.get("usuario") || null,
              AUDI_UsrCrea: this.storage.get("usuario") || null,
              ESTADO_OBJ: estadoObj,
            };
            this.insertActualizarOVItinerario.items.push(itemTablaItinerario);
          }
        });
        console.log("Data traída para los itinerarios", this.insertActualizarOVItinerario);

        // obtener data servicios locales
        // Los datos de ListadoOperaciones


        this.slrequest.cope_codigo = (this.datosRecibidos && this.datosRecibidos["listadooperaciones"] &&
          this.datosRecibidos["listadooperaciones"].length > 0)
          ? this.datosRecibidos["listadooperaciones"][0].cope_codigo
          : 0;
        this.slrequest.cope_numdoc = (this.datosRecibidos && this.datosRecibidos["listadooperaciones"] &&
          this.datosRecibidos["listadooperaciones"].length > 0)
          ? this.datosRecibidos["listadooperaciones"][0].cope_numdoc
          : null;
        this.slrequest.cope_fecemi = (this.datosRecibidos && this.datosRecibidos["listadooperaciones"] &&
          this.datosRecibidos["listadooperaciones"].length > 0)
          ? this.datosRecibidos["listadooperaciones"][0].cope_fecemi
          : new Date();
        // this.slrequest.cope_numdoc = this.datosRecibidos != null || undefined ? this.datosRecibidos["listadooperaciones"] != null || undefined ? this.datosRecibidos["listadooperaciones"][0].cope_numdoc : null : null;
        // this.slrequest.cope_fecemi = this.datosRecibidos != null || undefined ? this.datosRecibidos["listadooperaciones"] != null || undefined ? this.datosRecibidos["listadooperaciones"][0].cope_fecemi : new Date() : new Date();
        this.slrequest.cope_observacion = null;
        this.slrequest.cope_hbl = this.datosRecibidos != null ? this.datosRecibidos.doov_hbl ?? ' ' : "  ";
        this.slrequest.ccot_codigo = (this.datosRecibidos && this.datosRecibidos["listadooperaciones"] &&
          this.datosRecibidos["listadooperaciones"].length > 0)
          ? this.datosRecibidos["listadooperaciones"][0].ccot_codigo
          : null;
        this.slrequest.ccot_numero = (this.datosRecibidos && this.datosRecibidos["listadooperaciones"] &&
          this.datosRecibidos["listadooperaciones"].length > 0)
          ? this.datosRecibidos["listadooperaciones"][0].ccot_numero
          : null;
        // this.slrequest.ccot_codigo = this.datosRecibidos != null || undefined ? this.datosRecibidos.ccot_codigo : null;
        // this.slrequest.ccot_numero = this.datosRecibidos != null || undefined ? this.datosRecibidos.ccot_numero : null;
        this.slrequest.ccot_tipo = "2";
        this.slrequest.entc_codcliente = this.codCliente;
        this.slrequest.entc_codtransporte = this.codTransporte;
        this.slrequest.entc_codagente = this.codAgente;
        this.slrequest.cons_codestado = this.group_datosgenerales.value["estado"];
        this.slrequest.cons_tabestado = "OPE";
        this.slrequest.cope_excepcion = null;
        this.slrequest.cope_fob = 0.00;
        this.slrequest.cope_seguro = 0.00;
        this.slrequest.cope_flete = 0.00;
        this.slrequest.cope_cif = 0.00;
        this.slrequest.cope_partarancelaria = 0.00;
        this.slrequest.cope_ipm = 0.00;
        this.slrequest.cope_igv = 0.00;
        this.slrequest.cope_percepcion = 0.00;
        this.slrequest.cope_tasadespacho = 0.00;
        this.slrequest.cope_advalorem = 0.00;
        this.slrequest.cope_1erembarque = null;
        this.slrequest.audi_usrmod = this.storage.get("usuario") || null;
        this.slrequest.audi_usrcrea = this.storage.get("usuario") || null;
        this.slrequest.tipo_tabmnd = "MND";
        this.slrequest.tipo_codmnd = this.group_serviciostarifa.value["moneda"]
        this.slrequest.cons_codcrg = null;  // preguntar a miguel
        this.slrequest.cons_tabcrg = "CRG";
        this.slrequest.ccot_codaduana = null;
        this.slrequest.ccot_codlogistico = null;
        this.slrequest.ccot_codtransporte = null;
        this.slrequest.cope_nave = null;
        this.slrequest.cope_viaje = null;
        this.slrequest.cope_mbl = this.slrequest.cope_mbl = this.group_novedades.value["blmaster"] ?? ' ';// preguntar
        this.slrequest.cope_fechaarribo = null;
        this.slrequest.cope_cantidaddias = null;
        this.slrequest.cope_kilos = null;
        this.slrequest.cope_volumen = null;
        this.slrequest.cope_preciosada = null;
        this.slrequest.entc_codagenport = null;
        this.slrequest.entc_codtermport = null;
        this.slrequest.entc_coddeptemporal = null;
        this.slrequest.ctar_codigo = this.idTarifa !== undefined
          ? this.idTarifa
          : ((this.datosRecibidos != null && this.datosRecibidos["listadotarifaasociada"]  &&
            this.datosRecibidos["listadotarifaasociada"].length > 0 )
            ? this.datosRecibidos["listadotarifaasociada"][0].ctar_codigo
            : null);
        this.slrequest.ctar_version = 1;
        this.slrequest.entc_codtransterre = this.group_servicioslocales.get('transportistaTerre')?.value || null;
        this.slrequest.cope_version = 1;
        this.slrequest.cons_codlng = this.group_datosgenerales.value["lineanegocio"];
        this.slrequest.cons_tablng = "LNG";
        this.slrequest.cons_codrgm = this.group_datosgenerales.get('regimen')?.value
        this.slrequest.cons_tabrgm = "RGM";
        this.slrequest.cons_codvia = this.group_datosgenerales.get('via')?.value
        this.slrequest.cons_tabvia = "VIA";

        console.log("slrequest:", this.slrequest)

        // obtener det de SL
        this.sldetrequest = []
        this.servicioslocales_datadetalle.forEach((item: any) => {
          const requestItem: DetalleServiciosLocalesRequest = {
            dope_item: item.dope_item ?? null,
            cope_codigo: this.datosRecibidos != null ? this.datosRecibidos["listadooperaciones"][0].cope_codigo : 0,
            cope_version: 1,
            dtar_item: item.dtar_item ?? null,
            ctar_codigo: this.idTarifa !== undefined ? this.idTarifa : null,
            ctar_tipo: item.ctar_tipo ?? null,
            pack_codigo: item.pack_codigo ?? null,
            cons_codbas: item.cons_codbas ?? null,
            cons_tabbas: item.cons_tabbas ?? "BAS",
            dope_cantidad: item.dope_cantidad ?? null,
            dope_preciounitcosto: item.dope_preciounitcosto ?? null,
            dope_preciototcosto: item.dope_preciototcosto ?? item.dope_preciounitcosto * item.dope_cantidad,
            dope_preciounitvta: item.dope_preciounitvta ?? null,
            dope_preciototvta: item.dope_preciototvta ?? null,
            dope_minimo: item.dope_minimo ?? 0.00,
            dope_peso: item.dope_peso ?? null,
            dope_volumen: item.dope_volumen ?? null,
            usr_usrmod: item.usr_usrmod ?? this.storage.get("usuario"),
            audi_usrcrea: item.audi_usrcrea ?? this.storage.get("usuario"),
            dope_costosada: item.dope_costosada ?? null,
            dope_costo: item.dope_costo ?? null,
            dope_ventasada: item.dope_ventasada ?? 0.00,
            dope_venta: item.dope_venta ?? 0.00,
            tipo_tabzon: item.tipo_tabzon ?? null,
            tipo_codzonorigen: item.tipo_codzonorigen ?? null,
            cons_codtra: item.cons_codtra ?? null,
            cons_tabtra: item.cons_tabtra ?? null,
            tipo_codzondestino: item.tipo_codzondestino ?? null,
            tipe_codigo: item.tipe_codigo ?? null,
            entc_codigo: item.entc_codigo ?? null,
            cons_codest: this.group_datosgenerales.value["estado"],
            cons_tabest: "EDO",
            serv_codigo: item.serv_codigo ?? null,
            tipo_tabmnd: "MND",
            tipo_codmnd: item.tipo_codmnd ?? null,
            cons_tablng: "LNG",
            cons_codlng: this.group_datosgenerales.value["lineanegocio"],
            dope_changecontrol: item.dope_changecontrol ?? false,
            dope_itemchangecontrol: item.dope_itemchangecontrol ?? null,
            dope_fechaoperacion: item.dope_fechaoperacion ?? null,
            ESTADO_OBJ: item.ESTADO_OBJ ?? "NA"
          };
          this.sldetrequest.push(requestItem);
        });

        console.log("sldetrequest", this.sldetrequest);

        // obtener servicios de SL

        const length = this.listarServTariff.length;
        let itemCounter = 1;

        for (let i = 0; i < length; i++) {
          const servicio = this.listarServTariff[i];
          const item = this.servicioslocales_dataObservaciones[i] || {};

          const rItem: EvenSiRequest = {
            ccot_numero: this.datosRecibidos != null ? this.datosRecibidos.ccot_numero : null,
            ccot_tipo: 2,
            even_item: itemCounter++,
            even_fecha: item.even_fecha || null,
            even_cumplida: false,
            even_usuario: item.even_usuario || null,
            even_observaciones: item.even_observaciones || null,
            tipo_tabeve: "EVE",
            tipo_codeve: servicio.tipe_codigo.toString(),
            cons_tabmod: "MOD",
            cons_codmod: "001",
            even_manual: false,
            audi_usrcrea: this.storage.get("usuario") || null,
            audi_usrmod: this.storage.get("usuario") || null,
            even_filename: item.even_filename || null,
            even_filecontent: item.even_filename || null
          };

          // Almacenamos el objeto rItem en el arreglo slevenrequest
          this.slevenrequest.push(rItem);
        }

        console.log("slevenrequest", this.slevenrequest);

        //private void btnGuardar_Click(object sender, EventArgs e)
        if (request.pintENTC_CodCliente == null || request.pintENTC_CodCliente == 0) {
          this.util.ShowMessage({ titulo: "ERROR", mensaje: "Razon social vacia", tipo: "warning" });
        } else {
          console.log('request', request);
          console.log('listadofletes', listadofletes);
          console.log('listadofletes guardar OV', insertActualizarOVFlete);
          console.log('listaServicio', listaServicio);
          console.log('listaServicio guardar OV', insertActualizarOVServicio);
          console.log('listaArchivos guardar OV', this.insertActualizarOVArchivo)
          console.log('listaEventos guardar OV', this.insertActualizarOVEvento)
          console.log('listaItinerarios guardar OV', this.insertActualizarOVItinerario)
          const validacion = new MantenimientoOrdenVentaValidacion(request, insertActualizarOVFlete, insertActualizarOVServicio);
          const { isValid, errorMessage } = validacion.Guardar(false); // (CrudOrdenesVentaRequest, datos flete , esprospecto false)
          if (isValid) {
            request.pintENTC_CodCliente = this.group_datosgenerales.get('cliente')?.value ?
              Number(this.ListaTodosCliente.find(cliente => cliente.nombre === this.group_datosgenerales.get('cliente')?.value)?.codigo) || 0 :
              null;

            request.pintENTC_CodTransportista = this.group_serviciostarifa.get('transportista')?.value ?
              Number(this.ListaTodosTransportista.find(transportista => transportista.nombre === this.group_serviciostarifa.get('transportista')?.value)?.codigo) || 0 :
              null;

            request.pintENTC_CodAgente = this.group_datosgenerales.get('agente')?.value ?
              Number(this.ListaTodosAgentes.find(agente => agente.nombre === this.group_datosgenerales.get('agente')?.value)?.codigo) || 0 :
              null;

            request.pintENTC_CodCustomer = this.group_datosgenerales.get('customer')?.value ?
              Number(this.ListaTodosCustomer.find(customer => customer.nombre === this.group_datosgenerales.get('customer')?.value)?.codigo) || 0 :
              null;

            request.pintENTC_CodEjecutivo = this.group_datosgenerales.get('ejecutivo')?.value ?
              Number(this.ListaTodosEjecutivos.find(ejecutivo => ejecutivo.nombre === this.group_datosgenerales.get('ejecutivo')?.value)?.codigo) || 0 :
              null;

            request.pintENTC_CodShipper = valuesDatosEmbarque["shipper"] ?
              Number(this.ListaTodosShipper.find(item => item.nombre === valuesDatosEmbarque["shipper"])?.codigo) || 0 :
              null;

            request.pintENTC_CodConsignee = valuesDatosEmbarque["consignee"] ?
              Number(this.ListaTodosConsignee.find(item => item.nombre === valuesDatosEmbarque["consignee"])?.codigo) || 0 :
              null;

            request.pintENTC_CodNotify = valuesDatosEmbarque["notify"] ?
              Number(this.ListaTodosNotify.find(item => item.nombre === valuesDatosEmbarque["notify"])?.codigo) || 0 :
              null;

            request.pintENTC_CodDepTemporal = valuesNovedades["deposito"] ?
              Number(this.ListaTodosDeposito.find(item => item.nombre === valuesNovedades["deposito"])?.codigo) || 0 :
              null;

            // request.pintPUER_CodDestino = valuesServicioTarifa["puertodestino"] ?
            //   Number(this.ListaTodosPuertoDestino.find(item => item.nombre === valuesServicioTarifa["puertodestino"])?.codigo) || 0 :
            //   null;

            // request.pintPUER_CodTrasbordo = valuesServicioTarifa["puertotransbordo"] ?
            //   Number(this.ListaTodosPuertoTransbordo.find(item => item.nombre === valuesServicioTarifa["puertotransbordo"])?.codigo) || 0 :
            //   null;
            // request.pintPUER_CodOrigen = valuesServicioTarifa["puertoorigen"] ?
            //   Number(this.ListaTodosPuertoOrigen.find(item => item.nombre === valuesServicioTarifa["puertoorigen"])?.codigo) || 0 :
            //   null;

          }

          if (isValid) {
            const opcion = localStorage.getItem('Opcion')

            if (opcion == 'EDITAR') {
              console.log("serviciosytarifas_dataflete editar ", this.serviciosytarifas_dataflete);

              console.log("datos que se enviaran a la api:", request)
              this.ordenventaService.CrudOrdenesVentaEditar(request).pipe(

                switchMap(response => {
                  this.util.ShowMessage({ titulo: "Mantenimiento Orden Venta", mensaje: "Se actualizó correctamente", icono: "OK", tipo: "ok" });

                  if (insertActualizarOVFlete.items.length > 0) {
                    console.log("Datos de la OV actualizados correctamente:", response);
                    return this.ordenventaService.OVFleteActualizar(insertActualizarOVFlete);
                  } else {
                    return of(null);
                  }
                }),
                switchMap(fleteResponse => {
                  if (insertActualizarOVServicio.items.length > 0) {
                    console.log("Datos de fletes actualizados correctamente:", fleteResponse);
                    return this.ordenventaService.OVServicioActualizar(insertActualizarOVServicio);
                  } else {
                    return of(null);
                  }
                }),
                switchMap(itinerarioResponse => {
                  if (this.insertActualizarOVItinerario.items.length > 0) {
                    console.log("Datos de itinerario actualizados correctamente:", itinerarioResponse);
                    return this.ordenventaService.OVItinerarioActualizar(this.insertActualizarOVItinerario);
                  } else {
                    return of(null);
                  }
                }),
                switchMap(archivoResponse => {
                  if (this.insertActualizarOVArchivo.items.length > 0) {
                    console.log("Datos de archivo actualizados correctamente:", archivoResponse);
                    return this.ordenventaService.OVArchivoActualizar(this.insertActualizarOVArchivo);
                  } else {
                    return of(null);
                  }
                }),
                switchMap(servicioResponse => {
                  if (this.insertActualizarOVEvento.items.length > 0) {
                    console.log("Datos de servicios actualizados correctamente:", servicioResponse);
                    return this.ordenventaService.OVEventoActualizar(this.insertActualizarOVEvento);
                  } else {
                    return of(null);
                  }
                }),
                switchMap(responseSL => {
                  if (this.group_datosgenerales.value["serviciologistico"] == true) {
                    console.log("Datos de servicios actualizados correctamente:", responseSL);
                    return this.ordenventaService.ActualizarServiciosLocales(this.slrequest);
                  } else {
                    console.log('No se recibió la solicitud de servicios locales, no se actualizaron los servicios.');
                    return of(null);
                  }
                }),
                switchMap(responsedetSL => {
                  if (this.sldetrequest != null && this.sldetrequest.length != 0) {
                    console.log('Servicios locales actualizados correctamente:', responsedetSL);

                    return of(...this.sldetrequest).pipe(
                      concatMap((requestItem: DetalleServiciosLocalesRequest) => {
                        console.log('Enviando detalle de servicio:', requestItem);
                        return this.ordenventaService.ActualizarDetalleServiciosLocales(requestItem);
                      })
                    );
                  } else {
                    console.log('Error en la actualización de servicios locales o no hay detalles para actualizar');
                    return of(null);
                  }
                })

              ).subscribe(
                ovResponse => {
                  console.log("Datos de la OV actualizados correctamente:", ovResponse);
                  const rutaComponente = '/comercialordenventa';
                  this.router.navigate([rutaComponente]);
                },
                error => {
                  console.error("Error durante el proceso de actualización", error);
                }
              );


            } else {
              // Captura los datos de la tabla
              // const datosTabla = this.serviciosytarifas_dataflete;
              // console.log("Datos de la tabla:", datosTabla);

              // guardar OV 

              console.log("datos que se enviaran a la api:", request)
              console.log("datos del tableFlete: ", request.tablaFlete)
              console.log("datos del tablaServicio: ", request.tablaServicio)
              console.log("datos del tablaEvento: ", request.tablaEvento)
              this.ordenventaService.CrudOrdenesVenta(request).subscribe(response => {
                const codOVgenerada = response.datos.codigoEntidad
                if (codOVgenerada === 0) {
                  console.log("No se encontró la entidad.");
                  this.util.ShowMessage({
                    titulo: "Error al guardar",
                    mensaje: "No se pudo registrar la Orden de Venta. Por favor, intente nuevamente.",
                    icono: "Error",
                    tipo: "error"
                  });
                } else {
                  console.log("Código de entidad registrado:", codOVgenerada);

                  this.handleAdditionalInserts(codOVgenerada).subscribe({
                    next: (response) => {
                      this.util.ShowMessage({
                        titulo: "Mantenimiento Orden Venta",
                        mensaje: "Se guardó correctamente",
                        icono: "OK",
                        tipo: "ok"
                      });

                      const rutaComponente = '/comercialordenventa';
                      localStorage.removeItem('datoov');
                      this.router.navigate([rutaComponente]);
                    },
                    error: (error) => {
                      this.util.ShowMessage({
                        titulo: "Error",
                        mensaje: "Hubo un error al guardar los datos adicionales. Intente nuevamente.",
                        icono: "Error",
                        tipo: "error"
                      });
                      console.error("Error en handleAdditionalInserts:", error);
                    }
                  });
                }
              });
            }
          } else {
            if (errorMessage && errorMessage.length > 0) {
              // Contar el número de mensajes de error
              const errorCount = errorMessage.length;

              if (errorCount === 1) {
                this.util.ShowMessage({
                  titulo: "ERROR",
                  mensaje: errorMessage[0],
                  tipo: "warning"
                });
              } else {
                this.util.ShowMessage({
                  titulo: "ERROR",
                  mensaje: "Se han encontrado los siguientes errores. Revisar los detalles.",
                  tipo: "warning",
                  detalle: errorMessage.join('\n')
                });
              }
            }
            console.log(errorMessage);
          }

        }

        break;


      default:
        break;
    }
  }

  onUpload(event, fileUpload) {
    for (let file of event.files) {
      const reader = new FileReader();

      reader.onload = (e) => { // se ejecutará cuando la lectura del archivo se complete
        const base64Data = (e.target.result as string).split(',')[1];

        const request: ListaDetCotizacionOVArchivoRequest = {
          items: [{
            CCOT_Numero: this.datosRecibidos != null ? this.datosRecibidos.ccot_numero : null,
            CCOT_Tipo: this.datosRecibidos != null ? this.datosRecibidos.ccot_tipo : 2,
            OVAR_Descrip: file.name,
            OVAR_FecEmi: this.util.formatearfecha(new Date(), "yyyy-MM-ddTHH:mm:ss") || null,
            OVAR_Archivo: base64Data,
            AUDI_UsrMod: this.storage.get("usuario") || null,
            AUDI_UsrCrea: this.storage.get("usuario") || null,
            OVAR_Observacion: null,
            ESTADO_OBJ: "U"
          }]
        };

        const maxOvarCodigo = this.archivos_dataarchivos.length > 0 ? Math.max(...this.archivos_dataarchivos.map(item => item.ovar_codigo || 0)) : 0;
        const newOvarCodigo = maxOvarCodigo + 1;

        this.archivos_dataarchivos.push({
          ccot_tipo: request.items[0].CCOT_Tipo,
          ccot_numero: request.items[0].CCOT_Numero,
          ovar_codigo: newOvarCodigo,
          ovar_descrip: request.items[0].OVAR_Descrip,
          ovar_fecemi: request.items[0].OVAR_FecEmi,
          ovar_archivo: base64Data,
          ovar_observacion: request.items[0].OVAR_Observacion,
          audi_usrmod: request.items[0].AUDI_UsrMod,
          audi_usrcrea: request.items[0].AUDI_UsrCrea,
          ESTADO_OBJ: "I"
        });

        this.archivos_dataarchivos = [...this.archivos_dataarchivos];

        // console.log('Datos del archivo agregados a la tabla:', this.archivos_dataarchivos);
      };
      reader.readAsDataURL(file);
    }
    //this.uploadedFiles = [];
    fileUpload.clear();
    // console.log('Archivos subidos limpiados:', this.uploadedFiles);
  }

  onRemove(event) {
    console.log('fila eliminada: ', event)
    const index = this.uploadedFiles.findIndex(file =>
      file.name === event.file.name
    );
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
    }
    // console.log('Lista después de la eliminación: ', this.uploadedFiles);
  }

  onRowSelect(event: any) {
    this.row = event.data;
    const dataToReturn = {
      idtarifa: this.row.idtariff
    };
    console.log("Datos en la fila seleccionada: ", this.row);
    console.log("Datos dataToReturn: ", dataToReturn);
  }

  isTabActive: boolean = false;
  isDrawerOpen: boolean = false;

  onTabClick(): void {
    this.buscarTarifas()
    this.isTabActive = !this.isTabActive;
    this.isDrawerOpen = this.isTabActive;
  }

  idTarifa: number;
  aplicarTarifa() {
    if (!this.row || !this.row.idtariff) {
      this.util.ShowMessage({
        titulo: "ERROR",
        mensaje: "No se ha seleccionado ninguna fila. Por favor, seleccione una fila primero.",
        tipo: "warning",
      });
      return;
    }

    this.group_servicioslocales.get('descripcion')?.setValue(this.row.descripcion);
    // console.log("this.row", this.row)
    console.log("this.row.vigente_desde", this.row.vigente_desde)
    console.log("this.row.vigente_hasta", this.row.vigente_hasta)
    this.group_servicioslocales.get('vigenciadesde')?.setValue(this.row.vigente_desde);
    this.group_servicioslocales.get('vigenciahasta')?.setValue(this.row.vigente_hasta);

    const tarDetRequest = <ConsultarTariffDetailByIdRequest>{};
    tarDetRequest.idTariff = this.row.idtariff;
    this.idTarifa = this.row.idtariff;

    const servTarfRequest = <GetServiceCalculatedByTariffRequest>{};
    servTarfRequest.idTariff = this.row.idtariff;
    servTarfRequest.pintCCOTNumero = this.datosRecibidos != null ? this.datosRecibidos.ccot_numero : null;
    servTarfRequest.psinCCOTTipo = 2;
    servTarfRequest.pvchDOOVHBL = this.datosRecibidos != null ? this.datosRecibidos.doov_hbl : null;

    forkJoin({
      tarifaDetalle: this.ordenventaService.BuscarTariffDetailById(tarDetRequest),
      servicioCalculado: this.ordenventaService.GetServiceCalculatedByTariff(servTarfRequest)
    }).subscribe({
      next: (response) => {
        // const listarTarifaDetalle: ItemsTarifaDetalleResponse[] = [];
        if (response.tarifaDetalle.datos.status === "200" && response.tarifaDetalle.datos.result.length > 0) {
          this.listarTarifaDetalle.push(...response.tarifaDetalle.datos.result.map((item: ItemsTarifaDetalleResponse) => ({
            idTariffDetail: item.idTariffDetail,
            idTariff: item.idTariff,
            unitDescription: item.unitDescription,
            idUnit: item.idUnit,
            conceptDescription: item.conceptDescription,
            idConcept: item.idConcept,
            salesAmount: item.salesAmount,
            costsAmount: item.costsAmount,
            currency: item.currency,
            validaForm: item.validFrom,
            validTo: item.validTo,
            remarks: item.remarks,
            tariffType: item.tariffType
          })));
          console.log("listarTarifaDetalle", this.listarTarifaDetalle)
        }

        // const listarServTariff: ItemsServiciosCalculadosTarifaResponse[] = [];
        if (response.servicioCalculado.datos.status === "200" && response.servicioCalculado.datos.result.length > 0) {
          this.listarServTariff.push(...response.servicioCalculado.datos.result.map((item: ItemsServiciosCalculadosTarifaResponse) => ({
            equivalentCode: item.equivalentCode,
            serv_codigo: item.serv_codigo,
            conceptDescription: item.conceptDescription,
            idUnit: item.idUnit,
            cons_codbas: item.cons_codbas,
            cons_desc_spa: item.cons_desc_spa,
            dope_preciounitvta: item.dope_preciounitvta,
            dope_preciounitcosto: item.dope_preciounitcosto,
            dope_cantidad: item.dope_cantidad,
            dope_preciototvta: item.dope_preciototvta,
            dope_preciototcosto: item.dope_preciounitcosto * item.dope_cantidad,
            tipe_codigo: item.tipe_codigo,
            idTariffDetail: item.idTariffDetail,
            entc_codigo: item.entc_codigo,
            dtar_item: item.dtar_item,
            currency: item.currency,
            tipo_codmnd: item.tipo_codmnd,
            idTariff: item.idTariff,
            totalProfit: item.totalProfit
          })));
          console.log("listarServTariff", this.listarServTariff)
        }

        const originalRowCount = this.servicioslocales_datadetalle.length;
        const rowsConMismoId = this.servicioslocales_tarifa.filter(fila => fila.idtariff === this.row.idtariff);
        if (rowsConMismoId.length > 0) {
          while (this.servicioslocales_datadetalle.length < rowsConMismoId.length) {
            this.servicioslocales_datadetalle.push({});
          }

          rowsConMismoId.forEach((fila, index) => {
            const tarifaDetalle = this.listarTarifaDetalle[index] || {};
            const servicioCalculado = this.listarServTariff[index] || {};
            const descBase = this.ListaBase.find(item => item.value === servicioCalculado.cons_codbas)?.label;
            const monedaLabel = this.ListaMoneda.find(item => item.value === servicioCalculado.currency)?.label;

            // console.log('descBase:', descBase);
            // console.log('monedaLabel:', monedaLabel);

            this.servicioslocales_datadetalle[index] = {
              ...this.servicioslocales_datadetalle[index],
              ...tarifaDetalle,
              ...servicioCalculado,
              dope_item: index + 1,
              serv_nombre_spa: fila.concepto,
              cons_desc_spa: descBase,
              tipoentidad: "PROVEEDOR",
              entc_razonsocial: fila.transportista,
              dope_preciounitvta: fila.importe_venta,
              nombremnd: monedaLabel,
              dope_cantidad: 1,
              dope_preciototvta: fila.importe_venta,
              ESTADO_OBJ: index + 1 <= originalRowCount ? "I" : "I",
            };
          });

          this.servicioslocales_datadetalle = [...this.servicioslocales_datadetalle];

          console.log('Datos actualizados en resumen:', this.servicioslocales_datadetalle);
        }
      },
      error: (err) => {
        console.error('Error en las consultas:', err);
        this.util.ShowMessage({
          titulo: "ERROR",
          mensaje: "Hubo un error al consultar los datos de tarifa. Intenta nuevamente.",
          tipo: "error",
        });
      }
    });
  }

  handleAdditionalInserts(codOVgenerada: number): Observable<any> {
    const insertObservables: Observable<any>[] = [];

    if (this.insertActualizarOVArchivo && this.insertActualizarOVArchivo.items && this.insertActualizarOVArchivo.items.length > 0) {
      this.insertActualizarOVArchivo.items.forEach(item => {
        item.ccot_numero = codOVgenerada;
      });
      insertObservables.push(this.ordenventaService.OVArchivoInsertar(this.insertActualizarOVArchivo));
    }

    if (this.insertActualizarOVEvento && this.insertActualizarOVEvento.items && this.insertActualizarOVEvento.items.length > 0) {
      this.insertActualizarOVEvento.items.forEach(item => {
        item.ccot_numero = codOVgenerada;
      });
      insertObservables.push(this.ordenventaService.OVEventoInsertar(this.insertActualizarOVEvento));
    }

    if (this.insertActualizarOVItinerario && this.insertActualizarOVItinerario.items && this.insertActualizarOVItinerario.items.length > 0) {
      this.insertActualizarOVItinerario.items.forEach(item => {
        item.ccot_numero = codOVgenerada;
      });
      insertObservables.push(this.ordenventaService.OVItinerarioInsertar(this.insertActualizarOVItinerario));
    }

    // insertar servicios locales
    if (this.group_datosgenerales.value["serviciologistico"] == true) {
      // if (this.slrequest.ctar_codigo != null) {
      this.slrequest.ccot_numero = codOVgenerada
      this.slrequest.listaDetSL = this.sldetrequest
      this.slrequest.listaEvenSL = this.slevenrequest
      insertObservables.push(
        this.ordenventaService.InsertarServiciosLocales(this.slrequest).pipe(
          tap((response: InsertarServiciosLocalesResponse) => {
            if (response.datos && response.datos.status === 'OK') {
              if (response.datos.CopesiCodigo) {
                console.log('Código Copesi generado:', response.datos.CopesiCodigo);
              }
            } else {
              throw new Error('Error en la inserción de Servicio Local: ' + response.meta.mensaje);
            }
          }),
          catchError(error => {
            return throwError(() => new Error('Error en InsertarServiciosLocales: ' + error.message));
          })
        )
      );
    }

    if (insertObservables.length > 0) {
      return forkJoin(insertObservables).pipe(
        catchError(error => {
          console.error('Error al insertar datos adicionales', error);
          return throwError(() => new Error('Error al insertar datos adicionales: ' + error.message));
        })
      );
    } else {
      return of(null);
    }
  }

  buscarCustomerEjecutivoSegunCliente() {
    console.log('ListaTodosCliente', this.ListaTodosCliente);
    if (!this.codCliente) return;
    const clientesFiltrados = this.ListaTodosCliente.filter(cliente => cliente.codigo === this.codCliente.toString());
    if (clientesFiltrados.length === 0) return;
    clientesFiltrados.forEach(cliente => {
      let tipoRelacion: string = '';
      if (cliente.adicional1 && cliente.adicional1.trim() !== '') {
        tipoRelacion = 'CUSTOMER SERVICE';
        this.group_datosgenerales.get('customer')?.setValue(cliente.adicional1);
      }
      if (cliente.adicional2 && cliente.adicional2.trim() !== '') {
        tipoRelacion = 'EJECUTIVO';
        this.group_datosgenerales.get('ejecutivo')?.setValue(cliente.adicional2);
      }
      if (cliente.adicional3 && cliente.adicional3.trim() !== '') {
        tipoRelacion = 'DEPOSITO TEMPORAL';
        this.group_novedades.get('deposito')?.setValue(cliente.adicional3);
      }
    });
  }

  cargarServiciosEnTablaSegunLineaNegocio() {
    // var nombreLinN: string = this.group_datosgenerales.value["lineanegocio"]
    // console.log("nombreLinN:", nombreLinN)

    const prolrequest: ConsultarEntidadServicio_Request = {
      pintENTC_Codigo: this.codCliente || null
    };

    if (prolrequest.pintENTC_Codigo == null) return;

    this.ordenventaService.BuscarEntidadServicio(prolrequest).subscribe(
      (response: ConsultarEntidadServicioResponse) => {
        if (response.datos.status === 200) {
          console.log('Respuesta exitosa:', response.datos.result);

          const mappedData = response.datos.result.map((item, index) => {
            const proveedor = this.ListaProveedorG.find(r => r.value === item.entC_Codigo.toString());
            const proveedorNombre = proveedor ? proveedor.label : item.entC_Codigo;
            const moneda = this.ListaMoneda.find(r => r.value === item.tipO_CodMnd);
            const monedaDescripcion = moneda ? moneda.label : item.tipO_CodMnd;

            return {
              scot_item: index + 1,
              scot_exonerado: item.eseR_Exonerado,
              serv_nombre: item.serV_Nombre,
              ccot_ingresogasto: "INGRESO",
              tipedescripcion: "CLIENTE",
              entc_nomcom: proveedorNombre,
              cons_desc_spa: item.conS_DescBAS,
              scot_cantidad: 1,
              tipo_descmnd: monedaDescripcion,
              scot_preciouni: item.eseR_Valor || 0.00,
              scot_importe_ingreso: item.eseR_Valor || 0.00,
              scot_importe_egreso: 0.00,
              serv_afeigv: true,
              serv_afedet: true,
              tipo_desctdo: null,
              scot_serietdo: null,
              scot_numerotdo: null,
              audi_feccrea: new Date()
            };
          });
          this.serviciosytarifas_dataservicios = mappedData;
          console.log('Servicios:', this.serviciosytarifas_dataservicios);
        }
      }
    );
  }

  obtenerArchivos() {
    const prolrequest: GetCotizacionOVArchivoRequest = {
      pintCCOT_Numero: this.datosRecibidos.ccot_numero, psinCCOT_Tipo: 2
    };
    console.log("archivos antes de avtualizar:", this.archivos_dataarchivos)
    if (prolrequest.pintCCOT_Numero == null) return;
    this.ordenventaService.OVGetArchivos(prolrequest).subscribe(
      (response: GetCotizacionOVArchivoResponse) => {
        if (response.datos.status === 200) {
          this.archivos_dataarchivos = response.datos.result.map(item => {
            return {
              ovar_codigo: item.ovaR_Codigo ?? null,
              ccot_numero: item.ccoT_Numero ?? null,
              ccot_tipo: item.ccoT_Tipo ?? null,
              ovar_descrip: item.ovaR_Descrip ?? null,
              ovar_fecemi: item.ovaR_FecEmi ? new Date(item.ovaR_FecEmi) : null,
              ovar_archivo: item.ovaR_Archivo ?? null,
              ovar_observacion: item.ovaR_Observacion ?? null,
              audi_usrcrea: item.audI_UsrCrea ?? null,
              audi_feccrea: item.audI_FecCrea ? new Date(item.audI_FecCrea) : null,
              audi_usrmod: item.audI_UsrMod ?? null,
              audi_fecmod: item.audI_FecMod ? new Date(item.audI_FecMod) : null
            };
          });
          console.log('Respuesta exitosa:', response.datos.result);
          console.log('Archivos obtenidos:', this.archivos_dataarchivos);
        }
      }
    );
  }

  filtrarPuertosSegunVia() {
    const viaSeleccionada = this.group_datosgenerales.value["via"] || (this.datosRecibidos != null ? this.datosRecibidos.cons_codvia : null);
    console.log("filtrarPuertosSegunVia viaSeleccionada", viaSeleccionada)
    const puertosFiltrados = this.ListaTodosPuertoOrigen.filter(puerto => {
      if (!viaSeleccionada) return true;
      return puerto.via === viaSeleccionada;
    });
    this.ListaTodosPuertoOrigenyDestino = puertosFiltrados;
    this.ListaPuertosByVia = puertosFiltrados;
    console.log("filtrarPuertosSegunVia this.ListaTodosPuertoOrigenyDestino", this.ListaTodosPuertoOrigenyDestino)
    console.log("filtrarPuertosSegunVia this.ListaPuertosByVia", this.ListaPuertosByVia)
  }


}
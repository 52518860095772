import { Component } from '@angular/core';

@Component({
  selector: 'app-page-new-promociones',
  templateUrl: './page-new-proforma.component.html',
  styleUrls: ['./page-new-proforma.component.css']
})
export class PageNewPromocionesAPEC {

}

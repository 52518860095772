import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatosCompartidosService {
  private tablaDatos: any[] = [];
  private listaDatos: any[] = [];
  private listaDatosContratos: any[] = [];

  constructor() { }

  setTablaDatos(datos: any[]) {
    this.tablaDatos = datos;
  }

  getTablaDatos() {
    return this.tablaDatos;
  }

  setlistaDatos(list: any[]) {
    this.listaDatos = list;
  }

  getlistaDatos(): any[] {
    return this.listaDatos;
  }

  setlistaDatosContratos(list: any[]) {
    this.listaDatosContratos = list;
  }

  getlistaDatosContratos(): any[] {
    return this.listaDatosContratos;
  }
}

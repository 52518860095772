import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GestionUsuarioRepository } from '../gestion-usuario/domain/gestion-usuario-repository';
import { ListarUsuariosRequest, TransaccionUsuariosRequest } from '../gestion-usuario/domain/request/gestion-usuario-request';
import { ListarUsuariosResponse, TransaccionUsuariosResponse } from '../gestion-usuario/domain/response/gestion-usuario-response';

@Injectable()
export class GestionUsuarioService extends GestionUsuarioRepository {

  constructor(private readonly http: HttpClient
  ) {
    super();
  }
  listarUsuarios(prolrequest: ListarUsuariosRequest): Observable<ListarUsuariosResponse> {
    return this.http.post<ListarUsuariosResponse>(`${environment.PATH_API}/administracion/ListarUsuarios/`, prolrequest);
  }
  transaccionUsuarios(prolrequest: TransaccionUsuariosRequest): Observable<TransaccionUsuariosResponse> {
    return this.http.post<TransaccionUsuariosResponse>(`${environment.PATH_API}/administracion/TransaccionUsuario/`, prolrequest);
  }
}
<div class="titulo">NUEVA RESERVA</div>

<form [formGroup]="group" class="formulario example-full-width-alterno-content">
    <div class="example-full-width-alterno-content-column">

        <div class="example-full-width-alterno-content-row">
            <span class="p-float-label example-full-width-alterno-content-3">
                <p-dropdown formControlName="shipper" [options]="ListaShipper" placeholder="Select a City"
                    optionLabel="nombre" inputId="float-label" [filter]="true" filterBy="nombre"
                    [showClear]="!readonlyinput" class="example-full-width-alterno-content" [readonly]="readonlyinput"
                    (onChange)="listarCommodity()">
                </p-dropdown>
                <label for="float-label">Shipper</label>
            </span>
            <span class="p-float-label example-full-width-alterno-content-3">
                <p-dropdown formControlName="origen" [options]="ListaPuertoOrigen" placeholder="Select a City"
                    optionLabel="nombre" inputId="origenid" [filter]="true" filterBy="nombre" [showClear]="true"
                    (onFilter)="onFilter($event,'origen')" (onChange)="listarNave()">
                </p-dropdown>
                <label for="float-label">Origen</label>
            </span>
            <span class="p-float-label example-full-width-alterno-content-3">
                <p-dropdown formControlName="destino" [options]="ListaPuertoDestino" placeholder="Select a City"
                    optionLabel="nombre" inputId="float-label" [filter]="true" filterBy="nombre" [showClear]="true"
                    (onFilter)="onFilter($event,'destino')" (onChange)="listarNave()">
                </p-dropdown>
                <label for="float-label">Destino</label>
            </span>
            <span class="p-float-label example-full-width-alterno-content-3">
                <p-dropdown formControlName="via" [options]="ListaVia" placeholder="Select a City" optionLabel="nombre"
                    inputId="float-label" [filter]="true" filterBy="nombre" [showClear]="!readonlyinput"
                    [readonly]="readonlyinput">
                </p-dropdown>
                <label for="float-label">Via</label>
            </span>
        </div>

        <div class="example-full-width-alterno-content-row">
            <div class="example-full-width-alterno-content-3"
                *ngIf="group.value.via && group.value.via.codigo == '001'">
                <div class="input-indice">
                    <span class="p-float-label label-indices">
                        <input formControlName="contenedores" type="number" pInputText />

                        <label for="float-label">Cantidad Contenedores</label>
                    </span>
                    <div class="indice">x40’RH</div>
                </div>

            </div>
            <div class="example-full-width-alterno-content-3"
                *ngIf="group.value.via && group.value.via.codigo == '002'">
                <span class="p-float-label">
                    <input formControlName="skids" type="text" pInputText />
                    <label for="float-label">Cantidad Skids</label>
                </span>
            </div>
            <div class="example-full-width-alterno-content-3">
                <span class="p-float-label">
                    <input formControlName="bl" type="text" pInputText [readonly]="readonlyinput" />
                    <label for="float-label">Emisión B/L</label>
                </span>
            </div>
            <div class="example-full-width-alterno-content-3">
                <span class="p-float-label">
                    <input formControlName="booking" type="text" pInputText />
                    <label for="float-label">N° Booking</label>
                </span>
            </div>
        </div>

        <div class="example-full-width-alterno-content-row">
            <div class="p-float-label example-full-width-alterno-content-3">
                <span class="p-float-label example-full-width-alterno-content">
                    <p-dropdown formControlName="naviera" [options]="ListaNaviera" placeholder="Select a City"
                        optionLabel="nombre" inputId="float-label" [filter]="true" filterBy="nombre" [showClear]="true"
                        (onChange)="listarNave()">
                    </p-dropdown>
                    <label for="float-label">Naviera/Aerolinea</label>
                </span>
            </div>
            <span class="p-float-label example-full-width-alterno-content-3">
                <p-dropdown formControlName="semana" [options]="ListaSemanas" placeholder="Select a City"
                    optionLabel="codigo" inputId="float-label" [filter]="true" filterBy="codigo"
                    [showClear]="!readonlyinput" [readonly]="readonlyinput" (onChange)="listarNave()">
                </p-dropdown>
                <label for="float-label">Semana</label>
            </span>
            <span class="p-float-label example-full-width-alterno-content-3">
                <p-dropdown formControlName="nave" [options]="ListaNave" placeholder="Select a City"
                    optionLabel="nombre" inputId="float-label" [filter]="true" filterBy="nombre" [showClear]="true">
                </p-dropdown>
                <label for="float-label">Nave</label>
            </span>
            <span class="p-float-label example-full-width-alterno-content-3">
                <p-dropdown formControlName="commodity" [options]="ListaCommodity" placeholder="Select a City"
                    optionLabel="nombre" inputId="float-label" [filter]="true" filterBy="nombre"
                    [showClear]="!readonlyinput" [readonly]="readonlyinput">
                </p-dropdown>
                <label for="float-label">Commodity</label>
            </span>
        </div>
        <div class="example-full-width-alterno-content-row">
            <div class="example-full-width-alterno-content-3">
                <div class="example-full-width-alterno-content-row">

                    <div class="example-full-width-alterno-content-2">
                        <div class="input-indice">
                            <span class="p-float-label label-indices">
                                <input formControlName="neto" type="number" pInputText [readonly]="readonlyinput" />
                                <label for="float-label">Peso Neto</label>
                            </span>
                            <div class="indice">Kg</div>
                        </div>
                    </div>
                    <div class="example-full-width-alterno-content-2">
                        <div class="input-indice">
                            <span class="p-float-label label-indices">
                                <input formControlName="temperatura" type="number" pInputText />

                                <label for="float-label">Temperatura</label>
                            </span>
                            <div class="indice">°C</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="example-full-width-alterno-content-3">
                <div class="example-full-width-alterno-content-row">
                    <div class="example-full-width-alterno-content-2">
                        <div class="input-indice">
                            <span class="p-float-label label-indices">
                                <input formControlName="ventilacion" type="number" pInputText
                                    [readonly]="readonlyinput" />
                                <label for="float-label">Ventilación</label>
                            </span>
                            <div class="indice">%</div>
                        </div>


                        <!-- <div class="p-inputgroup">
                                <input formControlName="ventilacion" type="number" pInputText
                                    [readonly]="readonlyinput" />
                                <span class="p-inputgroup-addon">%</span>
                            </div> -->
                        <!-- <span class="p-float-label label-indices">
                            <span class="p-input-icon-right">
                                <i class="pi pi-spin pi-spinner"></i>
                                <input formControlName="ventilacion" type="number" pInputText
                                    [readonly]="readonlyinput" />
                            </span>
                            <label for="float-label">Ventilación</label>
                        </span> -->
                    </div>
                    <span class="p-float-label example-full-width-alterno-content-2">
                        <input formControlName="humedad" type="number" pInputText [readonly]="readonlyinput" />
                        <label for="float-label">Humedad</label>
                    </span>
                </div>
            </div>
            <div class="example-full-width-alterno-content-3">
                <div class="example-full-width-alterno-content-row">
                    <span class="p-float-label example-full-width-alterno-content-2">
                        <input formControlName="consignatario" type="text" pInputText [readonly]="readonlyinput" />
                        <label for="float-label">Consignatario</label>
                    </span>
                    <span class="p-float-label example-full-width-alterno-content-2">
                        <input formControlName="remarks" type="text" pInputText [readonly]="readonlyinput" />
                        <label for="float-label">Remarks</label>
                    </span>
                </div>
            </div>
            <div class="example-full-width-alterno-content-3">
                <span class="p-float-label example-full-width-alterno-content">
                    <p-dropdown formControlName="flete" [options]="ListaFlete" placeholder="Select a City"
                        optionLabel="nombre" inputId="float-label" [filter]="true" filterBy="nombre"
                        [showClear]="!readonlyinput" [readonly]="readonlyinput">
                    </p-dropdown>
                    <label for="float-label">Flete</label>
                </span>
            </div>
        </div>
        <div class="example-full-width-alterno-content-row">
            <div class="example-full-width-alterno-content-3 center">
                <div class="example-full-width-alterno-content-row center">
                    <div>Reseteo Temperatura</div>
                    <div class="flex align-items-center center">
                        <p-radioButton name="reseteotemp" value=1 formControlName="reseteotemp"
                            [disabled]="readonlyinput"></p-radioButton>
                        <label class="ml-2">Si</label>
                    </div>
                    <div class="flex align-items-center center">
                        <p-radioButton name="reseteotemp" value=0 formControlName="reseteotemp"
                            [disabled]="readonlyinput"></p-radioButton>
                        <label class="ml-2">No</label>
                    </div>
                </div>
            </div>
            <div class="example-full-width-alterno-content-3 center">
                <div class="example-full-width-alterno-content-row center">
                    <div>Atmosfera Controlada</div>
                    <div class="flex align-items-center center">
                        <p-radioButton name="atmcontrolada" value=1 formControlName="atmcontrolada"></p-radioButton>
                        <label for="ingredient1" class="ml-2">Si</label>
                    </div>
                    <div class="flex align-items-center center">
                        <p-radioButton name="atmcontrolada" value=0 formControlName="atmcontrolada"></p-radioButton>
                        <label for="ingredient2" class="ml-2">No</label>
                    </div>
                </div>
            </div>
            <div class="example-full-width-alterno-content-3 center">
                <div class="example-full-width-alterno-content-row center">
                    <div>Cold Treatment</div>
                    <div class="flex align-items-center center">
                        <p-radioButton name="coldtreatment" value=1 formControlName="coldtreatment"></p-radioButton>
                        <label for="ingredient1" class="ml-2">Si</label>
                    </div>
                    <div class="flex align-items-center center">
                        <p-radioButton name="coldtreatment" value=0 formControlName="coldtreatment"></p-radioButton>
                        <label for="ingredient2" class="ml-2">No</label>
                    </div>
                </div>
            </div>
            <div class="example-full-width-alterno-content-3">
                <div class="example-full-width-alterno-content-row center">
                    <div>SLI</div>
                    <div class="flex align-items-center center">
                        <p-radioButton name="sli" value=1 formControlName="sli"></p-radioButton>
                        <label for="ingredient1" class="ml-2">Si</label>
                    </div>
                    <div class="flex align-items-center center">
                        <p-radioButton name="sli" value=0 formControlName="sli"></p-radioButton>
                        <label for="ingredient2" class="ml-2">No</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="example-full-width-alterno-content-row">
            <div class="example-full-width-alterno-content-3" *ngIf="group.value.reseteotemp == 1">
                <div class="example-full-width-alterno-content-row">
                    <span class="p-float-label example-full-width-alterno-content-2">
                        <input formControlName="restemperatura" type="number" pInputText />
                        <label for="float-label">Temperatura</label>
                    </span>
                    <span class="p-float-label example-full-width-alterno-content-2">
                        <input formControlName="cantidad" type="number" pInputText />
                        <label for="float-label">Cantidad Días</label>
                    </span>
                </div>
            </div>
            <div class="example-full-width-alterno-content-3" *ngIf="group.value.atmcontrolada == 1">
                <span class="p-float-label example-full-width-alterno-content">
                    <p-dropdown formControlName="tecnologia" [options]="ListaTecnologias" placeholder="Select a City"
                        optionLabel="nombre" inputId="float-label" [filter]="true" filterBy="nombre" [showClear]="true">
                    </p-dropdown>
                    <label for="float-label">Tecnologia</label>
                </span>
            </div>
            <div class="example-full-width-alterno-content-3" *ngIf="group.value.atmcontrolada == 1">
                <div class="example-full-width-alterno-content-row">
                    <span class="p-float-label example-full-width-alterno-content-2">
                        <input formControlName="o2" type="number" pInputText />
                        <label for="float-label">02</label>
                    </span>
                    <span class="p-float-label example-full-width-alterno-content-2">
                        <input formControlName="co2" type="number" pInputText />
                        <label for="float-label">C02</label>
                    </span>
                </div>
            </div>
            <div class="example-full-width-alterno-content-3"
                *ngIf="group.value.flete && group.value.flete.codigo == '003'">
                <span class="p-float-label example-full-width-alterno-content">
                    <input formControlName="pagadero" type="text" pInputText [readOnly]="readonlyinput" />
                    <label for="float-label">Pagadero</label>
                </span>
            </div>
        </div>
        <div class="example-full-width-alterno-content-row center">
            <p-button label="Vista Previa" icon="pi pi-eye" styleClass="p-button-info p-button-sm"
                (onClick)="viewPDF()"></p-button>
            <p-button label="Guardar y Enviar" icon="pi pi-save" styleClass="p-button-info p-button-sm"
                (onClick)="guardar()"></p-button>
            <p-button label="Cancelar" icon="pi pi-external-link" styleClass="p-button-info p-button-sm"
                (onClick)="salir()"></p-button>
        </div>

    </div>
</form>

<div style="height: 60px;">

</div>
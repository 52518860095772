<mat-card appearance="outlined" fxFlex="1 2 340px" fxLayout="column" class="box">
  <div fxLayout="column" fxLayoutAlign="center center">
    <h3>LOGIN</h3>
  </div>

  <form [formGroup]="group" fxLayout="column" fxLayoutAlign="space" class="controlclaros">

    <mat-form-field appearance='outline' color="primary" class="example-full-width" class="controlclaros">
      <mat-label>Usuario</mat-label>
      <mat-icon matSuffix>face</mat-icon>
      <input type="text" matInput required formControlName="usuario" (keydown)="handleKeyDown($event)" (input)=" ingresaUsuario()"/>
      <mat-error>el campo es obligatorio</mat-error>
    </mat-form-field>

    <br>

    <mat-form-field appearance='outline' color="primary" class="example-full-width">
      <mat-label class="border">Password</mat-label>
      <input matInput type="password" formControlName="password" [type]="hide ? 'password':'text' " (keydown)="handleKeyDown($event)" >
      <i class="material-icons" matSuffix (click)="hide =!hide">{{hide ? 'visibility':'visibility_off'}}</i>
      <mat-error>el campo es obligatorio</mat-error>
    </mat-form-field>

    <br>

    <button mat-raised-button color="warn"  (click)="login()" type="button" [disabled]="group.invalid"> Ingresar</button>
    
    <br>
    <mat-label class="cambio" (click)="OpenModal('',{nombreUsuario})">Cambiar contraseña</mat-label> 
    <mat-label class="cambio" (click)="registrar()">Registrarse</mat-label> 
  </form>
</mat-card>
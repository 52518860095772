import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MetadataTable } from 'src/app/interfaces/metada-table.interface';
import { ListarTareaRequest } from '../../domain/request/gestionProyectos-request';
import { GestionProyectosRepository } from '../../domain/gestionProyectos-repository';
import { UtilService } from 'src/app/services/util.service';
import { AgregarNuevaTareaComponent } from '../agregar-nueva-tarea/agregar-nueva-tarea.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActualizarEstadoComponent } from '../actualizar-estado/actualizar-estado.component';
import { newmetadate } from 'src/app/shared/components/datatable/domain/datatable-domain';


@Component({
  selector: 'app-bandeja-tareas',
  templateUrl: './bandeja-tareas.component.html',
  styleUrls: ['./bandeja-tareas.component.css']
})
export class BandejaTareasComponent {
  constructor(private readonly router: Router,
    private readonly proyectosService: GestionProyectosRepository,
    private util: UtilService
  ){}

  ListaEstadosProyecto: any[]=[]
  idProyecto: number
  group: FormGroup
  dataTable: any[] = []
  metadataTable: MetadataTable [] = [
    { field: "item", title: 'Item' },
    { field: "descripcion_tarea", title: 'Descripcion tarea' },
    { field: "fecha_iniciotarea", title: 'Fecha inicio' },
    { field: "fecha_fintarea", title: 'Fecha fin' },
    { field: "dias", title: 'Dias' },
    { field: "horas", title: 'Horas' },
    { field: "nom_recurso", title: 'Recurso' },
    { field: "des_estado", title: 'Estado' },
    { field: "importancia", title: 'Prioridad' },
  ]
 
  iniciarFormulario(accion: string,row){
    if(accion == "D"){
      this.group = new FormGroup({
        nombreproyecto: new FormControl(null,null),
        fechaInicio: new FormControl(null,null),
        fechaFin: new FormControl(null,null),
        estado: new FormControl(null,null),
      })
    } else {
      this.group = new FormGroup({
        nombreproyecto: new FormControl(row.descripcion_proyecto,null),
        fechaInicio: new FormControl(null,null),
        fechaFin: new FormControl(null,null),
        estado: new FormControl(null,null),
      })
    }
  }


  eventostareas_dataeventos: any[] = []
  metadatableDataEventos: newmetadate[]


  ngOnInit(): void {
    if(localStorage.getItem('datos') == null){
      this.iniciarFormulario("D",null)
    } else {
      const datosSerializados = localStorage.getItem('datos')
      const datos = JSON.parse(datosSerializados);
      localStorage.removeItem('datos');
      this.iniciarFormulario("C",datos)
      this.group.controls["nombreproyecto"].setValue(datos.descripcion_proyecto)
      this.idProyecto = datos.id
      console.log(this.idProyecto)
    }
    this.buscar()

    this.metadatableDataEventos = [
      { title: 'Item' , field: 'item', filterType: 'text', inputType: 'text', inputStatus: 'lock'},
      { title: 'Descripcion Tarea', field: 'descripcion_tarea', filterType: 'text', inputType: 'text' , inputStatus: 'lock', style: { justify: 'left' }},
      { title: 'Fecha Inicio', field: 'fecha_iniciotarea', filterType: 'text', inputType: 'date' , inputStatus: 'lock'},
      { title: 'Fecha Fin', field: 'fecha_fintarea', filterType: 'text', inputType: 'date' },
      { title: 'Dias', field: 'dias', filterType: 'text', inputType: 'int' , inputStatus: 'lock'},
      { title: 'Horas', field: 'horas', filterType: 'text', inputType: 'text', inputStatus: 'lock'},
      { title: 'Recurso', field: 'nom_recurso', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
      
      { title: 'Estado', field: 'des_estado', filterType: 'text', inputType: 'dropdown',dropdown: [
        {label: 'NUEVO', value: 'N'},
        {label: 'APROBADO', value: 'A'},
        {label: 'PLANIFICADO', value: 'P'},
        {label: 'EN CURSO', value: 'E'},
        {label: 'EN REVISION', value: 'R'},
        {label: 'COMPLETADO', value: 'C'},
        {label: 'STAMPBY', value: 'S'},
      ]},
      
      { title: 'Prioridad', field: 'importancia', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
      
      
    ]

    this.ListaEstadosProyecto= [
      {nombre:"TODOS",codigo:""},
      {nombre:"NUEVO",codigo:"N"},
      {nombre:"APROBADO",codigo:"A"},
      {nombre:"PLANIFICADO",codigo:"P"},
      {nombre:"EN CURSO",codigo:"E"},
      {nombre:"EN REVISION",codigo:"R"},
      {nombre:"COMPLETADO",codigo:"C"},
      {nombre:"STAMPBY",codigo:"S"},
    ]
  }


  buscar(){
    const values = this.group.value
    const requestDatos: ListarTareaRequest = <ListarTareaRequest>{}
    requestDatos.nombre_proyecto = values["nombreproyecto"] !== null ? values['nombreproyecto'] : '';
    requestDatos.fecha_iniciotarea = values["fechaInicio"] !== null ? values['fechaInicio'] : '';
    requestDatos.fecha_fintarea = values['fechaFin'] !== null ? values['fechaFin'] : '';
    requestDatos.estado =  values['estado'] !== null ? values['estado'] : '';


    this.proyectosService.listarTarea(requestDatos).subscribe((response) => {
      if(response.datos.status === 200){

        this.eventostareas_dataeventos = response.datos.result.map(item => {
          // const fechaInicioHora = new Date(item.fecha_iniciotarea)
          // item.fecha_iniciotarea = item.fecha_iniciotarea != null ? fechaInicioHora.getDate() + '/' + (fechaInicioHora.getMonth() + 1).toString().padStart(2, '0') + '/' + fechaInicioHora.getFullYear() + ' - ' + fechaInicioHora.getHours().toString().padStart(2, '0') + ':' + fechaInicioHora.getMinutes().toString().padStart(2, '0') : ''
          // const fechaFinHora = new Date(item.fecha_fintarea)
          // item.fecha_fintarea = item.fecha_fintarea != null ? fechaFinHora.getDate() + '/' + (fechaFinHora.getMonth() + 1).toString().padStart(2, '0') + '/' + fechaFinHora.getFullYear() + ' - ' + fechaFinHora.getHours().toString().padStart(2, '0') + ':' + fechaFinHora.getMinutes().toString().padStart(2, '0') : ''


          item.fecha_iniciotarea = this.util.formatearfecha(item.fecha_iniciotarea,'fechaconT')

          item.fecha_fintarea = this.util.formatearfecha(item.fecha_fintarea,'fechaconT')


          // this.id = item.id.toString()
          // console.log("FECHAAPROB",item.fecha_aprobacion )


          return item
        })
      }


      console.log("DATOSSSS  3 ", response.datos.result)
    },(errorServicio)=>{
      this.dataTable = []
      this.util.ShowMessage({mensaje:'No se encontraron datos',tipo:'warning'})
    })
  }


  agregarTarea(){
    const idproyecto = this.idProyecto
    localStorage.setItem("idproyecto", String(idproyecto))
    const options = {
      disableClose: true,
      panelClass: 'AgregarNuevaTarea',
      data: 'T'
    };
    const reference = this.util.openModal(
      AgregarNuevaTareaComponent,
      options,
    );
    reference.subscribe((response) => {
      if (response) {
        this.buscar()
      }
    });
  }


  agregarSubTareas(value,row:any){
    const idproyecto = this.idProyecto
    localStorage.setItem("idproyecto", String(idproyecto))
    localStorage.setItem("row", JSON.stringify(row))
    const options = {
      disableClose: true,
      panelClass: 'AgregarNuevaTarea',
      data: 'S'
    };
    const reference = this.util.openModal(
      AgregarNuevaTareaComponent,
      options,
    );
    reference.subscribe((response) => {
      if (response) {
        this.buscar()
      }
    });
  }


  editar(value,row:any){
    console.log("Dato de la fila: ", row)
    const options = {
      disableClose: true,
      panelClass: 'AgregarNuevaTarea',
      data: row
    };
    const reference = this.util.openModal(
      AgregarNuevaTareaComponent,
      options,
    );
    reference.subscribe((response) => {
      if (response) {
        this.buscar()
      }
    });
  }


  editarEstado(value,row:any){
    localStorage.setItem("rowtarea", JSON.stringify(row))
    const options = {
      disableClose: true,
      panelClass: 'ActualizarEstado',
      data: 'T'
    };
    const reference = this.util.openModal(
      ActualizarEstadoComponent,
      options,
    );
    reference.subscribe((response) => {
      if (response) {
        this.buscar()
      }
    });
  }


  salir(path: string): void{
    this.router.navigate(['/gestionProyectos']);
  }


  onDateChangeDesde(event: any, campo: string) {
    const selectedDate = event.value; // Obtener la fecha seleccionada
    selectedDate.setHours(0, 0, 0, 0);
    // Formatear la fecha en el formato deseado y actualizar el valor del formulario
    this.group.get(campo).setValue(selectedDate.toISOString());
  }
}




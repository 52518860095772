import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeolocalizacionRoutingModule } from './geolocalizacion-routing.module';
import { GeolocalizacionComponent } from './presentacion/components/geolocalizacion/geolocalizacion.component';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [GeolocalizacionComponent],
  imports: [
    CommonModule,
    GeolocalizacionRoutingModule,
    SharedModule,
    MatTableModule,
    ReactiveFormsModule,
    MatRadioModule ,
    FormsModule,

  ],
  exports: [GeolocalizacionComponent],
})
export class GeolocalizacionModule { }

import { Observable } from "rxjs";
import { RequestAnularCobranza, RequestImportarCobranzas, RequestListarCobranzas, RequestTransaccionCobranza, RequestdashboardCobranza } from "./request/request_cobranzasdiarias";
import { ResponseAnularCobranza, ResponseImportarCobranzasDiarias, ResponseListadoCobranzasDiarias, ResponseListadoReporteEjecutivo, ResponseListadoReporteResumen, ResponseTransaccionCobranzasDiarias, ResponsedashboardCobranza, ResponsedashboardCobranzaEstado, ResponsedashboardCobranzacliente, ResponsedashboardMontoFecha } from "./response/response_cobranzasdiarias";
import { proformaRequest } from "src/app/proforma/domain/request/proforma_request";
import { ProformaResponse } from "src/app/proforma/domain/response/proforma_response";


export abstract class CobranzasRepository {


    abstract listarCobranzasDiarias(request:RequestListarCobranzas): Observable<ResponseListadoCobranzasDiarias> 

    abstract importarCobranzasDiarias(request:RequestImportarCobranzas): Observable<ResponseImportarCobranzasDiarias> 


    abstract listardashboardcobranzas(request:RequestdashboardCobranza): Observable<ResponsedashboardCobranza> 
  
    abstract listardashboardcobranzascliente(request:RequestdashboardCobranza): Observable<ResponsedashboardCobranzacliente> 
    
    abstract listadashboardcobranzasestado(request:RequestdashboardCobranza): Observable<ResponsedashboardCobranzaEstado> 
   
    abstract listar(prolrequest: proformaRequest): Observable<ProformaResponse> 

    abstract  transaccioncobranzas(request:RequestTransaccionCobranza): Observable<ResponseTransaccionCobranzasDiarias> 

    abstract listadoDashboardMontoPorFecha (request:RequestdashboardCobranza): Observable<ResponsedashboardMontoFecha> 
   
    abstract anularCobranza (request:RequestAnularCobranza): Observable<ResponseAnularCobranza> 

    abstract listarReporteEjecutivo (request:RequestListarCobranzas): Observable<ResponseListadoReporteEjecutivo> 

    abstract listarReporteResumen (request:RequestListarCobranzas): Observable<ResponseListadoReporteResumen> 
}

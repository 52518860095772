<div class="component data">


  <mat-table class="mat-elevation-z8" matTableExporter [dataSource]="dataSource" #exporter="matTableExporter"
    class="table" matSort (matSortChange)="announceSortChange($event)">
    <!-- Recibe los datos -->
    <ng-container *ngFor="let metadata of metadataTable" [matColumnDef]="metadata.field" class="container">

      <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell">
        <div class="cabecera" [ngClass]="{'alinear-sin-filtro': filtro == false}">
          <div class="filtro">
            <div style="margin-top: 5px;">{{ metadata.title }}</div>

            <div *ngIf="filtro" style="z-index: 1100; width: 100%;">
              <div class="input">
                <mat-icon matPrefix class="icono">search</mat-icon>
                <input type="text" [id]="metadata.field" (click)="$event.stopPropagation()"
                  (keyup)="applyFilter($event, metadata.field)">

              </div>
            </div>
          </div>
          <!-- Aquí se coloca el mat-sort-header y se le agrega el evento click -->
          <div mat-sort-header (click)="$event.stopPropagation()">
          </div>
        </div>
      </mat-header-cell>

      <!-- <mat-cell *matCellDef="let row" data-label="metadata.field" class="fila" class="cell">
        <ng-container *ngIf="row.IsEditing && metadata.editable; else showName" >
          <input   [(ngModel)]="row[metadata.field]"  [type] = "metadata.type" />
        </ng-container>
        
        <ng-template #showName >
          {{ row[metadata.field] }}
        </ng-template>
      </mat-cell> -->
      <mat-cell *matCellDef="let row" class="fila cell" (click)="cambiarColor(row)"
        [ngClass]="{'seleccionada': row === filaSeleccionada,'align-left': metadata.align === 'left', 'align-right': metadata.align === 'right', 'align-center': metadata.align !== 'left'}">
        <ng-container *ngIf="row.IsEditing && metadata.editable; else showName">
          <input [(ngModel)]="row[metadata.field]" [type]="metadata.type" />
        </ng-container>
        <ng-template #showName>
          <div [appLineBreak]="45">{{ row[metadata.field] }}</div>
        </ng-template>


      </mat-cell>


      <ng-container *ngIf="columnasConTotales.includes(metadata.field); else noTotal">
        <!-- <div *ngFor="let item of AlingTotales if item.field == metadata.fiel"> -->
        <!-- <td mat-footer-cell *matFooterCellDef class="fila cell" [ngClass]="{'total-center': AlingTotales['aling']=== 'center',
            'total-right': AlingTotales['aling'] === 'right',
            'align-left': AlingTotales['aling'] === 'left'}"> -->
        <td mat-footer-cell *matFooterCellDef class="fila cell" [ngClass]="{
              'align-center': getAling(metadata.field) === 'center',
              'align-right': getAling(metadata.field) === 'right',
              'align-left': getAling(metadata.field) === 'left'
            }">
          {{ getTotalCost(metadata.field)}}
        </td>
        <!-- </div> -->
      </ng-container>


      <!-- <ng-container *ngIf="columnasConTotales.includes(metadata.field); else noTotal">
        <td mat-footer-cell *matFooterCellDef class="fila cell" [ngClass]="{'total-center': AlingTotales['aling'] === 'center',
        'total-right': AlingTotales['aling'] === 'right',
        'align-left': AlingTotales.values['aling'] === 'left'}">

          {{ getTotalCost(metadata.field)}}
        </td>
      </ng-container> -->

      <ng-template #noTotal>
        <td mat-footer-cell *matFooterCellDef class="fila cell"> </td>
      </ng-template>
    </ng-container>


    <mat-header-row *matHeaderRowDef="columnsToView" class="header-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToView;" class="row"></mat-row>
    <ng-container *ngIf="footer">
      <tr class="mat-footer-row" mat-footer-row *matFooterRowDef="columnsToView"></tr>
    </ng-container>

  </mat-table>
</div>
<mat-paginator *ngIf="visiblepaginacion" [pageSizeOptions]="[ 25,50,75, 100,150,200]"
  [length]="dataTable?.length"></mat-paginator>
<button *ngIf="exportar" mat-raised-button
  (click)="exporter.exportTable('xlsx', {fileName:'data', sheet: 'reporte', Props: {Author: 'delfin'}})">Excel</button>
<div class="tituloPantalla">
    <mat-toolbar style="height: 33px;" fxLayout class="tituloPantalla">
        <h3 class="title">AYUDA CONTRATO</h3>
    </mat-toolbar>
</div>
<div style="width: 95%;margin: auto;padding: 0; height: 70%;">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutAlign="center"
        style="margin-top: 30px; overflow: hidden;">
        <div fxFlex="100" style="padding: 0px;" class="general">
            <form [formGroup]="group_contrato">
                <app-datatable [dataTable]="dataTableContrato" [metadataTable]="metadataTableContrato"
                    [scrollHeight]="'400px'" [key]="'codigo'" [style]="estilos" (Row)="row = $event" 
                    [filterGlobal]="true">
                </app-datatable>
                <ng-template #addHeaderStart>
                    <th style="width: 7px;">Resultado de la búsqueda</th>
                </ng-template>
                <ng-template #addBodyStart let-row="row">
                    <td>
                        <div class="card flex justify-content-center">
                            <p-button icon="pi pi-pencil" pTooltip="Editar"
                                styleClass="p-button-rounded p-button-secondary p-button-text"></p-button>
                            <!-- (onClick)="seleccionarNaveViaje(row) -->
                        </div>
                    </td>
                </ng-template>

                <div style="margin-top: 20px;"></div>
                <div fxLayoutGap="20px" class="label">
                    <p-button mat-raised-button class="button1" (click)="cancelar()">Cancelar</p-button>
                    <p-button mat-raised-button class="button1" (click)="aceptar()">Aceptar</p-button>
                </div>
            </form>
        </div>
    </div>

</div>
import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { PreResRepository } from "../cotizaciones_resumentes/domain/pre_res_repository";
import { PreResResponse } from "../cotizaciones_resumentes/domain/response/pre_res_response";
import { PreResRequest } from "../cotizaciones_resumentes/domain/request/pre_res_request";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class PreResService extends PreResRepository{

  constructor(private readonly http:HttpClient,
    private readonly storage: StorageService

    ){

    super();
  }

  listar(prolrequest:PreResRequest): Observable<PreResResponse>
  {
      return this.http.post<PreResResponse>(`${environment.PATH_API}/reportes/cotizacionesAvanzadas`,prolrequest);
  }
}
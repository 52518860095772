import { Component,Inject ,ViewChild} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { requestnaves } from 'src/app/proforma/domain/request/proforma_request';
import { listadonaves } from 'src/app/proforma/domain/response/proforma_response';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';
@Component({
  selector: 'app-datos-proforma',
  templateUrl: './datos-proforma.component.html',
  styleUrls: ['./datos-proforma.component.css']
})
export class DatosProformaComponent {

  constructor(private util: UtilService,private readonly proformaService : ProformaRepository,private readonly  reference: MatDialogRef<DatosProformaComponent>,@Inject(MAT_DIALOG_DATA) private  data:any,private readonly storage :StorageService){}
  groupDatos:FormGroup
  mostrarcontrol:boolean
  @ViewChild('checkboxstatus') checkboxstatus: any;
  @ViewChild('autoTransportista') autoTransportista: MatAutocomplete;
nave:string
textoFiltro:string



closeModal(){

const values = this.groupDatos.value;


if (values['monto'] < values['costo']){
   
  this.util.showMessage('monto invalido')
  //values['monto'].setValue(this.data.dcoT_PrecioUniVenta)
  this.groupDatos.controls['monto'].setValue(this.data.dcoT_PrecioUniVenta)
   return;
}

    const  datosadicionales = {
          
      nave: this.transportistaSeleccionado != undefined?  this.transportistaSeleccionado.nave_codigo:null, //values['nave'],
      etd:values['etd'],
      eta:values['eta'],
      tiempotransito:values['tiempotransito'],
      cutoff:values['cutOff'],
      monto:values['monto'],
      autizacion: this.checkboxstatus ===undefined || this.checkboxstatus ===null ? false : this.checkboxstatus.checked,
      diaslibressugerido : values['diaslibres'],
      ows :values['ows'],
      
      }

    this.reference.close(datosadicionales);
  }

  inicializaFormulario(){
    this.groupDatos = new FormGroup({
      nave : new FormControl(null,null),
      etd : new FormControl(this.data.dprO_ETD,null),
      eta : new FormControl(this.data.dprO_ETA,null),
      tiempotransito : new FormControl(this.data.dprO_TiempoTranscurrido,null),
      cutOff : new FormControl(this.data.dprO_CUTOFF,null),
      monto : new FormControl(this.data.montoajuste ===null || this.data.montoajuste === undefined || this.data.montoajuste  == 0 ? this.data.dcoT_PrecioUniVenta:this.data.montoajuste,null),
      costo:new FormControl(this.data.dcoT_PrecioUniCosto,null),
      autorizar : new FormControl(null,null),
      diaslibres : new FormControl(this.data.diaslibressugerido,null),
      ows : new FormControl(this.data.ows,null)
    })
  }

  ngOnInit(): void{

    const rol =  this.storage.get('rol')
    if ((rol === "1" ) ||  (rol === "9" ) ||  (rol === "2" )||  (rol === "15" )) {

   this.mostrarcontrol = true
    }else {
      this.mostrarcontrol = false
    }
 
    this.inicializaFormulario()
    this.listarnaves()
  }



  
 opcionSeleccionada(event: MatAutocompleteSelectedEvent) {
  this.transportistaSeleccionado = this.listadonaves.find(option => option.nave_nombre === event.option.value);
}
transportistaSeleccionado: any;
requestnaves:requestnaves
listadonaves:listadonaves[] = []
 listarnaves() {
  //const values = this.group.value;
  this.requestnaves = <requestnaves>{};
  
  //this.razonSocialControl =  this.group.get('razonSocial').toString() ;
  const usuario = this.storage.get('codusuario');
  const correo = this.storage.get('correo');
  this.requestnaves.pintENTC_CodTransportista = null
 

  //if(this.razonSocialControl.length > 3){
    this.proformaService.listarnaves(this.requestnaves).subscribe(response => {
  
  

      
      this.listadonaves = response.datos.result
  //    this.listRazonSocial.splice(0, this.listRazonSocial.length);
   //   for (let i = 0; i < response.datos.result.length; i++) {
   //     this.listRazonSocial.push(response.datos.result[i].cliente);
  //    }
     
      //this.codigoRazonSocial = (response.datos.result[0].codcliente)

    });
 // }


}
}

<div fxLayoutGap="20px" class="container_botones">
  <button mat-stroked-button class="button" (click)="guardarProforma('I')">Guardar
    <mat-icon>save</mat-icon>
  </button>
  <button mat-stroked-button class="button" (click)="Salir('')">Salir
    <mat-icon>exit_to_app</mat-icon>

  </button>
</div>
<!-- <div class="a-example-full-width-alterno-content-row-buttons"
  style="justify-content: center; display: flex; padding-top: 10px;">
  <div class="a-example-full-width-alterno-content-5">
    <p-button label="Guardar" (onClick)="guardarProforma('I')" icon="pi pi-save"></p-button>
  </div>
  <div class="a-example-full-width-alterno-content-5">
    <p-button label="Salir  " (onClick)="Salir('')" icon="pi pi-sign-out"></p-button>
  </div>
</div> -->

<!-- <p-tabView [(activeIndex)]="tabIndex">
  <p-tabPanel header="Proforma">
    <form [formGroup]="groupProforma" class="a-example-full-width-alterno-content">
      <div class="a-example-full-width-alterno-content-column" style="align-items: center; gap: 0px;">
        <div class="a-example-full-width-alterno-content-row">
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;" *ngIf="condicion">
            <span class="p-float-label">
              <input pInputText formControlName="nroProforma" appendTo="body" />
              <label>Nro Proforma</label>
            </span>
          </div>
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;" *ngIf="!condicion">
            <span class="p-float-label">
              <input pInputText formControlName="nroProforma" appendTo="body" />
              <label>Nro Proforma</label>
            </span>
          </div>
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;">
            <span class="p-float-label ">
              <p-dropdown [options]="listEstado" placeholder="‎ " optionLabel="nombre" formControlName="estado"
                optionValue="codigo" appendTo="body" class="p-inputtext-sm">
              </p-dropdown>
              <label for="float-label">Estado</label>
            </span>
          </div>
        </div>
        <div class="a-example-full-width-alterno-content-row">
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;">
            <span class="p-float-label ">
              <p-autoComplete formControlName="razonSocial" [suggestions]="limitedOptions"
                [readonly]="condicion ? true : null" (completeMethod)="cargaRucInterno('I')"
                optionGroupLabel="entC_NomCompleto" field="entC_NomCompleto" (onSelect)="cargaRucInterno('I')">
              </p-autoComplete>
              <label for="float-label">Razón social</label>
            </span>
          </div>
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;">
            <span class="p-float-label" style="display: inline-flex;">
              <p-button icon="pi pi-search" styleClass="p-button-rounded p-button-secondary p-button-text" (onClick)="cargaSunat()"></p-button>
              <input type="text" pInputText formControlName="ruc" [readonly]="condicion ? true : null" class="inputwidth"/>
              <label for="float-label" style="left: 60px;">RUC</label>
            </span>
          </div>
        </div>
        <div class="a-example-full-width-alterno-content-row">
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;">
            <span class="p-float-label ">
              <p-dropdown [options]="listContacto" placeholder="‎ " optionLabel="nombreCompleto"
                formControlName="contacto" optionValue="entC_CodContacto" appendTo="body" class="p-inputtext-sm">
              </p-dropdown>
              <label for="float-label">Contacto</label>
            </span>
          </div>
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;">
            <p-button icon="pi pi-user" (onClick)="openModal(contactoComponent, 'contacto')"
              styleClass="p-button-rounded p-button-info p-button-text" *ngIf="listaContactoJ.length === 0"></p-button>
            <span>Añadir contacto</span>
          </div>
        </div>
        <div class="a-example-full-width-alterno-content-row">
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;">
            <span class="p-float-label ">
              <p-dropdown [options]="listReg" placeholder="‎ " optionLabel="nombre" formControlName="regimen"
                optionValue="codigo" appendTo="body" class="p-inputtext-sm">
              </p-dropdown>
              <label for="float-label">Regimen</label>
            </span>
          </div>
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;">
            <span class="p-float-label ">
              <p-dropdown [options]="listVia" placeholder="‎ " optionLabel="nombre" formControlName="via"
                optionValue="codigo" appendTo="body" class="p-inputtext-sm">
              </p-dropdown>
              <label for="float-label">Vía</label>
            </span>
          </div>
        </div>
        <div class="a-example-full-width-alterno-content-row">
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;">
            <span class="p-float-label">
              <p-calendar formControlName="fechaInicio" appendTo="body" [showIcon]="true" [showWeek]="false"
                dateFormat="dd/mm/yy">
              </p-calendar>
              <label for="float-label">Fecha Inicio</label>
            </span>
          </div>
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;">
            <span class="p-float-label">
              <p-calendar formControlName="fechaFin" appendTo="body" [showIcon]="true" [showWeek]="false"
                dateFormat="dd/mm/yy">
              </p-calendar>
              <label for="float-label">Fecha Vigencia</label>
            </span>
          </div>
        </div>
        <div class="a-example-full-width-alterno-content-row">
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;">
            <input type="text" pInputText formControlName="incoter" />
          </div>
          <div class="a-example-full-width-alterno-content-2" style="margin: 2vh 0vh;">
            <span class="p-float-label ">
              <p-dropdown [options]="listTipoCarga" placeholder="‎ " optionLabel="nombre" formControlName="tipoCarga"
                optionValue="codigo" appendTo="body" class="p-inputtext-sm">
              </p-dropdown>
              <label for="float-label">Tipo Carga</label>
            </span>
          </div>
        </div>
        <div class="a-example-full-width-alterno-content-row">
          <div class="a-example-full-width-alterno-content" style="margin: 2vh 0vh;">
            <span class="p-float-label ">
              <p-autoComplete formControlName="agente" [suggestions]="listAgente" [(ngModel)]="textoFiltroAgente"
                [readonly]="condicion ? true : null" optionGroupLabel="codigo" field="nombre" 
                (onSelect)="agenteSelecciona($event)">
              </p-autoComplete>
              <label for="float-label">Agente</label>
            </span>
          </div>
        </div>
        <div class="a-example-full-width-alterno-content-row">
          <div class="a-example-full-width-alterno-content" style="margin: 2vh 0vh;">
            <span class="p-float-label">
              <input pInputText formControlName="observaciones" appendTo="body" />
              <label>Observaciones</label>
            </span>
          </div>
        </div>
        <div class="a-example-full-width-alterno-content-row">
          <div class="a-example-full-width-alterno-content" style="margin: 2vh 0vh;">
            <span class="p-float-label">
              <input pInputText formControlName="glosa" appendTo="body" />
              <label>Glosa y Condiciones</label>
            </span>
          </div>
        </div>
        <div class="a-example-full-width-alterno-content-row-buttons" style="justify-content: center; display: flex; padding-top: 10px;">
          <div>
            <p-button label="Siguiente" (onClick)="siguiente()"></p-button>
          </div>
        </div>
      </div>
    </form>
  </p-tabPanel>
  <p-tabPanel header="Contenedor">

  </p-tabPanel>
  <p-tabPanel header="Resumen">

  </p-tabPanel>
</p-tabView> -->

<mat-tab-group [selectedIndex]="tabIndex">
  <mat-tab>
    <ng-template mat-tab-label>
      Proforma
    </ng-template>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutAlign="center" style="margin-top: 0px;">
      <div fxFlex="100" style="padding: 0px;" class="general">

        <form [formGroup]="groupProforma">
          <div fxLayoutGap="20px" class="label">
            <!--NUMERO PROFORMA- EDIT -->
            <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="condicion">
              <mat-label>Nro Proforma</mat-label>
              <input matInput type="text" placeholder="NroProforma" formControlName="nroProforma" readonly>
            </mat-form-field>

            <!--NUMERO PROFORMA-->
            <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="!condicion">
              <mat-label>Nro Proforma</mat-label>
              <input matInput type="text" placeholder="NroProforma" formControlName="nroProforma">
            </mat-form-field>

            <mat-form-field class="example-full-width-alterno2" appearance="outline">
              <mat-label>Estado</mat-label>
              <mat-select formControlName="estado">
                <mat-option *ngFor="let reg of listEstado" [value]="reg.codigo">{{reg.nombre}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayoutGap="20px" class="label">
            <!--RAZON SOCIAL-->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno3">
              <input matInput type="text" formControlName="razonSocial" [matAutocomplete]="autoRazonSocial"
                (input)="cargaRucInterno('I')" [readonly]="condicion ? true : null">
              <mat-autocomplete #autoRazonSocial="matAutocomplete" (optionSelected)="cargaRucInterno('I')">
                <mat-option *ngFor="let option of limitedOptions" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
              <mat-label>Razon Social</mat-label>
              <mat-icon matSuffix (click)="limpiar('razonSocial','groupProforma')">close</mat-icon>
              <mat-error class="custom-error">Ingrese Razon social</mat-error>
            </mat-form-field>
            <!--RUC-->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno3">
              <input matInput type="text" formControlName="ruc" [readonly]="condicion ? true : null">
              <mat-label>RUC</mat-label>
              <mat-icon matSuffix (click)="cargaSunat()">search</mat-icon>
              <mat-icon matSuffix (click)="limpiar('ruc','groupProforma')">close</mat-icon>
              <mat-error class="custom-error">Ingrese RUC</mat-error>
            </mat-form-field>
          </div>

          <div fxLayoutGap="20px" class="label" style="display: flex;">

            <!-- VIA -->
            <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
              <mat-label>Contacto</mat-label>
              <mat-select formControlName="contacto">
                <mat-option *ngFor="let contacto of listContacto"
                  [value]="contacto.entC_CodContacto">{{contacto.nombre}} {{contacto.apellidos}}</mat-option>
              </mat-select>
              <mat-error class="custom-error">Ingrese Contacto</mat-error>
            </mat-form-field>
            <div style="display: flex;gap: 20px;align-items: center;height: 40px;">
              <mat-icon matSuffix (click)="openModal(contactoComponent, 'contacto')" *ngIf="listaContactoJ.length === 0"
                style="font-size: 30px;width: 30px;">people</mat-icon>
              <span>Añadir contacto</span>
            </div>


          </div>

          <div fxLayoutGap="20px" class="label">
            <!-- REGIMEN -->
            <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
              <mat-label>Regimen</mat-label>
              <mat-select (click)="cargaLista('RGM')" formControlName="regimen">
                <mat-option *ngFor="let reg of listReg" [value]="reg.codigo">{{reg.nombre}}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- VIA -->
            <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
              <mat-label>Via</mat-label>
              <mat-select (click)="cargaLista('VIA')" formControlName="via">
                <mat-option *ngFor="let via of listVia" [value]="via.codigo">{{via.nombre}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayoutGap="20px" class="label">
            <mat-form-field class="example-full-width-alterno3" appearance="outline">
              <mat-label>Fecha Inicio</mat-label>
              <input matInput formControlName="fechaInicio" [matDatepicker]="picker1" autocomplete="off"
                placeholder="Fecha" (ngModelChange)="validarFechas()" [disabled]="disable" readonly>
              <!-- [min] = "ahora" -->
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1 color="primary"></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="example-full-width-alterno3" appearance="outline">
              <mat-label>Fecha vigencia</mat-label>
              <input matInput formControlName="fechaFin" [matDatepicker]="picker2" autocomplete="off"
                placeholder="Fecha" (ngModelChange)="validarFechas()" [disabled]="disable" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2 color="primary"></mat-datepicker>
            </mat-form-field>
          </div>

          <div fxLayoutGap="20px" class="label">
            <!--INCOTER-->
            <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
              <input matInput type="text" placeholder="Incoter" formControlName="incoter" readonly>
            </mat-form-field>

            <!--TIPO CARGA SIEMPRE ES GENERAL-->
            <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
              <mat-label>Tipo Carga</mat-label>
              <mat-select formControlName="tipoCarga">
                <mat-option disabled value="usuario">Selecciona un Tipo Carga</mat-option>
                <mat-option value="1">General</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="c_observaciones">
            <mat-form-field class="observaciones" appearance="outline">
              <mat-label>Agente</mat-label>
              <input type="string" matInput formControlName="agente" [matAutocomplete]="autoAgente"
                [(ngModel)]="textoFiltroAgente">
              <mat-autocomplete #autoAgente="matAutocomplete" (optionSelected)="agenteSelecciona($event)">
                <ng-container *ngFor="let option of listAgente">
                  <mat-option [value]="option.nombre"
                    *ngIf="option.nombre?.toLowerCase().includes(textoFiltroAgente?.toLowerCase())">
                    {{ option.nombre}}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <!--OPERACIONES-->
          <div class="c_observaciones">
            <mat-form-field appearance="outline" class="observaciones">
              <mat-label>Observaciones</mat-label>
              <mat-icon matSuffix (click)="limpiar('observaciones','groupProforma')">close</mat-icon>
              <input matInput type="text" placeholder="Observaciones" formControlName="observaciones">
            </mat-form-field>
          </div>

          <!-- <div class="c_observaciones">
                          <mat-form-field  appearance="outline" class="observaciones">
                            <mat-label>Observaciones Ingles</mat-label>
                            <mat-icon matSuffix (click)="limpiar('observacionesIngles')">close</mat-icon>
                            <input matInput type="text" placeholder="Observaciones" formControlName="observacionesIngles" >
                           </mat-form-field>
                      </div> -->

          <div class="c_observaciones">
            <mat-form-field appearance="outline" class="observaciones">
              <mat-label>Glosa y Condiciones</mat-label>
              <mat-icon matSuffix (click)="limpiar('observaciones','groupProforma')">close</mat-icon>
              <input matInput type="text" placeholder="Glosa Y Condiciones" formControlName="glosa">
            </mat-form-field>
          </div>

        </form>
        <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px">
          <div fxFlex="50" style="background-color: rgb(219, 217, 217); padding: 1px;"></div>
        </div>
      </div>
    </div>
    <button mat-raised-button class="buttonadd" matTooltip="Siguiente" (click)="siguiente()">Siguiente</button>

  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      Contenedor
    </ng-template>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutAlign="center"
      style="margin-top: 0px; overflow: hidden;">
      <div fxFlex="100" style="padding: 0px;" class="general">
        <form [formGroup]="groupContenedor">

          <div class="formulario_nuevo">
            <div fxLayoutGap="20px" class="label ">
              <mat-form-field class="list" appearance="outline" class="example-full-width-alterno3">
                <mat-label>Puerto Origen</mat-label>

                <input matInput type="text" placeholder="Puerto Origen" formControlName="ptoOrigen"
                  [matAutocomplete]="ptoO" (input)="cargaPuerto('ptoOrigen')">
                <mat-autocomplete #ptoO="matAutocomplete" [displayWith]="displayPuertoOrigen">
                  <mat-option *ngFor="let option of listPrtoO" [value]="option">
                    {{ option.nombre}}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix (click)="limpiar('ptoOrigen','groupContenedor')">close</mat-icon>
                <mat-error class="custom-error">Ingrese Puerto origen</mat-error>
              </mat-form-field>

              <!-- Puerto Destino -->
              <mat-form-field class="list" appearance="outline" class="example-full-width-alterno3">
                <mat-label>Puerto Destino</mat-label>

                <input matInput type="text" placeholder="Puerto Destino" formControlName="ptoDestino"
                  [matAutocomplete]="ptoD" (input)="cargaPuerto('ptoDestino')" [readonly]="condicion ? true : false">
                <mat-autocomplete #ptoD="matAutocomplete" [displayWith]="displayPuertoDestino">
                  <mat-option *ngFor="let option of listPrtoD" [value]="option">
                    {{option.nombre}}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix (click)="limpiar('ptoDestino', 'groupContenedor')">close</mat-icon>
                <mat-error class="custom-error">Ingrese Puerto Destino</mat-error>
              </mat-form-field>
            </div>


            <div fxLayoutGap="20px" class="label">
              <!--CONTENEDOR-->
              <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
                <mat-label>Contenedor</mat-label>
                <mat-select formControlName="contenedor">
                  <mat-option *ngFor="let contenedor of listContenedor"
                    [value]="contenedor.codigo">{{contenedor.nombre}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="list" appearance="outline" class="example-full-width-alterno3">
                <input matInput type="number" placeholder="Cantidad" formControlName="cantidad" min="0">
                <mat-error class="custom-error">Ingrese cantidad valida</mat-error>
              </mat-form-field>
            </div>

            <div fxLayoutGap="30px" class="label" style="display: flex;justify-content: center;">

              <button [disabled]="!groupContenedor.valid" mat-raised-button class="buttonadd" matTooltip="Cargar Tarifa"
                type="search" (click)="cargaTarifa()">Buscar Tarifa
                <mat-icon>library_add</mat-icon>
              </button>

              <button mat-raised-button class="buttonadd" matTooltip="Cargar Tarifa" type="search"
                (click)="limpiartarifa()">Borrar
                <mat-icon>delete_outline</mat-icon>
              </button>

            </div>

            <div>
              <app-table [dataTable]="condicion ? dataTableEdita : dataTable" [metadataTable]="metadataTable">
                <ng-container matColumnDef="select">
                  <mat-header-cell *matHeaderCellDef>
                    <mat-checkbox #checkboxGeneral (change)="selectAllRows($event)"></mat-checkbox>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <mat-checkbox #checkboxRef (change)="selectRow(checkboxRef,row)" formControlName="estado"
                      [(ngModel)]="row.seleccionar"></mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
                  <mat-cell mat-cell *matCellDef="let row" class="cell">
                    <!--     <button mat-button type="button" (click)="openModal(datosProformaComponent, 'datosProforma')">
                          <mat-icon>face</mat-icon>
                        </button>-->
                    <button mat-button type="button" (click)='editarItem (row)'>
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-button type="button"
                      (click)="listarserviciolocales (0,'','','',row.entC_CodTransportista,true,row.pueR_CodigoOrigen,row.pueR_CodigoDestino,row.puertoorigen,row.puertodestino,row.pacK_Codigo,row.paquete,row.cantidad)">
                      <mat-icon>find_in_page</mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>
              </app-table>
            </div>

            <div class="tituloPantalla">
              <mat-toolbar style="height: 33px;margin-top: 10px;" fxLayout class="tituloPantalla">
                <h3 class="title">Servicio Locales</h3>
              </mat-toolbar>
            </div>

            <div class="formulario_nuevo">

              <!--Servicio locales-->
              <!-- Tarifa -->
              <div fxLayoutGap="20px" class="label">
                <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
                  <mat-label>Transportista</mat-label>
                  <mat-select (selectionChange)="listarServiciosLocalesCombo()" formControlName="transportistaSL">
                    <mat-option *ngFor="let reg of listLineas" [value]="reg.codigo">{{reg.nombre}}</mat-option>
                  </mat-select>
                </mat-form-field>

              </div>

              <div fxLayoutGap="20px" class="label">





                <mat-form-field class="list" appearance="outline" class="example-full-width-alterno3">
                  <mat-label>Id Tarifa</mat-label>
                  <mat-icon matSuffix (click)="limpiar('idtarifa','groupContenedor')">close</mat-icon>
                  <input matInput type="text" placeholder="Id Tarifa" formControlName="idtarifa">

                </mat-form-field>

                <!-- Almacen -->
                <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
                  <mat-label>Almacen</mat-label>
                  <mat-select formControlName="almacen">
                    <mat-option *ngFor="let reg of listAlmacen" [value]="reg.codigo">{{reg.nombre}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxLayoutGap="20px" class="label">
                <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
                  <mat-label>Terminal</mat-label>
                  <mat-select formControlName="terminal">
                    <mat-option *ngFor="let reg of listTerminal" [value]="reg.codigo">{{reg.nombre}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
                  <mat-label>Descripcion</mat-label>
                  <mat-select (click)="cargaLista('RGM')" formControlName="descripcion">
                    <mat-option *ngFor="let reg of listDescripcion" [value]="reg.codigo">{{reg.nombre}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>



              <div>
                <button mat-button type="button" class="buttonadd" (click)='filtrarsl ()'>
                  Buscar
                </button>
              </div>


              <!-- Servicio Locales Listado-->
              <app-table [dataTable]="dataTablesl" [metadataTable]="metadataTableSL">
                <ng-container matColumnDef="select">
                  <mat-header-cell *matHeaderCellDef>
                    <mat-checkbox #checkboxGeneralSL (change)="selectAllRows($event)"></mat-checkbox>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <mat-checkbox #checkboxrefsl formControlName="chksl" (change)="selectRowSL(checkboxrefsl,row)"
                      [(ngModel)]="row.selected"></mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
                  <mat-cell mat-cell *matCellDef="let row" class="cell">

                  </mat-cell>
                </ng-container>
              </app-table>
            </div>
            <mat-checkbox ng-model="checked" formControlName="gastoslocales" (change)="onCheckboxChange($event)">Agregar
              Gastos Locales</mat-checkbox>


            <!-- Gasto Locales Listado-->
            <app-table *ngIf="isgastoslocales" [dataTable]="dataTableGL" [metadataTable]="metadataTableGL">
              <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                  <mat-checkbox></mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <mat-checkbox forcontrolname="selectgasto" (change)="selectRowGL($event,row)"
                    [(ngModel)]="row.selected"></mat-checkbox>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let row" class="cell">

                </mat-cell>
              </ng-container>
            </app-table>
          </div>
        </form>
      </div>
    </div>


  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      Resumen
    </ng-template>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutAlign="center"
      style="margin-top: 0px; overflow: hidden;">
      <div fxFlex="100" style="padding: 0px;" class="general">
        <form [formGroup]="groupContenedor">

          <div fxLayoutGap="20px" class="label">


          </div>



          <div fxLayoutGap="20px" class="label">

          </div>
        </form>
        <app-table [dataTable]="dataTablePrincipal" [metadataTable]="metadataTablePrincipal">
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="selectAllRows($event)"></mat-checkbox>
            </mat-header-cell>

          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
            <mat-cell mat-cell *matCellDef="let row" class="cell">
              <button mat-button type="button"
                (click)="openModalDatosAdicionales(datosProformaComponent, 'datosProforma',row)">
                <mat-icon>face</mat-icon>
              </button>
              <button mat-button type="button" (click)='deletetarifa (row)'>
                <mat-icon>delete</mat-icon>

              </button>
              <!--   <button mat-button type="button"
                    (click) = 'editargeneral (row)'>
                    <mat-icon>edit</mat-icon>
                   
                    </button> -->
            </mat-cell>
          </ng-container>
        </app-table>


        <div class="tituloPantalla">
          <mat-toolbar style="height: 33px;margin-top: 10px;" fxLayout class="tituloPantalla">
            <h3 class="title">Servicio Locales</h3>
          </mat-toolbar>
        </div>

        <!-- Servicio Locales Listado-->
        <app-table [dataTable]="dataTablePrincipalSL" [metadataTable]="metadataTablePrincipalSL">
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="selectAllRows($event)"></mat-checkbox>
            </mat-header-cell>

          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
            <mat-cell mat-cell *matCellDef="let row" class="cell">
              <button mat-button type="button" (click)='deletetarifalocal (row)'>
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
        </app-table>

      </div>
    </div>


    <div class="tituloPantalla">
      <mat-toolbar style="height: 33px;margin-top: 10px;" fxLayout class="tituloPantalla">
        <h3 class="title">Gastos Locales</h3>
      </mat-toolbar>
    </div>

    <div fxLayout="column" *ngIf="isgastoslocales" fxLayout.xs="column" fxLayoutGap="20px">
      <div fxFlex="50" style="background-color: rgb(219, 217, 217); padding: 1px;" class="component data">
        <!--CONTAINER TABLE-->

        <mat-table matTableExporter [dataSource]="dataTablePrincipalGL" #exporter="matTableExporter" class="table">
          <!-- Recibe los datos -->
          <ng-container
            *ngFor="let metadata of metadataTablePrincipalGL;let isFirstCol = first; index as i;let columnIndex = index"
            [matColumnDef]="metadata.field" class="container" [sticky]="isFirstCol">

            <mat-header-cell mat-sort-header *matHeaderCellDef class="centro" class="header-cell"
              class="centro header-cell sticky-header">
              {{ metadata.title }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="metadata.field" class="fila" class="cell">
              <ng-container *ngIf="metadata.type == 'checkbox'">



              </ng-container>
              <ng-container *ngIf="metadata.type == 'text'">
                {{ row[metadata.field] }}

              </ng-container>

              <ng-container *ngIf="metadata.type == 'number'">

                <input matInput type="number" #inputValue [value]="row[metadata.field]" min='0'
                  (blur)="onInputBlur($event, row, metadata.field)">

              </ng-container>

            </mat-cell>


          </ng-container>


          <ng-container matColumnDef="actions">
            <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
            <mat-cell mat-cell *matCellDef="let row" class="cell">
              <button mat-button type="button" (click)='deletetarifalocal (row)'>
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>





          <mat-header-row *matHeaderRowDef="columnsToView" class="header-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columnsToView;" class="row"></mat-row>
        </mat-table>


      </div>

      <mat-paginator [pageSizeOptions]="[ 6,10,15,25,50,75,100]" [length]="5"
        (page)="onPageChange($event)"></mat-paginator>
    </div>


  </mat-tab>

  <!--------------

  -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->

</mat-tab-group>


<footer>
  <div class="contenido-pie">
    <p></p>
  </div>
</footer>

<style>
  ::ng-deep p-dropdown.readonly .p-dropdown-trigger,
  ::ng-deep p-dropdown.readonly .p-dropdown-label {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
    background-color: #f0f0f0;
  }

  input {
    width: 100%;
    /* height: 47px; */
  }

  ::ng-deep .p-inputtext{
    width: 100%;
  }
  ::ng-deep p-inputtext{
    width: 100%;
  }

  :host ::ng-deep .p-dropdown {
    width: 100%;
    height: 100%;
    /* height: 47px; */
  }

  .a-example-full-width-alterno-content-3 {
    margin: 2vh 0vh;
  }

  :host ::ng-deep .p-calendar {
    width: 100%;
    /* height: 47px; */
  }

  :host ::ng-deep .p-inputnumber {
    width: 100%;
  }

  :host ::ng-deep p-inputnumber,
  .p-inputnumber {
    width: 100%;
  }

  :host ::ng-deep .p-inputtext-sm .p-inputtext {
    width: 100;
  }

  .inputwidth{
    width: 85% !important;
  }

  ::ng-deep .p-float-label input:focus~label,
  .p-float-label input.p-filled~label,
  .p-float-label textarea:focus~label,
  .p-float-label textarea.p-filled~label,
  .p-float-label .p-inputwrapper-focus~label,
  .p-float-label .p-inputwrapper-filled~label {
    top: -.75rem;
    font-size: 10px;
    background-color: white;
    padding: 0 4px;
  }

  ::ng-deep .p-autocomplete{
    width: 100%;
  }

  ::ng-deep .p-inputtext{
    width: 100%;
  }

  ::ng-deep .p-float-label {
    display: inline;
  }

  p-checkbox {
    padding-left: 15px;
  }

  ::ng-deep .p-autoComplete {
    width: 100%;
  }

  ::ng-deep p-autoComplete {
    width: 100%;
  }

  @media (max-width: 1050px) {
    p-checkbox {
      padding-left: 10px;
    }

    .a-example-full-width-alterno-content-3 {
      margin: 2vh 0vh;
    }

    .a-example-full-width-alterno-content-row {
      gap: 0px;
    }
  }
</style>